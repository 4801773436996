import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch } from "react-redux";
import { addToCart } from "../helper/CartHelper";
import {
    get_min_product_price,
    get_optimized_image_url
} from "../helper/Helper";
import ImagePlaceholder from "./ImagePlaceholder";

const ProductBlock = (props) => {
    const item = props.item;
    const dispatch = useDispatch();

    if (!item) {
        return <>Couldn't load product</>;
    }

    return (
        <div className={props.className ?? "col-md-6 col-lg-4 col-xl-3 g-mb-70"}>
            {/* <!-- Product --> */}
            <figure className="g-px-10 product-block-wrapper">
                <div className="g-pos-rel g-mb-20">
                    {/* <img className="img-fluid" src="/assets/removable_images/product1.png" alt="Description"/> */}
                    <Link to={"/products/" + item.slug} className="g-pos-rel">
                        <LazyLoadImage
                            placeholderSrc="/assets/images/placeholder_images/plceholder_product.png"
                            effect="blur"
                            alt={item.name}
                            src={get_optimized_image_url(item.main_image)} // use normal <img> attributes as props
                            style={{ width: "100%  ", height: "auto" }}
                        />
                    </Link>
                    {/* <span className="product-block-tag position-absolute g-top-0 text-uppercase">$4 <br />save</span> */}
                </div>

                <div className="media">
                    {/* <!-- Product Info --> */}
                    <div className="col-12">
                        <h4 className="h4 g-color-black mb-2 w-100">
                            <Link
                                className="u-link-v5 g-color-black g-color-primary--hover"
                                to={"/products/" + item.slug}
                            >
                                {item.name}
                            </Link>
                        </h4>
                        {/* { props.showflavour && 
							<h4 className="h4 g-color-black mb-2 w-100">
									{item.flavor}
							</h4>
						} */}

                        {/* <Link className="d-inline-block g-color-gray-dark-v5 g-font-size-13" to="#">{item.category.name}</Link> */}
                        <span className="d-block g-color-black g-font-size-17 g-brd-y g-brd-secondary-light-v1 g-py-10">
                            {item.variant_prices_list.length > 0 ? (
                                <>
                                    From $
                                    {get_min_product_price(
                                        item.our_price,
                                        item.variant_prices_list
                                    )}
                                </>
                            ) : (
                                "$" + item.our_price
                            )}
                        </span>
                    </div>
                </div>
                <div className="col-12">
                    <button
                        className="btn btn-block u-btn-primary g-font-size-14 text-uppercase atbg_btn  g-py-6 g-px-15 g-mt-20 "
                        type="button"
                        onClick={(e) =>
                            addToCart(item.id, 1, dispatch, {}, false, false)
                        }
                    >
                        ADD TO CART
                    </button>
                </div>
            </figure>
            {/* <!-- End Product --> */}
        </div>
    );
};

export default ProductBlock;
