import Slider from "react-slick";
import ContainerWithGap from "./container/ContainerWithGap";
import FullWidthImageBanner from "./FullWidthImageBannner";
import { NextArrow, PrevArrow } from "./slider/CustomArrow";
import SectionHeaderCenter from "./section_header/SectionHeaderCenter";
import { Link } from "react-router-dom";
import Animate from "./Animate";

// Banner Slider
const BannerSliderRev = (props) => {
    const { category, blogCategory, recipes, gallery } = props;
    const settings = {
        className: "grower_slider",
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoPlay: true,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 567,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <Animate>
                {category && !gallery && (
                    <ContainerWithGap>
                        <div className="homepage-recipe-slider g-mb-50">
                            <SectionHeaderCenter header_title="our growers. your plates."></SectionHeaderCenter>
                            <h3 className="text-uppercase text-center g-mb-30  g-text-color"></h3>
                            <Slider {...settings}>
                                {category.map((item) => (
                                    <div className="px-3">
                                        <div className="homesilder-image-wrapper position-relative">
                                            <Link
                                                to={`/recipe-category/${item.slug}`}
                                            >
                                                <img
                                                    src={item.image}
                                                    className="w-100"
                                                    alt=""
                                                />
                                                <div className="homesilder-image-text position-absolute g-bottom-0 g-color-white g-left-15">
                                                    <h4 className="g-color-white text-capitalize">
                                                        {item.name}
                                                    </h4>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </ContainerWithGap>
                )}
                {gallery && category && (
                    <ContainerWithGap>
                        <div className="homepage-recipe-slider g-mb-50">
                            <SectionHeaderCenter header_title="our growers. your plates."></SectionHeaderCenter>
                            <h3 className="text-uppercase text-center g-mb-30  g-text-color"></h3>
                            <Slider {...settings}>
                                {category.map((item) => (
                                    <div className="px-3">
                                        <div className="homesilder-image-wrapper position-relative">
                                            <Link
                                                to={`/recipe-gallery/${item.slug}`}
                                            >
                                                <img
                                                    src={item.image}
                                                    className="w-100"
                                                    alt=""
                                                />
                                                <div className="homesilder-image-text position-absolute g-bottom-0 g-color-white g-left-15">
                                                    <h4 className="g-color-white text-capitalize">
                                                        {item.name}
                                                    </h4>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </ContainerWithGap>
                )}
                {blogCategory && (
                    <ContainerWithGap>
                        <div className="homepage-recipe-slider g-mb-50">
                            <SectionHeaderCenter header_title="our growers. your plates."></SectionHeaderCenter>
                            <h3 className="text-uppercase text-center g-mb-30  g-text-color"></h3>
                            <Slider {...settings}>
                                {blogCategory.map((item) => (
                                    <div className="px-3">
                                        <div className="homesilder-image-wrapper position-relative">
                                            <Link
                                                to={`/blog-category/${item.slug}`}
                                            >
                                                <img
                                                    src={item.image}
                                                    className="w-100"
                                                    alt=""
                                                />
                                                <div className="homesilder-image-text position-absolute g-bottom-0 g-color-white g-left-15">
                                                    <h4 className="g-color-white text-capitalize">
                                                        {item.name}
                                                    </h4>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </ContainerWithGap>
                )}
                {recipes && (
                    <ContainerWithGap>
                        <div className="homepage-recipe-slider g-mb-50">
                            <SectionHeaderCenter header_title="our growers. your plates."></SectionHeaderCenter>
                            <h3 className="text-uppercase text-center g-mb-30  g-text-color"></h3>
                            <Slider {...settings}>
                                {recipes.map((item) => (
                                    <div className="px-3">
                                        <div className="homesilder-image-wrapper position-relative">
                                            <img
                                                src={item.photo}
                                                className="w-100"
                                                alt=""
                                            />
                                            <div className="homesilder-image-text position-absolute g-bottom-0 g-color-white g-left-15">
                                                <h4 className="g-color-white text-capitalize">
                                                    {item.title}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </ContainerWithGap>
                )}
            </Animate>
        </>
    );
};

export default BannerSliderRev;
