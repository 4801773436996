import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BlogBlock from "../components/BlogBlock";
import ContainerWithGap from "../components/container/ContainerWithGap";
import Loading from "../components/Loading";
import HeadingCenter from "../components/page_headings/HeadingCenter";
import ReactPaginate from "react-paginate";
import { Dropdown } from "react-bootstrap";

const BlogPage = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [offset, setOffset] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [error, setError] = useState("");
    const [blogCategories, setBlogCategories] = useState();
    const [page, setPage] = useState(1);
    const [blogCategorySlug, setBlogCAtegorySlug] = useState("");
    const [activeCategory, setActiveCategory] = useState("All");
    const [selectedCategorySlug, setSelectedCategorySlug] = useState("");
    const { type } = props;

    useEffect(() => {
        const url = process.env.REACT_APP_API_BASE_URL + `/blog-category/`;

        fetch(url)
            .then((response) => response.json())
            .then((result) => {
                setBlogCategories(result.results);
            })
            .catch((error) => { });
    }, []);

    useEffect(() => {
        // this url with change based on pagination and sorting option
        var url;
        if (type) {
            url =
                process.env.REACT_APP_API_BASE_URL +
                `/blog?expand=type,category&type__slug=${type}&spage_size=12&page=${offset}&category__slug=${selectedCategorySlug}`;
        } else {
            url =
                process.env.REACT_APP_API_BASE_URL +
                `/blog?page_size=12&page=${offset}&category__slug=${selectedCategorySlug}`;
        }

        fetch(url)
            .then((response) => response.json())
            .then((result) => {
                setData(result);
                setTotalPages(result.total_pages);
                setIsLoading(false);
            })
            .catch((error) => { });
    }, [offset, type, selectedCategorySlug]);

    const handlePageClick = (event) => {
        const newOffset = event.selected + 1;
        setOffset(newOffset);
    };

    if (isLoading) {
        return <Loading />;
    }

    const handleCategoryClick = (name, slug) => {
        setActiveCategory(name);
        setSelectedCategorySlug(slug);
        setIsLoading(true);
    };

    return (
        <>
            {!type && (
                <HeadingCenter
                    title="Blogs"
                    background="/assets/images/testimonail_background.png"
                />
            )}
            <ContainerWithGap>
                <div className="row">
                    <div className="col-lg-2">
                        <div className="g-pt-40 recipe-menu-category">
                            <h2 className="h6 border-bottom pb-4">
                                Categories
                            </h2>

                            <>
                                <ul className="m-0 p-0 text-light g-mt-30">
                                    <li
                                        className="g-mb-10 g-font-weight-500"
                                        style={{
                                            cursor: "pointer",

                                            color: `${activeCategory === "All"
                                                ? "green"
                                                : "black"
                                                }`
                                        }}
                                        onClick={() =>
                                            handleCategoryClick("All", "")
                                        }
                                    >
                                        All
                                    </li>

                                    {blogCategories &&
                                        blogCategories.map((categoryItems) => {
                                            return (
                                                <li
                                                    key={categoryItems.id}
                                                    className="g-mb-10 g-font-weight-500"
                                                    style={{
                                                        cursor: "pointer",
                                                        color: `${activeCategory ===
                                                            categoryItems.name
                                                            ? "green"
                                                            : "black"
                                                            }`
                                                    }}
                                                    onClick={() =>
                                                        handleCategoryClick(
                                                            categoryItems.name,
                                                            categoryItems.slug
                                                        )
                                                    }
                                                >
                                                    {categoryItems.name}
                                                </li>
                                            );
                                        })}
                                </ul>
                            </>
                        </div>
                        <div className="d-lg-none d-flex justify-content-end">
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    Categories
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <ul className="recipe-dropdown-category g-pa-20 text-light g-mt-30">
                                        <li
                                            className="g-mb-10 g-font-weight-500"
                                            style={{
                                                cursor: "pointer",

                                                color: `${activeCategory === "All"
                                                    ? "green"
                                                    : "black"
                                                    }`
                                            }}
                                            onClick={() =>
                                                handleCategoryClick("All", "")
                                            }
                                        >
                                            All
                                        </li>

                                        {blogCategories &&
                                            blogCategories.map((categoryItems) => {
                                                return (
                                                    <li
                                                        key={categoryItems.id}
                                                        className="g-mb-10 g-font-weight-500"
                                                        style={{
                                                            cursor: "pointer",
                                                            color: `${activeCategory ===
                                                                categoryItems.name
                                                                ? "green"
                                                                : "black"
                                                                }`
                                                        }}
                                                        onClick={() =>
                                                            handleCategoryClick(
                                                                categoryItems.name,
                                                                categoryItems.slug
                                                            )
                                                        }
                                                    >
                                                        {categoryItems.name}
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>

                    <div className="col-lg-10">
                        <div className="g-pt-100 g-pb-70">
                            <div className="masonry-grid row">
                                {data.results.length > 0 ? (
                                    data.results.map((item, index) => (
                                        <BlogBlock item={item} />
                                    ))
                                ) : (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flex: "1"
                                        }}
                                    >
                                        <h2>No Blogs Found</h2>
                                    </div>
                                )}
                            </div>
                            {totalPages > 1 && (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        width: "100%",
                                        marginBottom: "25px",
                                        marginTop: "25px"
                                    }}
                                >
                                    <ReactPaginate
                                        nextLabel="Next"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                        pageCount={totalPages}
                                        previousLabel="Previous"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        renderOnZeroPageCount={null}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </ContainerWithGap>
        </>
    );
};

export default BlogPage;
