import CheckoutSidebar from "../../../components/cart_components/CheckoutSidebar";
import Container from "../../../components/container/Container";
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { useState } from "react";
import { useSelector } from "react-redux";


const Payment = (props) => {

	const stripe = useStripe();
	const elements = useElements();

	const [errorMesssage, setErrorMessage] = useState();
	const cart = useSelector(state => state.cart);
	const billing_add = cart.billing_address??{};
	const shipping_add = cart.shipping_address??{};

	const handlePaymentSubmit = async (event) => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();

		if (!stripe || !elements) {
		// Stripe.js has not yet loaded.
		// Make sure to disable form submission until Stripe.js has loaded.
		return;
		}

		const result = await stripe.confirmPayment({
			//`Elements` instance that was used to create the Payment Element
			elements,
			confirmParams: {
				return_url: `${process.env.REACT_APP_BASE_URL}/order-complete`,
			},
		});

		if (result.error) {
			// Show error to your customer (for example, payment details incomplete)
			setErrorMessage(result.error.message);
		} else {
			
			// Your customer will be redirected to your `return_url`. For some payment
			// methods like iDEAL, your customer will be redirected to an intermediate
			// site first to authorize the payment, then redirected to the `return_url`.
		}
	};
	

	return (
		<Container>
			<div className="row">
				<div className="col-md-8 g-mb-30">

					{/* Billing And Shiping Address Reivew  */}
					<div className="address_review">
						<div className="row">
							<div className="col-md-6">
								<div className="add_wrapper border py-2 px-3">
									<strong>Billing Address</strong>
									<hr className="my-1"/>
									<p className="text-secondary"></p>
									<p className="text-secondary">{billing_add.first_name} {billing_add.last_name}</p>
									{billing_add.email?<p className="text-secondary">{billing_add.email}</p>:''}
									{billing_add.phone?<p className="text-secondary">{billing_add.phone}</p>:''}
									{billing_add.street?<p className="text-secondary">{billing_add.street}</p>:''}
									{billing_add.confirmPayment?<p className="text-secondary">{billing_add.city}</p>:''}
									{billing_add.state?<p className="text-secondary">{billing_add.state}, {billing_add.zip_code} {billing_add.country}</p>:''}
								</div>
							</div>
							<div className="col-md-6">
								<div className="add_wrapper border py-2 px-3">
									<strong>Shipping Address</strong>
									<hr className="my-1"/>
									<p className="text-secondary"></p>
									<p className="text-secondary">{shipping_add.first_name} {shipping_add.last_name}</p>
									{shipping_add.email?<p className="text-secondary">{shipping_add.email}</p>:''}
									{shipping_add.phone?<p className="text-secondary">{shipping_add.phone}</p>:''}
									{shipping_add.street?<p className="text-secondary">{shipping_add.street}</p>:''}
									{shipping_add.confirmPayment?<p className="text-secondary">{shipping_add.city}</p>:''}
									{shipping_add.state?<p className="text-secondary">{shipping_add.state}, {shipping_add.zip_code} {shipping_add.country}</p>:''}
								</div>
								
							</div>
						</div>
					</div>

					{/* <!-- Payment Methods --> */}
					<ul className="list-unstyled mb-4">
						<li className="g-brd-bottom g-brd-gray-light-v3 pb-2 mt-3">
							<label className="form-check-inline u-check d-block u-link-v5 g-color-gray-dark-v4 g-color-primary--hover g-pl-0">
								Pay with
								<img className="g-width-70 ml-2" src="/assets/images/stripe-logo.png" alt=" Description"/>
							</label>
						</li>
					</ul>
					{/* <!-- End Payment Methods --> */}
					{
						(stripe)?
						<form onSubmit={handlePaymentSubmit} className="bg-light p-4">
							{errorMesssage?
								<div className="alert alert-danger">{errorMesssage}</div>
								:''
							}
							<PaymentElement />
							{/* <button  className="btn btn-primary mt-3">Continue</button> */}
							<div className="g-mt-30">
								<div className="text-right">
								<button className="btn u-btn-primary g-font-size-13 text-uppercase g-px-40 g-py-15" type="submit" disabled={!stripe}>Confirm & Make Payment</button>
								</div>
							</div>
						</form>
						:
						<>Loading...</>
					}
				

				</div>

				<CheckoutSidebar {...props} review={true} />
			</div>
		</Container>
	);

}

export default Payment;