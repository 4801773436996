import { Link } from "react-router-dom";

const HeaderWholesale = (props) => {
    return (
        <div className="header-wholesale">
            <Link to="/wholesale" className="g-font-size-18 g-color-white" >
                <img className="img-fluid g-width-30 g-height-30" src="/assets/images/header/wholesale.svg" alt="wholesale link" />
            </Link>
        </div>
    );
}

export default HeaderWholesale;