import { useEffect, useState } from "react";
import HtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import {
    setBillingAddress,
    setShippingAddress as setShippingAddressRedux
} from "../../helper/CartHelper";
import countryStates from "../../helper/country_state/country_state.json";

const AddressForm = (props) => {
    //type of address form : either "billing" or "shipping" should be value for now
    const type = props.type;

    const cart = useSelector((state) => state.cart);
    const dispatch = useDispatch();
    let add =
        type === "shipping" ? cart.shipping_address : cart.billing_address; //shipping or billing address
    const is_billing_shipping_add_same = cart.is_billing_shipping_add_same;

	const [selectedCountry, setSelectedCountry] = useState(!!add?.country ? add.country : '');
	const [states, setStates] = useState( {} )

    useEffect(() => {
        if (!!selectedCountry) {
            setStates(countryStates[selectedCountry]);
        }
    }, [selectedCountry]);

    add = add === null ? {} : add;

    const [address, setAddress] = useState({
        first_name: add.first_name ?? null,
        last_name: add.last_name ?? null,
        email: add.email ?? null,
        country: add.country ?? null,
        phone: add.phone ?? null,
        state: add.state ?? null,
        city: add.city ?? null,
        street: add.street ?? null,
        zip_code: add.zip_code ?? null
    });

    const setAddressVal = (value) => {
        const new_address = { ...address, ...value };
        setAddress(new_address);

        if (type === "shipping") {
            setShippingAddressRedux(new_address, { ...cart }, dispatch);
        }
        if (type === "billing") {
            // setBilling address to redux
            setBillingAddress(new_address, { ...cart }, dispatch);
        }
    };

    const changeCountryHandler = (value) => {
        setSelectedCountry(value);
        setAddressVal({
            country: value,
            state: ""
        });
    };


    return (
        <>
            <div className="row">
                <div className="col-sm-6 g-mb-5">
                    <div className="form-group">
                        <label className="d-block g-color-gray-dark-v2 g-font-size-13">
                            First Name
                        </label>
                        <input
                            id="inputGroup4"
                            className="form-control u-form-control g-placeholder-gray-light-v1 rounded-0 g-py-10"
                            name="firstName"
                            type="text"
                            placeholder="Alexander"
                            required
                            data-msg="This field is mandatory"
                            data-error-className="u-has-error-v1"
                            data-success-className="u-has-success-v1"
                            defaultValue={add.first_name ?? ""}
                            onChange={(e) =>
                                setAddressVal({ first_name: e.target.value })
                            }
                        />
                    </div>
                </div>

                <div className="col-sm-6 g-mb-5">
                    <div className="form-group">
                        <label className="d-block g-color-gray-dark-v2 g-font-size-13">
                            Last Name
                        </label>
                        <input
                            id="inputGroup5"
                            className="form-control u-form-control g-placeholder-gray-light-v1 rounded-0 g-py-10"
                            name="lastName"
                            type="text"
                            placeholder="Teseira"
                            required
                            data-msg="This field is mandatory"
                            data-error-className="u-has-error-v1"
                            data-success-className="u-has-success-v1"
                            defaultValue={add.last_name ?? ""}
                            onChange={(e) =>
                                setAddressVal({ last_name: e.target.value })
                            }
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-6 g-mb-5">
                    <div className="form-group">
                        <label className="d-block g-color-gray-dark-v2 g-font-size-13">
                            Email Address
                        </label>
                        <input
                            id="inputGroup6"
                            className="form-control u-form-control g-placeholder-gray-light-v1 rounded-0 g-py-10"
                            name="email"
                            type="email"
                            placeholder="alex@gmail.com"
                            required
                            data-msg="This field is mandatory"
                            data-error-className="u-has-error-v1"
                            data-success-className="u-has-success-v1"
                            defaultValue={add.email ?? ""}
                            onChange={(e) =>
                                setAddressVal({ email: e.target.value })
                            }
                        />
                    </div>
                </div>

                <div className="col-sm-6 g-mb-5">
                    <div className="form-group">
                        <label className="d-block g-color-gray-dark-v2 g-font-size-13">
                            Phone Number
                        </label>
                        <input
                            id="inputGroup10"
                            className="form-control u-form-control g-placeholder-gray-light-v1 rounded-0 g-py-10"
                            name="phoneNumber"
                            type="text"
                            placeholder="+01 (00) 555 666 77"
                            required
                            data-msg="This field is mandatory"
                            data-error-className="u-has-error-v1"
                            data-success-className="u-has-success-v1"
                            onChange={(e) =>
                                setAddressVal({ phone: e.target.value })
                            }
                            defaultValue={add.phone ?? ""}
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-6 g-mb-5">
                    <div className="form-group">
                        <label className="d-block g-color-gray-dark-v2 g-font-size-13">
                            Country
                        </label>
                        <select
                            className="js-custom-select u-select-v1 g-brd-gray-light-v2 g-color-black g-py-12"
                            name="country"
                            style={{ width: "100%" }}
                            data-placeholder="Choose your Country"
                            data-open-icon="fa fa-angle-down"
                            data-close-icon="fa fa-angle-up"
                            required
                            data-msg="This field is mandatory"
                            data-error-className="u-has-error-v1"
                            data-success-className="u-has-success-v1"
                            onChange={(e) =>
                                changeCountryHandler(e.target.value)
                            }
                            defaultValue={add.country ?? ""}
                        >
                            <option value="" disabled>
                                Select Country
                            </option>
                            <option value="CA">Canada</option>
                            <option value="US">United States</option>
                            <option value="UM">
                                United States Minor Outlying Islands
                            </option>
                        </select>
                    </div>
                </div>

                <div className="col-sm-6 g-mb-5">
                    <div className="form-group">
                        <label className="d-block g-color-gray-dark-v2 g-font-size-13">
                            State
                        </label>
                        <select
                            className="js-custom-select u-select-v1 g-brd-gray-light-v2 g-color-black g-py-12"
                            name="state"
                            style={{ width: "100%" }}
                            data-placeholder="Select State"
                            data-open-icon="fa fa-angle-down"
                            data-close-icon="fa fa-angle-up"
                            required
                            data-msg="This field is mandatory"
                            data-error-className="u-has-error-v1"
                            data-success-className="u-has-success-v1"
                            onChange={(e) =>
                                setAddressVal({ state: e.target.value })
                            }
                            value={add.state ?? ""}
                        >
                            <option value="">Select State</option>
                            {Object.keys(states).map((state) => (
                                <option value={state}>{states[state]}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-4 g-mb-5">
                    <div className="form-group">
                        <label className="d-block g-color-gray-dark-v2 g-font-size-13">
                            City
                        </label>
                        <input
                            id="inputGroup9"
                            className="form-control u-form-control g-placeholder-gray-light-v1 rounded-0 g-py-10"
                            name="city"
                            type="text"
                            placeholder="City"
                            required
                            data-msg="This field is mandatory"
                            data-error-className="u-has-error-v1"
                            data-success-className="u-has-success-v1"
                            defaultValue={add.city ?? ""}
                            onChange={(e) =>
                                setAddressVal({ city: e.target.value })
                            }
                        />
                    </div>
                </div>

                <div className="col-sm-4 g-mb-5">
                    <div className="form-group">
                        <label className="d-block g-color-gray-dark-v2 g-font-size-13">
                            Street Address
                        </label>
                        <input
                            id="inputGroup10"
                            className="form-control u-form-control g-placeholder-gray-light-v1 rounded-0 g-py-10"
                            name="street_address"
                            type="text"
                            placeholder="street address"
                            required
                            data-msg="This field is mandatory"
                            data-error-className="u-has-error-v1"
                            data-success-className="u-has-success-v1"
                            onChange={(e) =>
                                setAddressVal({ street: e.target.value })
                            }
                            defaultValue={add.street ?? ""}
                        />
                    </div>
                </div>

                <div className="col-sm-4 g-mb-5">
                    <div className="form-group">
                        <label className="d-block g-color-gray-dark-v2 g-font-size-13">
                            Zip/Postal Code
                        </label>
                        <input
                            id="inputGroup9"
                            className="form-control u-form-control g-placeholder-gray-light-v1 rounded-0 g-py-10"
                            name="zip"
                            type="text"
                            placeholder="AB123"
                            required
                            data-msg="This field is mandatory"
                            data-error-className="u-has-error-v1"
                            data-success-className="u-has-success-v1"
                            defaultValue={add.zip_code ?? ""}
                            onChange={(e) =>
                                setAddressVal({ zip_code: e.target.value })
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
export default AddressForm;
