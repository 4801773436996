import ContainerWithGap from "../components/container/ContainerWithGap";
import HomePageSlider from "../components/HomePageSlider";
import ProductRecipe from "../components/product_detail/ProductRecipe";
import SectionHeaderCenter from "../components/section_header/SectionHeaderCenter";

const GeneralRepoPage = (props) => {

    return (
        <>
            <ContainerWithGap>
                <div className="breadcrumbs">
                    <section className="g-brd-top g-py-50">
                        <div className=" g-bg-cover__inner">
                            <ul className="u-list-inline">
                                <li className="list-inline-item g-mr-7">
                                    <a className="u-link-v5 g-color-main" href="#">Home</a>
                                    <i className="g-color-gray-light-v2 g-ml-5">/</i>
                                </li>
                                <li className="list-inline-item g-mr-7">
                                    <a className="u-link-v5 g-color-main" href="#">Pages</a>
                                    <i className="g-color-gray-light-v2 g-ml-5">/</i>
                                </li>
                                <li className="list-inline-item g-color-primary">
                                    <span>About Us</span>
                                </li>
                            </ul>
                        </div>
                    </section>
                </div>

                <section className="featured_recipe g-brd-primary">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <img class="img-fluid" src="/assets/removable_images/recipe.jpg" alt="description" />
                        </div>
                        <div className="col-lg-6">
                            <span className="text-uppercase">Featured</span>
                            <h1>Egg with chilli sauce</h1>
                            <button className="btn btn-block u-btn-primary g-font-size-14 text-uppercase atbg_btn  g-py-10 g-px-15 g-mt-20 " type="button">
                                ADD TO CART
                            </button>
                        </div>
                    </div>
                </section>

                <HomePageSlider />

                <section className="recipe_listing">
                    <SectionHeaderCenter header_title="More Recipes"></SectionHeaderCenter>
                    <ProductRecipe />
                </section>
            </ContainerWithGap>
        </>
    )
}

export default GeneralRepoPage;