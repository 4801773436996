import React, { useState, useCallback, useEffect } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import ContainerWithGap from "../components/container/ContainerWithGap";
import AboutSlider from "../components/AboutSlider";
import { get_short_date_format } from "../helper/Helper";



const AboutPage = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [timeline, setTimeline] = useState();
    const [photos, setPhotos] = useState([]);
    const [galleryPhoto, setGalleryPhoto] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    useEffect(() => {
        let user_image_url = process.env.REACT_APP_API_BASE_URL + `/gallery/`;
        fetch(user_image_url)
            .then(response => response.json())
            .then(result => {
                setPhotos(result.results);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
            })
    }, []);

    useEffect(() => {
        let timeline_url = process.env.REACT_APP_API_BASE_URL + `/timeline/?ordering=-start_date`;
        fetch(timeline_url)
            .then(response => response.json())
            .then(result => {
                setTimeline(result.results);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
            })
    }, []);

    useEffect(() => {
        const newPhoto = photos.map(item => {
            return {
                ...item,
                src: item.image,
                width: Math.floor(Math.random() * 3) + 1,
                height: Math.floor(Math.random() * 2) + 1
            }
        });
        setGalleryPhoto(newPhoto);
    }, [photos]);

    return (
        <>
            <section className="about-slider">
                <AboutSlider></AboutSlider>
            </section>
            <ContainerWithGap>
                <div className="row g-mb-100">
                    <div className="col-lg-6">
                        <img src="/assets/removable_images/tomato.jpg" alt="about image" className="w-100" />
                    </div>
                    <div className="col-lg-6 d-flex align-items-center">
                        <div className="about-media">
                            <h1 className="g-mb-25 g-line-height-1_2 g-font-weight-700 g-font-size-28 g-font-size-40--md">Who is Homni?</h1>
                            <p className="">Homni, a name coined by science writer Gaia Vance, argues that our individual well-being is inseparable from the collective human experience.</p>
                            <p className="">Ninety percent of what Homni is revolves around a belief that humanity rises or falls together. The other ten percent is tied to beauty. We believe a diverse world is a beautiful world, and beauty, in its most profound sense, is why life is worth living.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 d-flex align-items-center">
                        <div className="about-media g-mr-30">
                            <h1 className="g-mb-25 g-line-height-1_2 g-font-weight-700 g-font-size-28 g-font-size-40--md">But who are the founders?</h1>
                            <p className="">But who are the founders?
                                We prefer the term leadership to founder. The person that wrote “Homni” on a legal document is no more responsible for the project than the folks who inspired and guided them. As for leadership, you’ve got two Nepali’s and one American. One has revolutionized disaster management in Nepal and has established a successful organization targeting youth employment, political participation, and environmental protection. Another is a passionate and brilliant young activist who launched a campaign to remove all single-use plastics from Nepal. The final, a child of two addicts who grew up in the rural south, graduated from a top university with a degree in international economics, and has committed themselves to helping folks from the bottom of the socio-economic ladder see just how incredible they are.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <img src="/assets/removable_images/tomato.jpg" alt="about image" className="w-100" />
                    </div>
                </div>
            </ContainerWithGap>
            <ContainerWithGap>
                <div className="about-photo-gallery g-mb-100">
                    <div className="g-mb-50">
                        <div
                            className="u-heading-v5-3 u-heading-v5-color-primary u-heading-v5-rounded-50x g-mb-20">
                            <h4 className="g-font-weight-700 g-font-size-12 g-mb-15">Why should we do this?</h4>
                            <h2
                                className="g-line-height-1_2 g-font-weight-700 g-font-size-28 g-font-size-40--md  g-bg-white--before">
                                It’s better. It’s beautiful.
                            </h2>
                        </div>
                    </div>
                    <Gallery photos={galleryPhoto} onClick={openLightbox} />
                    <ModalGateway>
                        {viewerIsOpen ? (
                            <Modal onClose={closeLightbox}>
                                <Carousel
                                    currentIndex={currentImage}
                                    views={galleryPhoto.map(x => ({
                                        ...x,
                                        src: x.src,
                                    }))}
                                />
                            </Modal>

                        ) : null}
                    </ModalGateway>
                </div>
                <div className="photo-media">
                    <h2
                        className="g-mb-25 g-line-height-1_2 g-font-weight-700 g-font-size-28 g-font-size-40--md  g-bg-white--before">
                        Why Nepal?
                    </h2>
                    <div className="photo-media-paragraph d-flex">
                        <p className="g-mr-20">
                            We are building Homni to be reproducible throughout the world. Wherever there are small farmers, traditional crops, and threatened environments we hope eventually to operate. For now, fate, opportunities, obstacles, leadership, and as always, beauty, led us to launch our project in Nepal.
                        </p>
                    </div>
                </div>
            </ContainerWithGap>
            <ContainerWithGap>
                <div className="u-heading-v5-3 u-heading-v5-color-primary u-heading-v5-rounded-50x g-mb-20">
                    <h2
                        className="g-max-width-550 g-line-height-1_3 g-font-weight-700 g-font-size-28 g-font-size-40--md  g-bg-white--before">
                        How are we going to accomplish this?
                    </h2>
                </div>
                <div className="row">
                    <div className="col-md-4 d-flex align-items-center">
                        <div className="g-mb-60">
                            <div className=" u-heading-v5-3 u-heading-v5-color-primary u-heading-v5-rounded-50x g-mb-20">
                                <p >We need to help produce environmentally, socially, and financially sustainable goods that folks who care nothing about sustainability will pay for. We need to produce goods that people buy not out of the goodness of their hearts, but out of a desire to experience and share the best. That is how we will force our traditional producers to become sustainable. </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <img src="/assets/images/about-page.svg" alt="about image" className="w-100 position-relative" style={{ top: "-80px" }} />
                    </div>
                    <div className="col-md-4 d-flex align-items-center">
                        <div className="">
                            <div className="about-illustration-text position-relative">
                                <p className="g-ml-60 g-pl-25 g-font-size-22">Quality</p>
                            </div>
                            <div className="about-illustration-text position-relative">
                                <p className="g-ml-60 g-pl-25 g-font-size-22">sustainability</p>
                            </div>
                            <div className="about-illustration-text position-relative">
                                <p className="g-ml-60 g-pl-25 g-font-size-22">Environment</p>
                            </div>
                            <div className="about-illustration-text position-relative">
                                <p className="g-ml-60 g-pl-25 g-font-size-22">Society</p>
                            </div>
                            <div className="about-illustration-text position-relative">
                                <p className="g-ml-60 g-pl-25 g-font-size-22">Financials</p>
                            </div>
                        </div>
                    </div>
                </div>
            </ContainerWithGap>
            <div className="container-fluid g-bg-secondary g-pt-100">
                <div className="container g-max-width-780 text-center g-mb-60">
                    <div
                        className="text-center text-uppercase u-heading-v5-3 u-heading-v5-color-primary u-heading-v5-rounded-50x g-mb-20">
                        <h2
                            className=" g-line-height-1_2 g-font-weight-700 g-font-size-32 g-font-size-40--md g-bg-white--before g-pb-25">
                            When</h2>
                        <p className="g-color-primary">We are not perfect. There will be challenges during this process and we hope you can forgive us!</p>
                    </div>
                </div>
                {
                    timeline &&
                    <VerticalTimeline>
                        {timeline.map(item =>
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                // contentStyle={{ background: 'rgb(114 192 44)', color: '#fff' }}
                                contentArrowStyle={{ borderRight: '7px solid  rgb(114 192 44)' }}
                                date={`${get_short_date_format(item.start_date)} - ${get_short_date_format(item.end_date) || 'Present'}`}
                                iconStyle={{ background: 'rgb(114 192 44)', color: '#fff' }}
                                icon={<img src={item.icon} alt="icon" className="timeline-icon" />}
                            >
                                <h3 className="vertical-timeline-element-title">{item.title}</h3>
                                <p>
                                    {item.description}
                                </p>
                            </VerticalTimelineElement>
                        )}

                        <VerticalTimelineElement
                            iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                            icon={<i class="fa fa-pagelines" aria-hidden="true"></i>}
                        />
                    </VerticalTimeline>
                }
            </div>

        </>
    );

}

export default AboutPage;