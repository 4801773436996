import React, { useEffect, useState } from "react";
import ContainerWithGap from "../components/container/ContainerWithGap";
import HeadingCenter from "../components/page_headings/HeadingCenter";
import BlogBlock from "../components/BlogBlock";
import { useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";
const GeneralListView = ({ type, header_title, categoryList }) => {
    const { category } = useParams();
    const [filteredBlogList, setFilterBlogList] = useState(null);
    const [filteredRecipe, setFilteredRecipe] = useState(null);
    const [offset, setOffset] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [initialData, setInitialData] = useState(null);

    useEffect(() => {
        const url =
            process.env.REACT_APP_API_BASE_URL +
            `/${type ? "blog-category" : "recipe-category"}/${category}`;

        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        };
        fetch(url, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setInitialData(result);
            })
            .catch((error) => {});
    }, []);

    useEffect(() => {
        if (type) {
            // this url with change based on pagination and sorting option
            // setLoading(true);
            let url =
                process.env.REACT_APP_API_BASE_URL +
                `/blog/?expand=type,category&page_size=12&page=${offset}&category__slug=${category}`;

            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            };
            fetch(url, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    setTotalPages(result.total_pages);
                    setFilterBlogList(result.results);
                })
                .catch((error) => {
                    setIsLoading(false);
                });
        } else {
            // this url with change based on pagination and sorting option
            // setLoading(true);
            let url =
                process.env.REACT_APP_API_BASE_URL +
                `/recipe/?expand=recipe_category,recipe_type&page_size=12&page=${offset}&recipe_category__slug=${category}`;

            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            };
            fetch(url, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    setTotalPages(result.total_pages);
                    const filteredCategoryWise = result.results.map((d) => {
                        return {
                            ...d,
                            textbody: d.description,
                            image: d.photo
                        };
                    });

                    setFilteredRecipe(filteredCategoryWise);
                })
                .catch((error) => {
                    setIsLoading(false);
                });
        }
    }, [offset]);

    useEffect(() => {
        if (isLoading && (filteredBlogList || filteredRecipe) && initialData) {
            setIsLoading(false);
        }
    }, [isLoading, filteredBlogList, filteredRecipe, initialData]);

    const handlePageClick = (event) => {
        const newOffset = event.selected + 1;
        setOffset(newOffset);
    };

    return (
        <>
            {" "}
            <HeadingCenter
                title={initialData?.name}
                background="/assets/images/testimonail_background.png"
            />
            {isLoading ? (
                <Loading />
            ) : (
                <ContainerWithGap>
                    <div className="g-pt-100 g-pb-70">
                        {/* <section className="g-brd-top g-py-50">
                            <div className=" g-bg-cover__inner">
                                <ul className="u-list-inline">
                                    <li className="list-inline-item g-mr-7">
                                        Learn  
                                        <i className="g-color-gray-light-v2 g-ml-5">
                                        /
                                        </i>  
                                    </li>
                                    
                                    <li className="list-inline-item g-mr-7">
                                        <Link
                                            className="u-link-v5 g-color-main"
                                            to="/interesting-stuff"
                                        >
                                            {" Interesting Stuff"}
                                        </Link>
                                        <i className="g-color-gray-light-v2 g-ml-5">
                                            /
                                        </i>
                                    </li>
                                    <li className="list-inline-item g-color-primary">
                                        <span>{category}</span>
                                    </li>
                                </ul>
                            </div>
                        </section> */}
                        {type ? (
                            <div className="masonry-grid row">
                                {filteredBlogList && filteredBlogList.length ? (
                                    filteredBlogList.map((item) => {
                                        return (
                                            <BlogBlock
                                                item={item}
                                                key={item.id}
                                                categoryList={categoryList}
                                            />
                                        );
                                    })
                                ) : (
                                    <p>No Blogs Available</p>
                                )}
                                {/* <BlogBlock item={item} /> */}
                            </div>
                        ) : (
                            <div className="masonry-grid row">
                                {filteredRecipe && filteredRecipe.length ? (
                                    filteredRecipe.map((item) => {
                                        return (
                                            <BlogBlock
                                                item={item}
                                                key={item.id}
                                                categoryList={categoryList}
                                            />
                                        );
                                    })
                                ) : (
                                    <p>No Blogs Available</p>
                                )}
                                {/* <BlogBlock item={item} /> */}
                            </div>
                        )}
                        {totalPages > 1 && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                    marginBottom: "25px",
                                    marginTop: "25px"
                                }}
                            >
                                <ReactPaginate
                                    nextLabel="Next"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    pageCount={totalPages}
                                    previousLabel="Previous"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        )}
                    </div>
                </ContainerWithGap>
            )}
        </>
    );
};

export default GeneralListView;
