import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    toggleDiscountCodeUse,
    applyDiscountCode,
    removeDiscountCode,
    toggleUseSubscribeDiscount,
    applySubscribeDiscount,
    removeSubscribeDiscount,
    applyDonation
} from "../../helper/CartHelper";
import { format_price } from "../../helper/Helper";

const CheckoutSidebar = (props) => {
    const [globalData, setGlobalData] = useState([]);
    const cart = useSelector((state) => state.cart);
    const dispatch = useDispatch();
    const cart_items = cart.cart_items;

    // local state variables
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/global-setting`)
            .then((response) => response.json())
            .then((data) => setGlobalData(data?.results))
            .catch((error) => {});
    }, []);

    const [discountCode, setDiscountCode] = useState();
    const [subscribedEmail, setSubscribedEmail] = useState(
        cart.subscribedEmail
    );
    const donate_amount_options = [3, 5];

    const setDonationDetail = (donate, donate_amt, customDonate = false) => {
        applyDonation(
            {
                doDonate: donate,
                donate_amt: donate_amt,
                custom_donate: customDonate
            },
            dispatch
        );
    };

    let total_cart_items = 0;
    cart_items?.map((item) => {
        total_cart_items += item.qty;
    });

    return (
        <div className="col-lg-4 g-mb-30">
            {/* <!-- Summary --> */}
            <div className="g-bg-gray-light-v5 g-pa-20 g-pb-50 mb-4">
                <h4 className="h6 text-uppercase mb-3">Order Summary</h4>

                <div className="row text-secondary g-font-size-13">
                    <div className="col-6 mb-1">
                        Items ({total_cart_items}):
                    </div>
                    <div className="col-6 mb-1 text-right">
                        ${cart.total_amt}
                    </div>

                    <div className="col-6 mb-1">Gift:</div>
                    <div className="col-6 mb-1 text-right">
                        {cart.post_card_price > 0
                            ? "$" + format_price(cart.post_card_price)
                            : "N/A"}
                    </div>

                    <div className="col-6 mb-1">Shipping & handling:</div>
                    <div className="col-6 mb-1 text-right">
                        {cart.shipping_charge > 0
                            ? `$${cart.shipping_charge}`
                            : "--"}
                    </div>

                    <div className="col-6 mb-1">Subscribe & Save:</div>
                    <div className="col-6 mb-1 text-right">
                        {cart.subscription
                            ? cart.subscription.amt + "%"
                            : "N/A"}
                    </div>

                    <div className="col-6 mb-1">Discount Code:</div>
                    <div className="col-6 mb-1 text-right">
                        {!!cart.discount_code
                            ? (cart.discount_code.type === "PRICE" ? "$" : "") +
                              `${cart.discount_code.value}` +
                              (cart.discount_code.type !== "PRICE" ? "%" : "")
                            : "N/A"}
                    </div>

                    <div className="col-6 mb-1">Donation:</div>
                    <div className="col-6 mb-1 text-right">
                        {cart.donate_amt > 0 ? `$${cart.donate_amt}` : "N/A"}
                    </div>

                    <div className="col-6 mb-1">Tax</div>
                    <div className="col-6 mb-1 text-right">
                        {cart.tax_amt > 0 ? `$${cart.tax_amt}` : "--"}
                    </div>
                </div>
                {/* End Summary  */}

                <hr className="my-2 row p-0" />

                {/* Order Total  */}
                <div className="row">
                    <div className="col-6">
                        <strong>Order Total:</strong>
                    </div>
                    <div className="col-6 text-right">
                        <strong>${cart.grand_total}</strong>
                    </div>
                </div>
                {/* End Order Total  */}

                {props.step === 3 ? (
                    <></>
                ) : (
                    <>
                        {globalData &&
                        globalData?.length > 0 &&
                        globalData[0]?.enable_subscription_discount ? (
                            <div className="col-12 d-flex mt-3 p-0 text-secondary">
                                <div className="g-pt-1">
                                    {cart.use_discount_code ? (
                                        <input
                                            type="checkbox"
                                            checked="checked"
                                            onChange={(e) =>
                                                toggleDiscountCodeUse(
                                                    e.target.checked,
                                                    dispatch
                                                )
                                            }
                                        />
                                    ) : (
                                        <input
                                            type="checkbox"
                                            onChange={(e) =>
                                                toggleDiscountCodeUse(
                                                    e.target.checked,
                                                    dispatch
                                                )
                                            }
                                        />
                                    )}
                                </div>
                                <div className="ch_cb_desc ml-2">
                                    <strong>
                                        Apply Discount Code / Gift Card
                                    </strong>
                                    <br />
                                    <small className="text-secondary">
                                        Apply discount code and get discounted
                                        amount accordingly.
                                    </small>
                                    {cart.discount_code ? (
                                        <div>
                                            <span className="badge badge-success g-font-size-14">
                                                {cart.discount_code.code}
                                            </span>
                                            <span
                                                className="remove_code ml-2"
                                                onClick={(e) =>
                                                    removeDiscountCode(dispatch)
                                                }
                                            >
                                                <i class="fa fa-times-circle"></i>
                                            </span>
                                        </div>
                                    ) : (
                                        <div
                                            className={
                                                "input-group mt-2 " +
                                                (cart.use_discount_code
                                                    ? ""
                                                    : "d-none")
                                            }
                                        >
                                            <input
                                                type="text"
                                                placeholder="Enter Code"
                                                className="text-center"
                                                onChange={(e) =>
                                                    setDiscountCode(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <div class="input-group-append">
                                                <button
                                                    class="btn btn-success"
                                                    type="button"
                                                    onClick={() =>
                                                        applyDiscountCode(
                                                            discountCode,
                                                            dispatch
                                                        )
                                                    }
                                                    disabled={
                                                        discountCode
                                                            ? false
                                                            : true
                                                    }
                                                >
                                                    Apply
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <span>Loading...</span>
                        )}

                        {/* Subscribe & Save  */}
                        <div className="col-12 d-flex mt-3 p-0 text-secondary">
                            <div className="g-pt-1">
                                {cart.use_subscribe_discount ? (
                                    <input
                                        type="checkbox"
                                        checked="checked"
                                        onChange={(e) =>
                                            toggleUseSubscribeDiscount(
                                                e.target.checked,
                                                dispatch
                                            )
                                        }
                                    />
                                ) : (
                                    <input
                                        type="checkbox"
                                        onChange={(e) =>
                                            toggleUseSubscribeDiscount(
                                                e.target.checked,
                                                dispatch
                                            )
                                        }
                                    />
                                )}
                            </div>
                            <div className="ch_cb_desc ml-2">
                                <strong>Subscribe & Save</strong>
                                <br />
                                <small className="text-secondary">
                                    Save 5% now and upto 10% on repeat delivery
                                </small>

                                {cart.subscription != null ? (
                                    <div>
                                        <span className="badge badge-success g-font-size-14">
                                            {cart.subscription.email}
                                        </span>
                                        <span
                                            className="remove_code ml-2"
                                            onClick={(e) =>
                                                removeSubscribeDiscount(
                                                    dispatch
                                                )
                                            }
                                        >
                                            <i class="fa fa-times-circle"></i>
                                        </span>
                                    </div>
                                ) : (
                                    <div
                                        className={
                                            "input-group mt-2 " +
                                            (cart.use_subscribe_discount
                                                ? ""
                                                : "d-none")
                                        }
                                    >
                                        <input
                                            type="text"
                                            placeholder="Enter email"
                                            className="text-center"
                                            onChange={(e) =>
                                                setSubscribedEmail(
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <div class="input-group-append">
                                            <button
                                                class="btn btn-success"
                                                type="button"
                                                onClick={() =>
                                                    applySubscribeDiscount(
                                                        subscribedEmail,
                                                        dispatch
                                                    )
                                                }
                                                disabled={
                                                    subscribedEmail
                                                        ? false
                                                        : true
                                                }
                                            >
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* End Subscribe and Save  */}

                        {/* Help and Donate  */}
                        <div className="col-12 d-flex mt-3 p-0 text-secondary">
                            <div className="g-pt-1">
                                {cart.donate ? (
                                    <input
                                        type="checkbox"
                                        checked="checked"
                                        onChange={(e) =>
                                            setDonationDetail(
                                                e.target.checked,
                                                cart.donate_amt
                                            )
                                        }
                                    />
                                ) : (
                                    <input
                                        type="checkbox"
                                        onChange={(e) =>
                                            setDonationDetail(
                                                e.target.checked,
                                                cart.donate_amt
                                            )
                                        }
                                    />
                                )}
                            </div>
                            <div className="ch_cb_desc ml-2">
                                <strong>
                                    Help Families in need this holiday season
                                </strong>
                                <br />
                                <small className="text-secondary">
                                    Donate your saving to help us send healthy
                                    groceries to families and kids through
                                    Baby2Baby & FoodCrops.
                                </small>

                                {/* Donate Button  */}
                                {cart.donate ? (
                                    <div className="row mt-3">
                                        <div className="col-12 donate-btns g-font-size-12">
                                            <strong className="text-secondary">
                                                Donate:
                                            </strong>
                                            <br />
                                            {donate_amount_options.map(
                                                (amt) => (
                                                    <button
                                                        className={`btn btn-sm ${
                                                            cart.donate_amt ==
                                                                amt &&
                                                            !cart.custom_donate
                                                                ? "selected"
                                                                : ""
                                                        }`}
                                                        onClick={() =>
                                                            setDonationDetail(
                                                                cart.donate,
                                                                amt
                                                            )
                                                        }
                                                    >
                                                        ${amt}
                                                    </button>
                                                )
                                            )}

                                            {/* <button className={"btn btn-sm "+((cart.donate_amt == 5)?'selected':'')} onClick={()=>setDonationDetail(cart.donate, 5)}>$5</button> */}
                                            <div
                                                className={
                                                    "custom_donate d-inline-block " +
                                                    (cart.custom_donate
                                                        ? "selected"
                                                        : "")
                                                }
                                            >
                                                <span className="pre_amt">
                                                    $
                                                </span>
                                                <input
                                                    type="number"
                                                    min="0.01"
                                                    max="9999999999"
                                                    step="0.01"
                                                    placeholder="custom"
                                                    className="text-center"
                                                    onChange={(e) =>
                                                        setDonationDetail(
                                                            cart.donate,
                                                            e.target.value,
                                                            true
                                                        )
                                                    }
                                                    onFocus={(e) =>
                                                        setDonationDetail(
                                                            cart.donate,
                                                            e.target.value,
                                                            true
                                                        )
                                                    }
                                                    value={
                                                        cart.custom_donate
                                                            ? cart.donate_amt
                                                            : ""
                                                    }
                                                    defaultValue={
                                                        cart.custom_donate
                                                            ? cart.donate_amt
                                                            : ""
                                                    }
                                                />
                                            </div>
                                            {/* <button className="btn btn-sm">custom</button> */}
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                        {/* End Help and Donate  */}

                        <hr className="my-2 mt-4 row p-0" />
                        {/* Ship Agreement  */}
                        <div className="col-12 d-flex mt-3 p-0 text-secondary">
                            <div className="ch_cb_desc ml-2">
                                <small className="text-secondary">
                                    <strong>Note:</strong> Discounts and
                                    shipping will be calculated at checkout and
                                    a checkbox agreeing to terms and shipping
                                    policy
                                </small>
                            </div>
                        </div>
                        {/* End Shipping Agreement  */}
                    </>
                )}
            </div>
            {/* <!-- End Summary --> */}

            <button
                className="btn btn-block u-btn-primary g-font-size-13 text-uppercase g-py-15 mb-4"
                type="button"
                onClick={props.nextStep}
            >
                Proceed to Checkout
            </button>
            {props.step > 1 ? (
                <button
                    className="btn btn-block u-btn-outline-black g-brd-gray-light-v1 g-bg-black--hover g-font-size-13 text-uppercase g-py-15 mb-4"
                    type="button"
                    onClick={props.prevStep}
                >
                    <i class="icon-arrow-left mr-3"></i>Back to Cart
                </button>
            ) : (
                ""
            )}
        </div>
    );
};

export default CheckoutSidebar;
