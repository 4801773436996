import { useDispatch } from "react-redux";
import { addToCart, reduceFromCart } from "../../helper/CartHelper";

const QuantityChange = (props) => {
    const item = props.item;
    const dispatch = useDispatch();

    return (
        <div
            className="cart_quantity_change bg-light d-inline-block px-0"
            style={{ fontSize: "14px" }}
        >
            <button
                className="cart_qty qty_decrement"
                onClick={() =>
                    reduceFromCart(item.product_id, dispatch, item.is_variant)
                }
            >
                -
            </button>
            <span>{item.qty}</span>
            <button
                className="cart_qty qty_increment"
                onClick={() =>
                    addToCart(
                        item.product_id,
                        1,
                        dispatch,
                        {},
                        false,
                        item?.default_varient ? false : item.is_variant,
                        item.varient_id
                    )
                }
            >
                +
            </button>
        </div>
    );
};

export default QuantityChange;
