import { toast } from "react-toastify";

export const cart_has_items = (cart) => {
	return ( cart.cart_items.length > 0 );
}

export const check_discout_code = (cart) => {
	if( cart.use_discount_code ){
		if( !cart.discount_code ){
			return false;
		}
	}
	return true;
}

export const check_subscription_discount = (cart) => {
	if( cart.use_subscribe_discount ){
		if( !cart.subscription ){
			return false;
		}
	}
	return true;
}

export const check_donate = (cart) => {
	if( cart.donate ){
		if( cart.donate_amt <= 0 ){
			return false;
		}

	}
	return true;
}

export const check_gift = (cart) => {
	if( cart.is_gift ){
		if( !cart.post_card || !cart.post_card_price ){
			return false;
		}
	}
	return true;
}


export const validateStep1 = ( cart ) => {

	// if( !cart_has_items(cart) ){
	// 	toast.error('Empty Cart');
	// }else{ total_validated += 1; }

	// if( !check_discout_code(cart) ){
	// 	alert( "Discount Code option is selected but code is not applied. Are you sure to continue without it?" )
	// }else {total_validated += 1;}

	// if( !check_subscription_discount(cart) ){

	// 	alert( "Subscription Discount option is selected but subscribed email is not applied. Are you sure to continue without it?" )
	// }else{total_validated += 1;}

	// if( !check_donate(cart) ){
	// 	alert( "You've selected donate option. Please select any generous amount to donate." );
	// }else{total_validated += 1;}

	if( !check_gift(cart) ){
		toast.error('You have selected gift option. Please select gift card to continue.');
		return false;
	}

	return true;

}

export const validateStep2 = (cart) => {
	if( !cart.shipping_address_verified ){
		toast.error('Could\'t verify shipping address. Please fill all vaild details.');
	} else if( !cart.billing_address_verified ){
		toast.error('Could\'t verify billing address. Please fill all vaild details.');
	}else{
		return true;
	}
}