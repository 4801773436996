import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from 'yup';
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const ForgetPasswordPage = () => {

	const validationSchema = Yup.object().shape({
		email: Yup.string().email("Please enter valid email").required("Email field is required"),
	});

	const navigate = useNavigate();

    let global_response = "";
	const forgot_password_url = process.env.REACT_APP_API_BASE_URL+'/accounts/forget-password';

	const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

	// Handles form submit 
	const formSubmitHandler = (formData, resetForm, setSubmitting) => {
        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            body: formData,
			headers: { 
				'Content-Type': 'application/json',
			}
        };
        fetch(forgot_password_url, requestOptions)
            .then((response) => {
				global_response = response;
				return response.json();
			})
            .then((result) => {
				if (global_response.ok) {
					toast.success("Email for resetting password sent successfully in your email. Please check and proceed accordingly.");
					navigate('/account/login');
					resetForm();
				} else {
					for (const r in result) {
						setErrorMessage(result[r][0]);
						break;
					}
				}
				setSubmitting(false);
			})
            .catch((error) => {
            })
    }

	return (
		// <!-- Forget Password -->
		<section className="g-bg-gray-light-v5">
			<div className="container g-py-100">
				<div className="row justify-content-center">
					<div className="col-sm-8 col-lg-5">
						<div className="u-shadow-v21 g-bg-white rounded g-py-40 g-px-30">
							<div className="text-center mb-4">
								<h2 className="h2 g-color-black g-font-weight-600">Reset Your Password</h2>
								<p className="g-text-grey g-mt-20">We will send you an email to reset your password.</p>
							</div>

							<Formik
								initialValues={{
									email: ''
								}}
								validationSchema={validationSchema}
								onSubmit={(values, { setSubmitting, resetForm }) => {
									setTimeout(() => {
										setSuccessMessage("");
										setErrorMessage("");
										formSubmitHandler(JSON.stringify(values, null, 2), resetForm, setSubmitting);
									}, 400);
								}}
							>
								{({ isSubmitting, setFieldValue }) => (
									// Form Start 
									<Form className="g-py-15">
										{ successMessage ? <div className="alert alert-success">{successMessage}</div> : '' }
										{ errorMessage ? <div className="alert alert-danger">{errorMessage}</div> : ''}
										<div className="mb-4">
											<Field className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v4 g-brd-primary--hover rounded g-py-15 g-px-15" type="email" placeholder="Enter your email" name="email" />
											<ErrorMessage name="email">
												{msg => <div className="g-mt-10 text-danger field_error_message"><i className="fa fa-info-circle g-mr-5"></i>{msg}</div>}
											</ErrorMessage>
										</div>
										<div className="mb-4">
											<button className="btn btn-md btn-block u-btn-primary rounded g-py-13" type="submit" disabled={isSubmitting}>Submit</button>
										</div>
									</Form>
								)}
							</Formik>
							{/* <!-- End Form --> */}

							<footer className="text-center">
								<p className="g-color-gray-dark-v5 g-font-size-13 mb-0">Don't have an account? <Link className="g-font-weight-600" to="/account/register">signup</Link>
								</p>
							</footer>
						</div>
					</div>
				</div>
			</div>
		</section>
	);

}

export default ForgetPasswordPage;