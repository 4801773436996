import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { validateStep1, validateStep2 } from "../../helper/CheckoutHelper";
import { appendScripts, appendStyles, removeScripts, removeStyles } from "../../helper/Helper";
import StepProgress from "./StepProgress";
import Cart from "./steps/Cart";
import Payment from "./steps/Payment";
import Shipping from "./steps/Shipping";
import Successful from "./steps/Successful";
import { Elements } from '@stripe/react-stripe-js';
import { createOrUpdatePaymentUpdate } from "../../helper/PaymentHelper";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";

const CartPage = (props) => {

	const [step, setStep] = useState(1);
	const cart = useSelector(state => state.cart);
	const cart_items = cart.cart_items;
	const stripePromise = props.stripePromise;

	const paymentInt = useSelector(state => state.payment.payment_int);
	const dispatch = useDispatch();
	const [stripeOptions, setStripeOptions] = useState(null);

	useEffect(() => {
		createOrUpdatePaymentUpdate(cart,dispatch,paymentInt,setStripeOptions, stripeOptions);
	}, [step]);
	
	/**
	 * external_styles and external_scripts contains array
	 * Array of Path External Styles and Scripts required for this component
	 * Paths should be relative from the public folder
	 */
	const external_styles = [
		'/assets/css/styles.e-commerce.css',
	];

	const external_scripts = [
	];

	useEffect(() => {
		removeScripts(['./assets/js/custom.js']);
		appendStyles(external_styles);
		appendScripts(external_scripts);

		return () => {
			removeStyles(external_styles);
			removeScripts(external_scripts);
		}
	}, [])

	const nextStep = () => {
		if (step == 1) {
			if (!validateStep1(cart)) {
				return false;
			}
		}
		if (step == 2) {
			if (!validateStep2(cart)) {
				return false;
			}
		}


		let new_step = step + 1;
		setStep(new_step);
	}

	const prevStep = () => {
		let new_step = step - 1;
		setStep(new_step);
	}

	if (cart_items.length == 0) {
		return <>
			<div className="empty-cart-wrapper g-py-50">
				<img src="/assets/images/cart/asset.svg" className="d-flex justify-content-center w-100" alt="" />
				<div className="empty-text-wrapper ">
					<h2 className="text-center">Your Cart is empty</h2>
					<p className="text-center">Please visit the product page for our wide ranges of spices</p>
				</div>
				<div className="d-flex justify-content-center">
					<Link to="/products" className="btn btn-block u-btn-primary g-font-size-14 text-uppercase g-py-10 g-px-15" type="button" style={{ width: 'fit-content' }}>
						Visit Store
					</Link>
				</div>
			</div>

		</>;
	}
	switch (step) {
		case 1:
			return <><StepProgress step={step} prevStep={prevStep} /><Cart step={step} nextStep={nextStep} prevStep={prevStep} /></>;
		case 2:
			return <><StepProgress step={step} prevStep={prevStep} /><Shipping step={step} nextStep={nextStep} prevStep={prevStep} /></>;
		case 3:
			return <Elements stripe={stripePromise} options={stripeOptions}><StepProgress step={step} prevStep={prevStep} /><Payment step={step} {...props} nextStep={nextStep} prevStep={prevStep} /></Elements>;
		default:
			return <><StepProgress step={step} prevStep={prevStep} /><Successful nextStep={nextStep} prevStep={prevStep} /></>;
	}

}

export default CartPage;