import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { get_fetched_menu_url, get_listing_page_url, get_menu_url, get_optimized_image_url } from "../../helper/Helper";

const MegaMenuShop = (props) => {

	const [activeIndex, setActiveIndex] = useState("spices");
	const parentMenu = props.menu;

	const [menus, setMenus] = useState(parentMenu?.child_menus);

	useEffect(() => {
		if ((parentMenu?.child_menus.length < 1) && parentMenu.autofetch) {
			setMenus(parentMenu.fetched_items);
		}
	}, [parentMenu]);


	console.log('fetched Menu', menus[0]);
	return (

		<div className="">

			<ul className="row">
				<div className="col-md-2 g-pa-0">
					{
						(menus.length > 0) &&
						menus.map((menu, index) => {
							return (
								<li className={"category-list text-capitalize g-mt-15  " + (activeIndex === "spice" ? 'active' : '')} key={"mega_menu_" + menu.id + "_" + index} onClick={() => setActiveIndex(menu.slug)}>
									<Link to="#">
										{menu.title}
									</Link>
								</li>
							)
						})
					}
				</div>
				<div className="col-md-10 g-mb-20">
					{
						(menus.length > 0) &&
						menus.map((menu, index) => {
							return (
								<div className={"menu_category_product " + (activeIndex === menu.slug ? 'active' : '')}>
									<div className="row mt-4 col-12">
										{(menu.fetched_items.length > 0) ?
											<>
												{
													menu.fetched_items.map((fm, index) => {
														return (
															<div className="col-md-4 col-lg-3 d-flex align-items-center" key={'fetched_submenu_' + fm.id + '_' + index}>
																<Link to={get_fetched_menu_url(menu, fm)} className="submenu-wrap" style={{ "backgroundImage": `url(${fm.cover_photo})` }}>
																	{/* <img className="" src={get_optimized_image_url(fm.cover_photo) } /> */}
																	<span className="h6 text-center text-capitalize">{fm.name}</span>
																</Link>
															</div>
														);
													})
												}
												<div className="col-md-4 col-lg-3 d-flex align-items-center" key={'fetched_submenu_vm' + menu.id }>
													<Link to={get_listing_page_url(menu)} className="submenu-wrap">
														<span className="h6 text-center text-capitalize">View All</span>
													</Link>
												</div>
											</>
											:
											(menu.has_submenu &&
												menu.child_menus.map((fm, index) => {
													return (
														<div className="col-md-3 d-flex align-items-center" key={'fetched_submenu_' + fm.id + '_' + index}>
															<Link to={get_fetched_menu_url(menu, fm)} className="submenu-wrap" style={{ "backgroundImage": `url(${fm.cover_photo})` }}>
																{/* <img className="" src={get_optimized_image_url(fm.cover_photo) } /> */}
																<span className="h6 text-center text-capitalize">{fm.name}</span>
															</Link>
														</div>
													);
												})
											)
										}
									</div>

								</div>
							)
						})
					}
				</div>
			</ul>
		</div>
	);
}
export default MegaMenuShop;