import { Link } from "react-router-dom";
import ContainerWithGap from "../components/container/ContainerWithGap";

const SuccessPage = () => {

    return (
        <>
            <div className="error-page-wrapper g-py-50">
                <img
                    src="/assets/images/complete.svg"
                    className="d-flex justify-content-center w-100"
                    alt=""
                />
                <div className="empty-text-wrapper g-pt-30">
                    <h2 className="text-center">Your order is complete!</h2>
                    <p className="text-center">
                        You will be receiving a confirmation email with order details.
                    </p>
                </div>
                <div className="d-flex justify-content-center">
                    <Link to="/">
                        <button
                            className="btn btn-block u-btn-primary g-font-size-14 text-uppercase g-py-10 g-px-15"
                            type="button"
                            style={{ width: "fit-content" }}
                        >
                            Back to HomePage
                        </button>
                    </Link>
                </div>
            </div>
        </>
    );

}

export default SuccessPage;