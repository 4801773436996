// import { appendScript } from "../helper/Helper";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import ContainerWithGap from "./container/ContainerWithGap";
import Newsletter from "./Newsletter";

const Footer = () => {

	return (
		<>
			{/* Footer Start  */}

			<footer className="g-brd-top g-brd-secondary-light-v1 g-brd-2">
				{/* <!-- Content --> */}
				<div className="g-brd-bottom g-brd-secondary-light-v1">
					<div className="homni-container g-py-100">
						{/* <!-- Secondary Content --> */}
						<div className="row">
							<Newsletter />
							<div className="col-12 col-lg-8 d-sm-flex justify-content-lg-end g-px-0">
								<div className="d-flex align-items-center justify-content-center">
									<ul class="nav text-uppercase g-color-text">
										<li class="nav-item">
											<Link class="nav-link g-color-text" to="/products">shop</Link>
										</li>
										<li class="nav-item">
											<Link class="nav-link g-color-text" to="/recipes">recipes</Link>
										</li>
										<li class="nav-item">
											<Link class="nav-link g-color-text" to="/wholesale">wholesale</Link>
										</li>
										<li class="nav-item">
											<Link class="nav-link  g-color-text" to="/blogs">blog</Link>
										</li>
										<li class="nav-item">
											<Link class="nav-link g-color-text" to="/contact">contact</Link>
										</li>
										<li class="nav-item">
											<Link class="nav-link g-color-text" to="/career">career</Link>
										</li>
										<li class="nav-item">
											<Link class="nav-link g-color-text" to="/faq">faqs</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						{/* <!-- End Secondary Content --> */}
					</div>
				</div>
				{/* <!-- End Content --> */}
			</footer>
			{/* <!-- Copyright --> */}
			<div className="container-fluid footer-copyright g-py-6">
				<div className="row align-items-center">
					<div className="col-md-12 d-flex justify-content-center">
						<p className="g-font-size-12 mb-0 g-color-white">2022 &copy; Homini LLC. All Rights Reserved.</p>
					</div>
				</div>
			</div>
			{/* <!-- End Copyright --> */}
		</>
	);
}
export default Footer;