export function NextArrow(props){
	const { className, style, onClick } = props;
	return (
		<div 
			className={className+" slick_custom_arrow next_arrow"}
			style={{...style, zIndex:99, right:0}}
			onClick={onClick}
		>Next</div>
	);
}

export function PrevArrow(props){
	const { className, style, onClick } = props;

	return(
		<div 
			className={className+" slick_custom_arrow prev_arrow"}
			style={{...style }}
			onClick={onClick}
		>Prev</div>
	)
}