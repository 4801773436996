import React from "react";
const Modals = ({
    children,
    buttonName,
    title,
    modalButtonClassName,
    onClick
}) => {
    return (
        <>
            <button
                className={modalButtonClassName}
                type="button"
                data-toggle="modal"
                data-target="#exampleModalCenter"
                onClick={() => onClick()}
            >
                {buttonName}
            </button>
            {/* <button
                type="button"
                class="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModalCenter"
            >
                Launch demo modal
            </button> */}

            <div
                className="modal fade"
                id="exampleModalCenter"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div
                    class="modal-dialog modal-dialog-centered"
                    role="document"
                    option
                >
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">
                                {title}
                            </h5>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">{children ? children : ""}</div>
                        {/* <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button type="button" class="btn btn-primary">
                                Save changes
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Modals;
