import ContainerWithGap from "../../components/container/ContainerWithGap";
import ProfileNavSidebar from "../../components/profile_page/ProfileNavSidebar";

const Notification = (props) => {
    return (
        <ContainerWithGap>
            <div className="row">
                {/* <!-- Profile Settings --> */}
                <ProfileNavSidebar active='notification'></ProfileNavSidebar>
                {/* <!-- End Profile Settings --> */}

                {/* <!-- Login & Security --> */}
                <div className="col-lg-9 g-mb-50 g-mt-50">
                    <div className="g-brd-around g-brd-gray-light-v4 rounded g-pa-30 g-mb-30">
                        <h3 className="h5 mb-3">Security Settings</h3>
                        {/* <!-- Security Settings --> */}
                        <div className="d-flex mb-3">
                            <span className="g-color-text g-font-size-13">Free SMS transaction alert service</span>

                            <form className="ml-auto">
                                <label className="form-check-inline u-check mb-0">
                                    <input className="g-hidden-xs-up g-pos-abs g-top-0 g-right-0" name="radGroup1_1" type="checkbox" />
                                    <div className="u-check-icon-radio-v7">
                                        <i className="fa" data-check-icon="" data-uncheck-icon=""></i>
                                    </div>
                                </label>
                            </form>
                        </div>
                        {/* <!-- End Security Settings --> */}

                        {/* <!-- Security Settings --> */}
                        <div className="d-flex mb-3">
                            <span className="g-color-text g-font-size-13">Email binding for password recovery</span>

                            <form className="ml-auto">
                                <label className="form-check-inline u-check mb-0">
                                    <input className="g-hidden-xs-up g-pos-abs g-top-0 g-right-0" name="radGroup1_1" type="checkbox" checked />
                                    <div className="u-check-icon-radio-v7">
                                        <i className="fa" data-check-icon="" data-uncheck-icon=""></i>
                                    </div>
                                </label>
                            </form>
                        </div>
                        {/* <!-- End Security Settings --> */}

                        {/* <!-- Security Settings --> */}
                        <div className="d-flex mb-3">
                            <span className="g-color-text g-font-size-13">Make a payment from your Android Pay wallet</span>

                            <form className="ml-auto">
                                <label className="form-check-inline u-check mb-0">
                                    <input className="g-hidden-xs-up g-pos-abs g-top-0 g-right-0" name="radGroup1_1" type="checkbox" />
                                    <div className="u-check-icon-radio-v7">
                                        <i className="fa" data-check-icon="" data-uncheck-icon=""></i>
                                    </div>
                                </label>
                            </form>
                        </div>
                        {/* <!-- End Security Settings --> */}

                        {/* <!-- Security Settings --> */}
                        <div className="d-flex mb-3">
                            <span className="g-color-text g-font-size-13">Receive email confirmations of your each order</span>

                            <form className="ml-auto">
                                <label className="form-check-inline u-check mb-0">
                                    <input className="g-hidden-xs-up g-pos-abs g-top-0 g-right-0" name="radGroup1_1" type="checkbox" checked />
                                    <div className="u-check-icon-radio-v7">
                                        <i className="fa" data-check-icon="" data-uncheck-icon=""></i>
                                    </div>
                                </label>
                            </form>
                        </div>
                        {/* <!-- End Security Settings --> */}

                        {/* <!-- Security Settings --> */}
                        <div className="d-flex mb-3">
                            <span className="g-color-text g-font-size-13">Receive promotions, news and gifts</span>

                            <form className="ml-auto">
                                <label className="form-check-inline u-check mb-0">
                                    <input className="g-hidden-xs-up g-pos-abs g-top-0 g-right-0" name="radGroup1_1" type="checkbox" checked />
                                    <div className="u-check-icon-radio-v7">
                                        <i className="fa" data-check-icon="" data-uncheck-icon=""></i>
                                    </div>
                                </label>
                            </form>
                        </div>
                        {/* <!-- End Security Settings --> */}
                    </div>
                    <button className="btn u-btn-primary g-font-size-12 text-uppercase g-py-12 g-px-25" type="submit">Update</button>
                </div>
            </div>
        </ContainerWithGap>
    )
}
export default Notification;