import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	payment_int : null
};

export const paymentSlice = createSlice({
	name : 'payment',
	initialState,
	reducers : {
		set_payment_intend : (state, action) => {
			if( action.payload == null ){
				state.payment_int = action.payload;
			}else{
				state.payment_int = action.payload.payment_int;
			}
		},
		clear_payment_intend : (state, action) => {
			return {...initialState};
		}
	}
})

export const {
	set_payment_intend,
	clear_payment_intend
 } = paymentSlice.actions;

export default paymentSlice.reducer;