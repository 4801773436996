// jshint esversion: 6

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";

import "./App.sass";
import HomePage from "./pages/HomePage";
import ProductPage from "./pages/ProductPage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import PageNotFound from "./pages/error/PageNotFound";
import LoginPage from "./pages/authentication/LoginPage";
import RegisterPage from "./pages/authentication/RegisterPage";
import ForgetPasswordPage from "./pages/authentication/ForgetPaswordPage";
import CartPage from "./pages/checkout/CartPage";
import CheckoutPage from "./pages/checkout/CheckoutPage";
import ProductDetailPage from "./pages/ProductDetailPage";
import ProfilePage from "./pages/profile/ProfilePage";
import BlogPage from "./pages/BlogPage";
import BlogDetailPage from "./pages/BlogDetailPage";
import AccountVerifyPage from "./pages/AccountVerifyPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CancelledOrder from "./pages/profile/CancelledOrder";
import WaitList from "./pages/profile/WaitList";
import AddressessPage from "./pages/profile/AddressesPage";
import Notification from "./pages/profile/Notification";
import OpenOrders from "./pages/profile/OpenOrders";

import { loadStripe } from "@stripe/stripe-js";
import Successful from "./pages/checkout/steps/Successful";
import FaqPage from "./pages/FaqPage";
import CareerPage from "./pages/CareerPage";
import TermsPage from "./pages/TermsPage";
import ContactPage from "./pages/ContactPage";
import WholesalePage from "./pages/WholesalePage";
import AboutPage from "./pages/AboutPage";
import ProductIndexHerbsPage from "./pages/ProductIndexHerbs";
import ScrollToTop from "./components/ScrollToTop";
import SuccessPage from "./pages/SuccessPage";
import RecipePage from "./pages/RecipePage";
import ProductCategoryPage from "./pages/ProductCategoryPage";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import ProfileSecurity from "./pages/profile/ProfileSecurity";
import CommitmentPage from "./pages/CommitmentPage";
import GeneralRepoPage from "./pages/GeneralRepo";
import InterestingRepoPage from "./pages/IntrestingRepoPage";
import GrowerGallery from "./pages/GrowerGallery";
import BlogType from "./pages/BlogType";
import RecipeType from "./pages/RecipeType";
import RecipeListingPage from "./pages/RecipeListingPage";
import HeaderSecond from "./components/HeaderSecond";
import GeneralListView from "./pages/GeneralListView";
import Aos from "aos";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

function App() {
    useEffect(() => {
        Aos.init({
            duration: 700,
            delay: 0
        });
        Aos.refresh();
    }, []);

    return (
        <BrowserRouter>
            {/* <Header /> */}
            <HeaderSecond />
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<HomePage />} exact />

                {/* Product Related  */}
                <Route path="/products" element={<ProductPage />} exact />
                <Route
                    path="/products/:slug"
                    element={<ProductDetailPage />}
                    exact
                />
                <Route
                    path="/product-categories/:slug"
                    element={<ProductCategoryPage />}
                    exact
                />

                {/* Account Related  */}
                <Route path="/account/login" element={<LoginPage />} exact />
                <Route
                    path="/account/register"
                    element={<RegisterPage />}
                    exact
                />
                <Route
                    path="/account/forget-password"
                    element={<ForgetPasswordPage />}
                    exact
                />
                <Route
                    path="/account/password-reset"
                    // element={<RegisterPage />}
                    element={<PublicRoute element={<RegisterPage />} />}
                    exact
                />
                <Route
                    path="/account/verify"
                    element={<AccountVerifyPage />}
                    exact
                />
                {/* Profile Page Route  */}
                <Route
                    path="/account/overview"
                    element={<PrivateRoute element={<ProfilePage />} />}
                    exact
                />
                <Route
                    path="/account/open-orders"
                    element={<PrivateRoute element={<OpenOrders />} />}
                    exact
                />
                <Route
                    path="/account/cancelled-orders"
                    element={
                        <PrivateRoute element={<CancelledOrder />} exact />
                    }
                />
                <Route
                    path="/account/waitlist"
                    element={<PrivateRoute element={<WaitList />} exact />}
                    exact
                />
                <Route
                    path="/account/addressess"
                    element={
                        <PrivateRoute element={<AddressessPage />} exact />
                    }
                    exact
                />
                <Route
                    path="/account/profile-security"
                    element={
                        <PrivateRoute element={<ProfileSecurity />} exact />
                    }
                    exact
                />
                <Route
                    path="/account/notification"
                    element={<PrivateRoute element={<Notification />} exact />}
                    exact
                />

                {/* Cart and Checkout  */}
                <Route
                    path="/cart"
                    element={<CartPage stripePromise={stripePromise} />}
                    exact
                />
                <Route path="/checkout" element={<CheckoutPage />} exact />
                <Route path="/order-complete" element={<Successful />} exact />

                {/* Blog Route  */}
                <Route path="/blogs" element={<BlogPage />} exact />
                <Route path="/blogs/:slug" element={<BlogDetailPage />} exact />

                {/* 404 Page  */}
                <Route path="*" element={<PageNotFound />} />

                {/* faq page */}
                <Route path="/faq" element={<FaqPage />} exact />

                {/* careers page */}
                <Route path="/career" element={<CareerPage />} exact />

                {/* faq page */}
                <Route path="/terms" element={<TermsPage />} exact />

                {/* WholesalePage */}
                <Route path="/wholesale" element={<WholesalePage />} exact />

                {/* contact page */}
                <Route path="/contact" element={<ContactPage />} exact />

                {/* about page */}
                <Route path="/about-us" element={<AboutPage />} exact />

                {/* productindexherb page */}
                <Route
                    path="/productherb"
                    element={<ProductIndexHerbsPage />}
                    exact
                />

                {/* successpage */}
                <Route path="/success" element={<SuccessPage />} exact />

                {/* recipe page */}
                <Route path="/recipes/:slug" element={<RecipePage />} exact />

                {/* Recipe listing page */}
                <Route path="/recipes" element={<RecipeListingPage />} exact />

                {/* commitment page */}
                <Route
                    path="/our-commitment"
                    element={<CommitmentPage />}
                    exact
                />
                {/* general repo */}
                <Route
                    path="/generalrepo"
                    element={<GeneralRepoPage />}
                    exact
                />

                {/* intresting repo */}
                <Route
                    path="/interesting-stuff"
                    element={<InterestingRepoPage />}
                    exact
                />

                {/* grower gallery */}
                <Route
                    path="/recipe-gallery/:category"
                    element={<GrowerGallery />}
                    exact
                />
                {/* Practice page route */}
                <Route
                    path="/recipe-type/:type"
                    element={<RecipeType />}
                    exact
                />
                <Route path="/blog-type/:type" element={<BlogType />} exact />
                <Route
                    path="/recipe-category/:category"
                    element={
                        <GeneralListView
                            type=""
                            categoryList="recipes"
                            header_title="RECIPIES"
                        />
                    }
                    exact
                />
                <Route
                    path="/blog-category/:category"
                    element={
                        <GeneralListView
                            categoryList="blogs"
                            type="spice-talk"
                            header_title="BLOGS"
                        />
                    }
                    exact
                />
            </Routes>
            <ToastContainer
                position="top-center"
                autoClose={2500}
                newestOnTop
                draggable
            />
            <Footer />
        </BrowserRouter>
    );
}

export default App;
