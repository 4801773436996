import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { authUser, login } from "../../features/authentication/userSlice";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Cookies from "js-cookie";
import { getInitialCartData } from "../../features/cartDataSlice";
import { getInitialSaveLaterData } from "../../features/saveLaterDataSlice";

const LoginPage = () => {
    const dispatch = useDispatch();
    const loggedInUser = useSelector(authUser);

    /**
     * Form Validation Rules [ Yup library used for validation ]
     */
    const validationSchema = Yup.object().shape({
        email: Yup.string().required("Email is required").email(),
        password: Yup.string().required("Password Required")
    });

    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    // Url required for signup
    const url = process.env.REACT_APP_API_BASE_URL + "/accounts/login";

    // this variable will store form submit response from api
    let global_response = "";

    const formSubmitHandler = (
        formData,
        resetForm,
        setSubmitting,
        setFieldError
    ) => {
        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: "POST",
            body: formData,
            headers: { "Content-Type": "application/json" }
        };
        fetch(url, requestOptions)
            .then((response) => {
                global_response = response;
                return response.json();
            })
            .then((result) => {
                if (global_response.ok) {
                    setSuccessMessage("Logged in successfully");
                    //set details in Redux
                    dispatch(login(result));
                    //set tokens in cookie
                    // if clause for handling -- possible crash
                    if (result.access_token && result.refresh_token) {
                        Cookies.set("access_token", result.access_token, {
                            path: "/"
                        });
                        Cookies.set("refresh_token", result.refresh_token, {
                            path: "/"
                        });
                    }

                    resetForm();
                    // dispatch(getInitialCartData());
                } else {
                    //if validation error
                    if (result.validation_errors) {
                        // return result.validation_errors;
                        for (const r in result.validation_errors) {
                            setFieldError(r, result.validation_errors[r][0]);
                        }
                    } else if (result.error) {
                        setErrorMessage(result.error);
                    } else if (result.detail) {
                        setErrorMessage(result.detail);
                    } else {
                        setErrorMessage(
                            "Couldn't process request. Please try again"
                        );
                    }
                }
                setSubmitting(false);
            })
            .catch((error) => {
                setErrorMessage(
                    "Some error occured at server side. Please try again later"
                );
                setSubmitting(false);
            });
    };

    let navigate = useNavigate();
    useEffect(() => {
        // if already loggedIn -- do smthing else
        if (loggedInUser.is_logged_in == true) {
            if (loggedInUser.is_verified) {
                dispatch(getInitialCartData());
                dispatch(getInitialSaveLaterData());
                navigate("/account/overview");
            } else {
                navigate("/account/verify");
            }
        }
    }, [loggedInUser]);

    return (
        // <!-- Login -->
        <section className="g-bg-gray-light-v5">
            <div className="container g-py-100">
                <div className="row justify-content-center">
                    <div className="col-sm-8 col-lg-5">
                        <div className="u-shadow-v21 g-bg-white rounded g-py-40 g-px-30">
                            <div className="text-center mb-4">
                                <h2 className="h2 g-color-black g-font-weight-600">
                                    Login
                                </h2>
                            </div>

                            <Formik
                                initialValues={{
                                    email: "",
                                    password: ""
                                }}
                                validationSchema={validationSchema}
                                onSubmit={(
                                    values,
                                    { setSubmitting, resetForm, setFieldError }
                                ) => {
                                    setTimeout(() => {
                                        setSuccessMessage("");
                                        setErrorMessage("");
                                        formSubmitHandler(
                                            JSON.stringify(values, null, 2),
                                            resetForm,
                                            setSubmitting,
                                            setFieldError
                                        );
                                    }, 400);
                                }}
                            >
                                {({ isSubmitting, setFieldValue }) => (
                                    // Form Start
                                    <Form className="g-py-15">
                                        {successMessage ? (
                                            <div className="alert alert-success">
                                                {successMessage}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        {errorMessage ? (
                                            <div className="alert alert-danger">
                                                {errorMessage}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <div className="mb-4">
                                            <label className="g-color-gray-dark-v2 g-font-weight-600 g-font-size-13">
                                                Email:
                                            </label>
                                            <Field
                                                className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v4 g-brd-primary--hover rounded g-py-15 g-px-15"
                                                name="email"
                                                type="email"
                                                placeholder="johndoe@gmail.com"
                                            />
                                            <ErrorMessage name="email">
                                                {(msg) => (
                                                    <div className="g-mt-10 text-danger field_error_message">
                                                        <i className="fa fa-info-circle g-mr-5"></i>
                                                        {msg}
                                                    </div>
                                                )}
                                            </ErrorMessage>
                                        </div>

                                        <div className="g-mb-35">
                                            <label className="g-color-gray-dark-v2 g-font-weight-600 g-font-size-13">
                                                Password:
                                            </label>
                                            <Field
                                                className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v4 g-brd-primary--hover rounded g-py-15 g-px-15 mb-3"
                                                name="password"
                                                type="password"
                                                placeholder="Password"
                                            />

                                            <ErrorMessage name="password">
                                                {(msg) => (
                                                    <div className="g-mt-10 text-danger field_error_message">
                                                        <i className="fa fa-info-circle g-mr-5"></i>
                                                        {msg}
                                                    </div>
                                                )}
                                            </ErrorMessage>

                                            <div className="row justify-content-between">
                                                <div className="col align-self-center text-right">
                                                    <Link
                                                        className="g-font-size-12"
                                                        to="/account/forget-password"
                                                    >
                                                        Forgot password?
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-4">
                                            <button
                                                className="btn btn-md btn-block u-btn-primary rounded g-py-13"
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                Login
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                            {/* <!-- End Form --> */}

                            <footer className="text-center">
                                <p className="g-color-gray-dark-v5 g-font-size-13 mb-0">
                                    Don't have an account?{" "}
                                    <Link
                                        className="g-font-weight-600"
                                        to="/account/register"
                                    >
                                        signup
                                    </Link>
                                </p>
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LoginPage;
