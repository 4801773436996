import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	userImages : []
};

export const userImagesSlice = createSlice({
	name : 'userImages',
	initialState,
	reducers : {
		set_userImages : (state, action) => {
			state.userImages = action.payload;
		},
	}
})

export const { set_userImages } = userImagesSlice.actions;

export default userImagesSlice.reducer;