import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { authUser } from "../features/authentication/userSlice";
import Loading from "./Loading";
import ReactPaginate from "react-paginate";

const format_date = (date) => {
    const months = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC"
    ];
    if (date === null || date === "") {
        return "";
    }
    let given_date = new Date(date);
    let new_date =
        given_date.getDate().toString().padStart(2, "0") +
        " " +
        months[given_date.getMonth()] +
        ", " +
        given_date.getFullYear();
    return new_date;
};
const RecipeReview = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [offset, setOffset] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const loggedInUser = useSelector(authUser);
    const [comments, setComments] = useState();
    const { item } = props;

    const getComments = () => {
        let recipe_comment_url =
            process.env.REACT_APP_API_BASE_URL +
            `/recipe-comment/?recipe__id=${item.id}&page_size=3&page=${offset}`;
        fetch(recipe_comment_url)
            .then((response) => response.json())
            .then((result) => {
                setComments(result.results);
                setTotalPages(result.total_pages);
                setIsLoading(false);
            });
    };
    useEffect(() => {
        if (item) {
            getComments();
        }
    }, [item, offset]);

    const handlePageClick = (event) => {
        const newOffset = event.selected + 1;
        setOffset(newOffset);
    };

    if (isLoading) {
        return <Loading />;
    }

    /**
     * Form Validation Rules [ Yup library used for validation ]
     */
    const validationSchema = Yup.object().shape({
        full_name: Yup.string().required("Name field is required"),
        email: Yup.string()
            .required("Email is required")
            .email("Valid email is required"),
        comment: Yup.string().max(500)
    });

    // Url required for submit product review -- post req
    const url = process.env.REACT_APP_API_BASE_URL + "/recipe-comment/";

    // this variable will store form submit response from api
    let global_response = "";

    const formSubmitHandler = (
        formData,
        resetForm,
        setSubmitting,
        setFieldError
    ) => {
        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: "POST",
            body: formData,
            headers: {
                "Content-Type": "application/json"
            }
        };

        if (loggedInUser.is_logged_in) {
            requestOptions.headers.Authorization =
                "Bearer " + loggedInUser.access_token;
        }

        fetch(url, requestOptions)
            .then((response) => {
                // console.log(response.json());
                global_response = response;
                return response.json();
            })
            .then((result) => {
                if (global_response.ok) {
                    // setSuccessMessage("Review Submitted Successfully");
                    toast.success("Thank You for your comment.");
                    resetForm();
                    getComments();
                } else {
                    //if validation error
                    if (result.validation_errors) {
                        // return result.validation_errors;
                        for (const r in result.validation_errors) {
                            setFieldError(r, result.validation_errors[r][0]);
                        }
                    } else {
                        setErrorMessage(
                            "Couldn't process request. Please try again"
                        );
                    }
                }
                setSubmitting(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <div className="g-brd-gray-light-v4 g-py-100">
            <div className="row justify-content-center">
                <div className="col-lg-9">
                    <h2 className="h4 mb-5">Comments</h2>

                    {/* <!-- Comments --> */}
                    <div className="g-brd-bottom g-brd-gray-light-v4 g-pb-30 g-mb-50">
                        {comments?.length > 0
                            ? comments.map((comment) => (
                                  <div className="media g-mb-30">
                                      {/* <img className="d-flex g-width-60 rounded-circle g-mt-3 g-mr-20" src="/assets/images/placeholder_images/user-icon.png" alt="Description" /> */}
                                      <div className="media-body">
                                          <div className="d-flex align-items-start g-mb-15 g-mb-10--sm">
                                              <div className="d-block">
                                                  <h5 className="h6">
                                                      {comment.full_name}
                                                  </h5>
                                                  <span className="d-block g-color-gray-dark-v5 g-font-size-11">
                                                      {format_date(
                                                          comment.created_at
                                                      )}
                                                  </span>
                                              </div>
                                          </div>

                                          <p>{comment.comment}</p>
                                      </div>
                                  </div>
                              ))
                            : ""}
                        {totalPages > 1 && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                    marginBottom: "25px",
                                    marginTop: "25px"
                                }}
                            >
                                <ReactPaginate
                                    nextLabel="Next"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    pageCount={totalPages}
                                    previousLabel="Previous"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        )}
                    </div>
                    {/* <!-- End Comments --> */}

                    <h2 className="h4 mb-5" id="rating">
                        Add Comment
                    </h2>

                    <Formik
                        initialValues={{
                            full_name: "",
                            email: "",
                            comment: "",
                            recipe: item.id
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(
                            values,
                            { setSubmitting, resetForm, setFieldError }
                        ) => {
                            setTimeout(() => {
                                setSuccessMessage("");
                                setErrorMessage("");
                                formSubmitHandler(
                                    JSON.stringify(values, null, 2),
                                    resetForm,
                                    setSubmitting,
                                    setFieldError
                                );
                            }, 400);
                        }}
                    >
                        {({ isSubmitting, setFieldValue }) => (
                            // Form Start
                            <Form className="g-py-15">
                                <div className="row">
                                    <div className="col-md-6 form-group g-mb-30">
                                        <Field
                                            className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--focus rounded-3 g-py-13 g-px-15"
                                            type="text"
                                            placeholder="Full Name"
                                            name="full_name"
                                        />

                                        <ErrorMessage name="full_name">
                                            {(msg) => (
                                                <div className="g-mt-10 text-danger field_error_message">
                                                    <i className="fa fa-info-circle g-mr-5"></i>
                                                    {msg}
                                                </div>
                                            )}
                                        </ErrorMessage>
                                    </div>

                                    <div className="col-md-6 form-group g-mb-30">
                                        <Field
                                            className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--focus rounded-3 g-py-13 g-px-15"
                                            type="email"
                                            placeholder="Email"
                                            name="email"
                                        />
                                        <ErrorMessage name="email">
                                            {(msg) => (
                                                <div className="g-mt-10 text-danger field_error_message">
                                                    <i className="fa fa-info-circle g-mr-5"></i>
                                                    {msg}
                                                </div>
                                            )}
                                        </ErrorMessage>
                                    </div>
                                </div>

                                {/* <!-- End Comment Form --> */}

                                <div className="g-mb-30">
                                    <Field
                                        className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--focus g-resize-none rounded-3 g-py-13 g-px-15"
                                        rows="12"
                                        placeholder="Your comment"
                                        as="textarea"
                                        name="comment"
                                    />
                                    <ErrorMessage name="comment">
                                        {(msg) => (
                                            <div className="g-mt-10 text-danger field_error_message">
                                                <i className="fa fa-info-circle g-mr-5"></i>
                                                {msg}
                                            </div>
                                        )}
                                    </ErrorMessage>
                                </div>

                                <div className="row align-items-center">
                                    <div className="col-5 col-sm-4 col-md-3">
                                        <button
                                            className="btn u-btn-primary g-font-size-12 text-uppercase g-py-15 g-px-25"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            Add Comment
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};
export default RecipeReview;
