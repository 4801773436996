
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { get_menu_url } from "../helper/Helper";
import HeaderWholesale from "./header_parts/HeaderWholesale";
import HeaderSearch from "./header_parts/HeaderSearch";
import HeaderCart from "./header_parts/HeaderCart";
import HeaderUser from "./header_parts/HeaderUser";
import MegaMenu from "./header_parts/MegaMenu";
import MegaMenuShop from "./header_parts/MegaMenuShop";
import MegaMenuNormal from "./header_parts/MegaMenuNormal";
import { get_fetched_menu_url, get_optimized_image_url } from "../helper/Helper";
import OffCanvasMenuShop from "./header_parts/OffcanvasMenuShop";


const HeaderSecond = (props) => {
    const url = process.env.REACT_APP_API_BASE_URL + '/menu?is_header=true';
    const [isLoading, setIsLoading] = useState(true);
    const [menus, setMenus] = useState([]);
    const [error, setError] = useState('');

    const [fixedHeader, setFixedHeader] = useState(false);
    const [sidecartActive, setSidecartActive] = useState(false);
    const [menuopenActive, setmenuopenActive] = useState(false);

    const [activeIndex, setActiveIndex] = useState("spice");
    const [activeDrop, setActiveDrop] = useState();



    useEffect(() => {
        fetch(url)
            .then(response => response.json())
            .then(result => {
                setMenus(result.results);
                setIsLoading(false);
            })
            .catch(error => {
                setError(error);
            });
    }, [url]);


    window.onscroll = () => {
        if (window.scrollY > 100) {
            setFixedHeader(true);
        }
        if (window.scrollY === 0) {
            setFixedHeader(false);
        }
    };

    const toggleSideCart = () => {
        if (sidecartActive) {
            setSidecartActive(false);
        } else {
            setSidecartActive(true);
        }
    }

    const openMenuDropdown = () => {
        if (menuopenActive) {
            setmenuopenActive(false);
        } else {
            setmenuopenActive(true);
        }

    }

    useEffect(() => {
        fetch(url)
            .then(response => response.json())
            .then(result => {
                setMenus(result.results);
                setIsLoading(false);
            })
            .catch(error => {
                setError(error);
            });
    }, [url]);

    return (
        <>
            {/* top header  */}
            <div className={"container-fluid shipping-discount g-py-10 " + (fixedHeader ? 'hide-top-bar' : '')}>
                <div className="row align-items-center">
                    <div className="col-md-12 d-flex justify-content-center">
                        <p className="g-font-size-13 mb-0 g-color-white">Free shipping on orders over $30.</p>
                    </div>
                </div>
            </div>


            {/* header */}
            <section className="header">
                <div className="homni-container">
                    <div className="row g-py-20 ">
                        <div className="col-md-12 d-flex justify-content-between align-items-center">
                            <div className="menu">
                                <div className="ruby-menu-demo-header">
                                    <div className="ruby-wrapper">
                                        <ul className="ruby-menu d-flex m-0">
                                            {
                                                (menus?.length > 0) &&
                                                menus[0].items.map((menu, index) => {
                                                    return (
                                                        menu.has_submenu ?
                                                            <li className="ruby-menu-mega-blog text-capitalize g-mr-15 align-items-center">

                                                                <a className="g-color-white d-flex align-items-center" href="#">{menu.title} <i class="fa fa-angle-down g-ml-5 g-color-white" aria-hidden="true"></i></a>
                                                                {
                                                                    menu.slug === 'shop' ?
                                                                        <MegaMenuShop menu={menu} key={"mega_menu_" + menu.id} />
                                                                        :
                                                                        <MegaMenuNormal menu={menu} key={"mega_menu_normal_" + menu.id} />
                                                                }

                                                            </li>
                                                            :
                                                            <li className="ruby-menu-mega-blog text-capitalize g-mr-15">
                                                                <Link to={get_menu_url(menu)} className="g-color-white">{menu.title}</Link>
                                                            </li>
                                                    )
                                                })
                                            }

                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="logo d-flex justify-content-center">
                                <Link to="/" className="">
                                    <img src="/assets/images/logo.png" alt="Homini LLC Logo" />
                                </Link>
                            </div>


                            <div className="action d-flex justify-content-end">
                                <HeaderWholesale />
                                <HeaderCart />
                                <HeaderSearch />
                                <HeaderUser />
                                <div className="mobile-view">
                                    {/* <!-- Basket --> */}
                                    <div className="hamburger-menu g-ml-15">
                                        <Link to="#" id="basket-bar-invoker" className="g-color-white g-text-underline--none--hover g-width-20 g-height-20" aria-controls="basket-bar" aria-haspopup="true" aria-expanded="false" data-dropdown-event="hover" data-dropdown-target="#basket-bar"
                                            data-dropdown-type="css-animation" data-dropdown-duration="500" data-dropdown-hide-on-scroll="false" data-dropdown-animation-in="fadeIn" data-dropdown-animation-out="fadeOut" onClick={toggleSideCart}>
                                            <i class="fa fa-bars position-relative g-top-3 g-font-size-20" aria-hidden="true"></i>
                                        </Link>
                                    </div>
                                    {/* <!-- End Basket --> */}
                                    <div className={"menu_overlay " + (sidecartActive ? 'active' : '')} onClick={toggleSideCart} />
                                    <div className={"menu_sideview " + (sidecartActive ? 'active' : '')}>
                                        <div className="mobile-menu">
                                            <div className="ruby-menu-demo-header">
                                                <div className="d-flex justify-content-between">
                                                    <h2 className="h6 g-pa-20 text-uppercase">Menu</h2>
                                                    <i className="fa fa-times g-pa-20" aria-hidden="true" onClick={toggleSideCart}></i>
                                                </div>
                                                <div className="ruby-wrapper">
                                                    <ul className="g-pt-10 g-pl-20 g-pr-20">
                                                        {
                                                            (menus?.length > 0) &&
                                                            menus[0].items.map((menu, index) => {
                                                                return (
                                                                    menu.has_submenu ?
                                                                        <li className={"ruby-menu-mega-blog text-capitalize g-mb-15 " + (activeDrop === "spice" ? 'active' : '')} onClick={() => setActiveDrop("spice")} ><a href="#">{menu.title}<i class="fa fa-caret-right" aria-hidden="true"></i></a>
                                                                            <div className={"mobile_menu_category_product " + (activeDrop === "spice" ? 'active' : '')}>
                                                                                <ul className="g-pt-10 g-pl-15">

                                                                                    <li className={"text-capitalize g-mb-15 " + (activeDrop === "akabare" ? 'active' : '')} onClick={() => setActiveDrop("akabare")}><a href="#">Shop<i class="fa fa-caret-right" aria-hidden="true"></i></a></li>
                                                                                    <div className={"dummy " + (activeDrop === "akabare" ? 'active' : '')}>
                                                                                        <ul className="dummy " >
                                                                                            {/* <li>xbhsxs</li> */}
                                                                                        </ul>
                                                                                    </div>

                                                                                </ul>
                                                                            </div>
                                                                        </li>
                                                                        :
                                                                        <li className="ruby-menu-mega-blog text-capitalize g-mr-15">
                                                                            <Link to={get_menu_url(menu)} className="g-color-white">{menu.title}</Link>
                                                                        </li>
                                                                )
                                                            })
                                                        }


                                                        <li className="ruby-menu-mega-blog text-capitalize g-mb-15 ">
                                                            <Link to="/wholesale" >
                                                                wholesale
                                                            </Link>
                                                        </li>

                                                        <li className="ruby-menu-mega-blog text-capitalize g-mb-15 ">
                                                            <Link to="/account/login" >
                                                                login
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
};

export default HeaderSecond;

