/*
 * This will show the Product Screen contains list of products
 */

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "../components/container/Container";
import ContainerWithGap from "../components/container/ContainerWithGap";
import Loading from "../components/Loading";
import HeadingCenter from "../components/page_headings/HeadingCenter";
import Pagination from "../components/Pagination";
import ReactPaginate from "react-paginate";
import { Dropdown } from "react-bootstrap";
import Animate from "../components/Animate";
import ProductGroup from "../components/ProductGroup";

const ProductPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [data, setData] = useState([]);
    const [activeCategory, setActiveCategory] = useState("All");
    const [clickedCategorySlug, setClickedCategorySlug] = useState("");
    const [filteredCategoryWiseProducts, setFilteredCategoryWiseProducts] =
        useState([]);
    const [initialSort, setInitialSort] = useState("");
    const [offset, setOffset] = useState(1);

    const [error, setError] = useState("");

    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        // this url with change based on pagination and sorting option
        let url =
            process.env.REACT_APP_API_BASE_URL +
            `/product?page=${offset}&ordering=${
                initialSort === "low To High" ? "our_price" : "-our_price"
            }`;
        const categoryUrl =
            process.env.REACT_APP_API_BASE_URL + `/product-category/`;
        fetch(url)
            .then((response) => response.json())
            .then((result) => {
                setData(result);
                setIsLoading(false);
            })
            .catch((error) => {});

        fetch(categoryUrl)
            .then((response) => response.json())
            .then((data) => {
                setCategories(data.results);
            })
            .catch((error) => {});
    }, [offset, initialSort]);

    //calling after category change
    useEffect(() => {
        setOffset(1);
        setIsLoading(true);
        if (activeCategory === "All") {
            setFilteredCategoryWiseProducts(data);
        }
        fetch(
            baseUrl +
                `/product/?category__slug=${clickedCategorySlug}&page=${offset}&ordering=${
                    initialSort === "low To High" ? "our_price" : "-our_price"
                }`
        )
            .then((response) => response.json())
            .then((data) => {
                setFilteredCategoryWiseProducts(data);
                setIsLoading(false);
            })
            .catch((error) => {});
    }, [clickedCategorySlug, initialSort]);

    useEffect(() => {
        if (!clickedCategorySlug) return;
        setInitialSort("");
    }, [clickedCategorySlug]);

    const handleCategoryClick = (name, slug) => {
        setOffset(1);
        setActiveCategory(name);
        setClickedCategorySlug(slug);
    };

    const handlePageClick = (event) => {
        window.scrollTo(0, 0);
        const newOffset = event.selected + 1;
        setOffset(newOffset);
    };

    const handlePriceSorting = (sortStyle) => {
        setInitialSort(sortStyle);
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            <HeadingCenter
                title="Products"
                background="/assets/removable_images/product_banner.jpeg"
            />
            <Container>
                <div className="row">
                    <Animate>
                        <div className="col-md-2">
                            <div className="g-pt-40 product-menu-category">
                                <h2 className="h6 border-bottom pb-4">
                                    Categories
                                </h2>
                                {categories.length > 0 && (
                                    <>
                                        <ul className="m-0 p-0 text-light g-mt-30">
                                            <li
                                                className="g-mb-10 g-font-weight-500"
                                                style={{
                                                    cursor: "pointer",
                                                    color: `${
                                                        activeCategory === "All"
                                                            ? "green"
                                                            : "black"
                                                    }`
                                                }}
                                                onClick={() =>
                                                    handleCategoryClick("All")
                                                }
                                            >
                                                All
                                            </li>
                                            {categories.map((categoryItems) => (
                                                <li
                                                    className="g-mb-10 g-font-weight-500"
                                                    style={{
                                                        cursor: "pointer",
                                                        color: `${
                                                            activeCategory ===
                                                            categoryItems.name
                                                                ? "green"
                                                                : "black"
                                                        }`
                                                    }}
                                                    onClick={() =>
                                                        handleCategoryClick(
                                                            categoryItems.name,
                                                            categoryItems.slug
                                                        )
                                                    }
                                                >
                                                    {categoryItems.name}
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                )}
                            </div>
                        </div>
                    </Animate>
                    <div className="col-md-9">
                        <div className="d-flex  category-wrapper-product justify-content-md-end g-brd-gray-light-v4 g-brd-bottom">
                            <div className="g-pt-40 d-md-none">
                                <Dropdown>
                                    <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                    >
                                        Categories
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {categories.length > 0 && (
                                            <>
                                                <ul className="g-pa-15 text-light">
                                                    <li
                                                        className="g-mb-10 g-font-weight-500"
                                                        style={{
                                                            cursor: "pointer",
                                                            color: `${
                                                                activeCategory ===
                                                                "All"
                                                                    ? "green"
                                                                    : "black"
                                                            }`
                                                        }}
                                                        onClick={() =>
                                                            handleCategoryClick(
                                                                "All"
                                                            )
                                                        }
                                                    >
                                                        All
                                                    </li>
                                                    {categories.map(
                                                        (categoryItems) => (
                                                            <li
                                                                className="g-mb-10 g-font-weight-500"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    color: `${
                                                                        activeCategory ===
                                                                        categoryItems.name
                                                                            ? "green"
                                                                            : "black"
                                                                    }`
                                                                }}
                                                                onClick={() =>
                                                                    handleCategoryClick(
                                                                        categoryItems.name,
                                                                        categoryItems.slug
                                                                    )
                                                                }
                                                            >
                                                                {
                                                                    categoryItems.name
                                                                }
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            </>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="d-md-flex align-items-center  g-pt-40 g-pb-20">
                                {/* <!-- Sort By --> */}
                                <div className="">
                                    <h2 className="h6 align-middle d-inline-block g-font-weight-400 text-capitalize g-pos-rel g-top-1 mb-0">
                                        Sort by:
                                    </h2>

                                    {/* <!-- Secondary Button --> */}
                                    <div className="d-inline-block btn-group g-line-height-1_2">
                                        <button
                                            type="button"
                                            className="btn btn-secondary dropdown-toggle h6 align-middle g-brd-none g-color-gray-dark-v5 g-color-black--hover g-bg-transparent text-capitalize g-font-weight-300 g-font-size-12 g-pa-0 g-pl-10 g-ma-0"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            {initialSort}
                                        </button>
                                        <div className="dropdown-menu rounded-0 sortby-dropdown-menu">
                                            {/* <Link className="dropdown-item g-color-gray-dark-v4 g-font-weight-300" to="#">Bestseller</Link>
										<Link className="dropdown-item g-color-gray-dark-v4 g-font-weight-300" to="#">New Arrival</Link> */}
                                            <Link
                                                onClick={() =>
                                                    handlePriceSorting(
                                                        "low To High"
                                                    )
                                                }
                                                className="dropdown-item g-color-gray-dark-v4 g-font-weight-300"
                                                to="#"
                                            >
                                                Price low to high
                                            </Link>
                                            <Link
                                                className="dropdown-item g-color-gray-dark-v4 g-font-weight-300"
                                                to="#"
                                                onClick={() =>
                                                    handlePriceSorting(
                                                        "high To Low"
                                                    )
                                                }
                                            >
                                                price high to low
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Sort By --> */}

                                {/* <!-- Sort By --> */}
                                <ul className="list-inline mb-0">
                                    {/* <li className="list-inline-item">
								<Link className="u-icon-v2 u-icon-size--xs g-brd-gray-light-v3 g-brd-black--hover g-color-gray-dark-v5 g-color-black--hover" to="#">
									<i className="icon-list"></i>
								</Link>
								</li>
								<li className="list-inline-item">
								<Link className="u-icon-v2 u-icon-size--xs g-brd-primary g-color-primary" to="#">
									<i className="icon-grid"></i>
								</Link>
								</li> */}
                                </ul>
                                {/* <!-- End Sort By --> */}
                            </div>
                        </div>
                        {activeCategory === "All"
                            ? !!data.results && (
                                  <ProductGroup items={data.results} />
                              )
                            : !!filteredCategoryWiseProducts.results > 0 && (
                                  <ProductGroup
                                      items={
                                          filteredCategoryWiseProducts.results
                                      }
                                  />
                              )}
                    </div>
                    {Object.keys(data).length > 0 &&
                    activeCategory === "All" ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                                marginBottom: "25px"
                            }}
                        >
                            <ReactPaginate
                                nextLabel="Next"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                pageCount={data.total_pages}
                                previousLabel="Previous"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    ) : (
                        filteredCategoryWiseProducts?.results?.length > 20 && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                    marginBottom: "25px"
                                }}
                            >
                                <ReactPaginate
                                    nextLabel="Next"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    pageCount={
                                        filteredCategoryWiseProducts.total_pages
                                    }
                                    previousLabel="Previous"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        )
                    )}
                </div>
            </Container>
        </>
    );
};
export default ProductPage;
