import React from "react";
import Chilli from "../../../assets/img/chilli.svg";
import ChilliRed from "../../../assets/img/chilliRed.svg";
import Application from "../../../assets/images/productCategoryImages/application.svg";
import Aromatherapy from "../../../assets/images/productCategoryImages/aromatherapy.svg";
// import clock from "../../../assets/images/productCategoryImages/clock.svg";
// import Help from "../../../assets/images/productCategoryImages/help.svg";
import Herbal from "../../../assets/images/productCategoryImages/herbal.svg";
// import Tea from "../../../assets/images/productCategoryImages/herbal-tea.svg";
import OpenBook from "../../../assets/images/productCategoryImages/open-book.svg";
import Spoon from "../../../assets/images/productCategoryImages/spoon.svg";
import Whisk from "../../../assets/images/productCategoryImages/whisk.svg";
import PairWellWith from "../../../assets/images/productCategoryImages/pair-well-with.svg";
import FourSimpleIngradients from "../../../assets/images/productCategoryImages/four-simple-ingredients.png";
import Characteristics from "../../../assets/images/productCategoryImages/characteristics.png";
const ProductDetailCategory = ({ data }) => {
    const spiceLevelCalculation = (level) => {
        const totalLevel = 5;
        const presentLevel = Number(level) > 5 ? 5 : Number(level);
        const remainingLevel = totalLevel - presentLevel;

        let data = [];
        for (let i = 0; i < presentLevel; i++) {
            data.push("present");
        }
        for (let i = 0; i < remainingLevel; i++) {
            data.push("remaining");
        }
        return data.map((d, index) => {
            return (
                <div className="ml-2">
                    {d === "present" ? (
                        <img
                            key={index}
                            src={ChilliRed}
                            className="d-flex g-pos-rel g-top-3"
                            alt="good pairs for the spice"
                            width="20px"
                            height="20px"
                        />
                    ) : (
                        <img
                            key={index}
                            src={Chilli}
                            className="d-flex g-pos-rel g-top-3"
                            alt="good pairs for the spice"
                            width="20px"
                            height="20px"
                        />
                    )}
                </div>
            );
        });
    };
    const selectedImage = {
        "four-simple-ingredients": FourSimpleIngradients,
        characteristics: Characteristics,
        "pairs-well-with": PairWellWith,
        "tastes-like": Spoon,
        "direction-for-use": OpenBook,
        "use-in-traditional-medicines": Herbal,
        "common-application": Application,
        "use-in-aromatherapy": Aromatherapy,
        "scent-profile": Aromatherapy,
        "blends-well-with": Whisk
    };

    return (
        <div
            className="g-brd-y g-brd-gray-light-v4"
            style={{ marginTop: "-7px" }}
        >
            <div className="homni-container g-py-30">
                <div className="row justify-content-center">
                    {data &&
                        data.map((item) => {
                            return (
                                <div
                                    id={item.id}
                                    className="col-md-6 col-lg-4 col-xl-3 mx-auto g-py-15 g-brd-right--md d-flex align-items-center"
                                >
                                    {item.slug === "spice-level" ? (
                                        ""
                                    ) : (
                                        <img
                                            src={
                                                selectedImage[item.slug]
                                                    ? selectedImage[item.slug]
                                                    : item.icon
                                            }
                                            className="d-flex g-pos-rel g-top-3 mr-4"
                                            alt="good pairs for the spice"
                                            width="40px"
                                            height="40px"
                                        />
                                    )}
                                    <div className="media g-px-5--lg">
                                        <div className="d-flex align-items-center">
                                            <div className="media-body">
                                                <span className="d-block g-font-weight-500 g-font-size-16">
                                                    {item.title}
                                                </span>
                                                <div className="row">
                                                    {item.value.map(
                                                        (i, index) => {
                                                            return (
                                                                <div
                                                                    className={
                                                                        item.slug ===
                                                                        "spice-level"
                                                                            ? "d-flex"
                                                                            : ""
                                                                    }
                                                                    // className={
                                                                    //     index ===
                                                                    //     item
                                                                    //         .value
                                                                    //         .length -
                                                                    //         1
                                                                    //         ? "col-md-4 col-sm-4 col-xs-12 mx-0"
                                                                    //         : "col-md-4 col-sm-4 col-xs-12 mx-0"
                                                                    // }
                                                                >
                                                                    {item.slug ===
                                                                    "spice-level" ? (
                                                                        spiceLevelCalculation(
                                                                            i
                                                                        )
                                                                    ) : (
                                                                        <div
                                                                            className={
                                                                                index ===
                                                                                item
                                                                                    .value
                                                                                    .length -
                                                                                    1
                                                                                    ? " ml-3 pr-3"
                                                                                    : " ml-3 pr-3 border-right border-#5D5174"
                                                                            }
                                                                        >
                                                                            {i}
                                                                        </div>
                                                                        // <span
                                                                        //     key={
                                                                        //         index
                                                                        //     }
                                                                        //     className="d-block g-color-primary g-font-size-15 mx-2 px-2"
                                                                        // >
                                                                        //     {i}
                                                                        // </span>
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    {data &&
                        data.map((r) => {
                            return r.slug === "spice-level" ? (
                                <div key={r.id} className="col-md-3 mx-auto">
                                    <div className="media g-px-20--lg">
                                        <div className="overlay position-relative">
                                            <img
                                                src={
                                                    "/assets/removable_images/product_recipe.jpg"
                                                }
                                                className="w-100"
                                                alt="good pairs for the spice"
                                                style={{ opacity: "0.4" }}
                                            />
                                        </div>
                                        <a
                                            class="btn btn-md u-btn-outline-primary g-pos-abs product-blog-link position-absolute view-recipe"
                                            href="#recipe"
                                        >
                                            View Recipe
                                        </a>
                                    </div>
                                </div>
                            ) : (
                                ""
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default ProductDetailCategory;
