import ContainerWithGap from "../components/container/ContainerWithGap";


const CommitmentPage = (props) => {
    return (
        <>
            <section className="clearfix commitment-banner">
                <div className="js-bg-video position-relative commitment-wrapper">
                    <div className="g-bg-cover g-pos-rel g-z-index-1 g-min-height-600" style={{ backgroundImage: "url('/assets/removable_images/farm.png')", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                        <div className="homni-container g-py-50 position-absolute g-z-index-1 commitment-container">
                            <div className="row g-mx-100 g-z-index-1 ">
                                <div className="col-12 g-z-index-1 ">
                                    <h1 className="g-color-white">Our Mission</h1>
                                    <p className="g-color-white">Generate supply chain that will transfer agriculture <br /> into the leading industry for social, enviromental and <br />economic sustainaibility</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ContainerWithGap>
                <div className="row commitment-mission-wrapper g-mb-50">
                    <div className="col-12 col-lg-4 mission-first-para-column">
                        <div className="mission-first-para">
                            <div className="mb-4">
                                <h3 className="mb-4">Our Mission</h3>
                                <p className="g-font-size-16 g-line-height-2">Generate supply chain that will transfer agriculture into the leading industry for social, enviromental and economic sustainaibility</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 d-md-flex mission-column">
                        <div className="icon g-mr-25">
                            <img src="/assets/images/diversity.png" alt="description" />
                        </div>
                        <div className="mission-text">
                            <h3 className="mb-2">Diversity</h3>
                            <p className="g-font-size-16 g-line-height-2">Hiring and purchasing will prioritize diversity in race, ethnicity sexuality, gender, socioeconomic class, and religion, before profits.</p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 d-md-flex mission-column">
                        <div className="icon g-mr-25">
                            <img src="/assets/images/regenerate.png" alt="description" />
                        </div>
                        <div className="mission-text">
                            <h3 className="mb-2">Regenerative</h3>
                            <p className="g-font-size-16 g-line-height-2">All partner producers will within one year of signing on with Homni, produce actionable paths to regenerative production.</p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 g-mt-20 d-md-flex mission-column">
                        <div className="icon g-mr-25">
                            <img src="/assets/images/dignity.png" alt=" description" />
                        </div>
                        <div className="mission-text">
                            <h3 className="mb-2">Dignity</h3>
                            <p className="g-font-size-16 g-line-height-2">All products will be purchased at rates at least 30% above fair trade. By 2024, we aim for all purchases to support a middle-class lifestyle for every member of our supply chain.</p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 g-mt-20 d-md-flex mission-column">
                        <div className="icon g-mr-25">
                            <img src="/assets/images/transparency.png" alt=" description" />
                        </div>
                        <div className="mission-text">
                            <h3 className="mb-2">Hypertransparency</h3>
                            <p className="g-font-size-16 g-line-height-2">All products and value add transactions will be traceable to at least the collective level. By 2023, every farm product will be traceable at the individual farm level.</p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 g-mt-20 d-md-flex mission-column">
                        <div className="icon g-mr-25">
                            <img src="/assets/images/quality.png" alt="description" />
                        </div>
                        <div className="mission-text">
                            <h3 className="mb-2">Quality</h3>
                            <p className="g-font-size-16 g-line-height-2">There is no such thing as good enough. We are a continual improvement company and we understand that holistic quality can and should always be improved. </p>
                        </div>
                    </div>
                </div>
            </ContainerWithGap>
            <section className="clearfix commitment-banner">
                <div className="js-bg-video position-relative commitment-wrapper">
                    <div className="g-bg-cover g-pos-rel g-z-index-1 g-min-height-400" style={{ backgroundImage: "url('/assets/removable_images/boat.jpg')", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                        <div className="homni-container g-py-50 position-absolute g-z-index-1 commitment-container">
                            <div className="row g-mx-100 g-z-index-1 ">
                                <div className="col-md-6 g-z-index-1 ">
                                    <h1 className="g-color-white">Zero Profit Commitment</h1>
                                    <p className="g-color-white">Until every member of our supply chain receives compensation to support a middle-class lifestyle, and until our environmental footprint is net-regenerative, all profits will be invested towards achieving those goals.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CommitmentPage;
