import Cookies from "js-cookie";
import { toast } from "react-toastify";
import {
    add_to_cart,
    remove_from_cart,
    reduce_from_cart,
    use_discount_code,
    apply_discount_code,
    remove_discount_code,
    use_subscribe_discount,
    apply_subscribe_discount,
    remove_subscribe_discount,
    set_donate,
    set_is_gift,
    set_post_card,
    set_gift_msg,
    set_shipping_address,
    clean_cart,
    set_billing_address,
    set_is_billing_shipping_add_same,
    set_delivery_detail,
    set_tax
} from "../features/cartSlice";
import { removeSaveLater } from "./SaveLaterHelper";

const processShippingAddress = async (value, cart) => {
    // check if shipping address is verified and if verified get tax amout and shipping charge and set to redux
    let shipping_verification_url =
        process.env.REACT_APP_API_BASE_URL + "/shipping/";

    const fetchOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            cart: cart,
            shipping_address: value
        })
    };

    try {
        const response = await fetch(shipping_verification_url, fetchOptions);
        if (!response.ok) {
            return {
                tax: null,
                shipping_address_verified: false
            };
        }
        const result = await response.json();
        return {
            tax: result.tax_dict.amount_to_collect,
            shipping_address_verified: true
        };
    } catch (err) {
        return {
            tax: null,
            shipping_address_verified: false
        };
    }
};

export const addToCart = (
    product_id,
    qty,
    dispatch,
    varient = {},
    productDetailsPage = false,
    varient_check,
    varient_id
) => {
    console.log("var check", varient_check);
    let addToCartUrl;

    if (productDetailsPage || varient_check) {
        addToCartUrl =
            process.env.REACT_APP_API_BASE_URL +
            "/cart/add_items/?" +
            "quantity=" +
            qty +
            `${
                varient?.default_varient
                    ? `&product_id=${varient.id}`
                    : `&variant_id=${
                          productDetailsPage ? varient.id : product_id
                      }`
            }`;
    } else {
        addToCartUrl =
            process.env.REACT_APP_API_BASE_URL +
            "/cart/add_items/?product_id=" +
            product_id +
            "&quantity=" +
            qty;
    }

    const cookie = Cookies.get("access_token");

    const header_without_cookie = {
        "Content-Type": "application/json"
    };

    const header_with_cookie = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("access_token")
    };

    const fetchOptions = {
        method: "GET",
        headers: cookie ? header_with_cookie : header_without_cookie
    };

    fetch(addToCartUrl, fetchOptions)
        .then((response) => response.json())
        .then((result) => {
            if (
                result[product_id]?.message?.toLowerCase() == "success" ||
                result[varient.id]?.message?.toLowerCase() == "success"
            ) {
                let product = productDetailsPage
                    ? result[varient.id]
                    : result[product_id];
                if (productDetailsPage && Object.keys(varient).length > 0) {
                    dispatch(
                        add_to_cart({
                            product_id: varient.id,
                            qty: qty,
                            image: product?.image,
                            price: varient.our_price,
                            attributes: product?.attributes,
                            title: varient.name,
                            slug: varient.slug,
                            is_variant: varient?.default_varinet ? false : true,
                            varient_id: varient.id,
                            default_varient: varient?.default_varient
                        })
                    );
                }

                if (!productDetailsPage) {
                    dispatch(
                        add_to_cart({
                            product_id: product_id,
                            qty: qty,
                            image: product?.image,
                            price: product?.price,
                            attributes: product?.attributes,
                            title: product?.title,
                            slug: product?.slug,
                            is_variant: product?.is_variant
                        })
                    );
                }
                toast.success("Product added to cart");
            } else {
                toast.error(result[product_id]?.message);
            }
        });
};

export const removeFromCart = (product_id, dispatch, is_variant) => {
    let removeFromCartUrl;

    if (is_variant) {
        removeFromCartUrl =
            process.env.REACT_APP_API_BASE_URL +
            "/cart/remove_items/?variant_id=" +
            product_id;
    } else {
        removeFromCartUrl =
            process.env.REACT_APP_API_BASE_URL +
            "/cart/remove_items/?product_id=" +
            product_id;
    }
    const cookie = Cookies.get("access_token");

    const header_without_cookie = {
        "Content-Type": "application/json"
    };

    const header_with_cookie = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("access_token")
    };

    const fetchOptions = {
        method: "GET",
        headers: cookie ? header_with_cookie : header_without_cookie
    };

    fetch(removeFromCartUrl, fetchOptions)
        .then((response) => response.json())
        .then((result) => {
            dispatch(
                remove_from_cart({
                    product_id: product_id
                })
            );
        })
        .catch((error) => {
            toast.error("Something went wrong");
        });
};

export const reduceFromCart = (product_id, dispatch, is_variant) => {
    let reduceFromCart;

    if (is_variant) {
        reduceFromCart =
            process.env.REACT_APP_API_BASE_URL +
            "/cart/remove_items/?variant_id=" +
            product_id +
            "&quantity=-1";
    } else {
        reduceFromCart =
            process.env.REACT_APP_API_BASE_URL +
            "/cart/remove_items/?product_id=" +
            product_id +
            "&quantity=-1";
    }

    const fetchOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("access_token")
        }
    };

    fetch(reduceFromCart, fetchOptions)
        .then((response) => response.json())
        .then((result) => {
            dispatch(
                reduce_from_cart({
                    product_id: product_id
                })
            );
        })
        .catch((error) => {
            toast.error("Something went wrong");
        });
};

export const cleanCart = (dispatch) => {
    dispatch(clean_cart());
};

export const toggleDiscountCodeUse = (value, dispatch) => {
    dispatch(use_discount_code(value));
};

export const applyDiscountCode = (value, dispatch) => {
    let url =
        process.env.REACT_APP_API_BASE_URL + "/discount-coupon/" + value + "/";
    fetch(url)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw Error(response.statusText);
            }
        })
        .then((result) => {
            dispatch(apply_discount_code(result));
            toast.success("Discount Code applied");
        })
        .catch((error) => {
            toast.error("Invalid Discount Code");
        });
};

export const removeDiscountCode = (dispatch) => {
    dispatch(remove_discount_code(""));
};

export const toggleUseSubscribeDiscount = (value, dispatch) => {
    dispatch(use_subscribe_discount(value));
};

export const applySubscribeDiscount = (email, dispatch) => {
    let url = process.env.REACT_APP_API_BASE_URL + "/newsletter/check/";

    fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ email: email })
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw Error(response.statusText);
            }
        })
        .then((result) => {
            if (result) {
                dispatch(
                    apply_subscribe_discount({
                        email: email,
                        amt: result.discount
                    })
                );
                toast.success("Subscription validated.");
            } else {
                toast.error(
                    "This email is not used for subscription. Please subscribe and try again."
                );
            }
        })
        .catch((error) => {
            toast.error(
                "This email is not used for subscription. Please subscribe and try again."
            );
        });
};

export const removeSubscribeDiscount = (dispatch) => {
    dispatch(remove_subscribe_discount());
};

export const applyDonation = (
    { doDonate, donate_amt, custom_donate },
    dispatch
) => {
    dispatch(
        set_donate({
            donate: doDonate,
            donate_amt: donate_amt,
            custom_donate: custom_donate
        })
    );
};

export const toggleIsGift = (value, dispatch) => {
    dispatch(set_is_gift(value));
};

export const selectPostCard = (post_card_id, post_card_price, dispatch) => {
    dispatch(
        set_post_card({
            id: post_card_id,
            price: post_card_price
        })
    );
};

export const setGiftMessage = (msg, dispatch) => {
    dispatch(set_gift_msg(msg));
};

// Shipping Address
export const setShippingAddress = (value, cart, dispatch) => {
    const res = processShippingAddress(value, cart);
    dispatch(set_shipping_address(value));
    dispatch(set_tax(res));
};

export const setBillingAddress = (value, cart, dispatch) => {
    dispatch(set_billing_address(value));

    if (cart.is_billing_shipping_add_same) {
        processShippingAddress(value, cart).then((res) => {
            dispatch(set_shipping_address(value));
            dispatch(set_tax(res));
        });
    }
};

export const setIsBillngAndShippingAddressSame = (value, cart, dispatch) => {
    dispatch(set_is_billing_shipping_add_same(value));
};

export const setDeliveryCharge = (value, dispatch) => {
    dispatch(set_delivery_detail(value));
};

export const updatePyamentIntent = (pyamentInt, dispatch) => {};
