import ContainerWithGap from "./container/ContainerWithGap";

const Loading = () => {

	return (
		<>
			<ContainerWithGap>
				<div className="row position-relative g-py-200">
					<div className="col g-py-200">
						<div class="loader">
							<div class="dot"></div>
							<div class="dot"></div>
							<div class="dot"></div>
							<div class="dot"></div>
							<div class="dot"></div>
						</div>
					</div>
				</div>
			</ContainerWithGap>
		</>
	);

}

export default Loading;