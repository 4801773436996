import Animate from "./Animate";
import ProductBlock from "./ProductBlock";

const ProductGroup = (props) => {
    const items = props.items;
    return (
        <Animate>
            <div id="shortcode25">
                <div className="shortcode-html g-mt-30">
                    <div className="row">
                        {items.length > 0 ? (
                            items.map((item, index) => (
                                <ProductBlock
                                    item={item}
                                    key={"PrdList_" + index}
                                    showflavour={props.showflavour}
                                />
                            ))
                        ) : (
                            <>No Products Found</>
                        )}
                    </div>
                </div>
            </div>
        </Animate>
    );
};
export default ProductGroup;
