import { useState } from "react";

const QuantityChange = (props) => {
    const [quantity, setquantity] = useState(props.quantity);
    const updateQuantityValue = (value) => {
        setquantity(value);
        props.setQuantity(value);
    };

    const handleQuantityIncrement = () => {
        let new_quantity = quantity + 1;
        updateQuantityValue(new_quantity);
    };

    const handleQuantityDecrement = () => {
        let new_quantity = quantity - 1;
        if (new_quantity > 0) {
            updateQuantityValue(new_quantity);
        }
    };

    return (
        <div
            className="cart_quantity_change bg-light d-inline-block px-3"
            style={{ fontSize: "14px" }}
        >
            <button
                className="g-mr-10 cart_quantity quantity_decrement"
                onClick={handleQuantityDecrement}
            >
                -
            </button>
            <span>{quantity}</span>
            <button
                className="g-ml-10 cart_quantity quantity_increment"
                onClick={handleQuantityIncrement}
            >
                +
            </button>
        </div>
    );
};

export default QuantityChange;
