import Cookies from "js-cookie";
import { remove_later_items, save_later_items } from "../features/cartSlice";
import { toast } from "react-toastify";
import { removeFromCart } from "./CartHelper";

export const addToSaveLater = (item, dispatch, show_message = true) => {
    const addToSaveUrl =
        process.env.REACT_APP_API_BASE_URL +
        "/save-later/add_product/?waitlist=false";

    const postData = async (url = "", data = {}) => {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + Cookies.get("access_token"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
            .then((result) => {
                if (result?.ok) {
                    dispatch(save_later_items(item));
                    dispatch(
                        removeFromCart(
                            item.product_id,
                            dispatch,
                            item.is_variant
                        )
                    );

                    if (show_message) {
                        toast.success("Product added to save later");
                    }
                } else {
                    toast.error("Please login to add items");
                }
            })
            .catch((error) => {
                // toast.error("Something went wrong");
            });
    };

    postData(
        addToSaveUrl,
        item.is_variant
            ? { variant: item.product_id }
            : { product: item.product_id }
    ).then((data) => {});
};

export const removeSaveLater = (item, dispatch, show_message = true) => {
    const addToSaveUrl =
        process.env.REACT_APP_API_BASE_URL +
        `/save-later/remove_product/?waitlist=false`;

    const postData = async (url = "", data = {}) => {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("access_token")
            },
            body: JSON.stringify(data)
        })
            .then((result) => {
                if (result.status == 200) {
                    dispatch(remove_later_items(item));
                    if (show_message) {
                        toast.success("Product removed from save later");
                    }
                } else {
                    toast.error("Error");
                }
            })
            .catch((error) => {
                toast.error("Something went wrong");
            });
    };

    postData(
        addToSaveUrl,
        item.is_variant
            ? { variant: item.product_id }
            : { product: item.product_id }
    ).then((data) => {
        // console.log(data);
    });
};
