import { useEffect, useState } from "react";
import ContainerWithGap from "./container/ContainerWithGap";
import Loading from "./Loading";
import ProductGroupSlider from "./ProductGroupSlider";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Animate from "./Animate";

/**
 * Landing page - Product Collection Section
 */
const ProductCollection = () => {
    const [loading, setLoding] = useState(true);
    const [error, setError] = useState();
    const [items, setItems] = useState([]);

    const url =
        process.env.REACT_APP_API_BASE_URL +
        "/product-collection?show_on_homepage=true";

    useEffect(() => {
        fetch(url)
            .then((response) => response.json())
            .then((result) => {
                setItems(result.results);
                setLoding(false);
            })
            .catch((error) => {
                //handle error here
                setError(error);
            });
    }, []);

    if (loading) {
        return <Loading />;
    }

    if (items.length <= 0) {
        return <></>;
    }

    return (
        <Animate>
            <ContainerWithGap>
                <Tabs>
                    <TabList className="nav justify-content-center pc_tab_nav">
                        {items.map((item, index) => (
                            <Tab
                                className="nav-item"
                                key={"pro_grp_slider_hp_header_" + item.id}
                            >
                                {item.name}
                            </Tab>
                        ))}
                    </TabList>

                    {items.map((item, index) => (
                        <TabPanel key={"tab_panel_" + item.id + "_" + index}>
                            <ProductGroupSlider
                                item={item}
                                key={"pro_grp_slider_hp_" + item.id}
                            />
                        </TabPanel>
                    ))}
                </Tabs>
            </ContainerWithGap>
        </Animate>
    );
};

export default ProductCollection;
