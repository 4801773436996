import { Link } from "react-router-dom";
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { authUser, login } from "../../features/authentication/userSlice";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

/**
 * For From Develop : Formik Library is used. 
 * Ref url : https://formik.org/docs/api/formik 
 */

const RegisterPage = (props) => {
	const dispatch = useDispatch();
	const loggedInUser = useSelector(authUser);


	/**	 
	 * Form Validation Rules [ Yup library used for validation ]
	 */
	const validationSchema = Yup.object().shape({
		first_name: Yup.string().required("First Name field is required"),
		last_name: Yup.string().required("Last Name field is required"),
		email: Yup.string().required('Email is required').email(),
		password: Yup.string().required('Password Required'),
		confirm_password: Yup.string().required('Confirm Password Required'),
		accept_policy: Yup.string().required('Please Read and accept our Terms and Conditions before signup.')
	});

	const [successMessage, setSuccessMessage] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	// Url required for signup 
	const url = process.env.REACT_APP_API_BASE_URL + '/accounts/signup';

	// this variable will store form submit response from api 
	let global_response = "";

	const formSubmitHandler = (formData, resetForm, setSubmitting, setFieldError) => {
		// POST request using fetch inside useEffect React hook
		const requestOptions = {
			method: 'POST',
			body: formData,
			headers: { 'Content-Type': 'application/json' }
		};
		fetch(url, requestOptions)
			.then((response) => {
				// console.log(response.json());
				global_response = response;
				return response.json();
			})
			.then((result) => {
				if (global_response.ok) {
					setSuccessMessage("User Registration Successful");

					//set details in Redux
					dispatch(login(result));

					//set tokens in cookie 
					// if clause for handling -- possible crash
					if (result.access_token && result.refresh_token) {

						Cookies.set('access_token', result.access_token, { path: '/' });
						Cookies.set('refresh_token', result.refresh_token, { path: '/' });
					}

					resetForm();
				} else {
					//if validation error
					if (result.validation_errors) {
						// return result.validation_errors;
						for (const r in result.validation_errors) {
							setFieldError(r, result.validation_errors[r][0]);
						}
					} else {
						setErrorMessage("Couldn't process request. Please try again");
					}
				}
				setSubmitting(false);
			})
			.catch((error) => {
				console.log(error);
			})
	}

	let navigate = useNavigate();
	useEffect(() => {
		// if already loggedIn -- do smthing else 
		if (loggedInUser.is_logged_in == true) {
			if (loggedInUser.is_verified) {
				navigate('/account/overview');
			} else {
				navigate('/account/verify');
			}
		}
	}, [loggedInUser])



	// if not logged in -  SignUp page 
	return (
		// <!-- Signup Section Start -->
		<section className="g-bg-gray-light-v5">
			<div className="container g-py-100">
				<div className="row justify-content-center">
					<div className="col-sm-10 col-md-9 col-lg-6">
						<div className="u-shadow-v21 g-bg-white rounded g-py-40 g-px-30">
							<div className="text-center mb-4">
								<h2 className="h2 g-color-black g-font-weight-600">Signup</h2>
							</div>

							<Formik
								initialValues={{
									first_name: '',
									last_name: '',
									email: '',
									password: '',
									confirm_password: '',
									accept_policy: ''
								}}
								validationSchema={validationSchema}
								onSubmit={(values, { setSubmitting, resetForm, setFieldError }) => {
									setTimeout(() => {
										setSuccessMessage("");
										setErrorMessage("");
										formSubmitHandler(JSON.stringify(values, null, 2), resetForm, setSubmitting, setFieldError);
									}, 400);
								}}
							>
								{({ isSubmitting, setFieldValue }) => (
									// Form Start 
									<Form className="g-py-15">
										{successMessage ? <div className="alert alert-success">{successMessage}</div> : ''}
										{errorMessage ? <div className="alert alert-danger">{errorMessage}</div> : ''}

										{/* <!-- Form --> */}
										<div className="row">
											<div className="col-xs-12 col-sm-6 mb-4">
												<label className="g-color-gray-dark-v2 g-font-weight-600 g-font-size-13">First name:</label>
												<Field className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v4 g-brd-primary--hover rounded g-py-15 g-px-15" name="first_name" type="text" placeholder="John" />
												<ErrorMessage name="first_name">
													{msg => <div className="g-mt-10 text-danger field_error_message"><i className="fa fa-info-circle g-mr-5"></i>{msg}</div>}
												</ErrorMessage>
											</div>

											<div className="col-xs-12 col-sm-6 mb-4">
												<label className="g-color-gray-dark-v2 g-font-weight-600 g-font-size-13">Last name:</label>
												<Field className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v4 g-brd-primary--hover rounded g-py-15 g-px-15" name="last_name" type="text" placeholder="Doe" />
												<ErrorMessage name="last_name">
													{msg => <div className="g-mt-10 text-danger field_error_message"><i className="fa fa-info-circle g-mr-5"></i>{msg}</div>}
												</ErrorMessage>
											</div>
										</div>

										<div className="mb-4">
											<label className="g-color-gray-dark-v2 g-font-weight-600 g-font-size-13">Email:</label>
											<Field className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v4 g-brd-primary--hover rounded g-py-15 g-px-15" name="email" type="email" placeholder="johndoe@gmail.com" />
											<ErrorMessage name="email">
												{msg => <div className="g-mt-10 text-danger field_error_message"><i className="fa fa-info-circle g-mr-5"></i>{msg}</div>}
											</ErrorMessage>
										</div>

										<div className="row">
											<div className="col-xs-12 col-sm-6 mb-4">
												<label className="g-color-gray-dark-v2 g-font-weight-600 g-font-size-13">Password:</label>
												<Field className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v4 g-brd-primary--hover rounded g-py-15 g-px-15" name="password" type="password" placeholder="Password" />
												<ErrorMessage name="password">
													{msg => <div className="g-mt-10 text-danger field_error_message"><i className="fa fa-info-circle g-mr-5"></i>{msg}</div>}
												</ErrorMessage>
											</div>

											<div className="col-xs-12 col-sm-6 mb-4">
												<label className="g-color-gray-dark-v2 g-font-weight-600 g-font-size-13">Confirm Password:</label>
												<Field className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v4 g-brd-primary--hover rounded g-py-15 g-px-15" name="confirm_password" type="password" placeholder="Password" />
												<ErrorMessage name="confirm_password">
													{msg => <div className="g-mt-10 text-danger field_error_message"><i className="fa fa-info-circle g-mr-5"></i>{msg}</div>}
												</ErrorMessage>
											</div>
										</div>

										<div className="row justify-content-between mb-5">
											<div className="col-8 align-self-center">
												<label className="form-check-inline u-check g-color-gray-dark-v5 g-font-size-13 g-pl-25">
													<Field className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="accept_policy" type="checkbox" />
													<div className="u-check-icon-checkbox-v6 g-absolute-centered--y g-left-0 register-check-icon">
														<i className="fa fa-check" style={{ fontFamily: "FontAwesome" }}></i>
														{/* <i className="fa fa-check" data-check-icon="\f00c" aria-hidden="true" style={{ fontFamily: "FontAwesome" }}></i> */}
													</div>
													I accept the<Link className="g-font-size-13" to="#">Terms and Conditions</Link>
													<br />
												</label>
												<ErrorMessage name="accept_policy">
													{msg => <div className="g-mt-10 text-danger field_error_message"><i className="fa fa-info-circle g-mr-5"></i>{msg}</div>}
												</ErrorMessage>
											</div>
											<div className="col-4 align-self-center text-right">
												<button type="submit" className="btn btn-md u-btn-primary rounded g-py-13 g-px-25" disabled={isSubmitting}>SignUp</button>
											</div>
										</div>
										{/* <!-- End Form --> */}
									</Form>
								)}
							</Formik>

							<footer className="text-center">
								<p className="g-color-gray-dark-v5 g-font-size-13 mb-0">Already have an account? <Link className="g-color-primary g-font-weight-500 " to="/account/login">Signin</Link>
								</p>
							</footer>
						</div>
					</div>
				</div>
			</div>
		</section>
		// SignUp Section End 
	);

}

export default RegisterPage;