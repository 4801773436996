import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { addToCart } from "../helper/CartHelper";

const FullWidthImageBanner = (props) => {

    const item = props.item;
    const dispatch = useDispatch();

    return (
        // <!-- Hero Info #02 -->
        <section className="u-bg-overlay g-bg-black-opacity-0_3--after g-bg-img-hero g-py-120--md g-py-80 g-min-height-500" style={{ backgroundImage: "url('/assets/removable_images/farms.png')" }}>
        </section>

    )

}

export default FullWidthImageBanner;