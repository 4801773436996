import Slider from "react-slick";
import ProductBlock from "./ProductBlock";
import { NextArrow, PrevArrow } from "./slider/CustomArrow";

const ProductGroupSlider = ({ item }) => {

	const settings = {
		className: "Producttab_slider",
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: true,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};

	return (
		<>
			<div className="product-group-slider-wrapper">
				{/* Product Group Slider */}
				<Slider {...settings}>

					{
						item &&
						item.products.map(product =>
							<ProductBlock item={product} className="col-12 g-mb-70" />
						)}
				</Slider>
			</div>
		</>
	);

}

export default ProductGroupSlider;