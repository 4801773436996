import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ContainerWithGap from "../components/container/ContainerWithGap";
import Loading from "../components/Loading";
import HeadingCenter from "../components/page_headings/HeadingCenter";
import HeadingLeftAlign from "../components/page_headings/HeadingLeftAlign";
import ProductGroup from "../components/ProductGroup";
import { get_optimized_image_url } from "../helper/Helper";
import ReactPaginate from "react-paginate";

const ProductCategoryPage = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isCatLoading, setIsCatLoading] = useState(true);
    const [data, setData] = useState([]);
    const [category, setCategory] = useState(null);
    const [error, setError] = useState("");
    const [initialSort, setInitialSort] = useState("low To High");

    const slug = useParams().slug;

    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(1);

    useEffect(() => {
        // this url with change based on pagination and sorting option
        let url =
            process.env.REACT_APP_API_BASE_URL +
            "/product/?category__slug=" +
            slug +
            `&ordering=${
                initialSort === "low To High" ? "our_price" : "-our_price"
            }`;

        fetch(url)
            .then((response) => response.json())
            .then((result) => {
                setData(result);
                setIsLoading(false);
            })
            .catch((error) => {});
    }, [slug, initialSort]);

    useEffect(() => {
        let category_url =
            process.env.REACT_APP_API_BASE_URL + "/product-category/" + slug;
        fetch(category_url)
            .then((response) => response.json())
            .then((result) => {
                setCategory(result);
                setIsCatLoading(false);
            })
            .catch((error) => {
                // handle error
            });
    }, [slug]);

    if (isLoading || isCatLoading) {
        return <Loading />;
    }

    const handlePageClick = (event) => {
        window.scrollTo(0, 0);
        const newOffset = event.selected + 1;
        setOffset(newOffset);
    };

    const handlePriceSorting = (sortStyle) => {
        setInitialSort(sortStyle);
    };

    return (
        <>
            <HeadingLeftAlign
                title={category.name}
                background={get_optimized_image_url(category.image, "square")}
                tagline={category.tagline}
            />

            <ContainerWithGap>
                <div className="d-flex justify-content-end align-items-center g-brd-bottom g-brd-gray-light-v4 g-pt-40 g-pb-20">
                    {/* <!-- Sort By --> */}
                    <div className="">
                        <h2 className="h6 align-middle d-inline-block g-font-weight-400 text-capitalize g-pos-rel g-top-1 mb-0">
                            Sort by:
                        </h2>

                        {/* <!-- Secondary Button --> */}
                        <div className="d-inline-block btn-group g-line-height-1_2">
                            <button
                                type="button"
                                className="btn btn-secondary dropdown-toggle h6 align-middle g-brd-none g-color-gray-dark-v5 g-color-black--hover g-bg-transparent text-capitalize g-font-weight-300 g-font-size-12 g-pa-0 g-pl-10 g-ma-0"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {initialSort}
                            </button>
                            <div className="dropdown-menu rounded-0">
                                {/* <Link className="dropdown-item g-color-gray-dark-v4 g-font-weight-300" to="#">Bestseller</Link>
										<Link className="dropdown-item g-color-gray-dark-v4 g-font-weight-300" to="#">New Arrival</Link> */}
                                <Link
                                    onClick={() =>
                                        handlePriceSorting("low To High")
                                    }
                                    className="dropdown-item g-color-gray-dark-v4 g-font-weight-300"
                                    to="#"
                                >
                                    Price low to high
                                </Link>
                                <Link
                                    className="dropdown-item g-color-gray-dark-v4 g-font-weight-300"
                                    to="#"
                                    onClick={() =>
                                        handlePriceSorting("high To Low")
                                    }
                                >
                                    price high to low
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Sort By --> */}

                    {/* <!-- Sort By --> */}
                    <ul className="list-inline mb-0">
                        {/* <li className="list-inline-item">
								<Link className="u-icon-v2 u-icon-size--xs g-brd-gray-light-v3 g-brd-black--hover g-color-gray-dark-v5 g-color-black--hover" to="#">
									<i className="icon-list"></i>
								</Link>
								</li>
								<li className="list-inline-item">
								<Link className="u-icon-v2 u-icon-size--xs g-brd-primary g-color-primary" to="#">
									<i className="icon-grid"></i>
								</Link>
								</li> */}
                    </ul>
                    {/* <!-- End Sort By --> */}
                </div>
                <ProductGroup items={data.results} showflavour={true} />
                {/* <Pagination /> */}
            </ContainerWithGap>
            {data?.results?.length > 20 && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        marginBottom: "25px"
                    }}
                >
                    <ReactPaginate
                        nextLabel="Next"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={data.total_pages}
                        previousLabel="Previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                    />
                </div>
            )}
        </>
    );
};

export default ProductCategoryPage;
