import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ProfileTab = (props) => {
    const active = props.active;
    return (
        <ul className="list-inline g-brd-bottom--sm g-brd-gray-light-v3 mb-5 g-mt-50">
            <li className="list-inline-item g-pb-10 g-pr-10 g-mb-20 g-mb-0--sm">
                <Link className={active === "orders"
                    ? "g-brd-bottom g-brd-2 g-brd-primary g-color-main g-color-black g-font-weight-600 g-text-underline--none--hover g-px-10 g-pb-13"
                    : "g-brd-bottom g-brd-2  g-brd-transparent g-color-main g-color-gray-dark-v4 g-text-underline--none--hover g-px-10 g-pb-13"
                }
                    to="/account/overview">Orders</Link>
            </li>
            <li className="list-inline-item g-pb-10 g-px-10 g-mb-20 g-mb-0--sm">
                <Link className={active === "openorders"
                    ? "g-brd-bottom g-brd-2 g-brd-primary g-color-main g-color-black g-font-weight-600 g-text-underline--none--hover g-px-10 g-pb-13"
                    : "g-brd-bottom g-brd-2  g-brd-transparent g-color-main g-color-gray-dark-v4 g-text-underline--none--hover g-px-10 g-pb-13"
                }
                    to="/account/open-orders">Open Orders</Link>
            </li>
            <li className="list-inline-item g-pb-10 g-pl-10 g-mb-20 g-mb-0--sm">
                <Link className={active === "cancelled"
                    ? "g-brd-bottom g-brd-2 g-brd-primary g-color-main g-color-black g-font-weight-600 g-text-underline--none--hover g-px-10 g-pb-13"
                    : "g-brd-bottom g-brd-2  g-brd-transparent g-color-main g-color-gray-dark-v4 g-text-underline--none--hover g-px-10 g-pb-13"
                }
                    to="/account/cancelled-orders">Cancelled Orders</Link>
            </li>
        </ul>
    )
}
export default ProfileTab;