import { useDispatch } from "react-redux";
import { selectPostCard } from "../../helper/CartHelper";

const PostCardBlock = (props) => {
	const selected = props.is_selected;
	const card = props.item;
	const dispatch = useDispatch();

	return (
	<div className="col-4">
		<div className={"post_card_wrap position-relative "+(selected?'selected':'')} onClick={(e)=>selectPostCard(card.id, card.price, dispatch)}>
			<img src={card.small_image} className="w-100" />
			<div className="post_card_price">
				${card.price}
			</div>
			{selected?
				<div className="post_card_tick">
					<i className="fa fa-check"></i>
				</div>
				:''
			}
		</div>
	</div>
	);
}
export default PostCardBlock;