import Cookies from "js-cookie";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { login } from "../../features/authentication/userSlice";
import { getInitialCartData } from "../../features/cartDataSlice";
import { getInitialSaveLaterData } from "../../features/saveLaterDataSlice";
import { signOut } from "../../services/AuthServices";
/**
 * 1. Checks if access_token available and is valid.
 * 2. If access_token in not valid, it will fetch another access_token using refresh_token
 * 3. After getting valid access_token it stores token to Redux
 * 4. If unable to get token it recognize user as 'Not logged in'
 */
const AuthCheck = (props) => {
    const dispatch = useDispatch();

    const checkIfTokenIsValid = async (token) => {
        let url = process.env.REACT_APP_API_BASE_URL + "/accounts/details";
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + Cookies.get("access_token")
            }
        };
        const response = await fetch(url, requestOptions);

        if (!response.ok) {
            return false;
        } else {
            let result = await response.json();
            let payload = {
                user: result,
                access_token: Cookies.get("access_token")
            };
            dispatch(login(payload));
            dispatch(getInitialCartData());
            dispatch(getInitialSaveLaterData());
            return true;
        }
    };

    const getNewTokenFromRefreshToken = async (refresh_token) => {
        let url =
            process.env.REACT_APP_API_BASE_URL + "/accounts/token/refresh";
        const requestOptions = {
            method: "POST",
            body: JSON.stringify({
                refresh_token: Cookies.get("refresh_token")
            }),
            headers: {
                "Content-Type": "application/json"
            }
        };

        const response = await fetch(url, requestOptions);
        if (!response.ok) {
            return false;
        } else {
            const result = await response.json();
            Cookies.set("access_token", result.access_token, { path: "/" });
            Cookies.set("refresh_token", result.refresh_token, { path: "/" });
            return true;
        }
    };

    const tokenCheck = () => {
        // if cookies has both access_token and refresh_token - check if access_token is valid
        if (Cookies.get("access_token") && Cookies.get("refresh_token")) {
            checkIfTokenIsValid(Cookies.get("access_token")).then(
                (response) => {
                    if (response === false) {
                        getNewTokenFromRefreshToken(
                            Cookies.get("refresh_token")
                        ).then((new_token) => {
                            if (new_token == true) {
                                checkIfTokenIsValid(
                                    Cookies.get("access_token")
                                ).then((response) => {
                                    if (response == false) {
                                        signOut(dispatch);
                                    }
                                });
                            } else {
                                Cookies.remove("access_token");
                                Cookies.remove("refresh_token");
                                signOut(dispatch);
                            }
                        }); //end getNewTokenFromRefreshToken
                    } //endif
                }
            ); //end checIfTokenIsVAlid
        } else {
            signOut(dispatch);
        }
    };

    useEffect(() => {
        tokenCheck();
    }, []);

    return <>{props.children}</>;
};

export default AuthCheck;
