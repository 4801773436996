import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { get_fetched_menu_url, get_menu_url, get_optimized_image_url } from "../../helper/Helper";

const MegaMenuNormal = (props) => {
	
	const [activeIndex, setActiveIndex] = useState("spice");
	const parentMenu = props.menu;

	const [menus, setMenus] = useState(parentMenu?.child_menus);

	useEffect(()=> {
		if( (parentMenu?.child_menus.length < 1) && parentMenu.autofetch ){
			setMenus(parentMenu.fetched_items);
		}
	},[parentMenu]);

	return (

		<div className="">

			<ul className="row col-12 mt-4">
				{
					(menus.length > 0) &&
						menus.map( (menu,index) => {
							return(
								<li className={"col-lg-3 d-flex align-items-center"}>
									<Link to={get_menu_url(menu)} className="submenu-wrap">
										{menu.title}
									</Link>
								</li>
							)
					})
				}
				
			</ul>
		</div>
	);
}
export default MegaMenuNormal;