import React, { useState } from "react";
import ContainerWithGap from "../components/container/ContainerWithGap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

const WholesalePage = (props) => {
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const validationSchema = Yup.object().shape({
        full_name: Yup.string().required("Name field is required"),
        job_title: Yup.string().required("Job field is required"),
        email: Yup.string()
            .required("Email is required")
            .email("Valid email is required"),
        full_text: Yup.string().max(500),
        phone: Yup.string().required("Phone number is required"),
        business_name: Yup.string().required("Business name field is required"),
        message: Yup.string().required("Message field is required")
    });
    let main_response = "";
    const url = process.env.REACT_APP_API_BASE_URL + "/wholesale-inquiry/";

    const onSubmitForm = async (data, resetForm, setSubmitting) => {
        const requestOptions = {
            method: "POST",
            body: data,
            headers: {
                "Content-Type": "application/json"
            }
        };
        fetch(url, requestOptions)
            .then((response) => {
                // console.log(response.json());
                main_response = response;
                return response.json();
            })
            .then((result) => {
                if (main_response.ok) {
                    toast.success("Review Submitted Successfully");
                    setSuccessMessage("Review Submitted Successfully");
                    resetForm();
                } else {
                    //if validation error
                    toast.error("Couldn't process request. Please try again");
                    setErrorMessage(
                        "Couldn't process request. Please try again"
                    );
                }
                setSubmitting(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <>
            <section className="clearfix">
                <div
                    className="js-bg-video"
                    data-hs-bgv-type="youtube"
                    data-hs-bgv-id="FxiskmF16gU"
                    data-hs-bgv-loop="1"
                >
                    <div className="g-bg-cover g-bg-black-opacity-0_8--after g-pos-rel g-z-index-1">
                        <div className="container text-center g-pos-rel g-z-index-1 g-pt-100 g-pb-80">
                            {/* <!-- Promo Block Info --> */}
                            <div className="g-mb-40">
                                <p className="g-color-white-opacity-0_8 g-font-size-22">
                                    Interested in partnering with Homni? Here
                                    are five reasons that’s a great idea.
                                </p>
                                <p className="g-color-white">
                                    *For wholesale inquiries please complete the{" "}
                                    <a className="g-color-primary" href="#form">
                                        Form
                                    </a>{" "}
                                    at the bottom of this page
                                </p>
                            </div>
                            {/* <!-- End Promo Block Info --> */}
                        </div>
                    </div>
                </div>
            </section>
            <ContainerWithGap>
                <div className="row">
                    <div className="col-lg-7 g-pr-50">
                        <div className="wholesale-paragraph g-mt-70">
                            <p className="g-mb-30">
                                <h5 className="g-mb-15">
                                    Sustainability, transparency, and sharing{" "}
                                </h5>
                                We are a mission-based for-profit. Our
                                investments in cleantech will be open to any and
                                all. As we discover new and efficient ways to
                                improve our footprint, we will share our
                                successes and techniques with your team. Want to
                                communicate sustainability messaging to your
                                customers? Let us help.
                            </p>
                            <p className="g-mb-30">
                                <h5 className="g-mb-15">
                                    Agility and deep-account management
                                </h5>
                                Regardless of what the markets are doing, or
                                what special requests your team may have, we
                                will be able to adapt. To specialize in
                                small-farm agriculture, Homni has to be an agile
                                company. If new opportunities are discovered,
                                market demand shifts, or business requirements
                                change, we can rapidly adapt and thrive. For our
                                partners, this means improved reliability and
                                room for out-of-the-box collaboration.
                            </p>
                            <p className="g-mb-30">
                                <h5 className="g-mb-15">
                                    Continual improvement processes
                                </h5>
                                Good is never good enough. A side-affect of
                                hyper-transparency is the need to stay ahead of
                                competitors. The only way to do that is through
                                continual improvement. From major strategic
                                investments down to work-from-home practices,
                                Homni approaches continual improvement in a
                                systematic way. Year-over-year you can expect to
                                see our quality, sustainability programming,
                                pricing, and general business practices improve.
                            </p>
                            <p className="g-mb-30">
                                <h5 className="g-mb-15">
                                    Logistical reliability and clear
                                    communication
                                </h5>{" "}
                                We are not a fulfillment company, but we are
                                great communicators. For your business, this
                                means that we’ve partnered with best-in-class
                                3PL ShipMonk. ShipMonk is a premier fulfillment
                                service that guarantees 99.9% picking accuracy,
                                same-day fulfillment times, and rock bottom
                                shipping costs. And for those inevitable times
                                when something does go wrong, the moment we
                                know, you’ll know. Brutal honesty is a best
                                practice in our book. improve.
                            </p>
                            <p className="g-mb-30">
                                <h5 className="g-mb-15">Quality control</h5>
                                All of our goods have strict lot tracking and
                                receive inspections before entering the port of
                                exit and again at our fulfillment center. We are
                                happy to provide lot-level details to the
                                product origin upon request. At the 10kg level,
                                we perform lab tests to guarantee purity for all
                                of our essential oils and spices. Though we
                                expect quality events to be exceedingly rare, we
                                are realistic and have internal protocols to
                                guarantee the same mistake never happens twice.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-5 pr-5">
                        <div className="wholesale-form">
                            <div className="g-mt-70 g-mb-30">
                                <h3 className="mb-3" id="form">
                                    Wholesale Inquiries
                                </h3>
                                <p className="g-font-size-17 mb-0">
                                    For wholesale inquiries please complete the
                                    form below:
                                </p>
                            </div>

                            <div className="row justify-content-start">
                                <Formik
                                    initialValues={{
                                        full_name: "",
                                        job_title: "",
                                        email: "",
                                        phone: "",
                                        business_name: "",
                                        website: "",
                                        message: ""
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={(
                                        values,
                                        {
                                            setSubmitting,
                                            resetForm,
                                            setFieldError
                                        }
                                    ) => {
                                        setTimeout(() => {
                                            setSuccessMessage("");
                                            setErrorMessage("");
                                            onSubmitForm(
                                                JSON.stringify(values, null, 2),
                                                resetForm,
                                                setSubmitting,
                                                setFieldError
                                            );
                                        }, 400);
                                    }}
                                >
                                    {({ isSubmitting, handleSubmit }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-md-6 form-group g-mb-20">
                                                    <Field
                                                        className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover rounded g-py-13 g-px-15"
                                                        type="text"
                                                        placeholder="Full Name"
                                                        name="full_name"
                                                    />
                                                    <ErrorMessage name="full_name">
                                                        {(msg) => (
                                                            <div className="g-mt-10 text-danger field_error_message">
                                                                <i className="fa fa-info-circle g-mr-5"></i>
                                                                {msg}
                                                            </div>
                                                        )}
                                                    </ErrorMessage>
                                                </div>

                                                <div className="col-md-6 form-group g-mb-20">
                                                    <Field
                                                        className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover rounded g-py-13 g-px-15"
                                                        type="text"
                                                        placeholder="Job Title"
                                                        name="job_title"
                                                    />
                                                    <ErrorMessage name="job_title">
                                                        {(msg) => (
                                                            <div className="g-mt-10 text-danger field_error_message">
                                                                <i className="fa fa-info-circle g-mr-5"></i>
                                                                {msg}
                                                            </div>
                                                        )}
                                                    </ErrorMessage>
                                                </div>
                                                <div className="col-md-12 form-group g-mb-20">
                                                    <Field
                                                        className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover rounded g-py-13 g-px-15"
                                                        type="email"
                                                        placeholder="Email"
                                                        name="email"
                                                    />
                                                    <ErrorMessage name="email">
                                                        {(msg) => (
                                                            <div className="g-mt-10 text-danger field_error_message">
                                                                <i className="fa fa-info-circle g-mr-5"></i>
                                                                {msg}
                                                            </div>
                                                        )}
                                                    </ErrorMessage>
                                                </div>
                                                <div className="col-md-12 form-group g-mb-20">
                                                    <Field
                                                        className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover rounded g-py-13 g-px-15"
                                                        type="tel"
                                                        placeholder="Phone Number"
                                                        name="phone"
                                                    />
                                                    <ErrorMessage name="phone">
                                                        {(msg) => (
                                                            <div className="g-mt-10 text-danger field_error_message">
                                                                <i className="fa fa-info-circle g-mr-5"></i>
                                                                {msg}
                                                            </div>
                                                        )}
                                                    </ErrorMessage>
                                                </div>
                                                <div className="col-md-6 form-group g-mb-20">
                                                    <Field
                                                        className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover rounded g-py-13 g-px-15"
                                                        type="text"
                                                        placeholder="Business Name"
                                                        name="business_name"
                                                    />
                                                    <ErrorMessage name="business_name">
                                                        {(msg) => (
                                                            <div className="g-mt-10 text-danger field_error_message">
                                                                <i className="fa fa-info-circle g-mr-5"></i>
                                                                {msg}
                                                            </div>
                                                        )}
                                                    </ErrorMessage>
                                                </div>

                                                <div className="col-md-6 form-group g-mb-20">
                                                    <Field
                                                        className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover rounded g-py-13 g-px-15"
                                                        type="link"
                                                        placeholder="Website(optional)"
                                                        name="website"
                                                    />
                                                </div>

                                                <div className="col-md-12 form-group g-mb-20">
                                                    <Field
                                                        as="textarea"
                                                        className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover g-resize-none rounded g-py-13 g-px-15"
                                                        rows="7"
                                                        placeholder="Message"
                                                        name="message"
                                                    ></Field>
                                                    <ErrorMessage name="message">
                                                        {(msg) => (
                                                            <div className="g-mt-10 text-danger field_error_message">
                                                                <i className="fa fa-info-circle g-mr-5"></i>
                                                                {msg}
                                                            </div>
                                                        )}
                                                    </ErrorMessage>
                                                </div>
                                            </div>

                                            <div className="g-mb-100">
                                                <button
                                                    disabled={isSubmitting}
                                                    type="submit"
                                                    className="btn u-btn-primary g-font-size-12 text-uppercase g-py-12 g-px-25"
                                                >
                                                    Send Message
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </ContainerWithGap>
        </>
    );
};

export default WholesalePage;
