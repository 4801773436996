import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    is_logged_in: false,
    is_verified: false,
    access_token: null,
    user: null
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        login: (state, action) => {
            state.is_logged_in = true;
            state.user = action.payload.user;
            state.is_verified = action.payload.user.is_verified;
            state.access_token = action.payload.access_token;
        },
        logout: (state) => {
            state.is_logged_in = false;
            state.user = null;
            state.access_token = null;
            state.is_verified = false;
        },
        verify: (state, action) => {
            state.is_verified = action.payload;
        }
    }
});

export const { login, logout, verify } = userSlice.actions;

export const authUser = (state) => state.user;

export default userSlice.reducer;
