import React, { useState, useLayoutEffect } from "react";
import { useRef } from "react";
import ContainerWithGap from "../components/container/ContainerWithGap";

const TermsPage = (props) => {
    const [activeDivPosition, setActiveDivPosition] = useState(0);
    const terms = useRef();
    const privacy = useRef();
    const accessibility = useRef();

    useLayoutEffect(() => {
        const event = document.addEventListener("scroll", () => {
            const screenHeight = window.scrollY + 10;
            try {
                if (screenHeight > terms?.current?.offsetTop) {
                    setActiveDivPosition(1);
                }
                if (screenHeight > privacy?.current?.offsetTop) {
                    setActiveDivPosition(2);
                }
                if (screenHeight > accessibility?.current?.offsetTop) {
                    setActiveDivPosition(3);
                }
            } catch {}
        });

        return () => document.removeEventListener("scroll", event);
    }, []);

    return (
        <>
            <section className="container g-py-150">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="d-inline-block g-width-80 g-height-4 g-bg-black mb-3"></div>
                        <h2 className="g-color-black g-font-weight-700 g-font-size-50 g-line-height-1 mb-4">
                            Terms &amp; Conditions
                        </h2>
                        <p className="mb-0">
                            This is where we sit down, grab a cup of coffee and
                            dial in the details. Understanding the task at hand
                            and ironing out the wrinkles is key. Now that we've
                            aligned the details, it's time to get things mapped
                            out and organized. This part is really crucial in
                            keeping the project in line to completion.
                        </p>
                    </div>
                </div>
            </section>
            <ContainerWithGap>
                <section className="g-bg-gray-light-v5">
                    <div className="container g-py-100">
                        <div className="row">
                            <div
                                // id="stickyblock-start"
                                className="col-md-4 g-mb-30"
                            >
                                <div
                                    className="js-sticky-block g-sticky-block--lg"
                                    data-type="responsive"
                                    data-start-point="#stickyblock-start"
                                    data-end-point="#stickyblock-end"
                                    style={{
                                        position: "sticky",
                                        top: "40%",
                                        marginBottom: 20
                                    }}
                                >
                                    <ul className="list-unstyled mb-0">
                                        <li className="g-rounded-top-5 g-px-20 g-pt-30">
                                            <a
                                                className={`js-go-to d-block g-color-white g-bg-primary--hover g-font-weight-600 g-font-size-18 text-uppercase g-text-underline--none--hover rounded g-px-20 g-py-15 ${
                                                    activeDivPosition === 1 &&
                                                    "active_term_menu"
                                                }`}
                                                href="#terms"
                                                data-target="#terns"
                                            >
                                                Terms of Use
                                            </a>
                                        </li>
                                        <li className="g-px-20">
                                            <a
                                                className={`js-go-to d-block g-color-white g-bg-primary--hover g-font-weight-600 g-font-size-18 text-uppercase g-text-underline--none--hover rounded g-px-20 g-py-15 ${
                                                    activeDivPosition === 2 &&
                                                    "active_term_menu"
                                                }`}
                                                href="#privacy"
                                                data-target="#privacy"
                                            >
                                                Privacy Policy
                                            </a>
                                        </li>
                                        <li className="g-px-20">
                                            <a
                                                className={`js-go-to d-block g-color-white g-bg-primary--hover g-font-weight-600 g-font-size-18 text-uppercase g-text-underline--none--hover rounded g-px-20 g-py-15 ${
                                                    activeDivPosition === 3 &&
                                                    "active_term_menu"
                                                }`}
                                                href="#accessibility"
                                                data-target="#accessibility"
                                            >
                                                Accessibility
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-md-8">
                                {/* <!-- License --> */}
                                <div
                                    id="terms"
                                    className="u-shadow-v19 g-bg-white rounded g-pa-40 g-mb-50"
                                    ref={terms}
                                >
                                    <h2 className="h3 g-color-black mb-3">
                                        Terms Of Use
                                    </h2>
                                    <div className="d-inline-block g-width-50 g-height-2 g-bg-black mb-3"></div>
                                    <p>
                                        Terms of Service LAST UPDATED:
                                        xx/xx/xxxx Please read Homni LLC's
                                        (“Company”, “we”, “us” or “our”) Terms
                                        of Service (the “Agreement”) carefully.
                                        This Agreement constitutes a binding
                                        obligation between you and Homni LLC.
                                        This Agreement applies to your use of:
                                        our website located at www.homni.supply
                                        (the “Site”), the products or services
                                        we may provide or offer through the Site
                                        or otherwise, and your member account if
                                        you choose to create one (collectively,
                                        the “Services”). 1. Agreement to Terms:
                                        By using our Services, you agree to be
                                        bound by this Agreement. If you do not
                                        agree to be bound by this Agreement, do
                                        not use the Services. If you are
                                        accessing and using the Services on
                                        behalf of a company (such as your
                                        employer) or other legal entity, you
                                        represent and warrant that you have the
                                        authority to bind that company or other
                                        legal entity to this Agreement. In that
                                        case, “you” and “your” will refer to
                                        that company or other legal entity. 2.
                                        Privacy Policy: Please refer to our
                                        Privacy Policy (the “Privacy Policy”)
                                        for information on how we collect, use
                                        and disclose information from our users.
                                        You acknowledge and agree that your use
                                        of the Services is subject to our
                                        Privacy Policy. IMPORTANT NOTICE
                                        REGARDING ARBITRATION: WHEN YOU AGREE TO
                                        THIS AGREEMENT YOU ARE AGREEING (WITH
                                        LIMITED EXCEPTION) TO RESOLVE ANY
                                        DISPUTE BETWEEN YOU AND COMPANY THROUGH
                                        BINDING, INDIVIDUAL ARBITRATION RATHER
                                        THAN IN COURT. PLEASE REVIEW CAREFULLY
                                        SECTION 18 “DISPUTE RESOLUTION” FOR
                                        DETAILS REGARDING ARBITRATION (INCLUDING
                                        THE PROCEDURE TO OPT OUT OF
                                        ARBITRATION). 3. Changes to Terms or
                                        Services: We may update this Agreement
                                        at any time, in our sole discretion. If
                                        we do so, we will deliver a notice
                                        either by posting the updated Agreement
                                        or through other communications deemed
                                        appropriate by us. It is important that
                                        you review this Agreement whenever we
                                        update them or you use the Services. If
                                        you continue to use the Services after
                                        we have posted an updated Agreement, you
                                        are agreeing to be bound by the updated
                                        Agreement. If you do not agree to be
                                        bound by the updated Agreement, then,
                                        except as otherwise provided in Section
                                        18(a)(viii),you may not use the Services
                                        anymore. Because our Services are
                                        evolving over time, we may change or
                                        discontinue all or any part of the
                                        Services, at any time and without
                                        notice, at our sole discretion. 4. Who
                                        May Use the Services? The products we
                                        offer for sale through the Services are
                                        currently only available to residents of
                                        United States and Canada. THE SERVICES
                                        NOT AVAILABLE TO PERSONS UNDER THE AGE
                                        OF 13, or in certain jurisdictions,
                                        under 16 (“Minors”) and we do not
                                        knowingly collect information from
                                        Minors. If you are between ages 13 (or
                                        16 in certain jurisdictions) and 18, you
                                        must have permission from your legal
                                        guardian before you are permitted to use
                                        the Services. If we learn that we have
                                        collected information of a Minor, we
                                        will take steps to delete such
                                        information from our files as soon as
                                        possible.
                                    </p>
                                    <p>
                                        19. General Terms: • Entire Agreement.
                                        This Agreement constitutes the entire
                                        and exclusive understanding and
                                        agreement between Company and you
                                        regarding the Services, and this
                                        Agreement supersedes and replaces any
                                        and all prior oral or written
                                        understandings or agreements between
                                        Company and you regarding the Services.
                                        If any provision of this Agreement is
                                        held invalid or unenforceable by an
                                        arbitrator or a court of competent
                                        jurisdiction, that provision will be
                                        enforced to the maximum extent
                                        permissible and the other provisions of
                                        this Agreement will remain in full force
                                        and effect. You may not assign or
                                        transfer this Agreement, by operation of
                                        law or otherwise, without Company’s
                                        prior written consent. Any attempt by
                                        you to assign or transfer this
                                        Agreement, without such consent, will be
                                        null. Company may freely assign or
                                        transfer this Agreement without
                                        restriction. Subject to the foregoing,
                                        this Agreement will bind and inure to
                                        the benefit of the parties, their
                                        successors and permitted assigns. •
                                        Notices. Any notices or other
                                        communications provided by Company under
                                        this Agreement, including those
                                        regarding modifications to this
                                        Agreement, will be given: (i) via email;
                                        or (ii) by posting to the Services
                                        (including on the Site). For notices
                                        made by e-mail, the date of receipt will
                                        be deemed the date on which such notice
                                        is transmitted. • Waiver of Rights.
                                        Company’s failure to enforce any right
                                        or provision of this Agreement will not
                                        be considered a waiver of such right or
                                        provision. The waiver of any such right
                                        or provision will be effective only if
                                        in writing and signed by a duly
                                        authorized representative of Company.
                                        Except as expressly set forth in this
                                        Agreement, the exercise by either party
                                        of any of its remedies under this
                                        Agreement will be without prejudice to
                                        its other remedies under this Agreement
                                        or otherwise. • Interpretation. For
                                        purposes of this Agreement: (i) the
                                        words and phrases “include,” “includes”,
                                        “including” and “such as” are deemed to
                                        be followed by the words “without
                                        limitation”; (ii) the word “or” is not
                                        exclusive; and (iii) the words “herein,”
                                        “hereof,” “hereby,” “hereto” and
                                        “hereunder” refer to this Agreement as a
                                        whole. The parties also agree that the
                                        normal rule of construction that an
                                        agreement will be interpreted against
                                        the drafting party does not apply to
                                        this Agreement. 20. Contact Information:
                                        If you have any questions about this
                                        Agreement or the Services, please
                                        contact Company at info@Homni.supply.
                                    </p>
                                </div>
                                {/* <!-- End License --> */}

                                {/* <!-- Ownership --> */}
                                <div
                                    id="privacy"
                                    ref={privacy}
                                    className="u-shadow-v19 g-bg-white rounded g-pa-40 g-mb-50"
                                >
                                    <h3 className="g-color-black mb-3">
                                        Privacy Policy
                                    </h3>
                                    <div className="d-inline-block g-width-50 g-height-2 g-bg-black mb-3"></div>
                                    <p>
                                        SECTION 1 - COLLECTED INFORMATION When
                                        you purchase something from our online
                                        store, as part of the buying and selling
                                        process, we collect the personal
                                        information you give us such as your
                                        name, address and email address. We
                                        offer two shopping options that allow
                                        you to choose how much information you
                                        wish to share with us: Guest Shopper –
                                        Only the record of your transaction is
                                        stored for customer service purposes.
                                        Your personal information is not stored
                                        on our servers so each time you shop
                                        with us, you will be asked for your
                                        shipping, billing and credit card
                                        information. Registered Shopper – If you
                                        create an account on our website, your
                                        billing address and shipping address
                                        information will be stored for your
                                        convenience. Your Bill-To and Ship-To
                                        information will be pre-populated in the
                                        appropriate areas in our checkout
                                        procedure to make things easier for you.
                                        As a benefit for registered shoppers, we
                                        may also maintain records of your
                                        purchase history (accessible through
                                        your account) and other interactions you
                                        have on our website (e.g. comments).
                                        When you browse our store, we
                                        automatically receive your computer’s
                                        internet protocol (IP) address. We use
                                        your IP address to determine where
                                        you’re purchasing from (at a city and
                                        regional level) and information
                                        pertaining to your browser and operating
                                        system type. Location information can be
                                        used to ensure maximum shipping
                                        efficiency, better service
                                        underrepresented populations, identify
                                        beneficial relationships in the area and
                                        more. Understanding the types of
                                        operating systems and browsers accessing
                                        our website allows our web development
                                        team to keep ahead of any
                                    </p>
                                    {/* <!-- End Ownership --> */}

                                    {/* <!-- Usage --> */}
                                    <div
                                        ref={accessibility}
                                        id="accessibility"
                                        className="u-shadow-v19 g-bg-white rounded g-pa-40 g-mb-50"
                                    >
                                        <h3 className="g-color-black mb-3">
                                            Accessibility
                                        </h3>
                                        <div className="d-inline-block g-width-50 g-height-2 g-bg-black mb-3"></div>
                                        <p>
                                            We are committed to making our
                                            website accessible to everyone,
                                            regardless of technology or ability.
                                            We do not have a physical storefront
                                            (though you can find us at stockists
                                            across the country!), so our website
                                            operates as our storefront. We aim
                                            to meet the Web Content
                                            Accessibility Guidelines (WCAG 2.1)
                                            at the AA level to the best of our
                                            ability. If you have trouble
                                            accessing any part of our website,
                                            please contact us at
                                            info@homni.supply; you can also use
                                            the orange chat widget located at
                                            the bottom right corner of the site.
                                        </p>
                                    </div>
                                    {/* <!-- End Usage --> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </ContainerWithGap>
        </>
    );
};

export default TermsPage;
