import { Link } from "react-router-dom";
import HtmlParser from "react-html-parser";
import { get_optimized_image_url } from "../helper/Helper";
const BlogBlock = (props) => {
    const item = props.item;
    // const routeLink = routeLink;
    const categoryList = props.categoryList;
    if (!item) {
        return <>Unable to load item</>;
    }

    return (
        <div className="masonry-grid-item col-md-6 col-lg-4 col-xl-3 g-mb-30">
            {/* <!-- Blog Grid Modern Blocks --> */}
            <article className="g-transition-0_3">
                <Link
                    to={
                        categoryList
                            ? `/${categoryList}/` + item.slug
                            : "/blogs/" + item.slug
                    }
                >
                    <img
                        className="img-fluid w-100 blog-image"
                        src={item.image}
                        alt={item.title}
                    />
                </Link>

                <div className="g-bg-white g-py-20 g-rounded-bottom-5">
                    <h2 className="h5 g-color-black g-font-weight-600 mb-3">
                        <Link
                            className="u-link-v5 h6 g-color-black g-color-primary--hover g-cursor-pointer"
                            to={
                                categoryList
                                    ? `/${categoryList}/` + item.slug
                                    : "/blogs/" + item.slug
                            }
                        >
                            {item.title}
                        </Link>
                    </h2>
                    <p className="blog-listing-description">
                        {HtmlParser(item.textbody)}
                    </p>
                    <Link
                        to={
                            categoryList
                                ? `/${categoryList}/` + item.slug
                                : "/blogs/" + item.slug
                        }
                        className="g-color-primary"
                    >
                        READ MORE
                    </Link>
                </div>
            </article>
            {/* <!-- End Blog Grid Modern Blocks --> */}
        </div>
    );
};

export default BlogBlock;
