import ContainerWithGap from "../components/container/ContainerWithGap";
import ProductGroup from "../components/ProductGroup";
import Pagination from "../components/Pagination";
import { useEffect, useState } from "react";
import Loading from "../components/Loading";

const ProductIndexHerbsPage = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [error, setError] = useState('');

    const [page, setPage] = useState(1);

    useEffect(() => {
        // this url with change based on pagination and sorting option 
        let url = process.env.REACT_APP_API_BASE_URL + "/product";

        fetch(url)
            .then(response => response.json())
            .then(result => {
                setData(result);
                setIsLoading(false);
            })
            .catch(error => {
            })

    }, [])

    if (isLoading) {
        return <Loading />;
    }
    return (
        <>
            <section className="clearfix">
                <div className="product-index-background" style={{ backgroundImage: "url('/assets/removable_images/product_banner2.png')" }}>
                    <div className="g-bg-cover g-bg-black-opacity-0_6--after g-pos-rel g-z-index-1">
                        <div className="container text-center g-pos-rel g-z-index-1 g-pt-100 g-pb-80">
                            <div className="g-mb-40">
                                <h1 className="g-color-white g-font-size-60--lg">Herbs & spices worth talking about. </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ContainerWithGap>
                <div className="herb-index-text">
                    <p>All spices are available both ground and whole. Freshly ground whole spices will always retain more flavor, but sometimes you’re in a hurry and just need to toss some pre-ground spices into your stew. We recommend never buying more ground spice than you can use in a 4 month period – to prevent it from going stale. For whole spices, given the right conditions, they can maintain their potency for up to a year and a half. </p>
                </div>
            </ContainerWithGap>

            <ContainerWithGap>
                <ProductGroup items={data.results} />
                <Pagination />
            </ContainerWithGap>

        </>
    )
}

export default ProductIndexHerbsPage;




