import { Link } from "react-router-dom";
import { get_optimized_image_url } from "../helper/Helper";
import Animate from "./Animate";

const FullWidthImageBanner = (props) => {
    const item = props.item;

    return (
        <section
            className="u-bg-overlay g-bg-black-opacity-0_3--after g-bg-img-hero g-py-120--md g-py-80 position-relative d-flex align-items-center"
            style={{
                backgroundImage: `url(${get_optimized_image_url(
                    item.slider_image,
                    "horizontal"
                )})`,
                height: "calc( 100vh - 150px )"
            }}
        >
            <div className="homni-container home_banner_slider_wrapper">
                <div className="hero-banner-text">
                    <Animate>
                        <h1
                            className="h4 text-capitalize g-mb-20 g-max-width-770"
                            style={{ zIndex: "1" }}
                        >
                            {item.slider_tagline}
                        </h1>
                        <div className="d-flex home-banner-btn justify-content-center">
                            <Link
                                to={"/products/" + item.slug}
                                className="btn btn-block u-btn-primary text-center g-font-size-14 text-uppercase g-py-10 g-px-15 g-pos-rel, g-font-weight-600 "
                                type="button"
                                style={{
                                    width: "fit-content",
                                    zIndex: "1"
                                }}
                            >
                                shop now
                            </Link>
                        </div>
                    </Animate>
                </div>
            </div>
        </section>
    );
};

export default FullWidthImageBanner;
