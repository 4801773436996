import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import {
    createStateSyncMiddleware,
    initStateWithPrevTab,
    initMessageListener
} from "redux-state-sync";
import counterReducer from "../features/counter/counterSlice";
import userReducer from "../features/authentication/userSlice";
import cartReducer from "../features/cartSlice";
import paymentReducer from "../features/paymentSlice";
import recipesReducer from "../features/recipesSlice";
import userImagesReducer from "../features/userImagesSlice";
import storage from "redux-persist/lib/storage";
import cartDataReducer from "../features/cartDataSlice";
import saveLaterDataReducer from "../features/saveLaterDataSlice";

import { combineReducers } from "redux";
import {
    FLUSH,
    PAUSE,
    PERSIST,
    persistReducer,
    persistStore,
    PURGE,
    REGISTER,
    REHYDRATE
} from "redux-persist";
import orderSlice from "../features/orderSlice";

const rootReducer = combineReducers({
    counter: counterReducer,
    user: userReducer,
    cart: cartReducer,
    payment: paymentReducer,
    recipes: recipesReducer,
    userImages: userImagesReducer,
    orders: orderSlice,
    cartData: cartDataReducer,
    saveLaterData: saveLaterDataReducer
});

// persist config obj
// blacklist a store attribute using it's reducer name. Blacklisted attributes will not persist. (I did not find a way to blacklist specific values)
const persistConfig = {
    key: "root",
    version: 1,
    storage,
    whitelist: ["cart", "payment", "user"] //blacklisting a store attribute name, will not persist that store attribute.
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = configureStore({
//   reducer: {
//     counter: counterReducer,
//     user : userReducer,
//     cart : cartReducer
//   },
//   middleware : (getDefaultMiddleware) => getDefaultMiddleware().concat(createStateSyncMiddleware({})),
// });

const store = configureStore({
    reducer: persistedReducer,
    // devTools : false,
    // middleware option needs to be provided for avoiding the error. ref: https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
    }).concat(createStateSyncMiddleware({}))
});

// initMessageListener(store);

export default store;
export const persistor = persistStore(store);

initStateWithPrevTab(store);
