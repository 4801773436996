import { useDispatch, useSelector } from "react-redux";
import PostCardBlock from "./PostCardBlock";
import { toggleIsGift } from "../../helper/CartHelper";
import { useEffect, useState } from "react";
import { setGiftMessage } from "../../helper/CartHelper";

const GiftOption = (props) => {

	const cart = useSelector(state => state.cart);
	const dispatch = useDispatch();

	const [giftMsg, setGiftMsg] = useState();
	const max_allowed_length = 210;
	const [msgLength, setMsgLength] = useState(max_allowed_length);

	const [loading, setLoading] = useState(true);
	const [items, setItems] = useState([]);

	const post_card_api_url = process.env.REACT_APP_API_BASE_URL + '/postcard/';

	useEffect(() => {
		fetch(post_card_api_url)
			.then(response => response.json())
			.then(result => {
				setItems(result.results);
				setLoading(false);
			})
	}, []);


	// only for developomemnt process. Later overlap this with api data 
	const post_cards = [
		{
			id: 2,
			price: 5,
			small_image: '/assets/removable_images/postcard.png',
			image: '/assets/removable_images/postcard.png',
			description: 'This is great art'
		},
		{
			id: 3,
			price: 4,
			small_image: '/assets/removable_images/postcard.png',
			image: '/assets/removable_images/postcard.png',
			description: 'This is great art'
		},
		{
			id: 4,
			price: 8,
			small_image: '/assets/removable_images/postcard.png',
			image: '/assets/removable_images/postcard.png',
			description: 'This is great art'
		},

	];

	const setDataInTextarea = (value) => {
		if (value.length <= max_allowed_length) {
			setGiftMsg(value);
			let new_length = max_allowed_length - value.length;
			setMsgLength(new_length);
			setGiftMessage(value, dispatch);
		}
	}

	return (
		<div className="row">
			<div className="col-12 gift_option d-flex mt-4 row g-0">
				{
					cart.is_gift ?
						<input type="checkbox" checked="checked" className="mt-2 col-1 mx-auto" onChange={(e) => toggleIsGift(e.target.checked, dispatch)} />
						:
						<input type="checkbox" className="mt-2 col-1 mx-auto" onChange={(e) => toggleIsGift(e.target.checked, dispatch)} />
				}

				<div className="gift_detail col-11">
					<h6 className="text-uppercase">This item is a gift</h6>
					{(cart.is_gift == true) ?
						<>
							<label><strong>Enter your message</strong> </label><span className="float-right small text-secondary">{msgLength} characters</span>
							<textarea placeholder="Enjoy your gift!" className="form-control" onChange={(e) => setDataInTextarea(e.target.value)} value={giftMsg}></textarea>

							<div className="gift_addons mt-4">
								<label><strong>Add-ons <em className="small text-secondary">(Custom message 4X6 Post Card)</em></strong></label>
								<div className="row">
									{
										items.map(card =>
											<PostCardBlock is_selected={(cart.post_card == card.id)} item={card} key={"post_crds_" + card.id} />
										)
									}
								</div>
								<hr className="mb-2 pb-0" />
								<p className="g-font-size-12"><em>*Learn about the artists behind the card and cards behind the art</em></p>
							</div>
						</>
						: ''
					}
				</div>

			</div>
		</div>
	);
}
export default GiftOption;