import React, { useEffect, useState } from "react";
import ContainerWithGap from "../../components/container/ContainerWithGap";
import Pagination from "../../components/Pagination";
import OrderItem from "../../components/profile_page/OrderItem";
import ProfileNavSidebar from "../../components/profile_page/ProfileNavSidebar";
import { Link } from "react-router-dom";
import OrderFilter from "../../components/profile_page/OrderFilter";
import ProfileTab from "../../components/profile_page/ProfileTab";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { set_orders } from "../../features/orderSlice";
import { orderSlice } from "../../features/orderSlice";
import { get_date_format } from "../../helper/Helper";
import { authUser } from "../../features/authentication/userSlice";
import Loader from "../../components/Loading";
import ReactPaginate from "react-paginate";

const OpenOrders = (props) => {
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(1);
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState();
    const { orders } = useSelector(orderSlice);
    const {
        user: { first_name, last_name }
    } = useSelector(authUser);

    useEffect(() => {
        // this url with change based on pagination and sorting option
        setLoading(true);
        let url =
            process.env.REACT_APP_API_BASE_URL +
            `/order/?expand=items&page_size=1&page=${offset}&order_status=Processing`;

        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + Cookies.get("access_token")
            }
        };
        fetch(url, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setPageSize(result.total_pages);
                dispatch(set_orders(result.results));
                setLoading(false);
            })
            .catch((error) => {
                //handle error here

                setLoading(false);
            });
    }, [offset]);

    const handlePageClick = (event) => {
        // window.scrollTo(0, 0);
        const newOffset = event.selected + 1;
        setOffset(newOffset);
    };

    const userName = first_name + " " + last_name;
    return (
        // <>
        //     <ContainerWithGap>
        //         <div className="row">
        //             {/* <!-- Profile Settings --> */}
        //             <ProfileNavSidebar active="openorders"></ProfileNavSidebar>
        //             {/* <!-- End Profile Settings --> */}
        //             <div className="col-lg-9 g-mb-50">
        //                 {/* <div className="row justify-content-end g-mb-20 g-mb-0--md">
        //                     <div className="col-md-7 g-mb-30">
        //                         <OrderFilter></OrderFilter>
        //                     </div>
        //                 </div> */}
        //                 {/* <!-- Links --> */}
        //                 <ProfileTab active="openorders"></ProfileTab>
        //                 {/* <!-- End Links --> */}
        //                 <div className="text-center">
        //                     <p>
        //                         Looking for an order? All of your orders have
        //                         shipped.
        //                         <Link to="/account/overview">
        //                             View all orders
        //                         </Link>
        //                     </p>
        //                 </div>
        //             </div>
        //         </div>
        //     </ContainerWithGap>
        // </>
        <ContainerWithGap>
            <div className="row">
                {/* <!-- Profile Settings --> */}
                <ProfileNavSidebar active="openorders"></ProfileNavSidebar>
                {/* <!-- End Profile Settings --> */}

                {/* <!-- Orders --> */}
                <div className="col-lg-9 g-mb-50">
                    {loading ? (
                        <Loader />
                    ) : (
                        <>
                            {/* <!-- Links --> */}
                            <ProfileTab active="openorders"></ProfileTab>
                            {/* <!-- End Links --> */}

                            <div className="mb-5">
                                <h3 className="h6 d-inline-block">
                                    {orders.length} orders{" "}
                                </h3>

                                {/* <!-- End Secondary Button --> */}
                            </div>
                            {/* <!-- Order Block --> */}
                            {orders.length
                                ? orders.map((item) => {
                                      return (
                                          <div
                                              className="g-brd-around g-brd-gray-light-v4 rounded g-mb-30"
                                              key={item.id}
                                          >
                                              <header className="g-bg-gray-light-v5 g-pa-20">
                                                  <div className="row">
                                                      <div className="col-sm-3 col-md-2 g-mb-20 g-mb-0--sm">
                                                          <h4 className="g-color-gray-dark-v4 g-font-weight-400 g-font-size-12 text-uppercase g-mb-2">
                                                              Order Placed
                                                          </h4>
                                                          <span className="g-color-black g-font-weight-300 g-font-size-13">
                                                              {get_date_format(
                                                                  item.created_at
                                                              )}
                                                          </span>
                                                      </div>

                                                      <div className="col-sm-3 col-md-2 g-mb-20 g-mb-0--sm">
                                                          <h4 className="g-color-gray-dark-v4 g-font-weight-400 g-font-size-12 text-uppercase g-mb-2">
                                                              Total
                                                          </h4>
                                                          <span className="g-color-black g-font-weight-300 g-font-size-13">
                                                              $
                                                              {item.total_price}
                                                          </span>
                                                      </div>

                                                      <div className="col-sm-3 col-md-2 g-mb-20 g-mb-0--sm">
                                                          <h4 className="g-color-gray-dark-v4 g-font-weight-400 g-font-size-12 text-uppercase g-mb-2">
                                                              Ship to
                                                          </h4>
                                                          <span className="g-color-black g-font-weight-300 g-font-size-13">
                                                              {userName}
                                                          </span>
                                                      </div>

                                                      <div className="col-sm-3 col-md-4 ml-auto text-sm-right">
                                                          <h4 className="g-color-gray-dark-v4 g-font-weight-400 g-font-size-12 text-uppercase g-mb-2">
                                                              Order # {item.idx}
                                                          </h4>
                                                          <Link
                                                              className="g-font-weight-300 g-font-size-13"
                                                              to="#"
                                                          >
                                                              Invoice
                                                          </Link>
                                                      </div>
                                                  </div>
                                              </header>

                                              {/* <!-- Order Content --> */}
                                              <div className="g-pa-20">
                                                  <div className="row">
                                                      <div className="col-md-8">
                                                          <div className="mb-4">
                                                              <h3 className="h5 mb-1">
                                                                  {item.is_shipped
                                                                      ? "Delivered"
                                                                      : "Order In Progress"}
                                                              </h3>
                                                              <p className="g-color-gray-dark-v4 g-font-size-13">
                                                                  {item.is_shipped
                                                                      ? "Your package was delivered per the instructions."
                                                                      : "Your Package is on shipping process"}
                                                              </p>
                                                          </div>

                                                          {item.items.length
                                                              ? item.items.map(
                                                                    (data) => {
                                                                        return (
                                                                            <OrderItem
                                                                                key={
                                                                                    data.id
                                                                                }
                                                                                data={
                                                                                    data
                                                                                }
                                                                            />
                                                                        );
                                                                    }
                                                                )
                                                              : ""}
                                                      </div>
                                                      {item.items.length
                                                          ? item.items.map(
                                                                (d) => {
                                                                    return (
                                                                        <div
                                                                            className="col-md-4"
                                                                            key={
                                                                                d.id
                                                                            }
                                                                        >
                                                                            <Link
                                                                                className="btn btn-block g-brd-around g-brd-gray-light-v3 g-color-gray-dark-v3 g-bg-gray-light-v5 g-bg-gray-light-v4--hover g-font-size-12 text-uppercase g-py-12 g-px-25"
                                                                                to={`/products/${d.product.slug}`}
                                                                            >
                                                                                Write
                                                                                a
                                                                                Product
                                                                                Review
                                                                            </Link>
                                                                        </div>
                                                                    );
                                                                }
                                                            )
                                                          : ""}
                                                  </div>
                                              </div>
                                              {/* <!-- End Order Content --> */}
                                          </div>
                                      );
                                  })
                                : ""}

                            {/* <!-- End Order Block --> */}

                            {/* Pagination */}
                        </>
                    )}
                    {orders.length ? (
                        <ReactPaginate
                            nextLabel="Next"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            pageCount={pageSize}
                            previousLabel="Previous"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />
                    ) : (
                        ""
                    )}
                </div>
                {/*  Orders */}
            </div>
        </ContainerWithGap>
    );
};
export default OpenOrders;
