import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    orders: []
};

export const ordersSlice = createSlice({
    name: "orders",
    initialState,
    reducers: {
        set_orders: (state, action) => {
            state.orders = action.payload;
        }
    }
});

export const { set_orders } = ordersSlice.actions;
export const orderSlice = (state) => state.orders;
export default ordersSlice.reducer;
