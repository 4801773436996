// Appends Scripts Dynamically to the body
export const appendScripts = (scriptsToAppend) => {
    if(scriptsToAppend.length > 0){
        scriptsToAppend.map( scriptToAppend => {
            let script = document.createElement("script");
            script.src = scriptToAppend;
            script.async = true;
            document.body.appendChild(script);
        } )
    }
};

/**
 *
 * @param {accepts Array of String Path} scriptsToRemove
 */
export const removeScripts = (scriptsToremove) => {
    if( scriptsToremove.length > 0 ){
        scriptsToremove.map( scriptToRemove => {
            let allsuspects=document.getElementsByTagName("script");
            for (let i=allsuspects.length; i>=0; i--){
            if (allsuspects[i] && allsuspects[i].getAttribute("src")!==null
                  && allsuspects[i].getAttribute("src").indexOf(`${scriptToRemove}`) !== -1 ){
                   allsuspects[i].parentNode.removeChild(allsuspects[i])
                }
            }
        } )
    }
}

/**
 *
 * @param {Accepts of Array of String Path} stylesToAppend
 */
export const appendStyles = (stylesToAppend) => {
    if( stylesToAppend.length > 0 ){
        stylesToAppend.map( styleToAppend => {
            let style = document.createElement("link");
            style.href = styleToAppend;
            style.rel = "stylesheet";
            style.async = true;
            document.head.appendChild(style);
        } )
    }
};

/**
 *
 * @param {Accpets Array of String Path} stylesToremove
 */
export const removeStyles = (stylesToremove) => {
    if( stylesToremove.length > 0 ){
        stylesToremove.map( styleToRemove => {
            let allsuspects=document.getElementsByTagName("link");
            for (let i=allsuspects.length; i>=0; i--){
            if (allsuspects[i] && allsuspects[i].getAttribute("href")!==null
                  && allsuspects[i].getAttribute("href").indexOf(`${styleToRemove}`) !== -1 ){
                   allsuspects[i].parentNode.removeChild(allsuspects[i])
                }
            }
        })
    }
}


/**
 * Date Format Converter Js
 * Return format : 7 Jun 2021
 */
export const get_date_format = ( given_date  ) => {
    // alert(!!given_date);

    if( !!given_date == false){
        return '';
    }

    const months_abbr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const d = new Date(given_date);
    let date_with_format = d.getDate();
    date_with_format +=" "+months_abbr[d.getMonth()];
    date_with_format +=" "+d.getFullYear();

    return date_with_format;

}

/**
 * Date Format Converter Js
 * Return format : Jun 2021
 */
export const get_short_date_format = ( given_date  ) => {
    // alert(!!given_date);

    if( !!given_date == false){
        return '';
    }

    const months_abbr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const d = new Date(given_date);
    let date_with_format = months_abbr[d.getMonth()];
    date_with_format +=" "+d.getFullYear();

    return date_with_format;

}


/**
 * Get Menu Url
 */
export const get_menu_url = (menuObj) => {
    const menu = menuObj;

    if(!!menu.url || menu.url===""){
        return menu.url;
    }

    let slug_prefix = '';
    let menu_type = menu.menu_type?.split(':')[0];
    let slug = menu.slug;
    switch(menu_type){
        case "ProductCategory":
        case 'ProductCollection':
            slug_prefix = '/product-categories/';
            break;
        case 'BlogCategory':
        case 'BlogType':
            slug_prefix = '/blog-categories/';
            break;
        case 'Product':
            slug_prefix = '/product/';
            break;
        case 'Blog':
            slug_prefix = '/blogs/';
            break;
        default:
            slug_prefix = '';
    }
    let url = slug_prefix+slug;

    return url;

}


/**
 * Get Fetched Menu Url
 */

 export const get_fetched_menu_url = (menuObj, fetchedMenuObj) => {
    const menu = menuObj;
    const submenu = fetchedMenuObj;
    let slug_prefix = '';
    let menu_type = menu.menu_type.split(':')[0];
    let slug = submenu.slug;
    switch(menu_type){
        case "ProductCategory":
        case 'ProductCollection':
            slug_prefix = '/products/';
            break;
        case 'BlogCategory':
        case 'BlogType':
            slug_prefix = '/blogs/';
            break;
        default:
            slug_prefix = '';
    }
    let url = slug_prefix+slug;

    return url;

}

/**
 * Get Listing Page Url
 */

 export const get_listing_page_url = (menuObj) => {
    const menu = menuObj;

    let slug_prefix = '';
    let menu_type = menu?.menu_type?.split(':')[0];
    let slug = menu?.menu_type_item?.split(':')[1];
    switch(menu_type){
        case "ProductCategory":
        case 'ProductCollection':
            slug_prefix = '/product-categories/';
            break;
        case 'BlogCategory':
        case 'BlogType':
            slug_prefix = '/blog-categories/';
            break;
        default:
            slug_prefix = '';
    }
    let url = slug_prefix+slug;

    return url;

}


/**
 * Get Image Url -- optimized
 * of either 'vertical' or 'square'
 * default will be 'square'
 */

export const get_optimized_image_url = (path, type="vertical") => {
    let sizes = {
        square : '700x700',
        vertical : '700x950',
        horizontal : '1200x500',
    }

    let org_path = path;

    if( org_path == '' || org_path==null ){
        return path;
    }

    let path_fragments = org_path.split('.');

    let image_ext = path_fragments[(path_fragments.length-1)]??'';
    // remove ext from path
    path_fragments.pop();
    let path_without_ext = path_fragments.join('.');

    let optimized_image_url = path_without_ext+'_'+sizes[type]+'.'+image_ext;

    //change path -- add "/thumb" folder path
    let op_img_path_parts = optimized_image_url.split('/');
    op_img_path_parts[op_img_path_parts.length-1] = 'thumbs/'+op_img_path_parts[op_img_path_parts.length-1];

    optimized_image_url = op_img_path_parts.join('/');

    return optimized_image_url;

}


/**
 * @params this function gets product price(int) and product varient's price(array of prices) as parameter
 *
 * @returns int price
 * price - min price of product
*/
export const get_min_product_price = (price, varient_prices) => {
    let min_price = price;

    varient_prices.map( p => {
        if( p < min_price ){
            min_price = p;
        }
    });
    return min_price;
}


/**
 * This function returns given price to amount format (2 digits after decimal)
 * @param {float} price
 */

export const format_price = ( price ) => {
    return parseFloat(price).toFixed(2);
}


/**
 *
 * @param {*} first_name  //Ex: Johh
 * @param {*} last_name   //Ex: Doe
 * @returns {string} acronym //Ex: JD
 */
export const getNameAcronym = ( first_name, last_name ) => {

    let fn_acr = first_name?first_name[0] : '';
    let ln_acr = last_name?last_name[0] : '';

    return (fn_acr+ln_acr).toUpperCase();

}