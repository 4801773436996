import { Link } from "react-router-dom";

// Page for any 404 routes 
const PageNotFound = () => {

	return (
		<>
			<div className="error-page-wrapper g-py-50">
                <img src="/assets/images/404.svg" className="d-flex justify-content-center w-100" alt="" />
                <div className="empty-text-wrapper g-pt-30">
                    <h2 className="text-center">Oops Page Not Found</h2>
                    <p className="text-center">Sorry for the inconvenience. It looks like you are trying to access the page that has been deleted or never even existed.</p>
                </div>
                <div className="d-flex justify-content-center">
                    <Link to="/" className="btn btn-block u-btn-primary g-font-size-14 text-uppercase g-py-10 g-px-15" type="button" style={{ width: 'fit-content' }}>
                        Back to HomePage
                    </Link>
                </div>

            </div>
		</>
	);

}

export default PageNotFound;