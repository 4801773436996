const HeadingCenter = (props) => {
	return (
			<>
			{/* <!-- Promo Block --> */}
			<section className="dzsparallaxer auto-init height-is-based-on-content use-loading mode-scroll loaded dzsprx-readyall position-relative" data-options='{direction: "fromtop", animation_duration: 25, direction: "reverse"}'>
				<div className="divimage dzsparallaxer--target w-100 g-bg-pos-top-center g-bg-cover g-bg-black-opacity-0_1--after" style={{height: '140%', backgroundImage: `url('${props.background}')`, backgroundPosition: '50% 50%'}}></div>
				<div className="divimage dzsparallaxer--target w-100 g-bg-pos-top-center g-bg-cover g-bg-gray-radialgradient-ellipse" style={{height: '140%', background: '#000', opacity:0.4}}></div>

				<div className="container g-color-white g-pt-100 g-pb-40">
					<div className="g-mb-50">
						{/* <span className="d-block g-color-white-opacity-0_8 g-font-weight-300 g-font-size-20">Best hand-made</span> */}
						<h3 className="g-color-white g-font-weight-600 g-font-size-50 g-font-size-50--md g-line-height-1_2 mb-0 text-center">{props.title}</h3>
						{/* <p className="g-color-white g-font-weight-600 g-font-size-20 text-uppercase">Trends 2018</p> */}
					</div>
				</div>
			</section>
			{/* <!-- End Promo Block --> */}
			</>
	);
}

export default HeadingCenter;