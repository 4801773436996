import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ScrollToTop = (props) => {
  	const nav = useNavigate();

  	useEffect(() => {
		window.scroll({
			top : 0,
			left : 0, 
			behavior :'instant'});
  	}, [nav]);

	return <></>;
	  
};

export default ScrollToTop;