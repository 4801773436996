import QuantityChange from "../cart_components/QuantityChange";

import { useDispatch } from "react-redux";
import { format_price, get_optimized_image_url } from "../../helper/Helper";
import { Link } from "react-router-dom";
import { removeSaveLater } from "../../helper/SaveLaterHelper";
const WaitlistItem = ({ item }) => {
    // const item = props.item;
    const dispatch = useDispatch();

    // const item = {
    //     image: "https://homnillc.yilab.org.np/my_media/products/thumbs/MicrosoftTeams-image_1_aXf6XUa_700x950.png",
    //     slug: "/category",
    //     title: "Adhuwa",
    //     price: "210",
    //     qty: 2,
    //     product_id: 23
    // };

    return (
        <tr className="g-brd-bottom g-brd-gray-light-v4">
            <td className="text-left g-py-25">
                <img
                    className="d-inline-block g-width-100 mr-4"
                    src={get_optimized_image_url(item.image)}
                    alt="Description"
                />
                <div className="d-inline-block align-middle cart-media-wrapper">
                    <Link
                        to={"/products/" + item?.slug}
                        className="g-color-black"
                    >
                        <h6 className="g-color-black">{item.title}</h6>
                    </Link>
                    <ul className="list-unstyled g-color-gray-dark-v4 g-font-size-12 g-line-height-1_6 mb-0">
                        {() => {
                            for (const key in item.attributes) {
                                return (
                                    <li>
                                        `${key}: ${item.attributes[key]}`
                                    </li>
                                );
                            }
                        }}
                        {/* <li className="g-font-size-16">Size: MD</li> */}
                    </ul>
                </div>
            </td>
            <td className="g-color-gray-dark-v2 g-font-size-13">
                $ {item.price}
            </td>
            {/* <td>
                <div className="js-quantity input-group u-quantity-v1 g-width-95 g-brd-primary--focus">
                    <QuantityChange item={item} />
                </div>
            </td> */}
            <td className="text-right g-color-black">
                <span className="g-color-gray-dark-v2 g-font-size-13 mr-4">
                    ${format_price(item.qty * item.price)}{" "}
                </span>
                <span
                    className="g-color-gray-dark-v4 g-color-black--hover g-cursor-pointer"
                    onClick={() => removeSaveLater(item, dispatch)}
                >
                    <i className="mt-auto fa fa-trash"></i>
                </span>
            </td>
        </tr>
    );
};
export default WaitlistItem;
