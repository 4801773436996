import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	recipes : []
};

export const recipesSlice = createSlice({
	name : 'recipes',
	initialState,
	reducers : {
		set_recipes : (state, action) => {
			state.recipes = action.payload;
		},
	}
})

export const { set_recipes } = recipesSlice.actions;

export default recipesSlice.reducer;