import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { authUser, verify } from "../features/authentication/userSlice";
import Cookies from "js-cookie";
import { signOut } from "../services/AuthServices";
import { toast } from "react-toastify";

const AccountVerifyPage = (props) => {
	/**	 
	 * Form Validation Rules [ Yup library used for validation ]
	 */ 
	const validationSchema = Yup.object().shape({
		code: Yup.string().required("Enter 5 digit OTP"),
	});
	
	const resendOtpUrl = process.env.REACT_APP_API_BASE_URL+"/accounts/resend-code";
	const accoutVerifyUrl = process.env.REACT_APP_API_BASE_URL+"/accounts/verify";
	
	const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
	
	const loggedInUser = useSelector(authUser);
	const dispatch = useDispatch();

	let navigate = useNavigate(); //used for navigation redirect
	
	// this variable will store form submit response from api 
    let global_response = "";

	// Function Executes when "Resend OTP" is clicked. Handles Resend OTP 
	const resendOtpHandler = () => {
		const requestOptions = {
            method: 'GET',
			headers: { 
				'authorization' : 'Bearer '+Cookies.get('access_token')
			}
        };
		fetch(resendOtpUrl, requestOptions)
			.then(response=>{
				if( response.ok ){
					setSuccessMessage("OTP send successfully");
				}
			});
	}

	// Handles form submit 
	const formSubmitHandler = (formData, resetForm, setSubmitting) => {
        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            body: formData,
			headers: { 
				'Content-Type': 'application/json',
				'authorization' : 'Bearer '+Cookies.get('access_token')
			}
        };
        fetch(accoutVerifyUrl, requestOptions)
            .then((response) => {
				global_response = response;
				return response.json();
			})
            .then((result) => {
				if (global_response.ok) {
					// setSuccessMessage("User Verification Successful");
					toast.success('User Verification Successful');
					dispatch(verify(true));
					resetForm();
				} else {
					for (const r in result) {
						setErrorMessage(result[r][0]);
						break;
					}
				}
				setSubmitting(false);
			})
            .catch((error) => {
            })
    }
	useEffect(() => {
		// if already loggedIn -- do smthing else 
		if( loggedInUser.is_logged_in == true ){
			if( loggedInUser.is_verified ){
				navigate('/account/overview');
			}
		}else{
			navigate('/account/login');
		}
	}, [loggedInUser])

	return (
		<>
		{/* <!-- Login --> */}
		<section className="g-bg-gray-light-v5">
			<div className="container g-py-100">
				<div className="row justify-content-center">
					<div className="col-sm-8 col-lg-5">
						<div className="u-shadow-v21 g-bg-white rounded g-py-40 g-px-30">
							<div className="text-center mb-4">
								<h2 className="h2 g-color-black g-font-weight-600">
									Account Verification
								</h2>
								<small>Please enter the OTP number sent to your email.</small>
							</div>

							<Formik
								initialValues={{
									code: ''
								}}
								validationSchema={validationSchema}
								onSubmit={(values, { setSubmitting, resetForm }) => {
									setTimeout(() => {
										setSuccessMessage("");
										setErrorMessage("");
										formSubmitHandler(JSON.stringify(values, null, 2), resetForm, setSubmitting);
									}, 400);
								}}
							>
								{({ isSubmitting, setFieldValue }) => (
									// Form Start 
									
									<Form className="g-py-15">
										{ successMessage ? <div className="alert alert-success">{successMessage}</div> : '' }
										{ errorMessage ? <div className="alert alert-danger">{errorMessage}</div> : ''}
										<div className="mb-4">
											{/* <label className="g-color-gray-dark-v2 g-font-weight-600 g-font-size-13">Enter OTP</label> */}
											<Field className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v4 g-brd-primary--hover rounded g-py-15 g-px-15" name="code" type="text" placeholder="OTP" />
											<ErrorMessage name="code">
												{msg => <div className="g-mt-10 text-danger field_error_message"><i className="fa fa-info-circle g-mr-5"></i>{msg}</div>}
											</ErrorMessage>
										</div>

										<div className="g-mb-35">
											<div className="row justify-content-between">
												<div className="col align-self-center text-center">
													<span className="g-font-size-12 text-primary verify-change" type="button" onClick={resendOtpHandler}>Didn't get OTP? Resend</span>
												</div>
											</div>
										</div>

										<div className="mb-4">
											<button className="btn btn-md btn-block u-btn-primary rounded g-py-13" type="submit" disabled={isSubmitting}>Submit</button>
										</div>
										<small rel="presentation" class="verify-change" onClick={()=> signOut(dispatch) }>Try another account?</small>
									{/* <!-- End Form --> */}
									</Form>
								)}
							</Formik>

						</div>
					</div>
				</div>
			</div>
		</section>
		</>
	);
}

export default AccountVerifyPage;