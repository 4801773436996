import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { get_optimized_image_url } from "../../helper/Helper";
import Loading from "../Loading";
import HtmlParser from "react-html-parser";
import Animate from "../Animate";
const ProductRecipe = (props) => {
    const { type } = props;

    const [items, setItems] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    var url;
    if (type) {
        url =
            process.env.REACT_APP_API_BASE_URL +
            `/recipe/?recipe_type__slug=${type}&expand=recipe_category`;
    } else {
        url =
            process.env.REACT_APP_API_BASE_URL +
            `/recipe/?&expand=recipe_category&page_size=3`;
    }

    useEffect(() => {
        fetch(url)
            .then((response) => response.json())
            .then((result) => {
                setItems(result.results);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
            });
    }, [type]);

    if (loading) {
        return <Loading />;
    }

    if (items.length <= 0) {
        return <></>;
    }

    return (
        <>
            <Animate>
                <div className="homni-container product-recipe-wrapper" id>
                    <div className="row g-mx-minus-10">
                        {items.map((item) => (
                            <div className="col-lg-4 g-px-10 g-mb-15">
                                {/* <Link to={"/recipes/" + item.slug}>
									<div className="image-gradient">
										<img className="w-100 u-block-hover__main--zoom-v1 g-mb-minus-8" src={get_optimized_image_url(item.photo, 'square')} alt="Image Description" />
									</div>
								</Link> */}

                                <div className="recipe-wrapper">
                                    <img
                                        className="w-100 h-100 u-block-hover__main--zoom-v1 g-mb-minus-8"
                                        src={get_optimized_image_url(
                                            item.photo,
                                            "square"
                                        )}
                                        alt="Recipe"
                                    />
                                    <div className="recipe-showcase g-pos-abs">
                                        {/* <div className="homesilder-image-text position-absolute g-bottom-0 g-color-white g-left-15">
											<h4>Egg with Chilli sauce</h4>
											<p><i class="fa fa-minus g-mr-5 g-font-size-10" aria-hidden="true"></i>Nisha Banjyakar</p>
										</div> */}
                                        <h4 className="mb-20 g-color-white">
                                            <Link
                                                className="g-color-white h4"
                                                to={"/recipes/" + item.slug}
                                            >
                                                {item.title}
                                            </Link>
                                        </h4>
                                        {/* <p className="g-color-white"><i class="fa fa-minus g-mr-5 g-font-size-10" aria-hidden="true"></i>Nisha Banjyakar</p> */}
                                        <p className="d-block g-color-white g-mb-0 recipe-author">
                                            {item.author ?? ""}
                                        </p>
                                        <p className="recipe-showcase-description py-2 g-color-white">
                                            {HtmlParser(item.description)}
                                        </p>
                                        <Link
                                            className="btn btn-md u-btn-outline-white g-mb-20 g-mr-10 g-mb-1 g-font-weight-600 g-font-size-12 text-uppercase g-px-5 g-py-5"
                                            to={"/recipes/" + item.slug}
                                        >
                                            View Recipe
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Animate>
        </>
    );
};

export default ProductRecipe;
