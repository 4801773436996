import ContainerWithGap from "../components/container/ContainerWithGap";


const CareerPage = (props) => {

    return (
        <>
            <ContainerWithGap>
                <div className="error-page-wrapper g-py-120">
                    <div className="empty-text-wrapper g-pt-30 g-pb-50 g-max-width-768">
                        <h2 className="text-center">Interested in working with Homni? </h2>
                    </div>
                    <div className="text-center">
                        <p className="text-center">We don't have any open positions right now, but we're always interested in hearing from motivated, innovative, creative human beings who care about the world. If you think you've got what it takes to make a position for yourself, send us an email at <a href="">talent@homni.supply.</a></p>
                        <i className="text-center">*We periodically cycle through project contracts. If you’d be interested in being contacted for temporary work, we always like to hear from professionals in marketing & design, life-cycle assessment, supply chain management, culinary arts, nutrition, agricultural management, or product management (essential oils or herbs & spices).
                        </i>
                    </div>
                </div>
            </ContainerWithGap>
        </>
    )
}

export default CareerPage;