// this component is for pagination view 

import { Link } from "react-router-dom";

const Pagination = (props) => {
	return (
		<>
		{/* this create grey border above the pagination  */}
		<div className="g-brd-bottom g-brd-gray-light-v4 g-pb-70 g-mb-60"></div>

		{/* // <!-- Pagination --> */}
		<nav className="g-mb-100" aria-label="Page Navigation">
			<ul className="list-inline mb-0">
				<li className="list-inline-item hidden-down">
				<Link className="active u-pagination-v1__item g-width-30 g-height-30 g-brd-gray-light-v3 g-brd-primary--active g-color-white g-bg-primary--active g-font-size-12 rounded-circle g-pa-5" to="#">1</Link>
				</li>
				<li className="list-inline-item hidden-down">
				<Link className="u-pagination-v1__item g-width-30 g-height-30 g-color-gray-dark-v5 g-color-primary--hover g-font-size-12 rounded-circle g-pa-5" to="#">2</Link>
				</li>
				<li className="list-inline-item g-hidden-xs-down">
				<Link className="u-pagination-v1__item g-width-30 g-height-30 g-color-gray-dark-v5 g-color-primary--hover g-font-size-12 rounded-circle g-pa-5" to="#">3</Link>
				</li>
				<li className="list-inline-item hidden-down">
				<span className="g-width-30 g-height-30 g-color-gray-dark-v5 g-font-size-12 rounded-circle g-pa-5">...</span>
				</li>
				<li className="list-inline-item g-hidden-xs-down">
				<Link className="u-pagination-v1__item g-width-30 g-height-30 g-color-gray-dark-v5 g-color-primary--hover g-font-size-12 rounded-circle g-pa-5" to="#">15</Link>
				</li>
				<li className="list-inline-item">
				<Link className="u-pagination-v1__item g-width-30 g-height-30 g-brd-gray-light-v3 g-brd-primary--hover g-color-gray-dark-v5 g-color-primary--hover g-font-size-12 rounded-circle g-pa-5 g-ml-15" to="#" aria-label="Next">
					<span aria-hidden="true">
					<i className="fa fa-angle-right"></i>
					</span>
					<span className="sr-only">Next</span>
				</Link>
				</li>
				<li className="list-inline-item float-right">
				<span className="u-pagination-v1__item-info g-color-gray-dark-v4 g-font-size-12 g-pa-5">Page 1 of 15</span>
				</li>
			</ul>
		</nav>
		{/* // <!-- End Pagination --> */}
		</>
	);
};

export default Pagination;