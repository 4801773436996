import React from "react";
import Slider from "react-slick";
import { useState } from "react";
import ContainerWithGap from "../container/ContainerWithGap";
import { NextArrow, PrevArrow } from "../slider/CustomArrow";
import { Link } from "react-router-dom";

const RecipeSlider = ({ data, categoryBaseLink }) => {
    // const { photo, title, slug } = data;
    const settings = {
        className: "grower_slider",
        slidesToShow: 4,
        swipeToSlide: true,
        focusOnSelect: true,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();

    return (
        <>
            <ContainerWithGap>
                <div className="repo-slider-section">
                    <div className="intresting-repo-first g-brd-primary">
                        <Slider
                            asNavFor={nav2}
                            ref={(slider1) => setNav1(slider1)}
                        >
                            {data &&
                                data.map((i) => {
                                    return (
                                        <div key={i.id}>
                                            <div className="row align-items-center">
                                                <div className="col-md-8">
                                                    <img
                                                        class="img-fluid"
                                                        src={i.image}
                                                        alt="description"
                                                    />
                                                </div>
                                                <div className="col-md-4 intresting-repo-first-text">
                                                    <h2>{i.name}</h2>
                                                    <Link
                                                        // to={{pathname:}}
                                                        to={`/${categoryBaseLink}/${i.slug}`}
                                                        style={{
                                                            textDecoration:
                                                                "none"
                                                        }}
                                                    >
                                                        <button
                                                            className="btn btn-block u-btn-primary g-font-size-14 text-uppercase atbg_btn  g-py-10 g-px-15 g-mt-20 "
                                                            type="button"
                                                        >
                                                            VIEW MORE
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </Slider>
                    </div>

                    <div className="intresting-repo-second g-mt-50">
                        <Slider
                            asNavFor={nav1}
                            ref={(slider2) => setNav2(slider2)}
                            {...settings}
                        >
                            {data &&
                                data.map((d) => {
                                    return (
                                        <div className="px-3" key={d.id}>
                                            <div className="intresting-slider-wrapper">
                                                <img
                                                    className="img-fluid position-relative"
                                                    src={d.image}
                                                    alt="description"
                                                />
                                                <div className="secondsilder-image-text position-absolute g-ml-10">
                                                    <h5 className="g-color-white text-capitalize">
                                                        {d.name}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </Slider>
                    </div>
                </div>
            </ContainerWithGap>
        </>
    );
};

export default RecipeSlider;
