import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "../../services/AuthServices";
import { useDropzone } from "react-dropzone";
import Cookies from "js-cookie";
// import { user } from "../../app/store";
import { authUser, login } from "../../features/authentication/userSlice";
import { toast } from "react-toastify";
import { persistor } from "../../app/store";
const ProfileNavSidebar = (props) => {
    const [files, setFiles] = useState([]);
    const active = props.active;

    const dispatch = useDispatch();
    const formData = new FormData();
    const { user } = useSelector(authUser);
    const { first_name, last_name, photo } = user;
    const [errorImage, setErrorImage] = useState("");
    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/jpeg, image/png, image/jpg",
        maxFiles: 1,
        multiple: false,
        maxSize: 2 * 1000000, //2MB
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
            );
        }
    });

    useEffect(() => {
        if (files.length > 0) {
            formData.append("photo", files[0]);
            let global_response = "";
            const options = {
                method: "POST",
                body: formData,
                headers: {
                    // "content-type": "multipart/form-data",
                    authorization: "Bearer " + Cookies.get("access_token")
                }
            };
            fetch(
                `${process.env.REACT_APP_API_BASE_URL}/accounts/details`,
                options
            )
                .then((response) => {
                    global_response = response;
                    return response.json();
                })
                .then((result) => {
                    setFiles([]);

                    let payload = {
                        user: result
                        // access_token: Cookies.get("access_token")
                    };
                    dispatch(login(payload));
                    toast.success("Profile Picture Updated Successfully !!");
                    // dispatch(user(result));
                });
        }
    }, [files]);

    const fullname = first_name + " " + last_name;
    return (
        <div className="col-lg-3 g-mb-50 g-mt-50">
            <aside className="g-brd-around g-brd-gray-light-v4 rounded g-px-20 g-py-30">
                {/* <!-- Profile Picture --> */}
                <div className="text-center g-pos-rel g-mb-30">
                    <div className="g-width-100 g-height-100 mx-auto mb-3">
                        <Link to="">
                            {/* <LazyLoadImage
                                className="img-fluid rounded-circle"
                                alt="Image Decor"
                                src={photo} // use normal <img> attributes as props
                            /> */}
                            <img
                                src={photo}
                                alt="profile"
                                className="rounded-circle img-fluid h-100 w-100"
                                style={{
                                    objectFit: "cover",
                                    objectPosition: "center"
                                }}
                            />
                        </Link>
                        {/* <img className="img-fluid rounded-circle" src="assets/img-temp/100x100/img1.jpg" alt="Image Decor" /> */}
                    </div>

                    <span className="d-block g-font-weight-500">
                        {fullname}
                    </span>
                    {/* {errorImage && errorImage} */}
                    <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        {
                            <button
                                style={{ border: "none", outline: "none" }}
                                // className="btn u-btn-outline-primary g-font-size-12 text-uppercase g-py-15 g-px-25"
                                // type="submit"
                            >
                                <span
                                    className="u-icon-v3 u-icon-size--xs g-color-white--hover g-bg-primary--hover rounded-circle g-pos-abs g-top-0 g-right-15 g-cursor-pointer"
                                    title="Change Profile Picture"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                >
                                    <i className="icon-finance-067 u-line-icon-pro"></i>
                                </span>
                            </button>
                        }
                    </div>
                </div>
                {/* <!-- End Profile Picture --> */}

                <hr className="g-brd-gray-light-v4 g-my-30" />

                {/* <!-- Profile Settings List --> */}
                <ul className="list-unstyled mb-0">
                    {/* <li className="g-pb-3">
                        <Link className="d-block align-middle u-link-v5 g-color-text g-color-primary--hover g-bg-gray-light-v5--hover rounded g-pa-3" to="">
                            <span className="u-icon-v1 g-color-gray-dark-v5 mr-2"><i className="icon-finance-059 u-line-icon-pro"></i></span>
                            Your Wallet
                        </Link>
                    </li> */}
                    <li className="g-py-3">
                        <Link
                            className={
                                active === "orders"
                                    ? "d-block align-middle active u-link-v5 g-color-text g-color-primary--hover g-bg-gray-light-v5--hover g-color-primary--parent-active g-bg-gray-light-v5--active rounded g-pa-3"
                                    : "d-block align-middle u-link-v5 g-color-text g-color-primary--hover g-bg-gray-light-v5--hover rounded g-pa-3"
                            }
                            to="/account/overview"
                        >
                            <span className="u-icon-v1 g-color-gray-dark-v5 mr-2">
                                <i className="icon-finance-114 u-line-icon-pro"></i>
                            </span>
                            Your Orders
                        </Link>
                    </li>
                    <li className="g-py-3">
                        <Link
                            className={
                                active === "openorders"
                                    ? "d-block align-middle active u-link-v5 g-color-text g-color-primary--hover g-bg-gray-light-v5--hover g-color-primary--parent-active g-bg-gray-light-v5--active rounded g-pa-3"
                                    : "d-block align-middle u-link-v5 g-color-text g-color-primary--hover g-bg-gray-light-v5--hover rounded g-pa-3"
                            }
                            to="/account/open-orders"
                        >
                            <span className="u-icon-v1 g-color-gray-dark-v5 mr-2">
                                <i className="icon-finance-115 u-line-icon-pro"></i>
                            </span>
                            Open Orders
                        </Link>
                    </li>
                    <li className="g-py-3">
                        <Link
                            className={
                                active === "cancelled"
                                    ? "d-block align-middle active u-link-v5 g-color-text g-color-primary--hover g-bg-gray-light-v5--hover g-color-primary--parent-active g-bg-gray-light-v5--active rounded g-pa-3"
                                    : "d-block align-middle u-link-v5 g-color-text g-color-primary--hover g-bg-gray-light-v5--hover rounded g-pa-3"
                            }
                            to="/account/cancelled-orders"
                        >
                            <span className="u-icon-v1 g-color-gray-dark-v5 mr-2">
                                <i className="icon-finance-113 u-line-icon-pro"></i>
                            </span>
                            Cancelled Orders
                        </Link>
                    </li>
                    <li className="g-py-3">
                        <Link
                            className={
                                active === "waitlist"
                                    ? "d-block align-middle active u-link-v5 g-color-text g-color-primary--hover g-bg-gray-light-v5--hover g-color-primary--parent-active g-bg-gray-light-v5--active rounded g-pa-3"
                                    : "d-block align-middle u-link-v5 g-color-text g-color-primary--hover g-bg-gray-light-v5--hover rounded g-pa-3"
                            }
                            to="/account/waitlist"
                        >
                            <span className="u-icon-v1 g-color-gray-dark-v5 mr-2">
                                <i className="icon-medical-022 u-line-icon-pro"></i>
                            </span>
                            Waitlist
                        </Link>
                    </li>
                    {/* <li className="g-py-3">
                        <Link
                            className={
                                active === "addressess"
                                    ? "d-block align-middle active u-link-v5 g-color-text g-color-primary--hover g-bg-gray-light-v5--hover g-color-primary--parent-active g-bg-gray-light-v5--active rounded g-pa-3"
                                    : "d-block align-middle u-link-v5 g-color-text g-color-primary--hover g-bg-gray-light-v5--hover rounded g-pa-3"
                            }
                            to="/account/addressess"
                        >
                            <span className="u-icon-v1 g-color-gray-dark-v5 mr-2">
                                <i className="icon-real-estate-027 u-line-icon-pro"></i>
                            </span>
                            Addresses
                        </Link>
                    </li> */}
                    {/* <li className="g-py-3">
                        <Link className="d-block align-middle u-link-v5 g-color-text g-color-primary--hover g-bg-gray-light-v5--hover rounded g-pa-3" to="page-payment-options-1.html">
                            <span className="u-icon-v1 g-color-gray-dark-v5 mr-2"><i className="icon-finance-110 u-line-icon-pro"></i></span>
                            Payment Options
                        </Link>
                    </li> */}
                    <li className="g-py-3">
                        <Link
                            className={
                                active === "profilelogin"
                                    ? "d-block align-middle active u-link-v5 g-color-text g-color-primary--hover g-bg-gray-light-v5--hover g-color-primary--parent-active g-bg-gray-light-v5--active rounded g-pa-3"
                                    : "d-block align-middle u-link-v5 g-color-text g-color-primary--hover g-bg-gray-light-v5--hover rounded g-pa-3"
                            }
                            to="/account/profile-security"
                        >
                            <span className="u-icon-v1 g-color-gray-dark-v5 mr-2">
                                <i className="icon-finance-135 u-line-icon-pro"></i>
                            </span>
                            Login &amp; Security
                        </Link>
                    </li>
                    {/* <li className="g-pt-3">
                        <Link
                            className={
                                active === "notification"
                                    ? "d-block align-middle active u-link-v5 g-color-text g-color-primary--hover g-bg-gray-light-v5--hover g-color-primary--parent-active g-bg-gray-light-v5--active rounded g-pa-3"
                                    : "d-block align-middle u-link-v5 g-color-text g-color-primary--hover g-bg-gray-light-v5--hover rounded g-pa-3"
                            }
                            to="/account/notification"
                        >
                            <span className="u-icon-v1 g-color-gray-dark-v5 mr-2">
                                <i className="icon-education-033 u-line-icon-pro"></i>
                            </span>
                            Notifications
                        </Link>
                    </li> */}

                    <li className="g-pt-3">
                        <Link
                            className={
                                active === "notification"
                                    ? "d-block align-middle active u-link-v5 g-color-text g-color-primary--hover g-bg-gray-light-v5--hover g-color-primary--parent-active g-bg-gray-light-v5--active rounded g-pa-3"
                                    : "d-block align-middle u-link-v5 g-color-text g-color-primary--hover g-bg-gray-light-v5--hover rounded g-pa-3"
                            }
                            to="#"
                            onClick={(e) => {
                                persistor.purge();
                                signOut(dispatch);
                            }}
                        >
                            <span className="u-icon-v1 g-color-gray-dark-v5 mr-2">
                                <i className="icon-education-033 u-line-icon-pro"></i>
                            </span>
                            Logout
                        </Link>
                    </li>
                </ul>
                {/* <!-- End Profile Settings List --> */}
            </aside>
        </div>
    );
};
export default ProfileNavSidebar;
