import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import ContainerWithGap from "../components/container/ContainerWithGap";

const ContactPage = (props) => {
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name field is required"),
        email: Yup.string()
            .required("Email is required")
            .email("Valid email is required"),
        subject: Yup.string().required("Subject is required"),
        phone: Yup.string().required("Phone number is required"),
        message: Yup.string().required("Message field is required")
    });
    let main_response = "";
    const url = process.env.REACT_APP_API_BASE_URL + "/contact-us/";

    const onSubmitForm = async (data, resetForm, setSubmitting) => {
        const requestOptions = {
            method: "POST",
            body: data,
            headers: {
                "Content-Type": "application/json"
            }
        };
        fetch(url, requestOptions)
            .then((response) => {
                // console.log(response.json());
                main_response = response;
                return response.json();
            })
            .then((result) => {
                if (main_response.ok) {
                    setSuccessMessage("Review Submitted Successfully");
                    resetForm();
                } else {
                    //if validation error
                    setErrorMessage(
                        "Couldn't process request. Please try again"
                    );
                }
                setSubmitting(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <>
            <section className="clearfix">
                <div className="js-bg-video position-relative">
                    <div
                        className="g-bg-cover g-pos-rel g-z-index-1 g-min-height-400"
                        style={{
                            backgroundImage:
                                "url('/assets/removable_images/map.jpg')",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover"
                        }}
                    >
                        {/* <div className="container text-center g-pos-rel g-z-index-1 g-pt-100 g-pb-80">
            </div> */}
                        <div className="homni-container g-py-50 position-absolute g-z-index-1 contact-container">
                            <div className="row ">
                                <div className="col-lg-6 mx-auto g-py-15">
                                    {/* <!-- Media --> */}
                                    <div className="media g-brd-around g-brd-gray-light-v4 rounded g-pa-40 contact-page-media">
                                        <div className="d-flex g-mr-30">
                                            <img
                                                className="img-fluid d-inline-block g-pos-rel g-top-3"
                                                src="/assets/images/faq.svg"
                                                alt="Image description"
                                            />
                                            {/* <i className="fa fa-question d-inline-block g-color-primary g-font-size-40 g-pos-rel g-top-3"></i> */}
                                        </div>
                                        <div className="media-body">
                                            <span className="d-block g-font-weight-500 g-font-size-default mb-2">
                                                Questions? Check out our <br />
                                                <Link
                                                    to="/faq"
                                                    className="g-font-size-18 text-uppercase"
                                                >
                                                    Frequently Asked Questions
                                                    page
                                                </Link>
                                            </span>
                                        </div>
                                    </div>
                                    {/* <!-- End Media --> */}
                                </div>

                                <div className="col-lg-6 mx-auto g-py-15">
                                    {/* <!-- Media --> */}
                                    <div className="media g-brd-around g-brd-gray-light-v4 rounded g-pa-40 contact-page-media">
                                        <div className="d-flex g-mr-30">
                                            <i class="fa fa-globe d-inline-block g-color-primary g-font-size-40 g-pos-rel g-top-3"></i>
                                        </div>
                                        <div className="media-body">
                                            <span className="d-block g-font-weight-500 g-font-size-default mb-2">
                                                Wholesale Inquiries? Write us at{" "}
                                                <br />
                                                <Link
                                                    to="/wholesale"
                                                    className="g-font-size-18 text-uppercase"
                                                >
                                                    Wholesale@homni.com
                                                </Link>
                                            </span>
                                        </div>
                                    </div>
                                    {/* <!-- End Media --> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ContainerWithGap>
                <div className="container g-py-100">
                    {/* <!-- Contact Info --> */}
                    <div className="g-max-width-645 text-center mx-auto g-mb-70">
                        <h2 className="h1 mb-3">Contact Us</h2>
                        <p className="g-font-size-17 mb-0">
                            Special Requests? Product or delivery questions?
                            Suggestions for new spices, recipes, or
                            sustainability practices? Use the form below to get
                            in touch!
                        </p>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <Formik
                                initialValues={{
                                    name: "",
                                    email: "",
                                    subject: "",
                                    phone: "",
                                    message: ""
                                }}
                                validationSchema={validationSchema}
                                onSubmit={(
                                    values,
                                    { setSubmitting, resetForm }
                                ) => {
                                    setTimeout(() => {
                                        setSuccessMessage("");
                                        setErrorMessage("");
                                        onSubmitForm(
                                            JSON.stringify(values, null, 2),
                                            resetForm,
                                            setSubmitting
                                        );
                                    }, 400);
                                }}
                            >
                                <Form>
                                    <div className="row">
                                        <div className="col-md-6 form-group g-mb-20">
                                            <Field
                                                className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover rounded g-py-13 g-px-15"
                                                type="text"
                                                placeholder="Name"
                                                name="name"
                                            />
                                            <ErrorMessage name="name">
                                                {(msg) => (
                                                    <div className="g-mt-10 text-danger field_error_message">
                                                        <i className="fa fa-info-circle g-mr-5"></i>
                                                        {msg}
                                                    </div>
                                                )}
                                            </ErrorMessage>
                                        </div>

                                        <div className="col-md-6 form-group g-mb-20">
                                            <Field
                                                className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover rounded g-py-13 g-px-15"
                                                type="email"
                                                placeholder="Email"
                                                name="email"
                                            />
                                            <ErrorMessage name="email">
                                                {(msg) => (
                                                    <div className="g-mt-10 text-danger field_error_message">
                                                        <i className="fa fa-info-circle g-mr-5"></i>
                                                        {msg}
                                                    </div>
                                                )}
                                            </ErrorMessage>
                                        </div>

                                        <div className="col-md-6 form-group g-mb-20">
                                            <Field
                                                className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover rounded g-py-13 g-px-15"
                                                type="text"
                                                placeholder="Subject"
                                                name="subject"
                                            />
                                            <ErrorMessage name="subject">
                                                {(msg) => (
                                                    <div className="g-mt-10 text-danger field_error_message">
                                                        <i className="fa fa-info-circle g-mr-5"></i>
                                                        {msg}
                                                    </div>
                                                )}
                                            </ErrorMessage>
                                        </div>

                                        <div className="col-md-6 form-group g-mb-20">
                                            <Field
                                                className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover rounded g-py-13 g-px-15"
                                                type="tel"
                                                placeholder="Phone"
                                                name="phone"
                                            />
                                            <ErrorMessage name="phone">
                                                {(msg) => (
                                                    <div className="g-mt-10 text-danger field_error_message">
                                                        <i className="fa fa-info-circle g-mr-5"></i>
                                                        {msg}
                                                    </div>
                                                )}
                                            </ErrorMessage>
                                        </div>

                                        <div className="col-md-12 form-group g-mb-40">
                                            <Field
                                                as="textarea"
                                                className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover g-resize-none rounded g-py-13 g-px-15"
                                                rows="7"
                                                placeholder="Message"
                                                name="message"
                                            />
                                            <ErrorMessage name="message">
                                                {(msg) => (
                                                    <div className="g-mt-10 text-danger field_error_message">
                                                        <i className="fa fa-info-circle g-mr-5"></i>
                                                        {msg}
                                                    </div>
                                                )}
                                            </ErrorMessage>
                                        </div>
                                    </div>

                                    <div className="text-center">
                                        <button
                                            className="btn u-btn-primary g-font-size-12 text-uppercase g-py-12 g-px-25"
                                            type="submit"
                                        >
                                            Send Message
                                        </button>
                                    </div>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </div>
                <div className="g-mt-30 text-center">
                    <p>
                        *We will do our best to answer all inquiries within 24
                        hours. We are a very small team and apologize that we
                        can’t always answer immediately.
                    </p>
                    <p>
                        For general contact, please reach out to:{" "}
                        <a className="g-color-primary">Info@homni.supply</a>
                    </p>
                </div>
            </ContainerWithGap>
        </>
    );
};

export default ContactPage;
