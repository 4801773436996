import ContainerWithGap from "../components/container/ContainerWithGap";
import HomePageSlider from "../components/HomePageSlider";
import ProductRecipe from "../components/product_detail/ProductRecipe";
import SectionHeaderCenter from "../components/section_header/SectionHeaderCenter";
import { useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";

const RecipeType = (props) => {
    const [recipes, setRecipes] = useState();
    const [recipeType, setRecipeType] = useState();
    const [recipeCategory, setRecipeCategory] = useState();
    const [totalPages, setTotalPages] = useState();
    const [featured, setFeatured] = useState();

    const type = useParams().type;

    useEffect(() => {
        let url =
            process.env.REACT_APP_API_BASE_URL +
            `/recipe/?expand=recipe_type&recipe_type__slug=${type}`;
        fetch(url)
            .then((response) => response.json())
            .then((result) => {
                setRecipes(result.results);
                setTotalPages(result.total_pages);
            })
            .catch((error) => { });
    }, [type]);
    useEffect(() => {
        let url = process.env.REACT_APP_API_BASE_URL + `/recipe-type/`;
        fetch(url)
            .then((response) => response.json())
            .then((result) => {
                setRecipeType(result.results);
            })
            .catch((error) => { });
    }, [type]);

    useEffect(() => {
        if (recipes) {
            var temp = recipes.filter(
                (item) =>
                    item.is_featured_globally === true ||
                    item.is_featured === true
            );
            setFeatured(temp);
        }
    }, [recipes]);

    useEffect(() => {
        var temp;
        if (recipeType) {
            temp = recipeType.find((item) => item.slug === type);
            setRecipeCategory(temp.categories);
        }
    }, [recipeType]);

    useEffect(() => { }, [recipeCategory]);

    useEffect(() => { }, [featured]);

    return (
        <>
            <ContainerWithGap>
                {/* <div className="breadcrumbs">
                    <section className="g-brd-top g-py-50">
                        <div className=" g-bg-cover__inner">
                            <ul className="u-list-inline">
                                <li className="list-inline-item g-mr-7">
                                    <a className="u-link-v5 g-color-main" href="#">Home</a>
                                    <i className="g-color-gray-light-v2 g-ml-5">/</i>
                                </li>
                                <li className="list-inline-item g-mr-7">
                                    <a className="u-link-v5 g-color-main" href="#">Pages</a>
                                    <i className="g-color-gray-light-v2 g-ml-5">/</i>
                                </li>
                                <li className="list-inline-item g-color-primary">
                                    <span>About Us</span>
                                </li>
                            </ul>
                        </div>
                    </section>
                </div> */}
                {featured && featured.length > 0 && (
                    <section className="featured_recipe g-brd-primary">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <img
                                    class="img-fluid"
                                    src={featured[0].photo}
                                    alt="description"
                                />
                            </div>
                            <div className="col-lg-6">
                                <span className="text-uppercase">Featured</span>
                                <h1>{featured[0].title}</h1>
                                <Link to={`/recipes/${featured[0].slug}`}>
                                    <button
                                        className="btn btn-block u-btn-primary g-font-size-14 text-uppercase atbg_btn  g-py-10 g-px-15 g-mt-20 "
                                        type="button"
                                    >
                                        Read more
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </section>
                )}
                {recipeCategory && <HomePageSlider category={recipeCategory} />}
                <section className="recipe_listing">
                    <SectionHeaderCenter header_title="More Recipes"></SectionHeaderCenter>
                    <ProductRecipe type={type} />
                </section>
            </ContainerWithGap>
        </>
    );
};

export default RecipeType;
