// jshint esversion: 6
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { authUser } from '../features/authentication/userSlice';

const PublicRoute = ({element}) => {

	const loggedInUser = useSelector(authUser);

	if( loggedInUser.is_logged_in ){
		return element;
	}

	return <Navigate to="/account/overview" />;


}

export default PublicRoute;