import { set_payment_intend } from "../features/paymentSlice";
import { toast } from "react-toastify";
export const setPaymentIntend = (payload, dispatch) => {
    dispatch(set_payment_intend(payload));
};

// either create or update payment intend

export const createOrUpdatePaymentUpdate = (
    cart,
    dispatch,
    paymentInt = null,
    setStripeOptions = null,
    stripeOptions
) => {
    const url =
        process.env.REACT_APP_API_BASE_URL + "/payments/payment-intent/";

    if (!paymentInt || paymentInt.amount / 100 !== cart.grand_total) {
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                payment_int: paymentInt,
                cart: cart
            })
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error("Couldn't connect to payment this time");
                }
                return response.json();
            })
            .then((result) => {
                if (setStripeOptions !== null && stripeOptions == null) {
                    setStripeOptions({
                        clientSecret: result.payment_int.client_secret
                    });
                }
                setPaymentIntend(result, dispatch);
            })
            .catch((error) => {
                toast.error(error);
            });
    }
};
