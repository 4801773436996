import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { cleanCart } from "../../../helper/CartHelper";
import { setPaymentIntend } from "../../../helper/PaymentHelper";

const Successful = () => {
	const dispatch = useDispatch();
	
	useEffect(() => {
		setPaymentIntend(null,dispatch);
		cleanCart(dispatch);
	}, [])

	return (
		<>Successful</>
	)
}

export default Successful;