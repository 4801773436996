import ContainerWithGap from "../components/container/ContainerWithGap";
import RecipeReview from "../components/RecipeReview";
import { useState, useEffect } from "react";
import { get_optimized_image_url } from "../helper/Helper";
import Loading from "../components/Loading";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { set_userImages } from "../features/userImagesSlice";
import { get_date_format } from "../helper/Helper";

const RecipePage = (props) => {
    const [item, setItem] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [files, setFiles] = useState([]);

    const slug = useParams().slug;
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const images = useSelector((state) => state.userImages);
    const formData = new FormData();

    useEffect(() => {
        let recipe_url = process.env.REACT_APP_API_BASE_URL + "/recipe/" + slug;
        fetch(recipe_url)
            .then((response) => response.json())
            .then((result) => {
                setItem(result);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
            });
    }, [slug]);

    const fetchImage = () => {
        let userImage_url =
            process.env.REACT_APP_API_BASE_URL +
            `/user-images/?user__email=${user.user.email}&ordering=-id&page_size=3`;
        fetch(userImage_url)
            .then((response) => response.json())
            .then((result) => {
                dispatch(set_userImages(result.results));
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
            });
    };
    useEffect(() => {
        if (user.user) {
            fetchImage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    useEffect(() => {
        if (item && files.length > 0) {
            formData.append("picture", files[0]);
            formData.append("user", user.user.id);
            formData.append("of_recipe", item.id);
            const options = {
                method: "POST",
                body: formData
            };
            fetch(
                `${process.env.REACT_APP_API_BASE_URL}/user-images/`,
                options
            )
                .then((response) => setFiles([]))
                .catch(error => console.log("error", error));
        }
    }, [files]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/jpeg, image/png, image/jpg",
        maxFiles: 1,
        multiple: false,
        maxSize: 2 * 1000000, //2MB
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
            );
        }
    });
    const deleteImage = (id) => {
        const options = {
            method: "DELETE"
        };
        fetch(
            `${process.env.REACT_APP_API_BASE_URL}/user-images/${id}`,
            options
        ).then((res) => fetchImage());
    };

    const thumbs = files.map((file) => (
        <div key={file.name}>
            <div>
                {/* <img
			  src={file.preview}
			  alt="Enterprise Logo"
			/> */}
                {file.name}
            </div>
        </div>
    ));

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach((file) => URL.revokeObjectURL(file.preview));
        // formik.setFieldValue('enterprise_logo', files[0]);
    }, [files]);

    const getTitle = (image) => {
        var arr = image.split("/");
        var count = arr.length;
        return arr[count - 1];
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            {item && (
                <>
                    <div className="container-fluid recipe-page-wrapper">
                        <div className="row">
                            {
                                <div className="col-md-12 g-mb-15 g-pa-0">
                                    <div className="h-100">
                                        <img
                                            className="w-100 h-100 u-block-hover__main--zoom-v1 g-mb-minus-8"
                                            src={get_optimized_image_url(
                                                item.photo
                                            )}
                                            alt="Image Description"
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    <ContainerWithGap>
                        <div className="row">
                            <div className="col-md-4 g-pr-25">
                                <h1 className="g-font-weight-600 g-brd-bottom g-brd-gray-light-v3 pb-2">
                                    {item.title}
                                </h1>
                                <span className="text-uppercase g-font-weight-600 g-color-primary">
                                    {item.recipe_by !== null
                                        ? `Recipe by: ${item.recipe_by}`
                                        : `Recipe inspired by: ${item.recipe_inspired_by}`}
                                </span>
                                <div className="ingredients-process g-mt-30">
                                    <h5 className="mb-2 g-font-weight-600 text-uppercase">
                                        ingredients
                                    </h5>
                                    <div className="recipe-list-view">
                                        {ReactHtmlParser(item.ingredients)}
                                    </div>
                                </div>
                                <div className="substitution-option g-mt-30">
                                    <h5 className="mb-2 g-font-weight-600 text-uppercase">
                                        substitution option
                                    </h5>
                                    <div className="recipe-list-view">
                                        {ReactHtmlParser(
                                            item.substitution_option
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8 g-pl-25">
                                <p className="g-mb-40">
                                    {ReactHtmlParser(item.description)}
                                </p>
                                <div className="g-brd-y g-brd-gray-light-v3 py-3 d-flex">
                                    <span className="text-uppercase g-mr-15 g-font-weight-500">
                                        prep time: {item.preparation_time}
                                    </span>
                                    <span className="text-uppercase g-mr-15 g-font-weight-500">
                                        difficulty: {item.difficulty}
                                    </span>
                                    <span className="text-uppercase g-mr-15 g-font-weight-500">
                                        make it a meal: {item.make_it_a_meal}
                                    </span>
                                </div>
                                <div className="g-mt-40 preparation-list">
                                    <h3 className="mb-2 g-font-weight-600">
                                        Preparation
                                    </h3>
                                    <div className="">
                                        {ReactHtmlParser(item.preparation)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ContainerWithGap>
                </>
            )}
            <ContainerWithGap>
                <RecipeReview item={item}></RecipeReview>
            </ContainerWithGap>
            <ContainerWithGap>
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <h2 className="h3 mb-4">Upload your photo</h2>
                        <p>
                            Let us know how you like the food! <br />
                            Submit a photo here and we will add it to the cover
                            page
                        </p>

                        <div className="row">
                            <div className="col-lg-6">
                                {user.user !== null ? (
                                    <div
                                        {...getRootProps({
                                            className: "dropzone"
                                        })}
                                    >
                                        <input {...getInputProps()} />
                                        {
                                            <div className="g-py-100 recipe-image-upload">
                                                <div class="justify-content-center d-flex">
                                                    <img
                                                        class="img-fluid"
                                                        src="/assets/images/upload.png"
                                                        alt="description"
                                                    />
                                                </div>
                                                <div className="upload-text g-mt-30 text-center">
                                                    <h5 className="">
                                                        Drag and Drop files here
                                                    </h5>
                                                    <span>
                                                        Files supported: JPG,
                                                        JPEG, PNG
                                                    </span>
                                                    <p>or</p>
                                                    <button
                                                        className="btn u-btn-outline-primary g-font-size-12 text-uppercase g-py-15 g-px-25"
                                                        type="submit"
                                                    >
                                                        Browse files
                                                    </button>
                                                    <p className="g-font-size-14 mt-3">
                                                        Maximum size: 2Mb
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                ) : (
                                    <div>Please login to upload image</div>
                                )}
                            </div>
                            {user.user && (
                                <div className="col-lg-6 files-uploaded-section">
                                    <h3 className="h4 g-mb-20">Files</h3>
                                    {images.userImages.map((item) => (
                                        <div className="d-flex align-items-center justify-content-between g-brd-bottom g-brd-gray-light-v1 g-pb-15 g-mb-20">
                                            <div className="uploaded-files d-flex align-items-center">
                                                <img
                                                    class="img-fluid"
                                                    src={item.picture}
                                                    alt="description"
                                                />
                                                <div className="uploaded-image-detail g-ml-10 text-break">
                                                    <h5 style={{ wordBreak: 'break-word' }}>
                                                        {getTitle(item.picture)}
                                                    </h5>
                                                    <span>
                                                        {get_date_format(
                                                            item?.created_at
                                                        )}{" "}
                                                        |{" "}
                                                        {item?.file_size.toFixed(
                                                            2
                                                        ) + "MB"}
                                                    </span>
                                                </div>
                                            </div>
                                            <button
                                                className="g-bg-transparent border-0"
                                                style={{ minWidth: '50px' }}
                                                onClick={() =>
                                                    deleteImage(item.id)
                                                }
                                            >
                                                <img
                                                    class="img-fluid"
                                                    src="/assets/images/delete.png"
                                                    alt="Description"
                                                />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </ContainerWithGap>
        </>
    );
};

export default RecipePage;
