import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { get_optimized_image_url } from "../../helper/Helper";
const ProfilePage = ({ data }) => {
    return (
        <div className="row">
            <div className="col-4 col-sm-3 g-mb-30">
                <Link to="">
                    <LazyLoadImage
                        className="img-fluid"
                        alt="Image Description"
                        src={get_optimized_image_url(data.product.main_image)} // use normal <img> attributes as props
                    />
                </Link>
            </div>

            <div className="col-8 col-sm-9 g-mb-30">
                <h4 className="h6 g-font-weight-400">
                    <Link to="#">
                        {data.product_variant
                            ? data.product_variant.name
                            : data.name}
                    </Link>
                </h4>
                {/* <span className="d-block g-color-gray-dark-v4 g-font-size-13 mb-2">
                    Sold by: Unify.com LLC
                </span> */}
                <span className="d-block g-color-lightred mb-2">
                    $
                    {data.product_variant
                        ? data.product_variant.our_price
                        : data.price}
                </span>
                <Link
                    className="btn g-brd-around g-brd-gray-light-v3 g-color-gray-dark-v3 g-bg-gray-light-v5 g-bg-gray-light-v4--hover g-font-size-13 rounded g-px-18 g-py-7"
                    to="#"
                >
                    Buy it Again
                </Link>
            </div>
        </div>
    );
};
export default ProfilePage;
