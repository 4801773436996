import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { format_price } from "../helper/Helper";
import { PURGE } from "redux-persist";

const initialState = {
    cart_items: [],
    isLoading: false,
    grand_total: 0,
    total_amt: 0,
    tax_amt: 0,
    shipping_charge: 0,
    use_discount_code: false,
    discount_code: null,
    use_subscribe_discount: false,
    subscription: null,
    donate: false,
    donate_amt: 0,
    custom_donate: false,
    is_gift: false,
    gift_message: null,
    post_card_price: null,
    post_card: null,
    shipping_address: null,
    shipping_address_verified: false,
    billing_address: null,
    billing_address_verified: false,
    is_billing_shipping_add_same: true,
    payment_method: null,
    shipping_type: null,
    save_for_later_items: []
};

// calculates total cart amount
const calculate = (state) => {
    let total_amt = 0;
    if (state.cart_items.length > 0) {
        state.cart_items.map((item) => {
            total_amt += parseFloat(item.price) * parseFloat(item.qty);
        });
    }

    // preserving cart total for discounts calculation
    let cart_total = total_amt;

    //add gift
    if (state.post_card_price) {
        total_amt += state.post_card_price;
    }
    let discountable_total = total_amt;

    //discount code/gift card
    if (state.discount_code) {
        let discount_amount = state.discount_code.value;
        if (state.discount_code.type === "PERCENT") {
            discount_amount =
                (discountable_total * state.discount_code.value) / 100;
        }
        total_amt -= discount_amount;
    }

    //subscribe discount
    if (state.subscription !== null) {
        let sub_discount_amount =
            (discountable_total * state.subscription.amt) / 100;
        total_amt -= sub_discount_amount;
    }

    //add tax
    if (state.tax_amt > 0) {
        total_amt += state.tax_amt;
    }

    //add shipping
    if (state.shipping_charge > 0) {
        total_amt += state.shipping_charge;
    }

    //donate amount
    if (state.donate_amt > 0) {
        total_amt += parseFloat(state.donate_amt);
    }

    state.total_amt = format_price(cart_total);
    state.grand_total = format_price(total_amt);
};

const processShippingAddressThunk = createAsyncThunk(
    "shipping",
    async (data, thunkAPI) => {
        const fetchOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                cart: data.state,
                shipping_address: data.state.shipping_address
            })
        };
        const response = await fetch(thunkAPI, fetchOptions);
        if (!response.ok) {
            throw Error(response.statusText);
        }
        const result = await response.json();

        return result;
    }
);

export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        add_to_cart: (state, action) => {
            let added_to_cart = false;
            if (state.cart_items.length > 0) {
                state.cart_items.map((item) => {
                    if (item.product_id == action.payload.product_id) {
                        item.qty += action.payload.qty;
                        added_to_cart = true;
                    }
                });
            }

            if (!added_to_cart) {
                state.cart_items = [...state.cart_items, action.payload];
            }

            if (state.save_for_later_items.length > 0) {
                state.save_for_later_items.map((item, index) => {
                    if (item.product_id == action.payload.product_id) {
                        state.save_for_later_items.splice(index, 1);
                    }
                });
            }

            calculate(state);
        },
        add_to_cart_api_data: (state, action) => {
            state.cart_items = action.payload;
            calculate(state);
        },
        remove_from_cart: (state, action) => {
            state.cart_items.map((item, index) => {
                if (item.product_id == action.payload.product_id) {
                    state.cart_items.splice(index, 1);
                }
            });
            calculate(state);
            // cart_items : [...state.cart_items, action.payload]
        },
        reduce_from_cart: (state, action) => {
            // reducing number of item from cart
            state.cart_items.map((item, index) => {
                if (item.product_id == action.payload.product_id) {
                    if (item.qty > 1) {
                        item.qty -= 1;
                    }
                }
            });
            calculate(state);
        },
        clean_cart: (state, action) => {
            return { ...initialState };
        },
        use_discount_code: (state, action) => {
            state.use_discount_code = action.payload;
            calculate(state);
        },
        apply_discount_code: (state, action) => {
            state.discount_code = action.payload;
            calculate(state);
        },
        remove_discount_code: (state, action) => {
            state.discount_code = null;
            calculate(state);
        },
        use_subscribe_discount: (state, action) => {
            state.use_subscribe_discount = action.payload;
            calculate(state);
        },
        remove_subscribe_discount: (state, action) => {
            state.subscription = null;
            calculate(state);
        },
        apply_subscribe_discount: (state, action) => {
            state.subscription = action.payload;
            calculate(state);
        },
        set_donate: (state, action) => {
            state.donate = action.payload.donate;
            state.custom_donate = action.payload.custom_donate;
            if (action.payload.donate == true) {
                state.donate_amt =
                    parseFloat(action.payload.donate_amt) > 0
                        ? parseFloat(action.payload.donate_amt)
                        : null;
            } else {
                state.donate_amt = 0;
            }
            calculate(state);
        },
        set_is_gift: (state, action) => {
            state.is_gift = action.payload;
            if (action.payload === false) {
                state.post_card = null;
                state.post_card_price = null;
            }
            calculate(state);
        },
        set_gift_msg: (state, action) => {
            state.gift_message = action.payload;
        },
        set_post_card: (state, action) => {
            state.post_card = action.payload.id;
            state.post_card_price = action.payload.price;
            calculate(state);
        },
        set_shipping_address: (state, action) => {
            state.shipping_address = action.payload;
            // processShippingAddress(action.payload, state);
            calculate(state);
        },
        set_tax: (state, action) => {
            state.tax_amt = action.payload.tax;
            state.shipping_address_verified =
                action.payload.shipping_address_verified; //After tax is calculated shipping address is considered verified
            calculate(state);
        },
        set_is_billing_shipping_add_same: (state, action) => {
            state.is_billing_shipping_add_same = action.payload;

            if (action.payload) {
                if (state.billing_address != null) {
                    state.shipping_address = state.billing_address;
                } else {
                    state.billing_address = state.shipping_address;
                }
            } else {
                state.shipping_address = null;
            }
        },
        set_billing_address: (state, action) => {
            state.billing_address = action.payload;

            if (state.is_billing_shipping_add_same) {
                state.shipping_address = action.payload;
                // processShippingAddress(action.payload, state);
            }

            for (const field in action.payload) {
                if (!action.payload[field]) {
                    state.billing_address_verified = false;
                    break;
                } else {
                    state.billing_address_verified = true;
                }
            }
        },
        set_delivery_detail: (state, action) => {
            state.shipping_charge = action.payload.shipping_charge;
            state.shipping_type = action.payload.shipping_type;
            calculate(state);
        },
        save_later_items: (state, action) => {
            let added_to_save_later = false;
            state.cart_items.map((item, index) => {
                if (item.product_id == action.payload.product_id) {
                    state.cart_items.splice(index, 1);
                }
            });
            if (state.save_for_later_items.length > 0) {
                state.save_for_later_items.map((item) => {
                    if (item.product_id == action.payload.product_id) {
                        item.qty += action.payload.qty;
                        added_to_save_later = true;
                    }
                });
            }
            if (!added_to_save_later) {
                state.save_for_later_items = [
                    ...state.save_for_later_items,
                    action.payload
                ];
            }
            calculate(state);
        },
        save_later_api_data: (state, action) => {
            state.save_for_later_items = action.payload;
            calculate(state);
        },
        remove_later_items: (state, action) => {
            state.save_for_later_items.map((item, index) => {
                if (item.product_id == action.payload.product_id) {
                    state.save_for_later_items.splice(index, 1);
                }
            });
        }
    },
    extraReducers: (builder) => {
        builder.addCase(
            processShippingAddressThunk.fulfilled,
            (state, action) => {
                state.tax_amt = action.tax_dict.amount_to_collect;
                state.shipping_address_verified = true;
            }
        );
        builder.addCase(PURGE, (state) => {
            state.cart_items = [];
            state.grand_total = 0;
            state.total_amt = 0;
            state.save_for_later_items = [];
        });
    }
});

export const {
    add_to_cart,
    add_to_cart_api_data,
    remove_from_cart,
    clean_cart,
    reduce_from_cart,
    use_discount_code,
    apply_discount_code,
    use_subscribe_discount,
    apply_subscribe_discount,
    remove_subscribe_discount,
    set_donate,
    set_is_gift,
    set_post_card,
    set_gift_msg,
    remove_discount_code,
    set_shipping_address,
    set_billing_address,
    set_is_billing_shipping_add_same,
    set_delivery_detail,
    set_tax,
    save_later_api_data,
    remove_later_items,
    save_later_items
} = cartSlice.actions;

export const cartItems = (state) => state.cart_items;

export default cartSlice.reducer;
