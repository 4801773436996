import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
const baseURL = `${process.env.REACT_APP_API_SERVER_URL}`;

const initialState = {
    loading: false,
    data: []
};

export const getInitialSaveLaterData = createAsyncThunk(
    "cartData/getInitialSaveLaterData",
    async (thunkAPI) => {
        const res = fetch(
            `${process.env.REACT_APP_API_BASE_URL}/save-later/?expand=products,product_variants&is_waitlist=false`,
            {
                metgod: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("access_token")
                }
            }
        ).then((response) => response.json());

        return res;
    }
);

export const saveLaterData = createSlice({
    name: "saveLaterData",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getInitialSaveLaterData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getInitialSaveLaterData.fulfilled, (state, action) => {
            state.loading = false;
            state.data = [
                ...action.payload?.results[0]?.products,
                ...action.payload?.results[0]?.product_variants
            ];
        });
        builder.addCase(getInitialSaveLaterData.rejected, (state) => {
            state.loading = false;
        });
    }
});

export const saveLaterDataSelector = (state) => state.saveLaterData;

export default saveLaterData.reducer;
