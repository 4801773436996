import { useEffect, useState } from "react";
import HtmlParser from "react-html-parser";
import { useParams } from "react-router-dom";
import BlogBlock from "../components/BlogBlock";
import ContainerWithGap from "../components/container/ContainerWithGap";
import Loading from "../components/Loading";
import HeadingBlog from "../components/page_headings/HeadingBlog";
import SectionHeaderCenter from "../components/section_header/SectionHeaderCenter";
import { Link } from "react-router-dom";

const BlogDetailPage = (props) => {
    const [blog, setBlog] = useState();
    const [loading, setLoading] = useState(false);
    const [newBlogs, setNewBlogs] = useState();
    const slug = useParams().slug;

    useEffect(() => {
        let blog_url = process.env.REACT_APP_API_BASE_URL + "/blog/" + slug;
        setLoading(true);
        fetch(blog_url)
            .then((response) => response.json())
            .then((result) => {
                setBlog(result);
                setLoading(false);
            })
            .catch((error) => {});
    }, [slug]);
    
    useEffect(() => {
        if (blog && blog.new_blogs.length > 2) {
            var temp = blog.new_blogs.slice(0,2);
            setNewBlogs(temp);
        }
    },[blog]);

    if (loading) {
        return <Loading />;
    }
   
    return (
        <>
            {/* <HeadingBlog /> */}

            <ContainerWithGap>
                {blog && blog.id ? (
                    <div className="row">
                        <div className="col-lg-3">
                            <h1 className="h3 g-font-weight-600 pb-1">
                                {blog.title}
                            </h1>
                            <p>{blog.published_on}</p>
                            <span className="text-uppercase g-font-weight-600 g-color-primary">
                                By: {blog.author}
                            </span>

                            <div className="g-mt-100">
                                <h1 className="h3 g-font-weight-600 pb-1">
                                    New Blogs
                                </h1>
                                {newBlogs?.map((item) => (
                                    <div className="new-blog-section">
                                        <Link to={`/blogs/${item.slug}`}>
                                            <img
                                                class="img-fluid"
                                                src={item.image}
                                                alt={item.title}
                                            />
                                            <h1 className="h6 mt-3">
                                                {item.title}
                                            </h1>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-9">
                            {HtmlParser(blog.textbody)}
                        </div>
                    </div>
                ) : (
                    <p>No Blogs Available</p>
                )}
            </ContainerWithGap>
        </>
    );
};

export default BlogDetailPage;
