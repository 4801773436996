import { useSelector } from "react-redux";
import CheckoutSidebar from "../../../components/cart_components/CheckoutSidebar";
import GiftOption from "../../../components/cart_components/GiftOption";
import Container from "../../../components/container/Container";
import CartItem from "../../../components/cart_components/CartItem";

const Cart = (props) => {
    const cartItems = useSelector((state) => state.cart.cart_items);

    return (
        <Container>
            <div className="row">
                <div className="col-lg-8 g-mb-30">
                    {/* <!-- Products Block --> */}
                    <div className="g-overflow-x-scroll g-overflow-x-visible--lg cart-wrapper">
                        <table className="text-center w-100">
                            <thead className="h6 g-color-black text-uppercase cart-table-header">
                                <tr>
                                    <th className="g-font-weight-400 text-left g-pb-20">
                                        Product
                                    </th>
                                    <th className="g-font-weight-400 g-width-130 g-pb-20">
                                        Price
                                    </th>
                                    <th className="g-font-weight-400 g-width-50 g-pb-20">
                                        Qty
                                    </th>
                                    <th className="g-font-weight-400 g-width-130 g-pb-20">
                                        Total
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {cartItems.map((item) => (
                                    <CartItem item={item} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <!-- End Products Block --> */}

                    {/* Gift Option  */}
                    <GiftOption />
                </div>

                <CheckoutSidebar {...props} />
            </div>
        </Container>
    );
};

export default Cart;
