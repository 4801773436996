import React, { useEffect, useState } from "react";
import ContainerWithGap from "../components/container/ContainerWithGap";
import SliderData from "../components/intresting_repo/Slider";
import SectionHeaderCenter from "../components/section_header/SectionHeaderCenter";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";

const IntrestingRepoPage = (props) => {
    const [recipesCategory, setRecipesCategory] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [remedies, setRemedies] = useState(null);
    const [spiceTalk, setSpiceTalk] = useState(null);
    useEffect(() => {
        // this url with change based on pagination and sorting option
        // setLoading(true);
        let url = process.env.REACT_APP_API_BASE_URL + `/recipe-category/`;

        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        };
        fetch(url, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                const globalFeatured = result.results.find(
                    (i) => i.is_featured_globally === true
                );
                setRecipesCategory(result.results);

                //    setPageSize(result.total_pages);
                //    dispatch(set_orders(result.results));
                //    setLoading(false);
            })
            .catch((error) => {
                //handle error here
                setIsLoading(false);

                //    setLoading(false);
            });
    }, []);
    useEffect(() => {
        // this url with change based on pagination and sorting option
        // setLoading(true);
        let url = process.env.REACT_APP_API_BASE_URL + `/blog-type/`;

        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        };
        fetch(url, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                const filteredremedies = result.results.find(
                    (t) => t.slug === "remedies"
                ).categories;
                const filteredSpiceTalk = result.results.find(
                    (t) => t.slug === "spice-talk"
                ).categories;
                setRemedies(filteredremedies);
                setSpiceTalk(filteredSpiceTalk);

                //    setPageSize(result.total_pages);
                //    dispatch(set_orders(result.results));
                //    setLoading(false);
            })
            .catch((error) => {
                //handle error here
                setIsLoading(false);

                //    setLoading(false);
            });
    }, []);
    useEffect(() => {
        if (
            isLoading &&
            recipesCategory &&
            remedies &&
            spiceTalk &&
            recipesCategory.length &&
            remedies.length &&
            spiceTalk.length
        ) {
            setIsLoading(false);
        }
    }, [recipesCategory, remedies, spiceTalk]);

    return (
        <>
            <ContainerWithGap>
                {/* <div className="breadcrumbs">
                    <section className="g-brd-top g-py-50">
                        <div className=" g-bg-cover__inner">
                            <ul className="u-list-inline">
                                <li className="list-inline-item g-mr-7">
                                    Learn
                                    <i className="g-color-gray-light-v2 g-ml-5">
                                        /
                                    </i>
                                </li>
                                <li className="list-inline-item g-color-primary">
                                    <Link
                                        className="u-link-v5 g-color-main"
                                        to="/interesting-stuff"
                                    >
                                    <span>Interesting Stuff</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </section>
                </div> */}
                {/* recipe slider start */}
                <SectionHeaderCenter
                    header_title="Recipes"
                    header_description="Unveil the inner chef inside you with our spices."
                />
                {isLoading ? (
                    <Loading />
                ) : (
                    <SliderData
                        data={recipesCategory}
                        categoryBaseLink="recipe-category"
                    />
                )}

                {/* recipe slider end */}

                {/* remedies slider start */}
                <SectionHeaderCenter
                    header_title="Remedies"
                    header_description="Trove of household remedies for healthy lifestyle."
                />
                {isLoading ? (
                    <Loading />
                ) : (
                    <SliderData
                        data={remedies}
                        categoryBaseLink="blog-category"
                    />
                )}

                {/* remedies slider end */}

                {/* spice talk slider start */}
                <SectionHeaderCenter
                    header_title="Spice Talk"
                    header_description="Cradle to grave spice talk, learn everything about us."
                />

                {isLoading ? (
                    <Loading />
                ) : (
                    <SliderData
                        data={spiceTalk}
                        categoryBaseLink="blog-category"
                    />
                )}
                {/* spice talk slider end */}

                {/* blog listing */}
                {/* <section className="recipe_listing">
                    <SectionHeaderCenter header_title="More Recipes"></SectionHeaderCenter>
                    <ProductRecipe />
                    <ProductRecipe />
                    <ProductRecipe />
                    <ProductRecipe />
                </section> */}
            </ContainerWithGap>
        </>
    );
};

export default IntrestingRepoPage;
