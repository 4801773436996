import { useState, useEffect, useRef } from "react";
import HtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { get_optimized_image_url } from "../helper/Helper";
import Animate from "./Animate";
import Loading from "./Loading";

function CustomNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={"custom_arrow next"}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <i className="fa fa-arrow-down" />
        </div>
    );
}

function CustomPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={"custom_arrow prev"}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <i className="fa fa-arrow-up" />
        </div>
    );
}

const ProductCategorySlider = () => {
    const [items, setItems] = useState();
    const [loading, setLoading] = useState(true);

    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    let sliderVertical = useRef(null);
    let sliderHorizontal = useRef(null);

    const url = process.env.REACT_APP_API_BASE_URL + "/product-category";

    const settingsMobile = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1
    };

    const settingsVertical = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />
    };

    const settingsHorizontal = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };

    useEffect(() => {
        fetch(url)
            .then((response) => response.json())
            .then((result) => {
                setItems(result.results);
                setLoading(false);
            })
            .catch((error) => {
                // handle error here
            });
    }, []);

    useEffect(() => {
        setNav1(sliderVertical.current);
        setNav2(sliderHorizontal.current);
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <Animate>
            <section className="hl-bg-light">
                <div className="homni-container">
                    {/* Category Slider for desktop view  */}
                    <div className="row pro-cat-slider-wrapper g-py-100 d-none d-lg-flex">
                        <div className="col-3 col-lg-2">
                            {/* Product Category Slider */}
                            <Slider
                                {...settingsVertical}
                                asNavFor={nav1}
                                ref={(sliderHorizontal) =>
                                    setNav2(sliderHorizontal)
                                }
                            >
                                {items.length > 0 &&
                                    items.map((item) => (
                                        <div
                                            className="cat_vertical_slider"
                                            style={{ height: "300px" }}
                                        >
                                            <Link
                                                to={
                                                    "/product-categories/" +
                                                    item.slug
                                                }
                                            >
                                                <div
                                                    className="sld_image"
                                                    style={{
                                                        background: `url(${get_optimized_image_url(
                                                            item.image,
                                                            "square"
                                                        )})`,
                                                        "background-size":
                                                            "cover",
                                                        "background-position":
                                                            "50% 50%"
                                                    }}
                                                ></div>
                                            </Link>
                                            <p className="text-center cat_heading m-0">
                                                <Link
                                                    className="cat_heading"
                                                    to={
                                                        "/product-categories/" +
                                                        item.slug
                                                    }
                                                >
                                                    {item.name}
                                                </Link>
                                            </p>
                                        </div>
                                    ))}
                            </Slider>
                        </div>

                        <div className="col-9 col-lg-10">
                            <div className="cat_horizontal_slider_wrap h-100">
                                {/* Product Category Slider */}
                                <Slider
                                    {...settingsHorizontal}
                                    asNavFor={nav2}
                                    ref={(sliderVertical) =>
                                        setNav1(sliderVertical)
                                    }
                                >
                                    {items.length > 0 &&
                                        items.map((item) => (
                                            <div className="row cat_horizontal_slider h-100">
                                                <div className="col-md-7">
                                                    <div
                                                        className="wrapper cat_image h-100"
                                                        style={{
                                                            background: `url(${get_optimized_image_url(
                                                                item.image,
                                                                "square"
                                                            )})`,
                                                            "background-size":
                                                                "cover",
                                                            "background-position":
                                                                "50% 50%"
                                                        }}
                                                    ></div>
                                                </div>
                                                <div className="col-md-5 cat_desc">
                                                    <div className="cat_desc_inner_wrap">
                                                        <p className="h3">
                                                            {item.name}
                                                        </p>
                                                        <p>
                                                            {HtmlParser(
                                                                item.description
                                                            )}
                                                        </p>
                                                        <Link
                                                            to={
                                                                "/product-categories/" +
                                                                item.slug
                                                            }
                                                            className="btn u-btn-primary mt-3"
                                                        >
                                                            SHOP NOW
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                    {/* End : Slider for desktop view */}

                    {/* Category Slider for Mobile view  */}
                    <div className="row d-flex d-lg-none py-5">
                        {items.length > 0 &&
                            items.map((item) => (
                                <div
                                    className="cat_vertical_slider col-6 col-sm-4 h-auto mb-5"
                                    style={{ height: "300px" }}
                                >
                                    <Link
                                        to={"/product-categories/" + item.slug}
                                    >
                                        <div
                                            className="sld_image"
                                            style={{
                                                background: `url(${get_optimized_image_url(
                                                    item.image,
                                                    "square"
                                                )})`,
                                                "background-size": "cover",
                                                "background-position": "50% 50%"
                                            }}
                                        ></div>
                                    </Link>
                                    <p className="text-center cat_heading m-0">
                                        <Link
                                            className="cat_heading"
                                            to={
                                                "/product-categories/" +
                                                item.slug
                                            }
                                        >
                                            {item.name}
                                        </Link>
                                    </p>
                                </div>
                            ))}
                    </div>
                    {/* End : Category slider for Mobile view */}
                </div>
            </section>
        </Animate>
    );
};

export default ProductCategorySlider;
