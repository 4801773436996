import ContainerWithGap from "../../components/container/ContainerWithGap";
import ProfileNavSidebar from "../../components/profile_page/ProfileNavSidebar";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { authUser } from "../../features/authentication/userSlice";
import { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import Modals from "../../components/modal/modal";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { login } from "../../features/authentication/userSlice";
const ProfileSecurity = (props) => {
    const dispatch = useDispatch();
    const [userInfo, setUserInfo] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        photo: ""
    });
    const [isLoading, setIsLoading] = useState(true);
    const { user } = useSelector(authUser);
    const [changePassword, setChangePassword] = useState({
        old_password: "",
        password: "",
        confirm_password: ""
    });
    const [errorMessage, setErrorMessage] = useState("");
    const [submittingData, setSubmittingData] = useState(false);
    const [successFulMessage, setSuccessfulMessage] = useState("");
    const [profileErrors, setProfileErrors] = useState({
        first_name_error: "",
        last_name_error: "",
        email_error: "",
        phone_number_error: "",
        generalError: ""
    });
    useEffect(() => {
        setUserInfo({
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            phone_number: user.phone_number ? user.phone_number : "-",
            photo: user.photo
        });
        setIsLoading(false);
    }, [user]);

    const handleChange = (e) => {
        setProfileErrors({
            first_name_error: "",
            last_name_error: "",
            email_error: "",
            phone_number_error: "",
            generalError: ""
        });
        setUserInfo({
            ...userInfo,
            [e.target.name]: e.target.value
        });
    };
    const handlePasswordChange = (e) => {
        setErrorMessage("");
        setChangePassword({
            ...changePassword,
            [e.target.name]: e.target.value
        });
    };

    const handleUpdateProfile = (e) => {
        e.preventDefault();
        setSubmittingData(true);
        let url = process.env.REACT_APP_API_BASE_URL + "/accounts/details";
        let global_response = "";
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + Cookies.get("access_token")
            },
            body: JSON.stringify({
                first_name: userInfo.first_name,
                last_name: userInfo.last_name,
                email: userInfo.email,
                phone_number:
                    userInfo.phone_number !== "-" ? userInfo.phone_number : ""
            })
        };
        fetch(url, requestOptions)
            .then((response) => {
                global_response = response;
                return response.json();
            })
            .then((result) => {
                if (global_response.ok) {
                    setSubmittingData(false);
                    toast.success("Profile Update Successful!!");
                    let payload = {
                        user: result
                        // access_token: Cookies.get("access_token")
                    };
                    dispatch(login(payload));
                    // setUserInfo({})
                    // setChangePassword({
                    //     old_password: "",
                    //     password: "",
                    //     confirm_password: ""
                    // });
                    // setSubmittingData(false);
                    // const message = result.message;
                    // toast.success(message);

                    // setSuccessfulMessage(message);
                } else {
                    //if validation error

                    if (result.validation_errors) {
                        if (result.validation_errors.phone_number) {
                            setProfileErrors({
                                phone_number_error:
                                    result.validation_errors.phone_number[0]
                            });
                            setErrorMessage(
                                result.validation_errors.phone_number[0]
                            );
                            toast.error(
                                result.validation_errors.phone_number[0]
                            );
                        } else if (result.validation_errors.email) {
                            setProfileErrors({
                                email_error: result.validation_errors.email[0]
                            });
                            setErrorMessage(result.validation_errors.email[0]);
                            toast.error(result.validation_errors.email[0]);
                        } else if (result.validation_errors.first_name) {
                            setProfileErrors({
                                first_name_error:
                                    result.validation_errors.first_name[0]
                            });
                            setErrorMessage(
                                result.validation_errors.first_name[0]
                            );
                            toast.error(result.validation_errors.first_name[0]);
                        } else if (result.validation_errors.last_name) {
                            setProfileErrors({
                                last_name_error:
                                    result.validation_errors.last_name[0]
                            });
                            setErrorMessage(
                                result.validation_errors.last_name[0]
                            );
                            toast.error(result.validation_errors.last_name[0]);
                        } else {
                            setProfileErrors({
                                generalError:
                                    "Couldn't process request. Please try again"
                            });
                            setErrorMessage(
                                "Couldn't process request. Please try again"
                            );
                            toast.error(
                                "Couldn't process request. Please try again"
                            );
                        }

                        // return result.validation_errors;
                        // for (const r in result.validation_errors) {
                        //     setErrorMessage(r, result.validation_errors[r]);
                        // }
                    } else if (result.error) {
                        setProfileErrors({
                            generalError: result.error
                        });
                        setErrorMessage(result.error);
                        toast.error(result.error);
                    } else if (result.detail) {
                        setProfileErrors({
                            generalError: result.detail
                        });
                        setErrorMessage(result.detail);
                        toast.error(result.detail);
                    } else {
                        setProfileErrors({
                            generalError:
                                "Couldn't process request. Please try again"
                        });
                        setErrorMessage(
                            "Couldn't process request. Please try again"
                        );
                        toast.error(
                            "Couldn't process request. Please try again"
                        );
                    }
                    setSubmittingData(false);
                }
            })
            .catch((error) => {
                // setErrorMessage(
                //     "Some error occured at server side. Please try again later"
                // );
                setProfileErrors({
                    generalError: error
                });
                console.log("error", error);
                // setErrorMessage(error);
                setSubmittingData(false);
            });
    };

    const handleSavePassword = async (e) => {
        e.preventDefault();
        setSubmittingData(true);
        let url =
            process.env.REACT_APP_API_BASE_URL + "/accounts/change-password";
        let global_response = "";
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + Cookies.get("access_token")
            },
            body: JSON.stringify({
                password: changePassword.password,
                old_password: changePassword.old_password,
                confirm_password: changePassword.confirm_password
            })
        };
        fetch(url, requestOptions)
            .then((response) => {
                global_response = response;
                return response.json();
            })
            .then((result) => {
                if (global_response.ok) {
                    setChangePassword({
                        old_password: "",
                        password: "",
                        confirm_password: ""
                    });
                    setSubmittingData(false);
                    const message = result.message;
                    toast.success(message);

                    setSuccessfulMessage(message);
                } else {
                    //if validation error
                    if (result.validation_errors) {
                        if (result.validation_errors.confirm_password) {
                            setErrorMessage(
                                result.validation_errors.confirm_password[0]
                            );
                            toast.error(
                                result.validation_errors.confirm_password[0]
                            );
                        } else if (result.validation_errors.old_password) {
                            setErrorMessage(
                                result.validation_errors.old_password[0]
                            );
                            toast.error(
                                result.validation_errors.old_password[0]
                            );
                        } else if (result.validation_errors.password) {
                            setErrorMessage(
                                result.validation_errors.password[0]
                            );
                            toast.error(result.validation_errors.password[0]);
                        } else {
                            setErrorMessage(
                                "Couldn't process request. Please try again"
                            );
                            toast.error(
                                "Couldn't process request. Please try again"
                            );
                        }

                        // return result.validation_errors;
                        // for (const r in result.validation_errors) {
                        //     setErrorMessage(r, result.validation_errors[r]);
                        // }
                    } else if (result.error) {
                        setErrorMessage(result.error);
                        toast.error(result.error);
                    } else if (result.detail) {
                        setErrorMessage(result.detail);
                        toast.error(result.detail);
                    } else {
                        setErrorMessage(
                            "Couldn't process request. Please try again"
                        );
                        toast.error(
                            "Couldn't process request. Please try again"
                        );
                    }
                    setSubmittingData(false);
                }
            })
            .catch((error) => {
                // setErrorMessage(
                //     "Some error occured at server side. Please try again later"
                // );
                console.log("error", error);
                // setErrorMessage(error);
                setSubmittingData(false);
            });
    };
    const removeMessage = () => {
        setSuccessfulMessage("");
        setErrorMessage("");
        setChangePassword({
            old_password: "",
            password: "",
            confirm_password: ""
        });
    };

    const fullname = userInfo.first_name + " " + userInfo.last_name;
    return (
        <ContainerWithGap>
            <div className="row">
                {/* <!-- Profile Settings --> */}
                <ProfileNavSidebar active="profilelogin"></ProfileNavSidebar>
                {/* <!-- End Profile Settings --> */}
                {/* <!-- Login & Security --> */}

                <div className="col-lg-9 g-mb-50 g-mt-50">
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <div className="g-brd-around g-brd-gray-light-v4 rounded g-pa-30 g-mb-30">
                                <div className="row">
                                    <div className="col-8">
                                        <span className="d-block g-color-text g-font-size-13 mb-1">
                                            First Name:
                                        </span>
                                        <input
                                            type="text"
                                            value={userInfo.first_name}
                                            name="first_name"
                                            style={{ border: "none" }}
                                            onChange={handleChange}
                                        />

                                        {/* <span className="d-block">
                                            {userInfo.first_name}
                                        </span> */}
                                    </div>

                                    {/* <div className="col-4 text-right">
                                        <Link
                                            className="btn g-brd-around g-brd-gray-light-v3 g-color-gray-dark-v3 g-bg-gray-light-v5 g-bg-gray-light-v4--hover g-font-size-13 rounded g-px-18 g-py-7"
                                            to="#"
                                        >
                                            Edit
                                        </Link>
                                    </div> */}
                                </div>
                                {profileErrors.first_name_error ? (
                                    <span style={{ color: "red" }}>
                                        {profileErrors.first_name_error}
                                    </span>
                                ) : (
                                    ""
                                )}
                                <hr className="g-brd-gray-light-v4 g-my-20" />

                                <div className="row">
                                    <div className="col-8">
                                        <span className="d-block g-color-text g-font-size-13 mb-1">
                                            Last Name:
                                        </span>
                                        <input
                                            type="text"
                                            value={userInfo.last_name}
                                            name="last_name"
                                            style={{ border: "none" }}
                                            onChange={handleChange}
                                        />

                                        {/* <span className="d-block">
                                            {userInfo.last_name}
                                        </span> */}
                                    </div>

                                    {/* <div className="col-4 text-right">
                                        <Link
                                            className="btn g-brd-around g-brd-gray-light-v3 g-color-gray-dark-v3 g-bg-gray-light-v5 g-bg-gray-light-v4--hover g-font-size-13 rounded g-px-18 g-py-7"
                                            to="#"
                                        >
                                            Edit
                                        </Link>
                                    </div> */}
                                </div>
                                {profileErrors.last_name_error ? (
                                    <span style={{ color: "red" }}>
                                        {profileErrors.last_name_error}
                                    </span>
                                ) : (
                                    ""
                                )}
                                <hr className="g-brd-gray-light-v4 g-my-20" />

                                <div className="row">
                                    <div className="col-8">
                                        <span className="d-block g-color-text g-font-size-13 mb-1">
                                            Email:
                                        </span>
                                        <input
                                            type="text"
                                            value={userInfo.email}
                                            name="email"
                                            style={{ border: "none" }}
                                            onChange={handleChange}
                                        />

                                        {/* <span className="d-block">
                                            {userInfo.email}
                                        </span> */}
                                    </div>

                                    {/* <div className="col-4 text-right">
                                        <Link
                                            className="btn g-brd-around g-brd-gray-light-v3 g-color-gray-dark-v3 g-bg-gray-light-v5 g-bg-gray-light-v4--hover g-font-size-13 rounded g-px-18 g-py-7"
                                            to="#"
                                        >
                                            Edit
                                        </Link>
                                    </div> */}
                                </div>
                                {profileErrors.email_error ? (
                                    <span style={{ color: "red" }}>
                                        {profileErrors.email_error}
                                    </span>
                                ) : (
                                    ""
                                )}
                                <hr className="g-brd-gray-light-v4 g-my-20" />

                                <div className="row">
                                    <div className="col-8">
                                        <span className="d-block g-color-text g-font-size-13 mb-1">
                                            Mobile Phone Number:
                                        </span>
                                        <input
                                            type="text"
                                            value={userInfo.phone_number}
                                            name="phone_number"
                                            style={{ border: "none" }}
                                            onChange={handleChange}
                                        />

                                        {/* <span className="d-block">
                                            {userInfo.phone_number
                                                ? userInfo.phone_number
                                                : "-"}
                                        </span> */}
                                    </div>

                                    {/* <div className="col-4 text-right">
                                        <Link
                                            className="btn g-brd-around g-brd-gray-light-v3 g-color-gray-dark-v3 g-bg-gray-light-v5 g-bg-gray-light-v4--hover g-font-size-13 rounded g-px-18 g-py-7"
                                            to="#"
                                        >
                                            Edit
                                        </Link>
                                    </div> */}
                                </div>
                                {profileErrors.phone_number_error ? (
                                    <span style={{ color: "red" }}>
                                        {profileErrors.phone_number_error}
                                    </span>
                                ) : (
                                    ""
                                )}
                                {/* <hr className="g-brd-gray-light-v4 g-my-20" />

                                <div className="row">
                                    <div className="col-8">
                                        <span className="d-block g-color-text g-font-size-13 mb-1">
                                            Password:
                                        </span>
                                        <span className="d-block">*******</span>
                                    </div>

                                    <div className="col-4 text-right">
                                        <Link
                                            className="btn g-brd-around g-brd-gray-light-v3 g-color-gray-dark-v3 g-bg-gray-light-v5 g-bg-gray-light-v4--hover g-font-size-13 rounded g-px-18 g-py-7"
                                            to="#"
                                        >
                                            Edit
                                        </Link>
                                    </div>
                                </div> */}

                                {/* <hr className="g-brd-gray-light-v4 g-my-20" /> */}

                                {/* <div className="row">
                                    <div className="col-8">
                                        <span className="d-block g-color-text g-font-size-13 mb-1">
                                            Advanced Security Settings:
                                        </span>
                                        <span className="d-block">
                                            Manage how and when you receive
                                            security codes
                                        </span>
                                    </div>

                                    <div className="col-4 text-right">
                                        <Link
                                            className="btn g-brd-around g-brd-gray-light-v3 g-color-gray-dark-v3 g-bg-gray-light-v5 g-bg-gray-light-v4--hover g-font-size-13 rounded g-px-18 g-py-7"
                                            to="#"
                                        >
                                            Edit
                                        </Link>
                                    </div>
                                </div> */}
                            </div>

                            <button
                                className="btn u-btn-primary g-font-size-12 text-uppercase g-py-10 g-px-20"
                                type="submit"
                                style={{ marginRight: "10px" }}
                                onClick={handleUpdateProfile}
                                disabled={submittingData}
                            >
                                {submittingData ? "Updating..." : "Update"}
                            </button>
                            <Modals
                                buttonName="Update Password"
                                title="Update Password"
                                modalButtonClassName="btn u-btn-primary g-font-size-12 text-uppercase g-py-10 g-px-20"
                                onClick={removeMessage}
                            >
                                <div className="row px-2 py-2">
                                    <div className="col-5">
                                        <h3>
                                            <span className="d-block g-color-text g-font-size-13 mb-1">
                                                Old Password:
                                            </span>
                                        </h3>
                                    </div>
                                    <div className="col-7">
                                        <input
                                            type="password"
                                            value={changePassword.old_password}
                                            name="old_password"
                                            // style={{ border: "none" }}
                                            onChange={handlePasswordChange}
                                        />
                                    </div>
                                </div>
                                <div className="row px-2 py-2">
                                    <div className="col-5">
                                        <h3>
                                            <span className="d-block g-color-text g-font-size-13 mb-1 mr-5">
                                                New Password:
                                            </span>
                                        </h3>
                                    </div>
                                    <div className="col-7">
                                        <input
                                            type="password"
                                            value={changePassword.password}
                                            name="password"
                                            // style={{ border: "none" }}
                                            onChange={handlePasswordChange}
                                        />
                                    </div>
                                </div>
                                <div className="row px-2 py-2">
                                    <div className="col-5">
                                        <h3>
                                            <span className="d-block g-color-text g-font-size-13 mb-1 mr-5">
                                                Confirm Password:
                                            </span>
                                        </h3>
                                    </div>
                                    <div className="col-7">
                                        <input
                                            type="password"
                                            value={
                                                changePassword.confirm_password
                                            }
                                            name="confirm_password"
                                            // style={{ border: "none" }}
                                            onChange={handlePasswordChange}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <span
                                        style={{
                                            color: "red",
                                            textAlign: "center"
                                        }}
                                    >
                                        {errorMessage && errorMessage}
                                    </span>
                                    <span
                                        style={{
                                            color: "green",
                                            textAlign: "center"
                                        }}
                                    >
                                        {successFulMessage && successFulMessage}
                                    </span>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <button
                                            className="btn u-btn-primary g-font-size-12 text-uppercase g-py-6 g-px-15"
                                            type="button"
                                            onClick={handleSavePassword}
                                            disabled={
                                                changePassword.password !==
                                                changePassword.confirm_password ||
                                                !changePassword.password ||
                                                !changePassword.confirm_password
                                            }
                                        >
                                            {submittingData
                                                ? "SAVING..."
                                                : "SAVE"}
                                        </button>
                                    </div>
                                    <div className="col-8"></div>
                                </div>
                            </Modals>
                            {/* <button
                                className="btn u-btn-primary g-font-size-12 text-uppercase g-py-12 g-px-25"
                                style={{ marginLeft: "10px" }}
                                type="submit"
                            >
                                Update Password
                            </button> */}
                        </>
                    )}
                </div>
            </div>
        </ContainerWithGap>
    );
};
export default ProfileSecurity;
