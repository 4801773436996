import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getNameAcronym } from "../../helper/Helper";

const HeaderUser = (props) => {

	const user = useSelector(state => state.user);


	return (
		<>
			<div className="user-login g-ml-15">
				{
					user.is_logged_in ?
						<Link to="/account/login">
							<span className="login-user">
								{getNameAcronym(user.user.first_name, user.user.last_name)}
							</span>
						</Link>
						:
						<Link to="/account/login" className="g-font-size-18 g-color-white" >
							<img class="img-fluid" src="/assets/images/user.svg" alt="description" />
						</Link>
				}
			</div>


			{/* Mobile User */}
			{/* <div className="d-flex d-md-none">
				{
					user.is_logged_in ?
						<Link to="/account/login">
							<span className="login-user">
								{getNameAcronym(user.user.first_name, user.user.last_name)}
							</span>
						</Link>
						:
						<Link to="/account/login" className="g-font-size-18 g-color-white" >
							<img class="img-fluid" src="/assets/images/user.svg" alt="description" />
						</Link>
				}
			</div> */}
		</>
	);
}

export default HeaderUser;