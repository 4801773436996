import Container from "../../components/container/Container";

const StepProgress = (props) => {
	const current_step = props.step;
	const steps = [
		'SHOPPING CART',
		'SHIPPING & Billing',
		'PAYMENT & REVIEW'
	];
	// return <></>;
	return (
		<Container>
			<div className="g-mb-100 g-pt-100">
				{/* <!-- Step Titles --> */}
				<ul id="stepFormProgress" className="js-step-progress row justify-content-center list-inline text-center g-font-size-17 mb-0">

					{steps.map((step, index) => {
						let is_active = (current_step >= (index + 1));
						return (
							<li className="col-3 list-inline-item g-mb-20 g-mb-0--sm step-progress-wrapper" key={"checkout_steps_" + index}>
								<span className={"d-block u-icon-v2 u-icon-size--sm g-rounded-50x mx-auto mb-3" + (is_active ? ' g-brd-primary g-color-primary g-color-white g-bg-primary' : ' g-brd-gray-light-v2 g-color-gray-dark-v5 g-brd-primary')}>

									{is_active ?
										<i className="fa fa-check"></i>
										:
										<i className="g-font-style-normal g-font-weight-700">{index + 1}</i>
									}
								</span>
								<h4 className="g-font-size-16 text-uppercase mb-0">{step}</h4>
							</li>
						)
					})}
				</ul>
				{/* <!-- End Step Titles --> */}
			</div>
			{/* {
				(current_step > 1)?
					<button className="btn btn-secondary" onClick={props.prevStep}><i className="fa fa-chevron-left"></i> Back to Previous step</button>
				: ''
			} */}
		</Container>
	);

}

export default StepProgress;