import { useEffect, useState } from "react";
import BannerSliderRev from "../components/BannerSliderRev";
import ContainerWithGap from "../components/container/ContainerWithGap";
import ProductCollection from "../components/ProductCollection";
import SectionHeaderCenter from "../components/section_header/SectionHeaderCenter";
import HomePageSlider from "../components/HomePageSlider";
import {
    appendScripts,
    appendStyles,
    removeScripts,
    removeStyles
} from "../helper/Helper";
import ProductRecipe from "../components/product_detail/ProductRecipe";
import ProductCategorySlider from "../components/ProductCategorySlider";
import Map from "../components/map/Map";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import Animate from "../components/Animate";
/*
 * This is Landing Page of the website
 */
const HomePage = () => {
    const [recipes, setRecipes] = useState();
    const [category, setCategory] = useState();
    /**
     * external_styles and external_scripts contains array
     * Array of Path External Styles and Scripts required for this component
     * Paths should be relative from the public folder
     */
    const external_styles = [
        "/assets/vendor/dzsparallaxer/dzsparallaxer.css",
        "/assets/vendor/animate.css"
    ];
    const external_scripts = [
        "/assets/js/components/hs.tabs.js",
        "/assets/js/custom.js"
    ];
    const [heritageBlogs, setHeritageBlogs] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [mapData, setMapData] = useState([]);

    useEffect(() => {
        removeScripts(["./assets/js/custom.js"]);
        appendStyles(external_styles);
        appendScripts(external_scripts);

        return () => {
            removeStyles(external_styles);
            removeScripts(external_scripts);
        };
    }, []);
    useEffect(() => {
        fetch(
            `${process.env.REACT_APP_API_BASE_URL}/blog/?show_in_homepage=true`,
            {
                metgod: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => response.json())
            .then((data) => {
                setHeritageBlogs(data.results);
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        fetch(
            `${process.env.REACT_APP_API_BASE_URL}/product/?expand=farms&page_size=1000`,
            {
                metgod: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => response.json())
            .then((data) => setMapData(data.results));
    }, []);

    useEffect(() => {
        let url =
            process.env.REACT_APP_API_BASE_URL + `/recipe/?expand=recipe_type`;
        fetch(url)
            .then((response) => response.json())
            .then((result) => {
                setRecipes(result.results);
            })
            .catch((error) => {});
    }, []);

    useEffect(() => {
        let url = process.env.REACT_APP_API_BASE_URL + `/recipe-category/`;
        fetch(url)
            .then((response) => response.json())
            .then((result) => {
                setCategory(result.results);
            })
            .catch((error) => {});
    }, []);
    const geoJsonData = mapData &&
        mapData?.length > 0 && {
            type: "FeatureCollection",
            features: mapData.map((item) => ({
                type: "Feature",
                geometry: item.farms[0]?.point,
                properties: {
                    id: item.farms[0]?.id,
                    name: item.farms[0]?.name,
                    province: item.farms[0]?.province,
                    district: item.farms[0]?.district,
                    municipality: item.farms[0]?.municipality,
                    ward: item.farms[0]?.ward,
                    point_image: item.farms[0]?.point_indicator
                }
            }))
        };

    return (
        <>
            <BannerSliderRev />

            {/* Product Category Slider  */}
            <ProductCategorySlider />
            {/* Tabbed Product Collection Section  */}
            <ProductCollection />

            {/* Our Growers. Your Plate  */}
            <HomePageSlider category={category} gallery={true} />
            <section class="product_recipe_section">
                <SectionHeaderCenter header_title="Recipes" />
                <ProductRecipe />
            </section>
            {/* map section */}
            <Animate>
                <div className="container-fluid  g-mb-50">
                    <div className="row">
                        <div className="col-12 col-lg-6 g-px-0 heritage-map-image  d-flex align-item-center">
                            <div style={{ width: "100%" }}>
                                {geoJsonData &&
                                    geoJsonData.features &&
                                    geoJsonData.features.length > 0 && (
                                        <Map geoJsonData={geoJsonData} />
                                    )}
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 d-flex align-items-center heritage-wrapper">
                            {!isLoading ? (
                                <div class="shortcode-html g-px-60--md g-py-30">
                                    {/* <!-- Hero Info #01 --> */}
                                    <div class="g-mb-20">
                                        <h3 class="u-heading-v2__title text-uppercase g-font-weight-600 mb-0">
                                            A Heritage
                                        </h3>
                                    </div>
                                    <p class="lead  g-mb-40">
                                        Many of the products we carry have been
                                        used for generations by the folks that
                                        produce them. Most have a vibrant story
                                        surrounding their use in foods,
                                        medicines, and history. As part of our
                                        mission to preserve and increase global
                                        diversity, we like to share these unique
                                        cultural heritage stories.
                                    </p>
                                    <div className="row row-eq-height heritage-product">
                                        {heritageBlogs.length > 0 ? (
                                            heritageBlogs.map((item) => {
                                                return (
                                                    <div
                                                        className="col-md-4 g-mb-15 heritage-product-column"
                                                        key={item.id}
                                                    >
                                                        <div className="h-100">
                                                            <img
                                                                class="img-fluid"
                                                                src={item.image}
                                                                alt="description"
                                                            />
                                                            <p className="text-center g-pt-15">
                                                                {item.title}
                                                            </p>
                                                            <div className="d-flex justify-content-center">
                                                                <Link
                                                                    className="btn btn-md u-btn-outline-black text-center g-mr-10 g-mb-1 g-font-weight-600 g-font-size-12 text-uppercase g-px-20 g-py-10"
                                                                    to={`/blogs/${item.slug}`}
                                                                >
                                                                    Read More
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className="col-md-4 g-mb-15 heritage-product-column">
                                                <p>No Blogs Available</p>
                                            </div>
                                        )}
                                    </div>

                                    {/* <!-- End Hero Info #01 --> */}
                                </div>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    </div>
                </div>

                <ContainerWithGap>
                    <div className="row mission-wrapper g-mb-50">
                        <div className="col-12 col-lg-4 mission-first-para-column">
                            <div className="mission-first-para">
                                <div className="mb-4">
                                    <h3 className="mb-4">Our Mission</h3>
                                    <p className="g-font-size-16 g-line-height-2">
                                        Generate supply chain that will transfer
                                        agriculture into the leading industry
                                        for social, enviromental and economic
                                        sustainaibility
                                    </p>
                                </div>
                                <div className="mission-button">
                                    <Link
                                        className="btn btn-md u-btn-outline-black g-mr-10 g-mb-1 g-font-weight-600 g-font-size-12 text-uppercase g-px-20 g-py-10"
                                        to="/our-commitment"
                                    >
                                        Read More
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-8">
                            <div className="row">
                                <div className="col-md-6 d-md-flex mission-column">
                                    <div className="icon g-mr-25">
                                        <img
                                            class="img-fluid"
                                            src="/assets/images/regenerate.png"
                                            alt="description"
                                        />
                                    </div>
                                    <div className="mission-text">
                                        <h3 className="mb-2">Regenerative</h3>
                                        <p className="g-font-size-16 g-line-height-2">
                                            All producers must chart actionable
                                            path to regenerative production.{" "}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6 d-md-flex mission-column">
                                    <div className="icon g-mr-25">
                                        <img
                                            class="img-fluid"
                                            src="/assets/images/diversity.png"
                                            alt="description"
                                        />
                                    </div>
                                    <div className="mission-text">
                                        <h3 className="mb-2">Diversity</h3>
                                        <p className="g-font-size-16 g-line-height-2">
                                            Majority owned and operated by
                                            members of exporting countries
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-pt-25">
                                <div className="col-md-6 d-md-flex mission-column">
                                    <div className="icon g-mr-25">
                                        <img
                                            class="img-fluid"
                                            src="/assets/images/dignity.png"
                                            alt=" description"
                                        />
                                    </div>
                                    <div className="mission-text">
                                        <h3 className="mb-2">Dignity</h3>
                                        <p className="g-font-size-16 g-line-height-2">
                                            Price atleast 50% above Fairtrade.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6 d-md-flex h-100">
                                    <div className="icon g-mr-25">
                                        <img
                                            class="img-fluid"
                                            src="/assets/images/transparency.png"
                                            alt=" description"
                                        />
                                    </div>
                                    <div className="mission-text">
                                        <h3 className="mb-2">
                                            Hypertransparency
                                        </h3>
                                        <p className="g-font-size-16 g-line-height-2">
                                            All products and value add
                                            transaction will be traceable to
                                            atleast the collective level.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContainerWithGap>
            </Animate>
        </>
    );
};
export default HomePage;
