export const getAttributesByCategory = (category) => {
	// default attribute
	let attributes = {
		size : ['Small','Medium','Large'],
		grind : []
	};
	switch(category){
		case 'spices':
			attributes = {
				size : ['Small','Medium','Large'],
				grind : ['Whole','Ground']
			};
			break;
		case 'tea':
			attributes = {
				size : ['Small','Medium','Large'],
				grind : ['Whole','Ground']
			};
			break;
		case 'essential-oil':
			attributes = {
				size : ['Small','Medium','Large'],
				grind : ['Whole','Ground']
			};
			break;
		case 'animal-feed':
			attributes = {
				size : ['Small','Medium','Large'],
				grind : ['Whole','Ground']
			};
			break;
		case 'dietary-suppliments':
			attributes = {
				size : ['Small','Medium','Large'],
				grind : ['Whole','Ground']
			};
			break;
		default:
			break;
	}

	return attributes;

}