import React, { useEffect, useState } from "react";
import Loading from "../components/Loading";
import HtmlParser from "react-html-parser";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";

const FaqPage = (props) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [activeCategory, setActivecategory] = useState("");
    const [queryLoader, setQueryLoader] = useState(false);
    const [faqs, setFaqs] = useState();
    const [query, setQuery] = useState({
        name: "",
        subject: "",
        phone: "",
        email: "",
        message: ""
    });
    let global_response = "";
    useEffect(() => {
        let product_url = process.env.REACT_APP_API_BASE_URL + "/faq-category/";
        fetch(product_url)
            .then((response) => response.json())
            .then((result) => {
                setData(result.results);
                setActivecategory(result.results[0].name);
                const filteredFaqsCategory = result.results.find(
                    (d) => d.name === result.results[0].name
                );
                setFaqs(filteredFaqsCategory.faqs);
                setIsLoading(false);
            });
    }, []);

    const handleClickFaq = (faqCatName) => {
        const filteredFaqsCategory = data.find((d) => d.name === faqCatName);
        setFaqs(filteredFaqsCategory.faqs);
    };

    const handleChange = (e) => {
        setQuery({ ...query, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setQueryLoader(true);
        const QueryURL = process.env.REACT_APP_API_BASE_URL + "/contact-us/";
        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: "POST",
            body: query,
            headers: {
                "Content-Type": "application/json"
            }
        };
        fetch(QueryURL, requestOptions)
            .then((response) => {
                global_response = response;
                return response.json();
            })
            .then((result) => {
                // setSuccessMessage("User Verification Successful");
                toast.success("Query Submitted Successful");
                setQueryLoader(false);
                setQuery({
                    name: "",
                    subject: "",
                    phone: "",
                    email: "",
                    message: ""
                });
            })
            .catch((error) => { });
    };
    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="container g-pt-100 g-pb-50">
                    <div className="row">
                        <div className="col-md-4 g-mb-30">
                            <h3 className="mb-5">FAQ's</h3>

                            {/* <!-- Nav tabs --> */}
                            <ul
                                className="nav flex-column u-nav-v5-3 u-nav-primary g-bg-gray-light-v5 rounded g-pa-20 faq-category-section"
                                role="tablist"
                                data-target="nav-5-3-primary-ver"
                                data-tabs-mobile-type="slide-up-down"
                                data-btn-classNamees="btn btn-md btn-block rounded-0 u-btn-outline-primary"
                            >
                                {data &&
                                    data.map((item) => {
                                        return (
                                            <li
                                                className="nav-item"
                                                key={item.id}
                                                onClick={() =>
                                                    handleClickFaq(item.name)
                                                }
                                            >
                                                <a
                                                    className={
                                                        activeCategory ===
                                                            item.name
                                                            ? "nav-link active g-brd-bottom-none g-color-primary--hover"
                                                            : "nav-link g-brd-bottom-none g-color-primary--hover"
                                                    }
                                                    data-toggle="tab"
                                                    href="#nav-5-3-primary-ver--1"
                                                    role="tab"
                                                >
                                                    {item.name}
                                                </a>
                                            </li>
                                        );
                                    })}
                            </ul>
                            {/* <!-- End Nav tabs --> */}
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    FAQ'S
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <ul
                                        className="nav u-nav-v5-3 u-nav-primary g-bg-gray-light-v5 rounded g-pa-20 mobile-faq-category"
                                        role="tablist"
                                        data-target="nav-5-3-primary-ver"
                                        data-tabs-mobile-type="slide-up-down"
                                        data-btn-classNamees="btn btn-md btn-block rounded-0 u-btn-outline-primary"
                                    >
                                        {data &&
                                            data.map((item) => {
                                                return (
                                                    <li
                                                        className="nav-item"
                                                        key={item.id}
                                                        onClick={() =>
                                                            handleClickFaq(item.name)
                                                        }
                                                    >
                                                        <a
                                                            className={
                                                                activeCategory ===
                                                                    item.name
                                                                    ? "nav-link active g-brd-bottom-none g-color-primary--hover"
                                                                    : "nav-link g-brd-bottom-none g-color-primary--hover"
                                                            }
                                                            data-toggle="tab"
                                                            href="#nav-5-3-primary-ver--1"
                                                            role="tab"
                                                        >
                                                            {item.name}
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        <div className="col-md-8 g-mb-30 g-mt-90 faq-page-column">
                            {/* <!-- Tab panes --> */}
                            <div
                                id="nav-5-3-primary-ver"
                                className="tab-content g-pt-20 g-pt-0--md"
                            >
                                <div
                                    className="tab-pane fade show active"
                                    id="nav-5-3-primary-ver--1"
                                    role="tabpanel"
                                >
                                    {/* <!-- Accordion --> */}
                                    <div
                                        id="accordion-12-1"
                                        className="u-accordion u-accordion-color-primary"
                                        role="tablist"
                                        aria-multiselectable="true"
                                    >
                                        {faqs &&
                                            faqs.map((data) => {
                                                return (
                                                    <div
                                                        className="card g-brd-none g-brd-bottom g-brd-gray-light-v3 rounded-0 g-pb-30 g-mb-30"
                                                        key={data.id}
                                                    >
                                                        <div
                                                            id="accordion-12-1-heading-01"
                                                            className="u-accordion__header g-color-gray-dark-v4 g-font-weight-500 g-font-size-18 g-pa-0 d-flex justify-content-between"
                                                            role="tab"
                                                        >
                                                            <div
                                                                style={{
                                                                    flex: 1
                                                                }}
                                                            >
                                                                {/* <span className="g-color-primary g-font-weight-700 g-font-size-16 g-line-height-1_2"></span> */}
                                                                {data.question}
                                                            </div>
                                                            <h5 className="g-font-weight-400 g-font-size-12 g-pl-8 mb-0">
                                                                <a
                                                                    className="g-color-primary g-font-size-14 g-text-underline--none--hover g-pa-10"
                                                                    href={`#accordion-12-1-body-${data.id}`}
                                                                    data-toggle="collapse"
                                                                    data-parent="#accordion-12-1"
                                                                    aria-expanded="false"
                                                                    aria-controls={`accordion-12-1-body-${data.id}`}
                                                                >
                                                                    <span className="u-accordion__control-icon">
                                                                        <i className="g-font-style-normal">
                                                                            Read
                                                                            More
                                                                            <span className="ml-2 fa fa-caret-down"></span>
                                                                        </i>
                                                                        <i className="g-font-style-normal">
                                                                            Read
                                                                            Less
                                                                            <span className="ml-2 fa fa-caret-up"></span>
                                                                        </i>
                                                                    </span>
                                                                </a>
                                                            </h5>
                                                        </div>

                                                        <div
                                                            id={`accordion-12-1-body-${data.id}`}
                                                            className="collapse"
                                                            role="tabpanel"
                                                            aria-labelledby="accordion-12-1-heading-01"
                                                        >
                                                            <div className="u-accordion__body g-color-gray-dark-v4 faqs">
                                                                {/* Hello whats up */}
                                                                {HtmlParser(
                                                                    data.answer
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        {/* <!-- Card --> */}
                                    </div>
                                    {/* <!-- End Accordion --> */}
                                </div>

                                <div
                                    className="tab-pane fade"
                                    id="nav-5-3-primary-ver--3"
                                    role="tabpanel"
                                >
                                    <h3 className="h5 g-color-gray-dark-v2 g-mb-30">
                                        Managing Your Orders
                                    </h3>

                                    {/* <!-- Accordion --> */}
                                    <div
                                        id="accordion-12-3"
                                        className="u-accordion u-accordion-color-primary"
                                        role="tablist"
                                        aria-multiselectable="true"
                                    >
                                        {/* <!-- Card --> */}
                                        <div className="card g-brd-none g-brd-bottom g-brd-gray-light-v3 rounded-0 g-pb-30 g-mb-30">
                                            <div
                                                id="accordion-12-1-heading-01"
                                                className="u-accordion__header g-color-gray-dark-v4 g-font-weight-500 g-font-size-16 g-pa-0 d-flex justify-content-between"
                                                role="tab"
                                            >
                                                {/* <span className="g-color-primary g-font-weight-700 g-font-size-16 g-line-height-1_2"></span> */}
                                                Cancel Items or Orders
                                                <h5 className="g-font-weight-400 g-font-size-12 g-pl-8 mb-0">
                                                    <a
                                                        className="g-color-primary g-font-size-14 g-text-underline--none--hover g-pa-10"
                                                        href="#accordion-12-1-body-01"
                                                        data-toggle="collapse"
                                                        data-parent="#accordion-12-1"
                                                        aria-expanded="false"
                                                        aria-controls="accordion-12-1-body-01"
                                                    >
                                                        <span className="u-accordion__control-icon">
                                                            <i className="g-font-style-normal">
                                                                Read More
                                                                <span className="ml-2 fa fa-caret-down"></span>
                                                            </i>
                                                            <i className="g-font-style-normal">
                                                                Read Less
                                                                <span className="ml-2 fa fa-caret-up"></span>
                                                            </i>
                                                        </span>
                                                    </a>
                                                </h5>
                                            </div>
                                            <div
                                                id="accordion-12-1-body-01"
                                                className="collapse show"
                                                role="tabpanel"
                                                aria-labelledby="accordion-12-1-heading-01"
                                            >
                                                <div className="u-accordion__body g-color-gray-dark-v4">
                                                    This is where we sit down,
                                                    grab a cup of coffee and
                                                    dial in the details.
                                                    Understanding the task at
                                                    hand and ironing out the
                                                    wrinkles is key. Now that
                                                    we've aligned the details,
                                                    it's time to get things
                                                    mapped out and organized.
                                                    This part is really crucial
                                                    in keeping the project in
                                                    line to completion.
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Card --> */}

                                        {/* <!-- Card --> */}
                                        <div className="card g-brd-none g-brd-bottom g-brd-gray-light-v3 rounded-0 g-pb-30 g-mb-30">
                                            <div
                                                id="accordion-12-1-heading-02"
                                                className="u-accordion__header g-color-gray-dark-v4 g-font-weight-500 g-font-size-16 g-pa-0 d-flex justify-content-between"
                                                role="tab"
                                            >
                                                {/* <span className="g-color-primary g-font-weight-700 g-font-size-16 g-line-height-1_2">2.</span> */}
                                                Manage Your Orders
                                                <h5 className="g-font-weight-400 g-font-size-13 g-pl-8 mb-0">
                                                    <a
                                                        className="collapsed g-color-primary g-text-underline--none--hover g-pa-10 g-font-size-14"
                                                        href="#accordion-12-1-body-02"
                                                        data-toggle="collapse"
                                                        data-parent="#accordion-12-1"
                                                        aria-expanded="false"
                                                        aria-controls="accordion-12-1-body-02"
                                                    >
                                                        <span className="u-accordion__control-icon">
                                                            <i className="g-font-style-normal">
                                                                Read More
                                                                <span className="ml-2 fa fa-caret-down"></span>
                                                            </i>
                                                            <i className="g-font-style-normal">
                                                                Read Less
                                                                <span className="ml-2 fa fa-caret-up"></span>
                                                            </i>
                                                        </span>
                                                    </a>
                                                </h5>
                                            </div>
                                            <div
                                                id="accordion-12-1-body-02"
                                                className="collapse"
                                                role="tabpanel"
                                                aria-labelledby="accordion-12-1-heading-02"
                                            >
                                                <div className="u-accordion__body g-color-gray-dark-v4">
                                                    This is where we sit down,
                                                    grab a cup of coffee and
                                                    dial in the details.
                                                    Understanding the task at
                                                    hand and ironing out the
                                                    wrinkles is key. Now that
                                                    we've aligned the details,
                                                    it's time to get things
                                                    mapped out and organized.
                                                    This part is really crucial
                                                    in keeping the project in
                                                    line to completion.
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Card -->

                  <!-- Card --> */}
                                        <div className="card g-brd-none g-brd-bottom g-brd-gray-light-v3 rounded-0 g-pb-30 g-mb-30">
                                            <div
                                                id="accordion-12-1-heading-03"
                                                className="u-accordion__header g-color-gray-dark-v4 g-font-weight-500 g-font-size-16 g-pa-0 d-flex justify-content-between"
                                                role="tab"
                                            >
                                                {/* <span className="g-color-primary g-font-weight-700 g-font-size-16 g-line-height-1_2">3.</span> */}
                                                Track Your Package
                                                <h5 className="g-font-weight-400 g-font-size-13 g-pl-8 mb-0">
                                                    <a
                                                        className="collapsed g-color-primary g-text-underline--none--hover g-pa-10 g-font-size-14"
                                                        href="#accordion-12-1-body-03"
                                                        data-toggle="collapse"
                                                        data-parent="#accordion-12-1"
                                                        aria-expanded="false"
                                                        aria-controls="accordion-12-1-body-03"
                                                    >
                                                        <span className="u-accordion__control-icon">
                                                            <i className="g-font-style-normal">
                                                                Read More
                                                                <span className="ml-2 fa fa-caret-down"></span>
                                                            </i>
                                                            <i className="g-font-style-normal">
                                                                Read Less
                                                                <span className="ml-2 fa fa-caret-up"></span>
                                                            </i>
                                                        </span>
                                                    </a>
                                                </h5>
                                            </div>
                                            <div
                                                id="accordion-12-1-body-03"
                                                className="collapse"
                                                role="tabpanel"
                                                aria-labelledby="accordion-12-1-heading-03"
                                            >
                                                <div className="u-accordion__body g-color-gray-dark-v4">
                                                    The time has come to bring
                                                    those ideas and plans to
                                                    life. Sifting through
                                                    teaspoons of clay and sand
                                                    scraped from the floors of
                                                    caves, German researchers
                                                    have managed to isolate
                                                    ancient human DNA — without
                                                    turning up a single bone.
                                                    Their new technique,
                                                    described in a study
                                                    published on Thursday in the
                                                    journal Science, promises to
                                                    open new avenues of research
                                                    into human prehistory and
                                                    was met with excitement by
                                                    geneticists and
                                                    archaeologists.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- End Accordion --> */}
                                </div>

                                <div
                                    className="tab-pane fade"
                                    id="nav-5-3-primary-ver--4"
                                    role="tabpanel"
                                >
                                    <h3 className="h5 g-color-gray-dark-v2 g-mb-30">
                                        Account Settings &amp; Payment Methods
                                    </h3>

                                    {/* <!-- Accordion --> */}
                                    <div
                                        id="accordion-12-4"
                                        className="u-accordion u-accordion-color-primary"
                                        role="tablist"
                                        aria-multiselectable="true"
                                    >
                                        {/* <!-- Card --> */}
                                        <div className="card g-brd-none g-brd-bottom g-brd-gray-light-v3 rounded-0 g-pb-30 g-mb-30">
                                            <div
                                                id="accordion-12-1-heading-01"
                                                className="u-accordion__header g-color-gray-dark-v4 g-font-weight-500 g-font-size-16 g-pa-0 d-flex justify-content-between"
                                                role="tab"
                                            >
                                                {/* <span className="g-color-primary g-font-weight-700 g-font-size-16 g-line-height-1_2"></span> */}
                                                Cancel Items or Orders
                                                <h5 className="g-font-weight-400 g-font-size-12 g-pl-8 mb-0">
                                                    <a
                                                        className="g-color-primary g-font-size-14 g-text-underline--none--hover g-pa-10"
                                                        href="#accordion-12-1-body-01"
                                                        data-toggle="collapse"
                                                        data-parent="#accordion-12-1"
                                                        aria-expanded="false"
                                                        aria-controls="accordion-12-1-body-01"
                                                    >
                                                        <span className="u-accordion__control-icon">
                                                            <i className="g-font-style-normal">
                                                                Read More
                                                                <span className="ml-2 fa fa-caret-down"></span>
                                                            </i>
                                                            <i className="g-font-style-normal">
                                                                Read Less
                                                                <span className="ml-2 fa fa-caret-up"></span>
                                                            </i>
                                                        </span>
                                                    </a>
                                                </h5>
                                            </div>
                                            <div
                                                id="accordion-12-1-body-01"
                                                className="collapse show"
                                                role="tabpanel"
                                                aria-labelledby="accordion-12-1-heading-01"
                                            >
                                                <div className="u-accordion__body g-color-gray-dark-v4">
                                                    This is where we sit down,
                                                    grab a cup of coffee and
                                                    dial in the details.
                                                    Understanding the task at
                                                    hand and ironing out the
                                                    wrinkles is key. Now that
                                                    we've aligned the details,
                                                    it's time to get things
                                                    mapped out and organized.
                                                    This part is really crucial
                                                    in keeping the project in
                                                    line to completion.
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Card --> */}

                                        {/* <!-- Card --> */}
                                        <div className="card g-brd-none g-brd-bottom g-brd-gray-light-v3 rounded-0 g-pb-30 g-mb-30">
                                            <div
                                                id="accordion-12-1-heading-02"
                                                className="u-accordion__header g-color-gray-dark-v4 g-font-weight-500 g-font-size-16 g-pa-0 d-flex justify-content-between"
                                                role="tab"
                                            >
                                                {/* <span className="g-color-primary g-font-weight-700 g-font-size-16 g-line-height-1_2">2.</span> */}
                                                Manage Your Orders
                                                <h5 className="g-font-weight-400 g-font-size-13 g-pl-8 mb-0">
                                                    <a
                                                        className="collapsed g-color-primary g-text-underline--none--hover g-pa-10 g-font-size-14"
                                                        href="#accordion-12-1-body-02"
                                                        data-toggle="collapse"
                                                        data-parent="#accordion-12-1"
                                                        aria-expanded="false"
                                                        aria-controls="accordion-12-1-body-02"
                                                    >
                                                        <span className="u-accordion__control-icon">
                                                            <i className="g-font-style-normal">
                                                                Read More
                                                                <span className="ml-2 fa fa-caret-down"></span>
                                                            </i>
                                                            <i className="g-font-style-normal">
                                                                Read Less
                                                                <span className="ml-2 fa fa-caret-up"></span>
                                                            </i>
                                                        </span>
                                                    </a>
                                                </h5>
                                            </div>
                                            <div
                                                id="accordion-12-1-body-02"
                                                className="collapse"
                                                role="tabpanel"
                                                aria-labelledby="accordion-12-1-heading-02"
                                            >
                                                <div className="u-accordion__body g-color-gray-dark-v4">
                                                    This is where we sit down,
                                                    grab a cup of coffee and
                                                    dial in the details.
                                                    Understanding the task at
                                                    hand and ironing out the
                                                    wrinkles is key. Now that
                                                    we've aligned the details,
                                                    it's time to get things
                                                    mapped out and organized.
                                                    This part is really crucial
                                                    in keeping the project in
                                                    line to completion.
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Card -->

                  <!-- Card --> */}
                                        <div className="card g-brd-none g-brd-bottom g-brd-gray-light-v3 rounded-0 g-pb-30 g-mb-30">
                                            <div
                                                id="accordion-12-1-heading-03"
                                                className="u-accordion__header g-color-gray-dark-v4 g-font-weight-500 g-font-size-16 g-pa-0 d-flex justify-content-between"
                                                role="tab"
                                            >
                                                {/* <span className="g-color-primary g-font-weight-700 g-font-size-16 g-line-height-1_2">3.</span> */}
                                                Track Your Package
                                                <h5 className="g-font-weight-400 g-font-size-13 g-pl-8 mb-0">
                                                    <a
                                                        className="collapsed g-color-primary g-text-underline--none--hover g-pa-10 g-font-size-14"
                                                        href="#accordion-12-1-body-03"
                                                        data-toggle="collapse"
                                                        data-parent="#accordion-12-1"
                                                        aria-expanded="false"
                                                        aria-controls="accordion-12-1-body-03"
                                                    >
                                                        <span className="u-accordion__control-icon">
                                                            <i className="g-font-style-normal">
                                                                Read More
                                                                <span className="ml-2 fa fa-caret-down"></span>
                                                            </i>
                                                            <i className="g-font-style-normal">
                                                                Read Less
                                                                <span className="ml-2 fa fa-caret-up"></span>
                                                            </i>
                                                        </span>
                                                    </a>
                                                </h5>
                                            </div>
                                            <div
                                                id="accordion-12-1-body-03"
                                                className="collapse"
                                                role="tabpanel"
                                                aria-labelledby="accordion-12-1-heading-03"
                                            >
                                                <div className="u-accordion__body g-color-gray-dark-v4">
                                                    The time has come to bring
                                                    those ideas and plans to
                                                    life. Sifting through
                                                    teaspoons of clay and sand
                                                    scraped from the floors of
                                                    caves, German researchers
                                                    have managed to isolate
                                                    ancient human DNA — without
                                                    turning up a single bone.
                                                    Their new technique,
                                                    described in a study
                                                    published on Thursday in the
                                                    journal Science, promises to
                                                    open new avenues of research
                                                    into human prehistory and
                                                    was met with excitement by
                                                    geneticists and
                                                    archaeologists.
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Card --> */}
                                    </div>
                                    {/* <!-- End Accordion --> */}
                                </div>

                                <div
                                    className="tab-pane fade"
                                    id="nav-5-3-primary-ver--5"
                                    role="tabpanel"
                                >
                                    <h3 className="h5 g-color-gray-dark-v2 g-mb-30">
                                        Returns &amp; Refunds
                                    </h3>

                                    {/* <!-- Accordion --> */}
                                    <div
                                        id="accordion-12-5"
                                        className="u-accordion u-accordion-color-primary"
                                        role="tablist"
                                        aria-multiselectable="true"
                                    >
                                        {/* <!-- Card --> */}
                                        <div className="card g-brd-none g-brd-bottom g-brd-gray-light-v3 rounded-0 g-pb-30 g-mb-30">
                                            <div
                                                id="accordion-12-1-heading-01"
                                                className="u-accordion__header g-color-gray-dark-v4 g-font-weight-500 g-font-size-16 g-pa-0 d-flex justify-content-between"
                                                role="tab"
                                            >
                                                {/* <span className="g-color-primary g-font-weight-700 g-font-size-16 g-line-height-1_2"></span> */}
                                                Cancel Items or Orders
                                                <h5 className="g-font-weight-400 g-font-size-12 g-pl-8 mb-0">
                                                    <a
                                                        className="g-color-primary g-font-size-14 g-text-underline--none--hover g-pa-10"
                                                        href="#accordion-12-1-body-01"
                                                        data-toggle="collapse"
                                                        data-parent="#accordion-12-1"
                                                        aria-expanded="false"
                                                        aria-controls="accordion-12-1-body-01"
                                                    >
                                                        <span className="u-accordion__control-icon">
                                                            <i className="g-font-style-normal">
                                                                Read More
                                                                <span className="ml-2 fa fa-caret-down"></span>
                                                            </i>
                                                            <i className="g-font-style-normal">
                                                                Read Less
                                                                <span className="ml-2 fa fa-caret-up"></span>
                                                            </i>
                                                        </span>
                                                    </a>
                                                </h5>
                                            </div>
                                            <div
                                                id="accordion-12-1-body-01"
                                                className="collapse show"
                                                role="tabpanel"
                                                aria-labelledby="accordion-12-1-heading-01"
                                            >
                                                <div className="u-accordion__body g-color-gray-dark-v4">
                                                    This is where we sit down,
                                                    grab a cup of coffee and
                                                    dial in the details.
                                                    Understanding the task at
                                                    hand and ironing out the
                                                    wrinkles is key. Now that
                                                    we've aligned the details,
                                                    it's time to get things
                                                    mapped out and organized.
                                                    This part is really crucial
                                                    in keeping the project in
                                                    line to completion.
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Card --> */}

                                        {/* <!-- Card --> */}
                                        <div className="card g-brd-none g-brd-bottom g-brd-gray-light-v3 rounded-0 g-pb-30 g-mb-30">
                                            <div
                                                id="accordion-12-1-heading-02"
                                                className="u-accordion__header g-color-gray-dark-v4 g-font-weight-500 g-font-size-16 g-pa-0 d-flex justify-content-between"
                                                role="tab"
                                            >
                                                {/* <span className="g-color-primary g-font-weight-700 g-font-size-16 g-line-height-1_2">2.</span> */}
                                                Manage Your Orders
                                                <h5 className="g-font-weight-400 g-font-size-13 g-pl-8 mb-0">
                                                    <a
                                                        className="collapsed g-color-primary g-text-underline--none--hover g-pa-10 g-font-size-14"
                                                        href="#accordion-12-1-body-02"
                                                        data-toggle="collapse"
                                                        data-parent="#accordion-12-1"
                                                        aria-expanded="false"
                                                        aria-controls="accordion-12-1-body-02"
                                                    >
                                                        <span className="u-accordion__control-icon">
                                                            <i className="g-font-style-normal">
                                                                Read More
                                                                <span className="ml-2 fa fa-caret-down"></span>
                                                            </i>
                                                            <i className="g-font-style-normal">
                                                                Read Less
                                                                <span className="ml-2 fa fa-caret-up"></span>
                                                            </i>
                                                        </span>
                                                    </a>
                                                </h5>
                                            </div>
                                            <div
                                                id="accordion-12-1-body-02"
                                                className="collapse"
                                                role="tabpanel"
                                                aria-labelledby="accordion-12-1-heading-02"
                                            >
                                                <div className="u-accordion__body g-color-gray-dark-v4">
                                                    This is where we sit down,
                                                    grab a cup of coffee and
                                                    dial in the details.
                                                    Understanding the task at
                                                    hand and ironing out the
                                                    wrinkles is key. Now that
                                                    we've aligned the details,
                                                    it's time to get things
                                                    mapped out and organized.
                                                    This part is really crucial
                                                    in keeping the project in
                                                    line to completion.
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Card -->

                  <!-- Card --> */}
                                        <div className="card g-brd-none g-brd-bottom g-brd-gray-light-v3 rounded-0 g-pb-30 g-mb-30">
                                            <div
                                                id="accordion-12-1-heading-03"
                                                className="u-accordion__header g-color-gray-dark-v4 g-font-weight-500 g-font-size-16 g-pa-0 d-flex justify-content-between"
                                                role="tab"
                                            >
                                                {/* <span className="g-color-primary g-font-weight-700 g-font-size-16 g-line-height-1_2">3.</span> */}
                                                Track Your Package
                                                <h5 className="g-font-weight-400 g-font-size-13 g-pl-8 mb-0">
                                                    <a
                                                        className="collapsed g-color-primary g-text-underline--none--hover g-pa-10 g-font-size-14"
                                                        href="#accordion-12-1-body-03"
                                                        data-toggle="collapse"
                                                        data-parent="#accordion-12-1"
                                                        aria-expanded="false"
                                                        aria-controls="accordion-12-1-body-03"
                                                    >
                                                        <span className="u-accordion__control-icon">
                                                            <i className="g-font-style-normal">
                                                                Read More
                                                                <span className="ml-2 fa fa-caret-down"></span>
                                                            </i>
                                                            <i className="g-font-style-normal">
                                                                Read Less
                                                                <span className="ml-2 fa fa-caret-up"></span>
                                                            </i>
                                                        </span>
                                                    </a>
                                                </h5>
                                            </div>
                                            <div
                                                id="accordion-12-1-body-03"
                                                className="collapse"
                                                role="tabpanel"
                                                aria-labelledby="accordion-12-1-heading-03"
                                            >
                                                <div className="u-accordion__body g-color-gray-dark-v4">
                                                    The time has come to bring
                                                    those ideas and plans to
                                                    life. Sifting through
                                                    teaspoons of clay and sand
                                                    scraped from the floors of
                                                    caves, German researchers
                                                    have managed to isolate
                                                    ancient human DNA — without
                                                    turning up a single bone.
                                                    Their new technique,
                                                    described in a study
                                                    published on Thursday in the
                                                    journal Science, promises to
                                                    open new avenues of research
                                                    into human prehistory and
                                                    was met with excitement by
                                                    geneticists and
                                                    archaeologists.
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Card --> */}
                                    </div>
                                    {/* <!-- End Accordion --> */}
                                </div>

                                <div
                                    className="tab-pane fade"
                                    id="nav-5-3-primary-ver--6"
                                    role="tabpanel"
                                >
                                    <h3 className="h5 g-color-gray-dark-v2 g-mb-30">
                                        Shipping Policies
                                    </h3>
                                    {/* 
                <!-- Accordion --> */}
                                    <div
                                        id="accordion-12-6"
                                        className="u-accordion u-accordion-color-primary"
                                        role="tablist"
                                        aria-multiselectable="true"
                                    >
                                        {/* <!-- Card --> */}
                                        <div className="card g-brd-none g-brd-bottom g-brd-gray-light-v3 rounded-0 g-pb-30 g-mb-30">
                                            <div
                                                id="accordion-12-1-heading-01"
                                                className="u-accordion__header g-color-gray-dark-v4 g-font-weight-500 g-font-size-16 g-pa-0 d-flex justify-content-between"
                                                role="tab"
                                            >
                                                {/* <span className="g-color-primary g-font-weight-700 g-font-size-16 g-line-height-1_2"></span> */}
                                                Cancel Items or Orders
                                                <h5 className="g-font-weight-400 g-font-size-12 g-pl-8 mb-0">
                                                    <a
                                                        className="g-color-primary g-font-size-14 g-text-underline--none--hover g-pa-10"
                                                        href="#accordion-12-1-body-01"
                                                        data-toggle="collapse"
                                                        data-parent="#accordion-12-1"
                                                        aria-expanded="false"
                                                        aria-controls="accordion-12-1-body-01"
                                                    >
                                                        <span className="u-accordion__control-icon">
                                                            <i className="g-font-style-normal">
                                                                Read More
                                                                <span className="ml-2 fa fa-caret-down"></span>
                                                            </i>
                                                            <i className="g-font-style-normal">
                                                                Read Less
                                                                <span className="ml-2 fa fa-caret-up"></span>
                                                            </i>
                                                        </span>
                                                    </a>
                                                </h5>
                                            </div>
                                            <div
                                                id="accordion-12-1-body-01"
                                                className="collapse show"
                                                role="tabpanel"
                                                aria-labelledby="accordion-12-1-heading-01"
                                            >
                                                <div className="u-accordion__body g-color-gray-dark-v4">
                                                    This is where we sit down,
                                                    grab a cup of coffee and
                                                    dial in the details.
                                                    Understanding the task at
                                                    hand and ironing out the
                                                    wrinkles is key. Now that
                                                    we've aligned the details,
                                                    it's time to get things
                                                    mapped out and organized.
                                                    This part is really crucial
                                                    in keeping the project in
                                                    line to completion.
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Card --> */}

                                        {/* <!-- Card --> */}
                                        <div className="card g-brd-none g-brd-bottom g-brd-gray-light-v3 rounded-0 g-pb-30 g-mb-30">
                                            <div
                                                id="accordion-12-1-heading-02"
                                                className="u-accordion__header g-color-gray-dark-v4 g-font-weight-500 g-font-size-16 g-pa-0 d-flex justify-content-between"
                                                role="tab"
                                            >
                                                {/* <span className="g-color-primary g-font-weight-700 g-font-size-16 g-line-height-1_2">2.</span> */}
                                                Manage Your Orders
                                                <h5 className="g-font-weight-400 g-font-size-13 g-pl-8 mb-0">
                                                    <a
                                                        className="collapsed g-color-primary g-text-underline--none--hover g-pa-10 g-font-size-14"
                                                        href="#accordion-12-1-body-02"
                                                        data-toggle="collapse"
                                                        data-parent="#accordion-12-1"
                                                        aria-expanded="false"
                                                        aria-controls="accordion-12-1-body-02"
                                                    >
                                                        <span className="u-accordion__control-icon">
                                                            <i className="g-font-style-normal">
                                                                Read More
                                                                <span className="ml-2 fa fa-caret-down"></span>
                                                            </i>
                                                            <i className="g-font-style-normal">
                                                                Read Less
                                                                <span className="ml-2 fa fa-caret-up"></span>
                                                            </i>
                                                        </span>
                                                    </a>
                                                </h5>
                                            </div>
                                            <div
                                                id="accordion-12-1-body-02"
                                                className="collapse"
                                                role="tabpanel"
                                                aria-labelledby="accordion-12-1-heading-02"
                                            >
                                                <div className="u-accordion__body g-color-gray-dark-v4">
                                                    This is where we sit down,
                                                    grab a cup of coffee and
                                                    dial in the details.
                                                    Understanding the task at
                                                    hand and ironing out the
                                                    wrinkles is key. Now that
                                                    we've aligned the details,
                                                    it's time to get things
                                                    mapped out and organized.
                                                    This part is really crucial
                                                    in keeping the project in
                                                    line to completion.
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Card -->

                  <!-- Card --> */}
                                        <div className="card g-brd-none g-brd-bottom g-brd-gray-light-v3 rounded-0 g-pb-30 g-mb-30">
                                            <div
                                                id="accordion-12-1-heading-03"
                                                className="u-accordion__header g-color-gray-dark-v4 g-font-weight-500 g-font-size-16 g-pa-0 d-flex justify-content-between"
                                                role="tab"
                                            >
                                                {/* <span className="g-color-primary g-font-weight-700 g-font-size-16 g-line-height-1_2">3.</span> */}
                                                Track Your Package
                                                <h5 className="g-font-weight-400 g-font-size-13 g-pl-8 mb-0">
                                                    <a
                                                        className="collapsed g-color-primary g-text-underline--none--hover g-pa-10 g-font-size-14"
                                                        href="#accordion-12-1-body-03"
                                                        data-toggle="collapse"
                                                        data-parent="#accordion-12-1"
                                                        aria-expanded="false"
                                                        aria-controls="accordion-12-1-body-03"
                                                    >
                                                        <span className="u-accordion__control-icon">
                                                            <i className="g-font-style-normal">
                                                                Read More
                                                                <span className="ml-2 fa fa-caret-down"></span>
                                                            </i>
                                                            <i className="g-font-style-normal">
                                                                Read Less
                                                                <span className="ml-2 fa fa-caret-up"></span>
                                                            </i>
                                                        </span>
                                                    </a>
                                                </h5>
                                            </div>
                                            <div
                                                id="accordion-12-1-body-03"
                                                className="collapse"
                                                role="tabpanel"
                                                aria-labelledby="accordion-12-1-heading-03"
                                            >
                                                <div className="u-accordion__body g-color-gray-dark-v4">
                                                    The time has come to bring
                                                    those ideas and plans to
                                                    life. Sifting through
                                                    teaspoons of clay and sand
                                                    scraped from the floors of
                                                    caves, German researchers
                                                    have managed to isolate
                                                    ancient human DNA — without
                                                    turning up a single bone.
                                                    Their new technique,
                                                    described in a study
                                                    published on Thursday in the
                                                    journal Science, promises to
                                                    open new avenues of research
                                                    into human prehistory and
                                                    was met with excitement by
                                                    geneticists and
                                                    archaeologists.
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Card --> */}
                                    </div>
                                    {/* <!-- End Accordion --> */}
                                </div>

                                <div
                                    className="tab-pane fade"
                                    id="nav-5-3-primary-ver--7"
                                    role="tabpanel"
                                >
                                    <h3 className="h5 g-color-gray-dark-v2 g-mb-30">
                                        Other Topics
                                    </h3>

                                    {/* <!-- Accordion --> */}
                                    <div
                                        id="accordion-12-7"
                                        className="u-accordion u-accordion-color-primary"
                                        role="tablist"
                                        aria-multiselectable="true"
                                    >
                                        {/* <!-- Card --> */}
                                        <div className="card g-brd-none g-brd-bottom g-brd-gray-light-v3 rounded-0 g-pb-30 g-mb-30">
                                            <div
                                                id="accordion-12-1-heading-01"
                                                className="u-accordion__header g-color-gray-dark-v4 g-font-weight-500 g-font-size-16 g-pa-0 d-flex justify-content-between"
                                                role="tab"
                                            >
                                                {/* <span className="g-color-primary g-font-weight-700 g-font-size-16 g-line-height-1_2"></span> */}
                                                Cancel Items or Orders
                                                <h5 className="g-font-weight-400 g-font-size-12 g-pl-8 mb-0">
                                                    <a
                                                        className="g-color-primary g-font-size-14 g-text-underline--none--hover g-pa-10"
                                                        href="#accordion-12-1-body-01"
                                                        data-toggle="collapse"
                                                        data-parent="#accordion-12-1"
                                                        aria-expanded="false"
                                                        aria-controls="accordion-12-1-body-01"
                                                    >
                                                        <span className="u-accordion__control-icon">
                                                            <i className="g-font-style-normal">
                                                                Read More
                                                                <span className="ml-2 fa fa-caret-down"></span>
                                                            </i>
                                                            <i className="g-font-style-normal">
                                                                Read Less
                                                                <span className="ml-2 fa fa-caret-up"></span>
                                                            </i>
                                                        </span>
                                                    </a>
                                                </h5>
                                            </div>
                                            <div
                                                id="accordion-12-1-body-01"
                                                className="collapse show"
                                                role="tabpanel"
                                                aria-labelledby="accordion-12-1-heading-01"
                                            >
                                                <div className="u-accordion__body g-color-gray-dark-v4">
                                                    This is where we sit down,
                                                    grab a cup of coffee and
                                                    dial in the details.
                                                    Understanding the task at
                                                    hand and ironing out the
                                                    wrinkles is key. Now that
                                                    we've aligned the details,
                                                    it's time to get things
                                                    mapped out and organized.
                                                    This part is really crucial
                                                    in keeping the project in
                                                    line to completion.
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Card --> */}

                                        {/* <!-- Card --> */}
                                        <div className="card g-brd-none g-brd-bottom g-brd-gray-light-v3 rounded-0 g-pb-30 g-mb-30">
                                            <div
                                                id="accordion-12-1-heading-02"
                                                className="u-accordion__header g-color-gray-dark-v4 g-font-weight-500 g-font-size-16 g-pa-0 d-flex justify-content-between"
                                                role="tab"
                                            >
                                                {/* <span className="g-color-primary g-font-weight-700 g-font-size-16 g-line-height-1_2">2.</span> */}
                                                Manage Your Orders
                                                <h5 className="g-font-weight-400 g-font-size-13 g-pl-8 mb-0">
                                                    <a
                                                        className="collapsed g-color-primary g-text-underline--none--hover g-pa-10 g-font-size-14"
                                                        href="#accordion-12-1-body-02"
                                                        data-toggle="collapse"
                                                        data-parent="#accordion-12-1"
                                                        aria-expanded="false"
                                                        aria-controls="accordion-12-1-body-02"
                                                    >
                                                        <span className="u-accordion__control-icon">
                                                            <i className="g-font-style-normal">
                                                                Read More
                                                                <span className="ml-2 fa fa-caret-down"></span>
                                                            </i>
                                                            <i className="g-font-style-normal">
                                                                Read Less
                                                                <span className="ml-2 fa fa-caret-up"></span>
                                                            </i>
                                                        </span>
                                                    </a>
                                                </h5>
                                            </div>
                                            <div
                                                id="accordion-12-1-body-02"
                                                className="collapse"
                                                role="tabpanel"
                                                aria-labelledby="accordion-12-1-heading-02"
                                            >
                                                <div className="u-accordion__body g-color-gray-dark-v4">
                                                    This is where we sit down,
                                                    grab a cup of coffee and
                                                    dial in the details.
                                                    Understanding the task at
                                                    hand and ironing out the
                                                    wrinkles is key. Now that
                                                    we've aligned the details,
                                                    it's time to get things
                                                    mapped out and organized.
                                                    This part is really crucial
                                                    in keeping the project in
                                                    line to completion.
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Card --> */}
                                        <div className="card g-brd-none g-brd-bottom g-brd-gray-light-v3 rounded-0 g-pb-30 g-mb-30">
                                            <div
                                                id="accordion-12-1-heading-03"
                                                className="u-accordion__header g-color-gray-dark-v4 g-font-weight-500 g-font-size-16 g-pa-0 d-flex justify-content-between"
                                                role="tab"
                                            >
                                                {/* <span className="g-color-primary g-font-weight-700 g-font-size-16 g-line-height-1_2">3.</span> */}
                                                Track Your Package
                                                <h5 className="g-font-weight-400 g-font-size-13 g-pl-8 mb-0">
                                                    <a
                                                        className="collapsed g-color-primary g-text-underline--none--hover g-pa-10 g-font-size-14"
                                                        href="#accordion-12-1-body-03"
                                                        data-toggle="collapse"
                                                        data-parent="#accordion-12-1"
                                                        aria-expanded="false"
                                                        aria-controls="accordion-12-1-body-03"
                                                    >
                                                        <span className="u-accordion__control-icon">
                                                            <i className="g-font-style-normal">
                                                                Read More
                                                                <span className="ml-2 fa fa-caret-down"></span>
                                                            </i>
                                                            <i className="g-font-style-normal">
                                                                Read Less
                                                                <span className="ml-2 fa fa-caret-up"></span>
                                                            </i>
                                                        </span>
                                                    </a>
                                                </h5>
                                            </div>
                                            <div
                                                id="accordion-12-1-body-03"
                                                className="collapse"
                                                role="tabpanel"
                                                aria-labelledby="accordion-12-1-heading-03"
                                            >
                                                <div className="u-accordion__body g-color-gray-dark-v4">
                                                    The time has come to bring
                                                    those ideas and plans to
                                                    life. Sifting through
                                                    teaspoons of clay and sand
                                                    scraped from the floors of
                                                    caves, German researchers
                                                    have managed to isolate
                                                    ancient human DNA — without
                                                    turning up a single bone.
                                                    Their new technique,
                                                    described in a study
                                                    published on Thursday in the
                                                    journal Science, promises to
                                                    open new avenues of research
                                                    into human prehistory and
                                                    was met with excitement by
                                                    geneticists and
                                                    archaeologists.
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Card -->

                  <!-- Card --> */}
                                        <div className="card g-brd-none g-brd-bottom g-brd-gray-light-v3 rounded-0 g-pb-30 g-mb-30">
                                            <div
                                                id="accordion-12-1-heading-04"
                                                className="u-accordion__header g-color-gray-dark-v4 g-font-weight-500 g-font-size-16 g-pa-0 d-flex justify-content-between"
                                                role="tab"
                                            >
                                                {/* <span className="g-color-primary g-font-weight-700 g-font-size-16 g-line-height-1_2">4.</span> */}
                                                Check the Status of Your Refund
                                                <h5 className="g-font-weight-400 g-font-size-13 g-pl-8  mb-0">
                                                    <a
                                                        className="collapsed g-color-primary g-text-underline--none--hover g-pa-10 g-font-size-14"
                                                        href="#accordion-12-1-body-04"
                                                        data-toggle="collapse"
                                                        data-parent="#accordion-12-1"
                                                        aria-expanded="false"
                                                        aria-controls="accordion-12-1-body-04"
                                                    >
                                                        <span className="u-accordion__control-icon">
                                                            <i className="g-font-style-normal">
                                                                Read More
                                                                <span className="ml-2 fa fa-caret-down"></span>
                                                            </i>
                                                            <i className="g-font-style-normal">
                                                                Read Less
                                                                <span className="ml-2 fa fa-caret-up"></span>
                                                            </i>
                                                        </span>
                                                    </a>
                                                </h5>
                                            </div>
                                            <div
                                                id="accordion-12-1-body-04"
                                                className="collapse"
                                                role="tabpanel"
                                                aria-labelledby="accordion-12-1-heading-04"
                                            >
                                                <div className="u-accordion__body g-color-gray-dark-v4">
                                                    Sifting through teaspoons of
                                                    clay and sand scraped from
                                                    the floors of caves, German
                                                    researchers have managed to
                                                    isolate ancient human DNA —
                                                    without turning up a single
                                                    bone.
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Card -->

                  <!-- Card --> */}
                                        <div className="card g-brd-none g-brd-bottom g-brd-gray-light-v3 rounded-0 g-pb-30 g-mb-30">
                                            <div
                                                id="accordion-12-1-heading-05"
                                                className="u-accordion__header g-color-gray-dark-v4 g-font-weight-500 g-font-size-16 g-pa-0 d-flex justify-content-between"
                                                role="tab"
                                            >
                                                {/* <span className="g-color-primary g-font-weight-700 g-font-size-16 g-line-height-1_2">5.</span> */}
                                                Change Your Payment Method
                                                <h5 className="g-font-weight-400 g-font-size-13 g-pl-8 mb-0">
                                                    <a
                                                        className="collapsed g-color-primary g-text-underline--none--hover g-pa-10 g-font-size-14"
                                                        href="#accordion-12-1-body-05"
                                                        data-toggle="collapse"
                                                        data-parent="#accordion-12-1"
                                                        aria-expanded="false"
                                                        aria-controls="accordion-12-1-body-05"
                                                    >
                                                        <span className="u-accordion__control-icon">
                                                            <i className="g-font-style-normal">
                                                                Read More
                                                                <span className="ml-2 fa fa-caret-down"></span>
                                                            </i>
                                                            <i className="g-font-style-normal">
                                                                Read Less
                                                                <span className="ml-2 fa fa-caret-up"></span>
                                                            </i>
                                                        </span>
                                                    </a>
                                                </h5>
                                            </div>
                                            <div
                                                id="accordion-12-1-body-05"
                                                className="collapse"
                                                role="tabpanel"
                                                aria-labelledby="accordion-12-1-heading-05"
                                            >
                                                <div className="u-accordion__body g-color-gray-dark-v4">
                                                    This is where we sit down,
                                                    grab a cup of coffee and
                                                    dial in the details.
                                                    Understanding the task at
                                                    hand and ironing out the
                                                    wrinkles is key. Now that
                                                    we've aligned the details,
                                                    it's time to get things
                                                    mapped out and organized.
                                                    This part is really crucial
                                                    in keeping the project in
                                                    line to completion.
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Card -->

                  <!-- Card --> */}
                                    </div>
                                    {/* <!-- End Accordion --> */}
                                </div>

                                <div
                                    className="tab-pane fade"
                                    id="nav-5-3-primary-ver--8"
                                    role="tabpanel"
                                >
                                    <h3 className="h5 g-color-gray-dark-v2 g-mb-30">
                                        Need more help?
                                    </h3>

                                    {/* <!-- Accordion --> */}
                                    <div
                                        id="accordion-12-8"
                                        className="u-accordion u-accordion-color-primary"
                                        role="tablist"
                                        aria-multiselectable="true"
                                    >
                                        {/* <!-- Card --> */}
                                        <div className="card g-brd-none g-brd-bottom g-brd-gray-light-v3 rounded-0 g-pb-30 g-mb-30">
                                            <div
                                                id="accordion-12-1-heading-01"
                                                className="u-accordion__header g-color-gray-dark-v4 g-font-weight-500 g-font-size-16 g-pa-0 d-flex justify-content-between"
                                                role="tab"
                                            >
                                                {/* <span className="g-color-primary g-font-weight-700 g-font-size-16 g-line-height-1_2"></span> */}
                                                Cancel Items or Orders
                                                <h5 className="g-font-weight-400 g-font-size-12 g-pl-8 mb-0">
                                                    <a
                                                        className="g-color-primary g-font-size-14 g-text-underline--none--hover g-pa-10"
                                                        href="#accordion-12-1-body-01"
                                                        data-toggle="collapse"
                                                        data-parent="#accordion-12-1"
                                                        aria-expanded="false"
                                                        aria-controls="accordion-12-1-body-01"
                                                    >
                                                        <span className="u-accordion__control-icon">
                                                            <i className="g-font-style-normal">
                                                                Read More
                                                                <span className="ml-2 fa fa-caret-down"></span>
                                                            </i>
                                                            <i className="g-font-style-normal">
                                                                Read Less
                                                                <span className="ml-2 fa fa-caret-up"></span>
                                                            </i>
                                                        </span>
                                                    </a>
                                                </h5>
                                            </div>
                                            <div
                                                id="accordion-12-1-body-01"
                                                className="collapse show"
                                                role="tabpanel"
                                                aria-labelledby="accordion-12-1-heading-01"
                                            >
                                                <div className="u-accordion__body g-color-gray-dark-v4">
                                                    This is where we sit down,
                                                    grab a cup of coffee and
                                                    dial in the details.
                                                    Understanding the task at
                                                    hand and ironing out the
                                                    wrinkles is key. Now that
                                                    we've aligned the details,
                                                    it's time to get things
                                                    mapped out and organized.
                                                    This part is really crucial
                                                    in keeping the project in
                                                    line to completion.
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Card --> */}

                                        {/* <!-- Card --> */}
                                        <div className="card g-brd-none g-brd-bottom g-brd-gray-light-v3 rounded-0 g-pb-30 g-mb-30">
                                            <div
                                                id="accordion-12-1-heading-02"
                                                className="u-accordion__header g-color-gray-dark-v4 g-font-weight-500 g-font-size-16 g-pa-0 d-flex justify-content-between"
                                                role="tab"
                                            >
                                                {/* <span className="g-color-primary g-font-weight-700 g-font-size-16 g-line-height-1_2">2.</span> */}
                                                Manage Your Orders
                                                <h5 className="g-font-weight-400 g-font-size-13 g-pl-8 mb-0">
                                                    <a
                                                        className="collapsed g-color-primary g-text-underline--none--hover g-pa-10 g-font-size-14"
                                                        href="#accordion-12-1-body-02"
                                                        data-toggle="collapse"
                                                        data-parent="#accordion-12-1"
                                                        aria-expanded="false"
                                                        aria-controls="accordion-12-1-body-02"
                                                    >
                                                        <span className="u-accordion__control-icon">
                                                            <i className="g-font-style-normal">
                                                                Read More
                                                                <span className="ml-2 fa fa-caret-down"></span>
                                                            </i>
                                                            <i className="g-font-style-normal">
                                                                Read Less
                                                                <span className="ml-2 fa fa-caret-up"></span>
                                                            </i>
                                                        </span>
                                                    </a>
                                                </h5>
                                            </div>
                                            <div
                                                id="accordion-12-1-body-02"
                                                className="collapse"
                                                role="tabpanel"
                                                aria-labelledby="accordion-12-1-heading-02"
                                            >
                                                <div className="u-accordion__body g-color-gray-dark-v4">
                                                    This is where we sit down,
                                                    grab a cup of coffee and
                                                    dial in the details.
                                                    Understanding the task at
                                                    hand and ironing out the
                                                    wrinkles is key. Now that
                                                    we've aligned the details,
                                                    it's time to get things
                                                    mapped out and organized.
                                                    This part is really crucial
                                                    in keeping the project in
                                                    line to completion.
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Card --> */}
                                    </div>
                                    {/* <!-- End Accordion --> */}
                                </div>
                            </div>
                            {/* <!-- End Tab panes --> */}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            {/* <div className="media">
              <img src="/assets/removable_images/tomato.jpg" alt="Product Origin Map" className="w-100" />
            </div> */}
                        </div>

                        <div className="col-md-8 g-mb-30">
                            {/* <!-- Media --> */}
                            <div className="media g-mb-30">
                                <div className="media-body">
                                    <a
                                        className="u-link-v5 g-color-main g-color-primary--hover g-font-weight-600"
                                        href="#"
                                    >
                                        Can't find answer?
                                    </a>
                                    <p>
                                        Do not worry. Our support team will help
                                        you.
                                    </p>
                                </div>
                            </div>
                            {/* <!-- End Media --> */}
                            {/* <!-- Contact Form --> */}
                            <form>
                                <div className="row">
                                    <div className="col-md-6 form-group g-mb-20">
                                        <input
                                            className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover rounded g-py-13 g-px-15"
                                            type="text"
                                            placeholder="Name"
                                            name="name"
                                            value={query.value}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-md-6 form-group g-mb-20">
                                        <input
                                            className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover rounded g-py-13 g-px-15"
                                            type="email"
                                            placeholder="Email"
                                            name="email"
                                            value={query.email}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-md-6 form-group g-mb-20">
                                        <input
                                            className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover rounded g-py-13 g-px-15"
                                            type="text"
                                            placeholder="Subject"
                                            name="subject"
                                            value={query.subject}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-md-6 form-group g-mb-20">
                                        <input
                                            className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover rounded g-py-13 g-px-15"
                                            type="tel"
                                            placeholder="Phone"
                                            name="phone"
                                            value={query.phone}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-md-12 form-group g-mb-40">
                                        <textarea
                                            className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover g-resize-none rounded g-py-13 g-px-15"
                                            rows="7"
                                            placeholder="Message"
                                            name="message"
                                            value={query.message}
                                            onChange={handleChange}
                                        ></textarea>
                                    </div>
                                </div>

                                <div className="text-center">
                                    <button
                                        className="btn u-btn-primary g-font-size-12 text-uppercase g-py-12 g-px-25"
                                        type="submit"
                                        onClick={handleSubmit}
                                    >
                                        {queryLoader
                                            ? "Sending Please Wait..."
                                            : "Send Message"}
                                    </button>
                                </div>
                            </form>
                            {/* <!-- End Contact Form --> */}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FaqPage;
