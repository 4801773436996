import { useEffect, useState } from "react";
import HtmlParser from "react-html-parser";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import ContainerWithGap from "../components/container/ContainerWithGap";
import Loading from "../components/Loading";
import ProductBlock from "../components/ProductBlock";

import ProductRecipe from "../components/product_detail/ProductRecipe";
import ProductReview from "../components/product_detail/ProductReview";
import QuantityChange from "../components/product_detail/QuantityChange";
import { addToCart } from "../helper/CartHelper";
import { get_optimized_image_url } from "../helper/Helper";
import { getAttributesByCategory } from "../helper/ProductHelper";
import ProductDetailCategory from "../components/product_detail/categorywise_templates/productDetailCategory";
import ProductMap from "../components/product_detail/ProductMap";
import Animate from "../components/Animate";

const ProductDetailPage = () => {
    const productDetailsPage = true;
    const [isLoading, setIsLoading] = useState(true);
    const [item, setItem] = useState();

    // productAudio -> Product pronunciation Audio
    const [productAudio, setProductAudio] = useState(null);
    const [playing, setPlaying] = useState(false);

    //for different variation of product there might be different variation -- 'productDynamicAttributes' stores those variables of product
    const [productDynamicAttributes, setProductDynamicAttributes] = useState();

    // Quantity
    const [quantity, setQuantity] = useState(1);

    // Attributes
    const [attributes, setAttributes] = useState(); //available attributes as per category
    const [selectedAttributes, setSelectedAttributes] = useState({
        size: null,
        grind: null
    }); //selected attributes for add in cart

    const [selectedVarient, setSelectedVarient] = useState();

    const [itemVarientAvailable, setItemVarientAvailable] = useState(false);

    const dispatch = useDispatch();

    const [defaultVarient, setDefaultVarient] = useState();

    const slug = useParams().slug;

    useEffect(() => {
        let product_url =
            process.env.REACT_APP_API_BASE_URL +
            "/product/" +
            slug +
            "?expand=texture_type,category,farms";
        fetch(product_url)
            .then((response) => response.json())
            .then((result) => {
                let defaultVarient = {
                    id: result.id,
                    our_price: result.our_price,
                    stock: result.stock,
                    main_image: result.main_image,
                    size: result.size,
                    name: result.name,
                    grind: result.grind,
                    default_varient: true
                };
                setItem({
                    ...result,
                    variants: [...result.variants, defaultVarient]
                });
                if (result.pronunciation_audio) {
                    setProductAudio(result.pronunciation_audio);
                }
                setAttributes(getAttributesByCategory(result.category.slug));
                setSelectedAttributes({
                    size: result.size,
                    grind: result.grind != null ? result.grind : ""
                });
                setItemVarientAvailable(true);
                setDefaultVarient(defaultVarient);
                setSelectedVarient(defaultVarient);
                setProductDynamicAttributes({
                    price: result.our_price,
                    stock: result.stock,
                    image: result.main_image
                });
                setIsLoading(false);
            });
    }, [slug]);

    useEffect(() => {
        if (!!selectedVarient) {
            setProductDynamicAttributes({
                price: selectedVarient.our_price,
                stock: selectedVarient.stock,
                image: selectedVarient.main_image ?? item.main_image
            });
        } else {
            setProductDynamicAttributes({
                price: item?.our_price,
                stock: item?.stock,
                image: item?.main_image
            });
        }
    }, [selectedVarient, item]);

    useEffect(() => {
        let varient = item?.variants.filter((varient) => {
            if (!varient.size && !varient.grind) return;

            let size_check = false;
            let texture_check = false;

            // check size
            if (varient.size && varient.size === selectedAttributes.size) {
                size_check = true;
                texture_check = false;
            }

            // texture size
            if (varient.grind && varient.grind === selectedAttributes.grind) {
                texture_check = true;
            }

            if (size_check && texture_check) {
                return true;
            }
        });

        let size_only_varient = item?.variants.filter((varient) => {
            if (varient.size === selectedAttributes.size && !varient.grind) {
                return true;
            }
        });
        const main_varient =
            varient?.length > 0 ? "varient" : "size_only_varient";

        switch (main_varient) {
            case "varient":
                setSelectedVarient(varient?.length > 0 && varient[0]);
                setItemVarientAvailable(true);
                break;
            case "size_only_varient":
                setSelectedVarient(
                    size_only_varient?.length > 0 && size_only_varient[0]
                );
                setItemVarientAvailable(true);
                break;

            default:
                setSelectedVarient("");
                setItemVarientAvailable(false);
                break;
        }
    }, [selectedAttributes, item]);

    const playAudio = () => {
        if (productAudio) {
            let audio = new Audio(productAudio);
            if (!playing) {
                setPlaying(true);
                audio.play();
                setPlaying(false);
            }
        }
    };

    const sizeClickHandler = (size) => {
        setSelectedAttributes({
            ...selectedAttributes,
            size: size
        });
    };

    const grindClickHandler = (grind) => {
        setSelectedAttributes({
            ...selectedAttributes,
            grind: grind
        });
    };

    if (isLoading) {
        return <Loading />;
    }

    const geoJsonData = item && {
        type: "FeatureCollection",
        features: item.farms.length
            ? item.farms.map((itm) => ({
                  type: "Feature",
                  geometry: itm?.point,
                  properties: {
                      id: itm?.id,
                      name: itm?.name,
                      province: itm?.province,
                      district: itm?.district,
                      municipality: itm?.municipality,
                      ward: itm?.ward,
                      point_image: itm?.point_indicator
                  }
              }))
            : []
    };
    console.log("item", item);
    return (
        <>
            <ContainerWithGap>
                {/* Top Product Info Part with Slider  */}
                <Animate>
                    <div className="row mt-5">
                        {/* Slider Part  */}
                        <div className="col-lg-4">
                            {/* <!-- Carousel 1 --> */}
                            <Slider>
                                {item.main_image ? (
                                    <div className="g-bg-cover text-center">
                                        <img
                                            src={get_optimized_image_url(
                                                item.main_image
                                            )}
                                            alt={item.name}
                                            className="w-100"
                                        />
                                    </div>
                                ) : (
                                    ""
                                )}
                                {item.images.length > 0
                                    ? item.images.map((image) => (
                                          <div className="g-bg-cover text-center">
                                              <img
                                                  src={get_optimized_image_url(
                                                      image.image
                                                  )}
                                                  alt={item.name}
                                                  className="w-100"
                                              />
                                          </div>
                                      ))
                                    : ""}
                            </Slider>
                            {/* <!-- End Carousel 1 --> */}
                        </div>
                        {/* End Slider part  */}

                        {/* Description Part - Right side of Slider  */}
                        <div className="col-lg-5">
                            <div className="g-px-40--lg">
                                {/* <!-- Product Info --> */}
                                <div className="d-flex">
                                    <h1 className="g-font-weight-300 mb-2">
                                        {item.name}
                                    </h1>
                                    {item.pronunciation_audio ? (
                                        <div
                                            type="presentation"
                                            className="d-inline-block"
                                            onClick={playAudio}
                                        >
                                            <img
                                                src="/assets/images/product_detail/volume-up.svg"
                                                className="ml-5 mt-3"
                                                alt="speaker"
                                            />
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                {/* <!-- End Product Info --> */}

                                {/* review start */}
                                <div className="g-mb-15 d-flex align-items-center">
                                    <ul
                                        class="js-rating u-rating-v1 g-font-size-25 g-color-gray-light-v3 mb-0"
                                        data-hover-classes="g-color-primary"
                                    >
                                        {[1, 2, 3, 4, 5].map((rating) => (
                                            <>
                                                {rating <= item.rating ? (
                                                    <li
                                                        class="g-color-primary g-line-height-1_4"
                                                        key={
                                                            "ratin_prd" + rating
                                                        }
                                                    >
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                ) : (
                                                    ""
                                                )}

                                                {rating > item.rating ? (
                                                    <li
                                                        class="g-color-gray g-line-height-1_4"
                                                        key={
                                                            "ratin_prd" + rating
                                                        }
                                                    >
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                ) : (
                                                    ""
                                                )}
                                            </>
                                        ))}
                                    </ul>
                                    <span className="ml-4 product-detail-review g-font-size-12 g-font-weight-600">
                                        {" "}
                                        Reviews
                                    </span>
                                    <a
                                        className="ml-4 product-detail-review g-font-size-12 g-font-weight-600 g-color-gray"
                                        href="#rating"
                                    >
                                        <img
                                            src="/assets/images/product_detail/comment.svg"
                                            className="mr-2"
                                            alt="comment for the product"
                                        />
                                        ASK A QUESTION
                                    </a>
                                </div>
                                {/* end review */}

                                {/* <!-- Price --> */}
                                <div className="g-mb-30">
                                    <div className="d-flex">
                                        <span className="g-color-black g-font-weight-500 g-font-size-30 mr-2">
                                            ${productDynamicAttributes.price}
                                        </span>
                                        {/* <s className="g-color-gray-dark-v4 g-font-weight-500 g-font-size-16 d-flex align-items-center">$101.00</s> */}
                                        {selectedVarient &&
                                        productDynamicAttributes.stock < 5 ? (
                                            <p
                                                className="d-flex align-items-center  g-font-size-14"
                                                style={{
                                                    margin: "10px 20px",
                                                    color: "#D94949"
                                                }}
                                            >
                                                {" "}
                                                Stock Level Low{" "}
                                            </p>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                                {/* <!-- End Price --> */}
                                <div className="g-mb-10">
                                    {!selectedVarient && (
                                        <p className="text-danger g-font-size-14">
                                            Product Varient Not Available{" "}
                                        </p>
                                    )}
                                </div>

                                {/* Size Block */}
                                {attributes.size.length > 0 && item?.size && (
                                    <div
                                        className="d-flex align-items-center g-brd-bottom g-brd-gray-light-v3 py-4 g-brd-bottom"
                                        role="tab"
                                    >
                                        <h5 className="g-color-gray-dark-v5 g-font-weight-400 g-font-size-16 g-mr-60">
                                            Size
                                        </h5>

                                        {attributes.size.map((s) => (
                                            <label
                                                className="form-check-inline u-check mb-0  g-mr-30"
                                                key={"attr_size_" + s}
                                                onClick={() =>
                                                    sizeClickHandler(s)
                                                }
                                            >
                                                <span
                                                    className={
                                                        "d-block g-font-size-18 product-size g-font-weight-500 " +
                                                        (selectedAttributes.size ===
                                                        s
                                                            ? "g-color-primary"
                                                            : "g-color-gray-dark-v5")
                                                    }
                                                >
                                                    {s}
                                                </span>
                                            </label>
                                        ))}
                                    </div>
                                )}
                                {/* End : Size Block  */}

                                {/* Grind Block  */}
                                {attributes.grind.length > 0 && item?.grind && (
                                    <div
                                        className="d-flex align-items-center g-brd-bottom g-brd-gray-light-v3 py-4 g-brd-bottom"
                                        role="tab"
                                    >
                                        <h5 className="g-color-gray-dark-v5 g-font-weight-400 g-font-size-16 g-mr-60">
                                            Grind
                                        </h5>

                                        {attributes.grind.map((g) => (
                                            <label
                                                className="form-check-inline u-check mb-0  g-mr-30"
                                                key={"attr_grind_" + g}
                                                onClick={() =>
                                                    grindClickHandler(g)
                                                }
                                            >
                                                {/* <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="radInline2_1" type="radio" /> */}
                                                <span
                                                    className={
                                                        "d-block g-font-size-18 product-size g-font-weight-500 " +
                                                        (selectedAttributes.grind ===
                                                        g
                                                            ? "g-color-primary"
                                                            : "g-color-gray-dark-v5")
                                                    }
                                                >
                                                    {g}
                                                </span>
                                            </label>
                                        ))}
                                    </div>
                                )}

                                {/* <!-- Quantity --> */}
                                <div
                                    className="d-flex align-items-center g-brd-bottom g-brd-gray-light-v3 py-4 g-mb-30"
                                    role="tab"
                                >
                                    <h5 className="g-color-gray-dark-v5 g-font-weight-400 g-font-size-16 mb-0 g-mr-60">
                                        Quantity
                                    </h5>
                                    <QuantityChange
                                        quantity={quantity}
                                        setQuantity={setQuantity}
                                    />
                                </div>
                                {/* <!-- End Quantity --> */}

                                {/* <!-- Buttons --> */}
                                <div className="row g-mx-minus-5 g-mb-20">
                                    <div className="col-5 g-px-5 g-mb-10 mt-2">
                                        <button
                                            className="btn btn-block u-btn-primary g-font-size-14 text-uppercase g-py-15 g-px-15"
                                            type="button"
                                            disabled={!selectedVarient}
                                            onClick={(e) =>
                                                addToCart(
                                                    item.product_id,
                                                    quantity,
                                                    dispatch,
                                                    selectedVarient,
                                                    true
                                                )
                                            }
                                        >
                                            Add to Cart{" "}
                                            <i className="align-middle ml-4 icon-finance-100 u-line-icon-pro"></i>
                                        </button>
                                    </div>
                                    {/* <div className="col g-px-5 g-mb-10">
									<button className="btn btn-block u-btn-outline-black g-brd-gray-dark-v5 g-brd-black--hover g-color-gray-dark-v4 g-color-white--hover g-font-size-14 text-uppercase g-py-15 g-px-15" type="button">
										Add to Waitlist <i className="align-middle ml-2 icon-medical-022 u-line-icon-pro"></i>
									</button>
								</div> */}
                                </div>
                                {/* <!-- End Buttons --> */}
                            </div>
                        </div>
                        {/* End of Description Part - Right side of Slider  */}
                    </div>
                </Animate>
                {/* End of -- Top Product Info Part with Slider  */}

                <ProductDetailCategory data={item.chars} />

                {/* <!-- Description & Review --> */}
                {slug !== "raindrop-20-nebulizing-diffuser" ? (
                    <>
                        {" "}
                        <Animate>
                            <div class="container">
                                <div class="g-brd-gray-light-v3 g-py-100">
                                    {/* <!-- Nav tabs --> */}
                                    <ul
                                        class="nav g-max-width-200 text-center mx-auto"
                                        role="tablist"
                                        data-target="nav-1-2-accordion-default-hor-left-underline"
                                        data-tabs-mobile-type="accordion"
                                        data-btn-classes="btn btn-md btn-block rounded-0 u-btn-outline-primary g-mb-20"
                                    >
                                        <li class="nav-item text-center mx-auto g-pb-10">
                                            <a
                                                class="nav-link active g-brd-around g-brd-primary--active g-color-text g-color-primary--parent-active g-font-size-16 rounded"
                                                data-toggle="tab"
                                                href="#nav-1-2-accordion-default-hor-left-underline--1"
                                                role="tab"
                                            >
                                                Story
                                            </a>
                                        </li>
                                        <li class="nav-item text-center mx-auto g-pb-10">
                                            <a
                                                class="nav-link g-brd-around g-brd-primary--active g-color-text g-color-primary--parent-active g-font-size-16 rounded"
                                                data-toggle="tab"
                                                href="#nav-1-2-accordion-default-hor-left-underline--2"
                                                role="tab"
                                            >
                                                Production
                                            </a>
                                        </li>
                                    </ul>
                                    {/* <!-- End Nav tabs --> */}

                                    {/* <!-- Tab panes --> */}
                                    <div
                                        id="nav-1-2-accordion-default-hor-left-underline"
                                        class="tab-content"
                                    >
                                        <div
                                            class="tab-pane fade show active g-pt-50"
                                            id="nav-1-2-accordion-default-hor-left-underline--1"
                                            role="tabpanel"
                                        >
                                            <div class="row">
                                                <div class="col-md-6 g-mb-30">
                                                    <div className="product-detail-story g-mb-20">
                                                        <h2 class="h4 mb-3 g-font-size-25">
                                                            THE STORY
                                                        </h2>
                                                        {HtmlParser(
                                                            item.the_story
                                                        )}
                                                    </div>
                                                    <div className="product-detail-story">
                                                        <h2 class="h4 mb-3 g-font-size-25">
                                                            TRADITIONAL USE
                                                        </h2>
                                                        {HtmlParser(
                                                            item.traditional_use
                                                        )}
                                                    </div>
                                                </div>
                                                <div class="col-md-6 g-mb-30">
                                                    {/* <img src="/assets/removable_images/map.png" alt="Product Origin Map" className="w-100" /> */}
                                                    <ProductMap
                                                        geoJsonData={
                                                            geoJsonData
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            class="tab-pane fade g-pt-50"
                                            id="nav-1-2-accordion-default-hor-left-underline--2"
                                            role="tabpanel"
                                        >
                                            <div class="row">
                                                <div class="col-md-6 g-mb-30">
                                                    <div className="product-detail-story g-mb-50">
                                                        <h2 class="h4 mb-3 g-font-size-25">
                                                            ABOUT THE PRODUCER
                                                        </h2>
                                                        {HtmlParser(
                                                            item.about_producer
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    class="col-md-6 g-mb-30"
                                                    style={{
                                                        backgroundImage: `url('${get_optimized_image_url(
                                                            item.slider_image,
                                                            "horizontal"
                                                        )}')`,
                                                        backgroundPosition:
                                                            "50% 50%",
                                                        backgroundSize: "cover"
                                                    }}
                                                >
                                                    {/* <img src={ get_optimized_image_url( item.slider_image )} alt="Product Origin Map" className="w-100" /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- End Tab panes --> */}
                                </div>
                            </div>
                        </Animate>
                        <Animate>
                            {/* <!-- End Description & Review --> */}
                            {/* Altitude | Processing | Nearest City Section  */}
                            <div className="row g-pb-100 text-center align-items-center">
                                <div className="col g-mt-15 product_producer_detail_column">
                                    <img
                                        src="/assets/images/product_detail/producers.svg"
                                        className="mb-2"
                                        alt=""
                                    />
                                    <h5 className="mt-2 text-capitalize">
                                        Producer
                                    </h5>
                                    {item.producer}
                                </div>
                                <div className="col g-brd-right g-brd-left g-brd-gray-light-v3 g-mt-15 product_producer_detail_column">
                                    <img
                                        src="/assets/images/product_detail/total production.svg"
                                        className="mb-2"
                                        alt=""
                                    />
                                    <h5 className="mt-2 text-capitalize">
                                        Total Available
                                    </h5>
                                    {item.total_available_quantity}
                                </div>
                                <div className="col g-brd-right g-brd-gray-light-v3 g-mt-15 product_producer_detail_column">
                                    <img
                                        src="/assets/images/product_detail/characteristics.svg"
                                        className="mb-2"
                                        alt=""
                                    />
                                    <h5 className="mt-2 text-capitalize">
                                        Characteristics
                                    </h5>
                                    {/* {item.characteristics.join(" | ")} */}
                                </div>
                                <div className="col g-brd-right g-brd-gray-light-v3 g-mt-15 product_producer_detail_column">
                                    <img
                                        src="/assets/images/product_detail/altitude.svg"
                                        className="mb-2"
                                        alt=""
                                    />
                                    <h5 className="mt-2 text-capitalize">
                                        Altitude
                                    </h5>
                                    {item.altitude}
                                </div>
                                <div className="col g-mt-15 product_producer_detail_column">
                                    <img
                                        src="/assets/images/product_detail/currentprojects.svg"
                                        className="mb-2"
                                        alt=""
                                    />
                                    <h5 className="mt-2 text-capitalize">
                                        current projects
                                    </h5>
                                    {item.current_projects.join(" | ")}
                                </div>

                                {/* this is the commented section for harvest date section i didnt commented current project because api was already integrated */}

                                {/* <div className="col g-mt-15 product_producer_detail_column">
                        <img
                            src="/assets/images/product_detail/currentprojects.svg"
                            className="mb-2"
                            alt=""
                        />
                        <h5 className="mt-2">current_projects</h5>
                        {item.current_projects.join(" | ")}
                    </div> */}
                            </div>
                        </Animate>
                        {/* End of -- Altitude | Processing | Nearest City Section  */}
                    </>
                ) : (
                    ""
                )}
            </ContainerWithGap>
            {/* <!-- Section Content --> */}
            {slug !== "raindrop-20-nebulizing-diffuser" ? (
                <>
                    <Animate>
                        <section
                            id="services"
                            className="u-bg-overlay g-bg-img-hero g-bg-black-opacity-0_6--after g-py-85"
                            style={{
                                backgroundImage: `url('${item.cover_photo}')`
                            }}
                        >
                            <div className="homni-container u-bg-overlay__inner">
                                <div className="row">
                                    <div className="col-12 col-lg-6 d-flex align-items-center g-pr-30--md">
                                        <div className="product-pricing-story">
                                            <h2 class="mb-3">
                                                TRANSPARENT PRICING
                                            </h2>
                                            <p>
                                                The Homni team believes that
                                                radical transparency is critical
                                                in the global movement towards
                                                sustainable production. You
                                                deserve to understand how your
                                                money is used, no matter who you
                                                buy from. Though our team can't
                                                cleanly account for all costs
                                                insurance, art drives, lab
                                                testing, licensing, and
                                                e-commerce fees -we will do our
                                                best to communicate where your
                                                money goes.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 g-pl-30--md product-pricing-detail">
                                        <div className="row g-brd-bottom g-brd-gray-light-v4  text-center">
                                            <div
                                                className="col-4 mx-auto g-py-15 g-brd-right--md"
                                                style={{ color: "#fff" }}
                                            >
                                                <img
                                                    src="/assets/images/product_detail/labour.svg"
                                                    className="mb-2"
                                                    alt=""
                                                />
                                                <h5 style={{ color: "#fff" }}>
                                                    Farm Production
                                                </h5>
                                                $5.29
                                            </div>
                                            <div
                                                className="col-4 mx-auto g-py-15 g-brd-right--md"
                                                style={{ color: "#fff" }}
                                            >
                                                <img
                                                    src="/assets/images/product_detail/hardware.svg"
                                                    className="mb-2"
                                                    alt=""
                                                />
                                                <h5 style={{ color: "#fff" }}>
                                                    Post-farm Processing
                                                </h5>
                                                $7.69
                                            </div>
                                            <div
                                                className="col-4 mx-auto g-py-15"
                                                style={{ color: "#fff" }}
                                            >
                                                <img
                                                    src="/assets/images/product_detail/material.svg"
                                                    className="mb-2"
                                                    alt=""
                                                />
                                                <h5 style={{ color: "#fff" }}>
                                                    Packaging & fulfilment
                                                </h5>
                                                $4.47
                                            </div>
                                        </div>
                                        <div className="row g-brd-bottom g-brd-gray-light-v4  text-center">
                                            <div
                                                className="col-6 mx-auto g-py-15 g-brd-right--md"
                                                style={{ color: "#fff" }}
                                            >
                                                <img
                                                    src="/assets/images/product_detail/duties.svg"
                                                    className="mb-2"
                                                    alt=""
                                                />
                                                <h5 style={{ color: "#fff" }}>
                                                    Duties
                                                </h5>
                                                $29
                                            </div>
                                            <div
                                                className="col-6 mx-auto g-py-15"
                                                style={{ color: "#fff" }}
                                            >
                                                <img
                                                    src="/assets/images/product_detail/transport.svg"
                                                    className="mb-2"
                                                    alt=""
                                                />
                                                <h5 style={{ color: "#fff" }}>
                                                    Shipping
                                                </h5>
                                                $29
                                            </div>
                                        </div>
                                        <div className="row g-brd-bottom g-brd-gray-light-v4 text-center">
                                            <p
                                                className="mt-3"
                                                style={{ color: "#fff" }}
                                            >
                                                When applicable, we always pay
                                                at least 100% above fair trade
                                                pricing
                                            </p>
                                        </div>
                                        <div className="row text-center">
                                            <p
                                                className="mt-3"
                                                style={{ color: "#fff" }}
                                            >
                                                As a mission-based for-profit,
                                                50% of profits are allocated to
                                                improve the financial, social,
                                                and environmental sustainability
                                                of our partner producers.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Animate>
                    {/* <!-- End Section Content --> */}

                    {/* Recipe Section  */}
                    <section
                        class="g-bg-secondary product_recipe_section g-pt-100"
                        id="recipe"
                    >
                        <h2 className="g-py-10 text-center">RELATED RECIPES</h2>
                        <ProductRecipe />
                    </section>
                </>
            ) : (
                ""
            )}
            {/* End -- Recipe Section  */}

            {/* Product Review Section  */}
            <ContainerWithGap>
                <Animate>
                    <ProductReview item={item} />
                </Animate>
            </ContainerWithGap>
            {/* End -- Product Review Section  */}
            {/* Recipe Section  */}
            {item.related_products.length > 0 ? (
                <section class="g-bg-secondary product_recipe_section">
                    <h2 className="g-py-60 text-center">SUGGESTED PRODUCT</h2>
                    <ContainerWithGap>
                        <div className="row">
                            {item.related_products.map(
                                (product, index) =>
                                    index < 4 && <ProductBlock item={product} />
                            )}
                        </div>
                    </ContainerWithGap>
                </section>
            ) : (
                ""
            )}
            {/* End -- Recipe Section  */}

            {/* SignUp Section  */}

            {/* End -- SignUp Section  */}
        </>
    );
};

export default ProductDetailPage;
