import CheckoutSidebar from "../../../components/cart_components/CheckoutSidebar";
import Container from "../../../components/container/Container";
import { Link } from "react-router-dom";
import AddressForm from "../../../components/checkout/AddressForm";
import { useDispatch, useSelector } from "react-redux";
import {
  setDeliveryCharge,
  setIsBillngAndShippingAddressSame,
} from "../../../helper/CartHelper";
import { useEffect, useState } from "react";

const Shipping = (props) => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const is_billing_shipping_add_same = cart.is_billing_shipping_add_same;

  const [deliveryOptions, setDeliveryOptions] = useState(null);
  const [deliveryOptLoading, setDeliveryOptionsLoading] = useState(false);
  // const [shippingCountry, setShippingCountry] = useState(cart.shipping_address.country??);

  useEffect(() => {
    setDeliveryOptionsLoading(true);
    getDeliveryOptions().then((response) => {
      setDeliveryOptionsLoading(false);
    });
  }, [cart?.shipping_address]);

  const setBillingAndShippingSame = (value) => {
    setIsBillngAndShippingAddressSame(value, cart, dispatch);
  };

  const setDeliveryChargeDeatil = (value, type) => {
    setDeliveryCharge(
      {
        shipping_type: type,
        shipping_charge: value,
      },
      dispatch
    );

    //Calculate Tax
  };

  const getDeliveryOptions = async () => {
    let deliveryOptionsUrl =
      process.env.REACT_APP_API_BASE_URL + "/shipping-detail-index/?place=";

    if (!!cart.shipping_address && !!cart.shipping_address.country) {
      deliveryOptionsUrl += cart.shipping_address.country;

      const response = await fetch(deliveryOptionsUrl);
      const result = await response.json();
      setDeliveryOptions(result.results);
    } else {
      setDeliveryOptions(null);
    }
  };

  return (
    <Container>
      <div className="row">
        <div className="col-md-8 g-mb-30">
          {/* Start Billing Address  */}
          <div
            className="alert g-brd-around g-brd-gray-dark-v5 rounded-0 g-pa-0 mb-4 bg-light"
            role="alert"
          >
            <div className="media">
              <div className="d-flex g-brd-right g-brd-gray-dark-v5 g-pa-15">
                <span className="u-icon-v1 u-icon-size--xs g-color-black">
                  <i className="align-middle icon-media-065 u-line-icon-pro"></i>
                </span>
              </div>
              <div className="media-body g-pa-12">
                <p className="g-color-black m-0">
                  Billing {is_billing_shipping_add_same ? "& Shipping" : ""}{" "}
                  Address
                </p>
              </div>
            </div>
          </div>
          <AddressForm type="billing" />
          {/* End Billing Address  */}

          <div className="alert rounded-0 g-pa-0 my-4" role="alert">
            <div className="media">
              <div className="d-flex g-brd-gray-dark-v5 g-py-15">
                {is_billing_shipping_add_same ? (
                  <input
                    type="checkbox"
                    checked="checked"
                    onChange={(e) =>
                      setBillingAndShippingSame(e.target.checked)
                    }
                  />
                ) : (
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      setBillingAndShippingSame(e.target.checked)
                    }
                  />
                )}
              </div>
              <div className="media-body g-pa-10">
                <p className="g-color-black m-0">
                  My Billing and Shipping Address is same
                </p>
              </div>
            </div>
          </div>

          <div className={is_billing_shipping_add_same ? "d-none" : ""}>
            {/* Starting shippng address  */}
            <div
              className="alert g-brd-around g-brd-gray-dark-v5 rounded-0 g-pa-0 mb-4 mt-5 bg-light"
              role="alert"
            >
              <div className="media">
                <div className="d-flex g-brd-right g-brd-gray-dark-v5 g-pa-15">
                  <span className="u-icon-v1 u-icon-size--xs g-color-black">
                    <i className="align-middle icon-media-065 u-line-icon-pro"></i>
                  </span>
                </div>
                <div className="media-body g-pa-15">
                  <p className="g-color-black m-0">Shipping Address</p>
                </div>
              </div>
            </div>
            <AddressForm type="shipping" />
            {/* End shipping Address  */}
          </div>

          <div className="mt-3">
            <strong>Delivery Options</strong>
            {deliveryOptions != null ? (
              <ul className="list-group delivery_options">
                {deliveryOptions.map((deliveryOpt) => (
                  <li className="list-group-item d-flex align-items-center">
                    {deliveryOpt.category == "Standard" ? (
                      <input
                        type={"radio"}
                        name="delivery_type"
                        defaultChecked
                        onClick={(e) =>
                          setDeliveryChargeDeatil(
                            deliveryOpt.mt_one_pound,
                            deliveryOpt.category
                          )
                        }
                      />
                    ) : (
                      <input
                        type={"radio"}
                        name="delivery_type"
                        onClick={(e) =>
                          setDeliveryChargeDeatil(
                            deliveryOpt.mt_one_pound,
                            deliveryOpt.category
                          )
                        }
                      />
                    )}
                    <span className="ml-3">
                      {deliveryOpt.category} : ${deliveryOpt.mt_one_pound}{" "}
                    </span>
                  </li>
                ))}
              </ul>
            ) : (
              <p>
                <strong>**Please fill valid shipping address first.</strong>
              </p>
            )}
          </div>

          {/* <p className="g-mt-150">Already have account? <span className="text-success">Login</span></p> */}
          <hr className="g-mt-10 g-mb-5" />
          <div className="d-flex g-font-size-13">
            <Link className="text-secondary" to="#">
              Refund policy
            </Link>
            <Link className="text-secondary g-ml-20" to="#">
              Terms of Service
            </Link>
          </div>
        </div>

        <CheckoutSidebar {...props} />
      </div>
    </Container>
  );
};

export default Shipping;
