import { useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { authUser } from "../../features/authentication/userSlice";
import Loading from "../Loading";
import ReactPaginate from "react-paginate";
import { get_date_format } from "../../helper/Helper";

const ProductReview = (props) => {
    const [rating, setRating] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [offset, setOffset] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [reviews, setReviews] = useState();

    const loggedInUser = useSelector(authUser);
    const item = props.item;

    const getReview = () => {
        let product_review_fetch_url =
            process.env.REACT_APP_API_BASE_URL +
            `/product-review?ordering=-id&page_size=3&page=${offset}&product__id=` +
            item.id;
        fetch(product_review_fetch_url)
            .then((response) => response.json())
            .then((result) => {
                setReviews(result.results);
                setTotalPages(result.total_pages);
                setIsLoading(false);
            });
    };
    // Url required for fetch product review for perticulat product

    useEffect(() => {
        getReview();
    }, [offset]);

    const handlePageClick = (event) => {
        const newOffset = event.selected + 1;
        setOffset(newOffset);
    };

    if (isLoading) {
        return <Loading />;
    }

    /**
     * Form Validation Rules [ Yup library used for validation ]
     */
    const validationSchema = Yup.object().shape({
        full_name: Yup.string().required("Name field is required"),
        email: Yup.string()
            .required("Email is required")
            .email("Valid email is required"),
        full_text: Yup.string().max(500),
        user_rating: Yup.number().min(1, "Rating is required")
    });

    // Url required for submit product review -- post req
    const url = process.env.REACT_APP_API_BASE_URL + "/product-review/";

    // this variable will store form submit response from api
    let global_response = "";

    const formSubmitHandler = (
        formData,
        resetForm,
        setSubmitting,
        setFieldError
    ) => {
        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: "POST",
            body: formData,
            headers: {
                "Content-Type": "application/json"
            }
        };

        if (loggedInUser.is_logged_in) {
            requestOptions.headers.Authorization =
                "Bearer " + loggedInUser.access_token;
        }

        fetch(url, requestOptions)
            .then((response) => {
                global_response = response;
                return response.json();
            })
            .then((result) => {
                if (global_response.ok) {
                    // setSuccessMessage("Review Submitted Successfully");
                    toast.success("Thank You for your review.");
                    resetForm();
                    setRating(0);
                    getReview();
                } else {
                    //if validation error
                    if (result.validation_errors) {
                        // return result.validation_errors;
                        for (const r in result.validation_errors) {
                            setFieldError(r, result.validation_errors[r][0]);
                        }
                    } else {
                        // setErrorMessage("Couldn't process request. Please try again");
                    }
                }
                setSubmitting(false);
            })
            .catch((error) => {});
    };

    const ratingChanged = (newRating, setFieldValue) => {
        setRating(newRating);
        setFieldValue("user_rating", newRating);
    };

    return (
        <div className="g-brd-gray-light-v4 g-py-100">
            <div className="row justify-content-center">
                <div className="col-lg-9">
                    {reviews.length > 0 && <h2 className="h4 mb-5">Reviews</h2>}
                    {/* <!-- Review --> */}
                    <div className="g-brd-bottom g-brd-gray-light-v4 g-pb-30 g-mb-50">
                        {reviews.length > 0
                            ? reviews.map((review) => (
                                  <div className="media g-mb-30">
                                      {/* <img className="d-flex g-width-60 rounded-circle g-mt-3 g-mr-20" src="/assets/images/placeholder_images/user-icon.png" alt="Description" /> */}
                                      <div className="media-body">
                                          <div className="d-flex align-items-start g-mb-15 g-mb-10--sm">
                                              <div className="d-block">
                                                  <h5 className="h6">
                                                      {review.full_name}
                                                  </h5>
                                                  <Rating
                                                      ratingValue={
                                                          (review.user_rating /
                                                              100) *
                                                          5
                                                      }
                                                      size={22}
                                                      label
                                                      transition
                                                      fillColor="#72c02c"
                                                      emptyColor="gray"
                                                      iconsCount={5}
                                                      key={
                                                          "rating_" + rating.id
                                                      }
                                                  />

                                                  <span className="d-block g-color-gray-dark-v5 g-font-size-11">
                                                      {get_date_format(
                                                          review.created_at
                                                      )}
                                                  </span>
                                              </div>
                                          </div>

                                          <p>{review.full_text}</p>
                                      </div>
                                  </div>
                              ))
                            : ""}
                        {totalPages > 1 && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                    marginBottom: "25px",
                                    marginTop: "25px"
                                }}
                            >
                                <ReactPaginate
                                    nextLabel="Next"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    pageCount={totalPages}
                                    previousLabel="Previous"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        )}
                    </div>
                    {/* <!-- End Review --> */}

                    <h2 className="h4 mb-5" id="rating">
                        Add Review
                    </h2>

                    <Formik
                        initialValues={{
                            full_name: "",
                            email: "",
                            full_text: "",
                            product: item.id,
                            user_rating: rating,
                            highlight_text: null
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(
                            values,
                            { setSubmitting, resetForm, setFieldError }
                        ) => {
                            setTimeout(() => {
                                // setSuccessMessage("");
                                // setErrorMessage("");
                                formSubmitHandler(
                                    JSON.stringify(values, null, 2),
                                    resetForm,
                                    setSubmitting,
                                    setFieldError
                                );
                            }, 400);
                        }}
                    >
                        {({ isSubmitting, setFieldValue }) => (
                            // Form Start
                            <Form className="g-py-15">
                                <Field
                                    type="hidden"
                                    name="user_rating"
                                    value={rating}
                                />
                                <Field
                                    type="hidden"
                                    name="product"
                                    value={item.id}
                                />
                                <Field
                                    type="hidden"
                                    name="highlight_text"
                                    value={null}
                                />

                                <div className="row">
                                    <div className="col-md-6 form-group g-mb-30">
                                        <Field
                                            className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--focus rounded-3 g-py-13 g-px-15"
                                            type="text"
                                            placeholder="Full Name"
                                            name="full_name"
                                        />

                                        <ErrorMessage name="full_name">
                                            {(msg) => (
                                                <div className="g-mt-10 text-danger field_error_message">
                                                    <i className="fa fa-info-circle g-mr-5"></i>
                                                    {msg}
                                                </div>
                                            )}
                                        </ErrorMessage>
                                    </div>

                                    <div className="col-md-6 form-group g-mb-30">
                                        <Field
                                            className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--focus rounded-3 g-py-13 g-px-15"
                                            type="email"
                                            placeholder="Email"
                                            name="email"
                                        />
                                        <ErrorMessage name="email">
                                            {(msg) => (
                                                <div className="g-mt-10 text-danger field_error_message">
                                                    <i className="fa fa-info-circle g-mr-5"></i>
                                                    {msg}
                                                </div>
                                            )}
                                        </ErrorMessage>
                                    </div>
                                </div>

                                {/* <!-- End Comment Form --> */}

                                <div className="g-mb-30">
                                    <Field
                                        className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--focus g-resize-none rounded-3 g-py-13 g-px-15"
                                        rows="12"
                                        placeholder="Your review"
                                        as="textarea"
                                        name="full_text"
                                    />
                                    <ErrorMessage name="full_text">
                                        {(msg) => (
                                            <div className="g-mt-10 text-danger field_error_message">
                                                <i className="fa fa-info-circle g-mr-5"></i>
                                                {msg}
                                            </div>
                                        )}
                                    </ErrorMessage>
                                </div>

                                <div className="row align-items-center">
                                    <div className="col-5 col-sm-4 col-md-3">
                                        <button
                                            className="btn u-btn-primary g-font-size-12 text-uppercase g-py-15 g-px-25"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            Add Review
                                        </button>
                                    </div>

                                    {/* <!-- Rating --> */}
                                    <div className="col-5 col-sm-4 col-md-3">
                                        <h3 className="h6 mb-1">Rate:</h3>

                                        <ul
                                            className="js-rating u-rating-v1 g-font-size-20 g-color-gray-light-v3 mb-0"
                                            data-hover-classes="g-color-primary"
                                        >
                                            <Rating
                                                onClick={(e) =>
                                                    ratingChanged(
                                                        e,
                                                        setFieldValue
                                                    )
                                                }
                                                ratingValue={rating}
                                                size={22}
                                                label
                                                transition
                                                fillColor="#72c02c"
                                                emptyColor="gray"
                                                iconsCount={5}
                                                allowHover={false}
                                            />
                                        </ul>
                                        {/* <!-- End Rating --> */}

                                        <ErrorMessage name="user_rating">
                                            {(msg) => (
                                                <div className="g-mt-10 text-danger field_error_message">
                                                    <i className="fa fa-info-circle g-mr-5"></i>
                                                    {msg}
                                                </div>
                                            )}
                                        </ErrorMessage>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};
export default ProductReview;
