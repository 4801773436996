import Cookies from "js-cookie";
import { persistor } from "../app/store";
import { logout } from "../features/authentication/userSlice";

export const signOut = (dispatch) => {
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
    dispatch(logout());
    // persistor.purge();
};

// sends otp to logged in user
export const sendOtp = () => {
    const otp_resend_url =
        process.env.REACT_APP_API_BASE_URL + "/accounts/resend-code";

    fetch(otp_resend_url, {
        // method : "get",
        headers: {
            Authorization: "Bearer " + Cookies.get("access_token"),
            "Content-Type": "application/json"
        }
    })
        .then((response) => response.json())
        .then((result) => {
            // After successful response - show success message
        })
        .catch((error) => {});
};
