import ContainerWithGap from "../../components/container/ContainerWithGap";
import ProfileNavSidebar from "../../components/profile_page/ProfileNavSidebar";
import { Link } from "react-router-dom";

const AddressessPage = (props) => {
    return (
        // < !--Addresses -- >
        <ContainerWithGap>
            <div className="row">
                {/* <!-- Profile Settings --> */}
                <ProfileNavSidebar active='addressess'></ProfileNavSidebar>
                {/* <!-- End Profile Settings --> */}

                <div class="col-lg-9 g-mb-50 g-mt-50">
                    <div class="row">
                        <div class="col-md-6 g-mb-30">
                            {/* <!-- Addresses --> */}
                            <div class="g-brd-around g-brd-gray-light-v4 rounded g-pa-30">
                                <div class="g-mb-50">
                                    <h3 class="h5 mb-3">Address 1
                                        <label class="u-ribbon-v1 g-color-white g-bg-primary rounded ml-3">Default</label>
                                    </h3>
                                    <span class="d-block g-color-gray-dark-v3 g-font-weight-600 mb-2">James Collins</span>

                                    {/* <!-- Address --> */}
                                    <address class="media">
                                        <div class="d-flex mr-3">
                                            <span class="u-icon-v1 g-color-gray-dark-v4"><i class="icon-real-estate-027 u-line-icon-pro"></i></span>
                                        </div>

                                        <div class="media-body g-color-text">
                                            9455 Ne Alderwood Rd
                                            <br />
                                            C/O Uzw-Fh Portland Oregon 97252
                                            <br />
                                            Portland, Or 97252-1777
                                            <br />
                                            United States
                                            <br />
                                        </div>
                                    </address>
                                    {/* <!--End Address--> */}

                                    {/* <!--Phone--> */}
                                    <div class="media">
                                        <div class="d-flex mr-3">
                                            <span class="u-icon-v1 g-color-gray-dark-v4"><i class="icon-electronics-005 u-line-icon-pro"></i></span>
                                        </div>
                                        <div class="media-body g-color-text">
                                            +(01) 503­-914­-6317
                                        </div>
                                    </div>
                                    {/* <!--End Phone--> */}
                                </div>

                                {/* <!--Edit/Delete--> */}
                                <ul class="d-flex align-items-center list-inline mb-0">
                                    <li class="list-inline-item">
                                        <Link class="u-icon-v1 g-font-size-16 g-text-underline--none--hover" to="#" title="Edit"
                                            data-toggle="tooltip"
                                            data-placement="top">
                                            <i class="icon-finance-023 u-line-icon-pro"></i>
                                        </Link>
                                    </li>
                                    <li class="list-inline-item g-width-1 g-height-16 g-bg-gray-light-v2 g-pr-1 ml-2 mr-3"></li>
                                    <li class="list-inline-item">
                                        <Link class="u-icon-v1 g-font-size-16 g-text-underline--none--hover" to="#" title="Delete"
                                            data-toggle="tooltip"
                                            data-placement="top">
                                            <i class="icon-hotel-restaurant-214 u-line-icon-pro"></i>
                                        </Link>
                                    </li>
                                </ul>
                                {/* <!--End Edit/Delete--> */}
                            </div>
                            {/* <!--End Addresses--> */}
                        </div>

                        <div class="col-md-6 g-mb-30">
                            {/* <!-- Addresses --> */}
                            <div class="g-brd-around g-brd-gray-light-v4 rounded g-pa-30">
                                <div class="g-mb-50">
                                    <h3 class="h5 mb-3">Address 2</h3>
                                    <span class="d-block g-color-gray-dark-v3 g-font-weight-600 mb-2">James Collins</span>

                                    {/* <!-- Address --> */}
                                    <address class="media">
                                        <div class="d-flex mr-3">
                                            <span class="u-icon-v1 g-color-gray-dark-v4"><i class="icon-real-estate-027 u-line-icon-pro"></i></span>
                                        </div>

                                        <div class="media-body g-color-text">
                                            2091 Parkson
                                            <br />
                                            New York Avenue 2309
                                            <br />
                                            New York, Or 2309-1777
                                            <br />
                                            United States
                                            <br />
                                        </div>
                                    </address>
                                    {/* <!--End Address--> */}

                                    {/* <!--Phone--> */}
                                    <div class="media">
                                        <div class="d-flex mr-3">
                                            <span class="u-icon-v1 g-color-gray-dark-v4"><i class="icon-electronics-005 u-line-icon-pro"></i></span>
                                        </div>
                                        <div class="media-body g-color-text">
                                            +(01) 401-542-2900
                                        </div>
                                    </div>
                                    {/* <!--End Phone--> */}
                                </div>

                                {/* <!--Edit/Delete--> */}
                                <ul class="d-flex align-items-center list-inline mb-0">
                                    <li class="list-inline-item">
                                        <Link class="u-icon-v1 g-font-size-16 g-text-underline--none--hover" to="#" title="Edit"
                                            data-toggle="tooltip"
                                            data-placement="top">
                                            <i class="icon-finance-023 u-line-icon-pro"></i>
                                        </Link>
                                    </li>
                                    <li class="list-inline-item g-width-1 g-height-16 g-bg-gray-light-v2 g-pr-1 ml-2 mr-3"></li>
                                    <li class="list-inline-item">
                                        <Link class="u-icon-v1 g-font-size-16 g-text-underline--none--hover" to="#" title="Delete"
                                            data-toggle="tooltip"
                                            data-placement="top">
                                            <i class="icon-hotel-restaurant-214 u-line-icon-pro"></i>
                                        </Link>
                                    </li>
                                    <li class="list-inline-item g-width-1 g-height-16 g-bg-gray-light-v2 g-pr-1 ml-2 mr-3"></li>
                                    <li class="list-inline-item">
                                        <label class="form-check-inline u-check d-block u-link-v5 g-color-text g-pl-30 mb-0">
                                            <input class="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" type="checkbox" />
                                            <span class="d-block u-check-icon-checkbox-v4 g-absolute-centered--y g-left-0">
                                                <i class="fa" data-check-icon="&#xf00c"></i>
                                            </span>
                                            Set as Default
                                        </label>
                                    </li>
                                </ul>
                                {/* <!--End Edit/Delete--> */}
                            </div>
                            {/* <!--End Addresses--> */}
                        </div>
                    </div>

                    {/* <!-- Contact Form --> */}
                    <div class="g-brd-around g-brd-gray-light-v4 rounded g-pa-30 g-mb-30">
                        <h3 class="h5 mb-3">Add Address</h3>

                        <form>
                            <div class="row">
                                <div class="col-sm-6 form-group g-mb-20">
                                    <label class="g-color-text g-font-size-13">Country</label>
                                    <input class="form-control g-brd-gray-light-v4 g-brd-primary--focus g-color-text rounded g-py-13 g-px-15" type="text" placeholder="Country" />
                                </div>

                                <div class="col-sm-6 form-group g-mb-20">
                                    <label class="g-color-text g-font-size-13">City</label>
                                    <input class="form-control g-brd-gray-light-v4 g-brd-primary--focus g-color-text rounded g-py-13 g-px-15" type="text" placeholder="City" />
                                </div>

                                <div class="col-sm-6 form-group g-mb-20">
                                    <label class="g-color-text g-font-size-13">Zip Code</label>
                                    <input class="form-control g-brd-gray-light-v4 g-brd-primary--focus g-color-text rounded g-py-13 g-px-15" type="text" placeholder="Zip Code" />
                                </div>

                                <div class="col-sm-6 form-group g-mb-20">
                                    <label class="g-color-text g-font-size-13">Address</label>
                                    <input class="form-control g-brd-gray-light-v4 g-brd-primary--focus g-color-text rounded g-py-13 g-px-15" type="text" placeholder="Address" />
                                </div>
                            </div>
                        </form>
                    </div>
                    {/* <!-- End Contact Form --> */}

                    <button class="btn u-btn-primary g-font-size-12 text-uppercase g-py-12 g-px-25" type="submit">Add Address</button>
                </div>
                {/* <!-- Addresses --> */}
            </div>
        </ContainerWithGap >
    )
}
export default AddressessPage;