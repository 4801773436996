import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
const baseURL = `${process.env.REACT_APP_API_SERVER_URL}`;

const initialState = {
    loading: false,
    data: []
};

export const getInitialCartData = createAsyncThunk(
    "cartData/getInitialCartData",
    async (thunkAPI) => {
        const res = fetch(
            `${process.env.REACT_APP_API_BASE_URL}/cart/get_detail`,
            {
                metgod: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: "Bearer " + Cookies.get("access_token")
                }
            }
        ).then((response) => response.json());

        return res;
    }
);

export const cartDataSlice = createSlice({
    name: "cartData",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getInitialCartData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getInitialCartData.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(getInitialCartData.rejected, (state) => {
            state.loading = false;
        });
    }
});

export const cartDataSelector = (state) => state.cartData;

export default cartDataSlice.reducer;
