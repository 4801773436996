import { useEffect, useState } from "react";
import Slider from "react-slick";
import FullWidthImageBanner from "./FullWidthImageBannner";
import { NextArrow, PrevArrow } from "./slider/CustomArrow";
import Loading from "./Loading";
// Banner Slider 
const BannerSliderRev = (props) => {

	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState();

	const slider_products_url = process.env.REACT_APP_API_BASE_URL + '/product-collection/slider';

	useEffect(() => {
		fetch(slider_products_url)
			.then(response => response.json())
			.then(result => {
				setLoading(false);
				setItems(result.products);
			});

	}, [])

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoPlay: true,
		arrows: true,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,

	};

	if (loading) {
		return <Loading />;
	}

	return (
		<>
			<Slider {...settings}>
				{items.map(item =>
					<div style={{"height":"100vh"}}>
						<FullWidthImageBanner item={item} />
					</div>
				)}

			</Slider>
		</>
	);

};

export default BannerSliderRev;