// jshint esversion: 6

import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { authUser } from "../features/authentication/userSlice";
import { sendOtp } from "../services/AuthServices";

const PrivateRoute = ({element}) => {
	const loggedInUser = useSelector(authUser);

    if (loggedInUser.is_logged_in) {
        if (!loggedInUser.is_verified) {
            sendOtp();
            return <Navigate to="/account/verify" />;
        }
        return element;
    }

    if (!loggedInUser.is_logged_in) {
        return <Navigate to="/account/login" />;
    }

    return <Navigate to="/" />;
};
export default PrivateRoute;
