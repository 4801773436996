import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { persistor } from "../../app/store";
import {
    add_to_cart_api_data,
    remove_later_items,
    save_later_api_data,
    save_later_items
} from "../../features/cartSlice";
import { addToCart, removeFromCart } from "../../helper/CartHelper";
import { format_price, get_optimized_image_url } from "../../helper/Helper";
import { addToSaveLater, removeSaveLater } from "../../helper/SaveLaterHelper";
import QuantityChange from "../cart_components/QuantityChange";

const HeaderCart = (props) => {
    const [sidecartActive, setSidecartActive] = useState(false);
    const [IsSaveForLaterClicked, setIsSaveForLaterClicked] = useState(false);
    const cart = useSelector((state) => state.cart);
    const { data: cartApiData } = useSelector((state) => state.cartData);
    const { data: saveLaterApiData } = useSelector(
        (state) => state.saveLaterData
    );
    const cartItems = cart.cart_items;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (
            !Object.keys(cartApiData).length > 0 &&
            !saveLaterApiData.length > 0
        )
            return;
        persistor.purge();
        const dataKeys = Object.keys(cartApiData);
        const dataValues = Object.values(cartApiData);
        const cartData = dataValues.map((item, index) => ({
            ...item,
            product_id: Number(dataKeys[index]),
            qty: item.quantity
        }));

        const saveLaterData = saveLaterApiData.map((item) => ({
            product_id: item.id,
            title: item.name,
            slug: item.slug,
            qty: 1,
            image: item.main_image,
            price: item.our_price,
            is_variant: item.is_variant
        }));
        dispatch(add_to_cart_api_data(cartData));
        dispatch(save_later_api_data(saveLaterData));
    }, [cartApiData, saveLaterApiData]);

    const openWaitlist = () => {
        setIsSaveForLaterClicked(!IsSaveForLaterClicked);
    };

    const toggleSideCart = () => {
        if (sidecartActive) {
            setSidecartActive(false);
        } else {
            setSidecartActive(true);
        }
    };

    const redirectToCart = () => {
        setSidecartActive(false);
        navigate("/cart");
    };
    const handleSaveForLater = (item) => {
        dispatch(save_later_items(item));
    };

    let total_cart_items = 0;

    cartItems?.map((item) => {
        total_cart_items += item.qty;
    });

    const checkToken = Cookies.get("access_token");
    return (
        <>
            {/* <!-- Basket --> */}
            <div className="u-basket g-ml-15">
                <Link
                    to="#"
                    id="basket-bar-invoker"
                    className="u-icon-v1 g-color-white g-text-underline--none--hover g-width-20 g-height-20"
                    aria-controls="basket-bar"
                    aria-haspopup="true"
                    aria-expanded="false"
                    data-dropdown-event="hover"
                    data-dropdown-target="#basket-bar"
                    data-dropdown-type="css-animation"
                    data-dropdown-duration="500"
                    data-dropdown-hide-on-scroll="false"
                    data-dropdown-animation-in="fadeIn"
                    data-dropdown-animation-out="fadeOut"
                    onClick={toggleSideCart}
                >
                    {cartItems.length > 0 ? (
                        <span className="u-badge-v1--sm g-color-white g-bg-tag g-rounded-50x">
                            {total_cart_items}
                        </span>
                    ) : (
                        ""
                    )}
                    {/* <i className="icon-basket"></i> */}
                    <img
                        className="img-fluid"
                        src="/assets/images/header/cart.svg"
                        alt="description"
                    />
                </Link>
            </div>
            {/* <!-- End Basket --> */}

            <div
                className={
                    "sidecart_overlay " + (sidecartActive ? "active" : "")
                }
                onClick={toggleSideCart}
            />
            <div className={"hmn_sidecart " + (sidecartActive ? "active" : "")}>
                <div className="sidecart_header text-center py-3 border-bottom shadow">
                    <p className="g-font-size-14 text-dark g-font-weight-600">
                        Subtotal :{" "}
                        <span className="text-success">
                            $ {format_price(cart.total_amt)}
                        </span>
                    </p>
                    <button
                        className="btn btn-success"
                        onClick={redirectToCart}
                    >
                        Proceed to checkout
                    </button>
                </div>
                <div
                    className={
                        IsSaveForLaterClicked
                            ? "sidecart_body save-cart_visible"
                            : "sidecart_body"
                    }
                >
                    {cartItems.map((item, index) => (
                        <div
                            className="sidecart_item col-12 mt-4"
                            key={"side_cart_item_" + index}
                        >
                            <div className="row">
                                <div className="col-4">
                                    <img
                                        src={get_optimized_image_url(
                                            item.image
                                        )}
                                        className="w-100"
                                        alt={item.title}
                                    />
                                </div>
                                <div className="col-8">
                                    <p className="g-mb-5">
                                        <Link
                                            to={"/products/" + item.slug}
                                            className="g-color-black"
                                        >
                                            <strong className="g-font-size-16 g-font-weight-500">
                                                {item.title}
                                            </strong>
                                        </Link>
                                        <br />
                                        <span className="text-success">
                                            ${format_price(item.price)}
                                        </span>
                                    </p>
                                    <div className="d-flex sidecart_size_dropdown">
                                        {/* <div class="shortcode-html">
												<div class="btn-group g-brd-primary u-btn-outline-primary g-mr-10 g-mb-5 g-brd-primary g-brd-primary-light-v1">
													<button type="button" className="btn g-pa-0">Size</button>
													<button type="button" className="btn g-pa-0 dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<span className="sr-only">Toggle Dropdown</span>
													</button>
													<div className="dropdown-menu size-dropdown-position">
														<p className="dropdown-item g-mb-0" href="#">Small</p>
														<p className="dropdown-item g-mb-0" href="#">Medium</p>
														<p className="dropdown-item g-mb-0" href="#">Large</p>
													</div>
												</div>
											</div> */}
                                        <QuantityChange item={item} />
                                    </div>
                                    <div className="side_cart_cart_item_action ">
                                        <button
                                            className="pr-2 border-right cart_action_btn"
                                            onClick={() =>
                                                removeFromCart(
                                                    item.product_id,
                                                    dispatch,
                                                    item.is_variant
                                                )
                                            }
                                        >
                                            Delete
                                        </button>
                                        <button
                                            className="ml-2 cart_action_btn"
                                            onClick={() =>
                                                addToSaveLater(item, dispatch)
                                            }
                                        >
                                            Save for Later
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div
                    className={
                        IsSaveForLaterClicked
                            ? "sidecart_save_for_later save_cart"
                            : "sidecart_save_for_later"
                    }
                    // onClick={openWaitlist}
                >
                    {checkToken && (
                        <>
                            <div
                                className="save_for_later_wrapper d-flex justify-content-between g-py-8 g-px-15 align-items-center"
                                style={{
                                    position: "sticky",
                                    top: 0,
                                    zIndex: 999
                                }}
                            >
                                <p className="g-color-white g-mb-0">
                                    Saved For Later(
                                    {cart?.save_for_later_items &&
                                        cart.save_for_later_items?.length > 0 &&
                                        cart.save_for_later_items?.length}
                                    )
                                </p>
                                <button
                                    className="btn g-color-white"
                                    onClick={openWaitlist}
                                >
                                    {IsSaveForLaterClicked ? "-" : "+"}
                                </button>
                            </div>
                            {cart.save_for_later_items?.length > 0 && (
                                <>
                                    {cart?.save_for_later_items?.map((item) => (
                                        <div className="sidecart_item_wrapper">
                                            <div className="sidecart_item col-12 mt-4">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <img
                                                            class="img-fluid"
                                                            src={get_optimized_image_url(
                                                                item.image
                                                            )}
                                                            alt=" description"
                                                        />
                                                    </div>
                                                    <div className="col-8">
                                                        <p>
                                                            <strong className="g-font-size-16 g-font-weight-500">
                                                                {item.title}
                                                            </strong>
                                                            <br />
                                                            <span className="text-success">
                                                                {item.price}
                                                            </span>
                                                        </p>

                                                        <div className="side_cart_cart_item_action ">
                                                            <button
                                                                className="pr-2 border-right cart_action_btn"
                                                                onClick={() =>
                                                                    removeSaveLater(
                                                                        item,
                                                                        dispatch
                                                                    )
                                                                }
                                                            >
                                                                Remove
                                                            </button>
                                                            <button
                                                                className="ml-2 cart_action_btn"
                                                                onClick={() => {
                                                                    addToCart(
                                                                        item.product_id,
                                                                        1,
                                                                        dispatch,
                                                                        {},
                                                                        false,
                                                                        item.is_variant,
                                                                        null
                                                                    );
                                                                    removeSaveLater(
                                                                        item,
                                                                        dispatch
                                                                    );
                                                                }}
                                                            >
                                                                Add to Cart
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default HeaderCart;
