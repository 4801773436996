import ContainerWithGap from "../../components/container/ContainerWithGap";
import ProfileNavSidebar from "../../components/profile_page/ProfileNavSidebar";
import WaitlistItem from "../../components/profile_page/WaitlistItem";
import { useSelector } from "react-redux";

const WaitList = (props) => {
    const cart = useSelector((state) => state.cart);

    const { save_for_later_items } = cart;

    return (
        <ContainerWithGap>
            <div className="row">
                {/* <!-- Profile Settings --> */}
                <ProfileNavSidebar active="waitlist"></ProfileNavSidebar>
                {/* <!-- End Profile Settings --> */}

                {/* <!-- Payment Options --> */}
                <div className="col-lg-9 g-mb-50 g-mt-50">
                    {/* <!-- Products Block --> */}
                    <div className="rounded g-brd-around g-brd-gray-light-v4 g-overflow-x-scroll g-overflow-x-visible--lg g-pa-30">
                        <table className="text-center w-100">
                            <thead className="h6 g-brd-bottom g-brd-gray-light-v4 g-color-black text-uppercase">
                                <tr>
                                    <th className="g-font-weight-500 text-left g-pb-20">
                                        Product
                                    </th>
                                    <th className="g-font-weight-500 g-width-130 g-pb-20">
                                        Price
                                    </th>

                                    <th className="g-font-weight-500 g-width-130 g-pb-20">
                                        Total
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                {/* <!-- Item--> */}
                                {save_for_later_items.map((item) => (
                                    <WaitlistItem
                                        item={item}
                                        key={item.product_id}
                                    />
                                ))}

                                {/* <CartItem></CartItem>
                                <CartItem></CartItem>
                                <CartItem></CartItem> */}
                                {/* <!-- End Item --> */}
                            </tbody>
                        </table>
                    </div>
                    {/* <!-- End Products Block --> */}
                </div>
                {/* <!-- End Payment Options --> */}
            </div>
        </ContainerWithGap>
    );
};
export default WaitList;
