import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { FormControl } from "react-bootstrap";
import { toast } from "react-toastify";
import { get_optimized_image_url } from "../../helper/Helper";
import Cookies from "js-cookie";
const HeaderSearch = (props) => {
    const device = props.device;
    const [sidecartActive, setSidecartActive] = useState(false);
    const [show, setShow] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [queryName, setQueryName] = useState("");
    const [itemLoadingStatus, setItemLoadingStatus] = useState(false);

    const handleclose = () => setShow(false);

    const toggleSideCart = () => {
        if (sidecartActive) {
            setSidecartActive(false);
        } else {
            setSidecartActive(true);
        }
    };
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();

    useEffect(() => {
        const searchUrl = process.env.REACT_APP_API_BASE_URL + "/search/";
        const fetchSearchData = async (url = "", data = {}) => {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            }).catch((error) => {
                toast.error("Something went wrong");
            });
            setItemLoadingStatus(true);
            return response.json();
        };
        //to aviod flickering
        const delayCall = setTimeout(() => {
            fetchSearchData(searchUrl, { query: queryName }).then((data) => {
                setSearchData(data);
                setItemLoadingStatus(false);
            });
        }, 500);

        return () => clearTimeout(delayCall);
    }, [queryName]);

    const gotoSlugDetailsPage = (type, slug) => {
        navigate(`/${type}s/${slug}`);
    };

    return (
        <>
            <div className="g-ml-15">
                <Link
                    to="#"
                    className="g-font-size-18 g-color-white"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-controls={`searchform-1_${device}`}
                    data-dropdown-target={`#searchform-1_${device}`}
                    data-dropdown-type="css-animation"
                    data-dropdown-duration="300"
                    data-dropdown-animation-in="fadeInUp"
                    data-dropdown-animation-out="fadeOutDown"
                    onClick={toggleSideCart}
                >
                    <img
                        className="img-fluid g-width-20 g-height-20"
                        src="/assets/images/header/search.svg"
                        alt=" description"
                    />
                </Link>

                <div
                    className={
                        "search_overlay " + (sidecartActive ? "active" : "")
                    }
                    onClick={toggleSideCart}
                />
                <div
                    className={
                        "search_sidecart " + (sidecartActive ? "active" : "")
                    }
                >
                    <div className="sidecart_header text-center py-3 homni-container">
                        <img
                            className="g-width-20 g-height-20 g-mt-10 g-mr-10 position-absolute d-flex align-items-center g-top-7 g-right-30"
                            src="/assets/images/cross.png"
                            alt=" description"
                            onClick={toggleSideCart}
                        />
                        <div className="row align-items-center">
                            <div className="col-12 g-mt-10">
                                <Form
                                    className="d-flex justify-content-center position-relative"
                                    onSubmit={(e) => e.preventDefault()}
                                >
                                    <FormControl
                                        type="search"
                                        placeholder="Search"
                                        className="me-2 g-pa-10"
                                        aria-label="Search"
                                        value={queryName}
                                        onChange={(e) =>
                                            setQueryName(e.target.value)
                                        }
                                    />
                                    <img
                                        className="img-fluid g-width-25 g-height-25 g-mr-10 position-absolute d-flex align-items-center g-top-7"
                                        src="/assets/images/header/searchblack.svg"
                                        alt=" description"
                                        style={{ cursor: "pointer" }}
                                    />
                                    <div
                                        className="search_populate_dropdown hideScrollBar"
                                        onClick={toggleSideCart}
                                        style={{
                                            padding:
                                                searchData?.length > 0
                                                    ? "16px"
                                                    : 0,
                                            maxHeight: "50vh",
                                            overflowY: "scroll"
                                        }}
                                    >
                                        {!itemLoadingStatus ? (
                                            <>
                                                {searchData?.length > 0
                                                    ? searchData.map(
                                                        (searchItem) => (
                                                            <div
                                                                key={
                                                                    "search" +
                                                                    searchItem.id
                                                                }
                                                                className="search_item_wrapper d-flex g-mb-20 "
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                    cursor: "pointer"
                                                                }}
                                                                onClick={() =>
                                                                    gotoSlugDetailsPage(
                                                                        searchItem.type,
                                                                        searchItem.slug
                                                                    )
                                                                }
                                                            >
                                                                <img
                                                                    className="img-fluid"
                                                                    src={get_optimized_image_url(
                                                                        searchItem.image
                                                                    )}
                                                                    alt=""
                                                                />
                                                                <p
                                                                    style={{
                                                                        margin: "0px 10px"
                                                                    }}
                                                                >
                                                                    {
                                                                        searchItem.name
                                                                    }
                                                                </p>
                                                            </div>
                                                        )
                                                    )
                                                    : !!queryName &&
                                                    queryName.length > 1 && (
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                padding: 16
                                                            }}
                                                        >
                                                            Sorry searched
                                                            item was not found
                                                        </p>
                                                    )}
                                            </>
                                        ) : (
                                            <p> Loading...</p>
                                        )}
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HeaderSearch;
