import HeadingCenter from "../components/page_headings/HeadingCenter";
import Container from "../components/container/Container";
import Loading from "../components/Loading";
import { useState, useEffect } from "react";
import ProductRecipe from "../components/product_detail/ProductRecipe";
import { get_optimized_image_url } from "../helper/Helper";
import { Link } from "react-router-dom";
import HtmlParser from "react-html-parser";
import ContainerWithGap from "../components/container/ContainerWithGap";
import ReactPaginate from "react-paginate";
import { Dropdown } from "react-bootstrap";

const RecipeListingPage = () => {
    const [categories, setCategories] = useState([]);
    const [recipes, setRecipes] = useState([]);
    const [totalPages, setTotalPages] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [offset, setOffset] = useState(1);
    const [activeCategory, setActiveCategory] = useState("All");
    const [activeCategorySlug, setActiveCategorySlug] = useState("");

    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        // this url with change based on pagination and sorting option
        let url = process.env.REACT_APP_API_BASE_URL + `/recipe/?page=${offset}&page_size=12&recipe_category__slug=${activeCategorySlug}`;
        const categoryUrl = process.env.REACT_APP_API_BASE_URL + `/recipe-category/`;
        fetch(url)
            .then((response) => response.json())
            .then((result) => {
                setRecipes(result.results);
                setTotalPages(result.total_pages);
                setIsLoading(false);
            })
            .catch((error) => { });

        fetch(categoryUrl)
            .then((response) => response.json())
            .then((data) => {
                setCategories(data.results);
            })
            .catch((error) => { });
    }, [offset, activeCategorySlug]);

    const handleCategoryClick = (name, slug) => {
        setOffset(1);
        setActiveCategory(name);
        setActiveCategorySlug(slug);
    };
    const handlePageClick = (event) => {
        window.scrollTo(0, 0);
        const newOffset = event.selected + 1;
        setOffset(newOffset);
    };

    if (isLoading) {
        return <Loading />;
    }
    return (
        <>
            <HeadingCenter
                title="Recipes"
                background="/assets/removable_images/product_banner.jpeg"
            />
            <ContainerWithGap>
                <div className="row">
                    <div className="col-lg-2">
                        <div className="g-pt-40 recipe-menu-category">
                            <h2 className="h6 border-bottom pb-4">
                                Categories
                            </h2>
                            {categories.length > 0 && (
                                <>
                                    <ul className="m-0 p-0 text-light g-mt-30">
                                        <li
                                            className="g-mb-10 g-font-weight-500"
                                            style={{
                                                cursor: "pointer",
                                                color: `${activeCategory === "All"
                                                    ? "green"
                                                    : "black"
                                                    }`
                                            }}
                                            onClick={() =>
                                                handleCategoryClick("All", "")
                                            }
                                        >
                                            All
                                        </li>
                                        {categories.map((categoryItems) => (
                                            <li
                                                className="g-mb-10 g-font-weight-500"
                                                style={{
                                                    cursor: "pointer",
                                                    color: `${activeCategory ===
                                                        categoryItems.name
                                                        ? "green"
                                                        : "black"
                                                        }`
                                                }}
                                                onClick={() =>
                                                    handleCategoryClick(
                                                        categoryItems.name,
                                                        categoryItems.slug
                                                    )
                                                }
                                            >
                                                {categoryItems.name}
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            )}
                        </div>
                        <div className="d-lg-none d-flex justify-content-end">
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    Categories
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {categories.length > 0 && (
                                        <>
                                            <ul className="g-pa-20 text-light">
                                                <li
                                                    className="g-mb-10 g-font-weight-500"
                                                    style={{
                                                        cursor: "pointer",
                                                        color: `${activeCategory === "All"
                                                            ? "green"
                                                            : "black"
                                                            }`
                                                    }}
                                                    onClick={() =>
                                                        handleCategoryClick("All", "")
                                                    }
                                                >
                                                    All
                                                </li>
                                                {categories.map((categoryItems) => (
                                                    <li
                                                        className="g-mb-10 g-font-weight-500"
                                                        style={{
                                                            cursor: "pointer",
                                                            color: `${activeCategory ===
                                                                categoryItems.name
                                                                ? "green"
                                                                : "black"
                                                                }`
                                                        }}
                                                        onClick={() =>
                                                            handleCategoryClick(
                                                                categoryItems.name,
                                                                categoryItems.slug
                                                            )
                                                        }
                                                    >
                                                        {categoryItems.name}
                                                    </li>
                                                ))}
                                            </ul>
                                        </>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="col-lg-10">
                        <div className="row g-pt-90">
                            {
                                recipes.length > 0 &&
                                recipes.map(item =>
                                    <div className="col-md-6 col-lg-4 g-px-10 g-mb-15">
                                        <div className="recipe-wrapper">
                                            <img className="w-100 h-100 u-block-hover__main--zoom-v1 g-mb-minus-8" src={get_optimized_image_url(item.photo, 'square')} alt="Recipe" />
                                            <div className="recipe-showcase g-pos-abs">
                                                <h4 className="mb-20 g-color-white"><Link className="g-color-white h4" to={"/recipes/" + item.slug}>{item.title}</Link></h4>
                                                <p className="d-block g-color-white g-mb-0 recipe-author">{item.author ?? ''}</p>
                                                <p className="recipe-showcase-description py-2 g-color-white">
                                                    {HtmlParser(item.description)}
                                                </p>
                                                <Link className="btn btn-md u-btn-outline-white g-mb-20 g-mr-10 g-mb-1 g-font-weight-600 g-font-size-12 text-uppercase g-px-5 g-py-5"
                                                    to={"/recipes/" + item.slug}>
                                                    View Recipe
                                                </Link>
                                            </div>
                                        </div>


                                    </div>
                                )
                            }
                        </div>
                        <div>
                            {totalPages > 1 && (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        width: "100%",
                                        marginBottom: "25px"
                                    }}
                                >
                                    <ReactPaginate
                                        nextLabel="Next"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                        pageCount={totalPages}
                                        previousLabel="Previous"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        renderOnZeroPageCount={null}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </ContainerWithGap>
        </>
    )
}
export default RecipeListingPage;