import { useState } from "react";
import { toast } from "react-toastify";

const Newsletter = () => {

	const [subscriptionEmail, setSubscriptionEmail] = useState();
	const [isEmailVerified, setIsEmailVerified] = useState(false);

	const url = process.env.REACT_APP_API_BASE_URL + '/newsletter/';

	const setEmail = (email) => {
		setSubscriptionEmail(email);
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
			setIsEmailVerified(true);
		} else {
			setIsEmailVerified(false);
		}
	}

	const subscribeNewsletter = () => {
		const fetchOptions = {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				email: subscriptionEmail
			})
		}
		fetch(url, fetchOptions)
			.then(response => {
				if (response.ok) {
					return response.json();
				} else {
					throw Error('error');
				}
			})
			.then(result => {
				toast.success('Newsletter subscribed successfully');
				setSubscriptionEmail('');
				setIsEmailVerified(false);
			})
			.catch(error => {
				toast.error('Already subscribed with this email');
			})
	}

	return (
		<div className="col-12 col-lg-4 newsletter-wrapper">
			<h3 className="mb-3 text-uppercase g-font-weight-600">spice up your inbox</h3>
			<p className="mb-3">sign up for exclusive promotions, zero waste tips, recipes and more.</p>

			{/* <!-- Subscribe Form --> */}
			<form className="input-group u-shadow-v19">
				<input className="form-control g-brd-none g-color-gray-dark-v5 g-bg-main-dark-v2 g-bg-main-light-v2--focus g-placeholder-gray-dark-v3 g-px-20 g-py-8" type="email" placeholder="Enter your email" value={subscriptionEmail} onChange={(e) => setEmail(e.target.value)} />
				<span className="input-group-addon u-shadow-v19 g-brd-none">
					<button className="btn u-btn-primary text-uppercase g-py-8 g-px-18" type="button" disabled={!isEmailVerified} onClick={subscribeNewsletter}>
						Sign up
					</button>
				</span>
			</form>
			{/* <!-- End Subscribe Form --> */}
		</div>
	);
}

export default Newsletter;