/* eslint import/no-webpack-loader-syntax: off */
import mapboxgl from "!mapbox-gl";
import ReactDOM from "react-dom";

import { useEffect, useRef, useState } from "react";
import Image from "../../assets/img/black.png";
const Map = (props) => {
    const { geoJsonData } = props;
    mapboxgl.accessToken = `${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`;
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [center, setCenter] = useState([84.2676, 28.5465]);
    const [zoom, setZoom] = useState(6.1);
    const nepalBbox = [
        [80.05858661752784, 26.347836996368667],
        [88.20166918432409, 30.44702867091792]
    ];

    const CreatePopup = ({ name, adminArea }) => {
        return (
            <div className="mapPopUp">
                <p style={{ fontSize: 15 }} className="productHeaderMap">
                    {name}
                </p>
                <p className="mapDetails">Province : {adminArea[0]}</p>
                <p className="mapDetails">District : {adminArea[1]}</p>
                <p className="mapDetails">Municipality : {adminArea[2]}</p>
                <p className="mapDetails">Ward : {adminArea[3]}</p>
            </div>
        );
    };

    const mainImage = geoJsonData.features.map((item) => ({
        name: `${item.properties.id}`,
        url: item.properties.point_image
    }));

    const images = [
        {
            name: "120",
            url: Image
        }
    ];

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: process.env.REACT_APP_MAP_STYLE,
            center: center,
            zoom: zoom,
            interactive: false
        });

        map.current.on("resize", () => {
            map.current.fitBounds(nepalBbox, {
                padding: 20
            });
        });

        map.current.on("load", () => {
            mainImage.forEach((img) => {
                map.current.loadImage(img.url, (error, image) => {
                    if (error) throw error;
                    map.current.addImage(img.name, image);
                    map.current.addSource("projects", {
                        type: "geojson",
                        data: geoJsonData
                    });

                    map.current.addLayer({
                        id: `product_image`,
                        type: "symbol",
                        source: "projects",
                        layout: {
                            "icon-image": ["get", "id"],
                            "icon-size": 0.06,
                            "icon-anchor": "bottom",
                            visibility: "visible"
                        }
                    });
                });
            });

            map.current.fitBounds(nepalBbox, {
                padding: 20
            });
        });

        map.current.on("click", "product_image", (e) => {
            // Copy coordinates array.
            const coordinates = e.features[0].geometry.coordinates.slice();

            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            const popupNode = document.createElement("span");

            ReactDOM.render(
                <CreatePopup
                    point={e.features[0]}
                    name={e.features[0].properties.name}
                    adminArea={[
                        e.features[0].properties.province,
                        e.features[0].properties.district,
                        e.features[0].properties.municipality,
                        e.features[0].properties.ward
                    ]}
                />,
                popupNode
            );
            new mapboxgl.Popup()
                .setLngLat(coordinates)
                .setDOMContent(popupNode)
                .addTo(map.current);
        });
    }, []);

    return (
        <>
            <div
                ref={mapContainer}
                style={{
                    position: "relative",
                    width: "100%"
                }}
                className="mapContainerMain"
            />
        </>
    );
};
export default Map;
