import React, { useState, useCallback, useEffect } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import ContainerWithGap from "../components/container/ContainerWithGap";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";

const GrowerGallery = () => {
    const [photos, setPhotos] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [newPhotos, setNewPhotos] = useState([]);
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [categoryName, setCategoryName] = useState();
    const [offset, setOffset] = useState(1);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };
    const category = useParams().category;
    useEffect(() => {
        let user_image_url =
            process.env.REACT_APP_API_BASE_URL +
            `/user-images/?of_recipe__recipe_category__slug=${category}&expand=of_recipe&page=${offset}`;
        fetch(user_image_url)
            .then((response) => response.json())
            .then((result) => {
                setPhotos(result.results);
                setTotalPages(result.total_pages);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
            });
    }, [category, offset]);

    useEffect(() => {
        const newPhoto = photos.map((item) => {
            return {
                ...item,
                src: item.picture,
                slug: item.of_recipe.slug,
                width: Math.floor(Math.random() * 3) + 1,
                height: Math.floor(Math.random() * 2) + 1
            };
        });
        setNewPhotos(newPhoto);
        setCategoryName(
            photos && photos.length > 0 && photos[0].of_recipe.recipe_category
        );
    }, [photos]);

    // useEffect(()=>{
    //     console.log("photo",newPhotos);
    // },[newPhotos]);

    const handlePageClick = (event) => {
        const newOffset = event.selected + 1;
        setOffset(newOffset);
    };

    const FooterCaption = ({ isModal, currentIndex }) =>
        isModal ? (
            <div className="d-flex justify-content-center">
                <div className="grower-gallery-text">
                    {newPhotos[currentIndex].full_name && (
                        <h5 className="text-center g-mt-15">{`Photo by: ${newPhotos[currentIndex].full_name}`}</h5>
                    )}
                    <a
                        className="text-center g-mt-10 btn btn-md u-btn-outline-white text-center g-font-weight-600 g-font-size-12 text-uppercase g-px-15 g-py-8"
                        href={`/recipes/${newPhotos[currentIndex].slug}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        View Recipe
                    </a>
                </div>
            </div>
        ) : null;

    return (
        <>
            <ContainerWithGap>
                <div className="breadcrumbs">
                    <section className="g-brd-top g-py-50">
                        <div className=" g-bg-cover__inner">
                            <ul className="u-list-inline">
                                <li className="list-inline-item g-mr-7">
                                    <a
                                        className="u-link-v5 g-color-main"
                                        href="#"
                                    >
                                        Home
                                    </a>
                                    <i className="g-color-gray-light-v2 g-ml-5">
                                        /
                                    </i>
                                </li>
                                <li className="list-inline-item g-mr-7">
                                    <a
                                        className="u-link-v5 g-color-main"
                                        href="#"
                                    >
                                        Pages
                                    </a>
                                    <i className="g-color-gray-light-v2 g-ml-5">
                                        /
                                    </i>
                                </li>
                                <li className="list-inline-item g-color-primary">
                                    <span>About Us</span>
                                </li>
                            </ul>
                        </div>
                    </section>
                </div>
                <div className="about-photo-gallery g-mb-100">
                    <div className="g-mb-50">
                        <div className="u-heading-v5-3 u-heading-v5-color-primary u-heading-v5-rounded-50x g-mb-20">
                            <h2 className="g-line-height-1_2 g-font-weight-700 g-font-size-28 g-font-size-40--md  g-bg-white--before text-uppercase">
                                {categoryName && categoryName}
                            </h2>
                            <span>
                                These are the delicious delicacy prepared by our{" "}
                                <br /> customers, inspired by our recipe
                            </span>
                        </div>
                    </div>
                    <Gallery photos={newPhotos} onClick={openLightbox} />
                    <ModalGateway>
                        {viewerIsOpen ? (
                            <Modal onClose={closeLightbox}>
                                <Carousel
                                    // styles={customStyles}
                                    components={{ Footer: FooterCaption }}
                                    currentIndex={currentImage}
                                    views={newPhotos.map((x) => ({
                                        ...x
                                    }))}
                                />
                            </Modal>
                        ) : null}
                    </ModalGateway>
                    {totalPages > 1 && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                                marginBottom: "25px",
                                marginTop: "25px"
                            }}
                        >
                            <ReactPaginate
                                nextLabel="Next"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                pageCount={totalPages}
                                previousLabel="Previous"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    )}
                </div>
            </ContainerWithGap>
        </>
    );
};

export default GrowerGallery;
