const SectionHeaderCenter = ({ header_title, header_description }) => {
	return (
		<div className="text-center g-my-50">
			<h3 className="text-uppercase">
				{header_title}
			</h3>
			<p> {header_description} </p>
		</div>
	);
}

export default SectionHeaderCenter;